import { Color3, Color4, DynamicTexture, Mesh, MeshBuilder, StandardMaterial, Vector3 } from "@babylonjs/core";
import parseCoordinateString from "../helpers/parseCoordinateString";
import { ConnectedObjectData, DirectionSettings, EnergyTransferAnimation, ExistingObject, GridCellSettings, MomentumSettings, PotentialDirectionSettings, Task } from "../types";
import Direction from "./Direction";
import GameOfExistence from "./GameOfExistence";
import GridCell from "./GridCell";
import SpatialAction from "./SpatialAction";
import convertDirectionArrayToString from "../helpers/convertDirectionArrayToString";
import { DIRECTION_HOVER_HEIGHT, MINIMUM_DISTANCE, SPEED_OF_LIGHT, momentumTypes } from "../constants";
import wrapPosition from "../helpers/wrapPosition";
import makeDebugBox from "../helpers/makeDebugBox";
import isSourceAndTargetIdentical from "../helpers/isSourceAndTargetIdentical";
import changeBoxColor from "../helpers/changeBoxColor";
import PotentialObject from "./PotentialObject";
import calculateDirectionPosition from "../helpers/calculateDirectionPosition";
import calculateDistanceBetweenPoints from "../helpers/calculateDistanceBetweenPoints";
import isLessThanMinimumDistance from "../helpers/isLessThanMinimumDistance";

function delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const displayNumber = (parent: ExistingObject, value: number)=>{
    const { scene } = parent.GOE;
    const width = 0.075; 
    const height = 0.075;

    // console.log(`Creating displayer for ${parent.id}`)
    // console.log(`Position (${parent.position.x}, ${parent.position.z})`)
    // if(`(${parent.position.x}, ${parent.position.z})` === '(0, 0.25)') return null;

    const displayer = MeshBuilder.CreatePlane(`${parent.id}-plane-number`, { width, height }, scene);

    const dynamicTexture = new DynamicTexture("dynamicTexture", 512, scene, true);
    const parentPosition = parent.getPosition();
    displayer.position = new Vector3(parentPosition.x, DIRECTION_HOVER_HEIGHT, parentPosition.z);
    displayer.billboardMode = Mesh.BILLBOARDMODE_ALL;
    var material = new StandardMaterial("mat", scene);
    displayer.material = material;
    material.diffuseTexture = dynamicTexture;
    material.diffuseTexture.hasAlpha = true;
    material.useAlphaFromDiffuseTexture
    material.specularColor = new Color3(0, 0, 0);
    material.emissiveColor = new Color3(1, 1, 1);
    material.backFaceCulling = false;
    dynamicTexture.drawText(value.toString(), null, null, "450px verdana", "white", null);
    dynamicTexture.update();
    return displayer;
}

// const findUniqueNodesAndEdges=(pulledObject: ExistingObject): ConnectedObjectData=>{
//         const visitedNodes = new Set<string>(); // To keep track of visited nodes
//         const visitedEdges = new Set<string>(); // To keep track of visited edges
//         const queue: ExistingObject[] = [pulledObject]; // BFS queue initialized with the start node
//         const spatialNodes: GridCell[] = [];
//         const causalNodes: Direction[] = [];
//         const edges: Direction[] = []; // Store unique edges

//         while (queue.length > 0) {
//             const currentNode = queue.shift();
//             if (!currentNode){
//                 console.log("Skipping undefined node");
//                 continue;
//             }; // Skip if currentNode is undefined

//             if (!visitedNodes.has(currentNode.id)) {
//                 //Add node to visited.
//                 visitedNodes.add(currentNode.id);

//                 //Capturing spatial nodes
//                 if (currentNode instanceof GridCell) {
//                     spatialNodes.push(currentNode);
//                 }
//                 //Capture 
//                 if(currentNode instanceof Direction){
//                     const isBalanced = currentNode.areForcesBalanced();
//                     if(isBalanced) return;
//                     // isBalanced ? edges.push(currentNode) : causalNodes.push(currentNode);
//                     // // const oppositeDirection = currentNode.getOppositeDirectionObject();
//                     // // if(!visitedNodes.has(oppositeDirection.id)){
//                     // //     queue.push(oppositeDirection);
//                     // // }

//                     // const target = currentNode.target;
//                     // if(!(target instanceof GridCell)) return;
//                     // // spatialNodes.push(target)
//                     // queue.push(target);
//                 }
           

//                 // Iterate over the "correlating with" edges of the current node
//                 // console.log(`Current node ${currentNode.id} correlating with: `, currentNode.correlatingWith);
//                 if(!currentNode.correlatingWith.length) continue;
//                 currentNode.correlatingWith.forEach((edgeId: string) => {
//                     const direction = this.GOE.causalField.getDirectionById(edgeId);
//                     if (!direction || !direction.target) return;

//                     const receiverNode = direction.source;

//                     if (!visitedNodes.has(receiverNode.id)) {
//                         queue.push(receiverNode);
//                     }

//                     if (direction && !visitedEdges.has(direction.id)) {
//                         edges.push(direction);
//                         visitedEdges.add(direction.id);
//                     }
//                 });
//             }
//         }

//         return { spatialNodes, causalNodes, edges };
// }

// private findUniqueNodesAndEdges(pulledObject: ExistingObject): ConnectedObjectData {
//     const visitedNodes = new Set<string>(); // To keep track of visited nodes
//     const visitedEdges = new Set<string>(); // To keep track of visited edges
//     const queue: ExistingObject[] = [pulledObject]; // BFS queue initialized with the start node
//     const spatialNodes: GridCell[] = [];
//     const causalNodes: Direction[] = [];
//     const edges: Direction[] = []; // Store unique edges

//     while (queue.length > 0) {
//         const currentNode = queue.shift();
//         if (!currentNode){
//             console.log("Skipping undefined node");
//             continue;
//         }; // Skip if currentNode is undefined

//         if (!visitedNodes.has(currentNode.id)) {
//             //Add node to visited.
//             visitedNodes.add(currentNode.id);

//             //Capturing spatial nodes
//             if (currentNode instanceof GridCell) {
//                 spatialNodes.push(currentNode);
//             }
//             //Capture 
//             if (currentNode instanceof Direction) {
//                 if(currentNode.areForcesBalanced()) return;//Not storing edges here
//                 causalNodes.push(currentNode);
//                 const target = currentNode.target;
//                 const oppositeDirection = currentNode.getOppositeDirectionObject();
//                 queue.push(oppositeDirection);
//                 if(!(target instanceof GridCell)) return;
//                 // spatialNodes.push(target)
//                 queue.push(target);
//             }

//             // Iterate over the "correlating with" edges of the current node
//             // console.log(`Current node ${currentNode.id} correlating with: `, currentNode.correlatingWith);
//             currentNode.correlatingWith.forEach((edgeId: string) => {
//                 const direction = this.GOE.causalField.getDirectionById(edgeId);
//                 if (!direction || !direction.target){
//                     // console.log("Direction or target was undefined")
//                     // console.log("Edge Id: ", edgeId);
//                     // console.log("Direction: ", direction);
//                     return; // Handle undefined direction or target
//                 }

//                 const receiverNode = direction.source;

//                 // If the receiver node has not been visited, add it to the queue
//                 if (!visitedNodes.has(receiverNode.id)) {
//                     queue.push(receiverNode);
//                 }

//                 // Add the edge and its opposite to the visited edges set
//                 // const oppositeDirection = direction.getOppositeDirectionObject();
//                 if (direction && !visitedEdges.has(direction.id)) {
//                     edges.push(direction);
//                     visitedEdges.add(direction.id);
//                 }
//                 // if (oppositeDirection && !visitedEdges.has(oppositeDirection.id)) {
//                 //     edges.push(oppositeDirection);
//                 //     queue.push(oppositeDirection);
//                 // }
//             });
//         }
//     }

//     return { spatialNodes, causalNodes, edges };
// }

var i = 0;

class Momentum {
    GOE: GameOfExistence
    constructor(GOE: GameOfExistence){
        this.GOE = GOE;
    }

    private findUniqueNodesAndEdgesWorkingButSusClean=(pulledObject: ExistingObject): ConnectedObjectData=> {
        const visitedNodes = new Set<string>(); // To keep track of visited nodes
        const visitedEdges = new Set<string>(); // To keep track of visited edges
        const queue: ExistingObject[] = [pulledObject]; // BFS queue initialized with the start node
        const spatialNodes: GridCell[] = [];
        const causalNodes: Direction[] = [];
        const edges: Direction[] = []; // Store unique edges

        const startNode = pulledObject;


        let count = 2;
        while (queue.length > 0) {
            const currentNode = queue.shift();
            if (!currentNode){
                console.log("Skipping undefined node");
                continue;
            }; // Skip if currentNode is undefined

            if (!visitedNodes.has(currentNode.id)) {
                //Add node to visited.
                visitedNodes.add(currentNode.id);

                if (currentNode instanceof GridCell) {
                    spatialNodes.push(currentNode);
                }
                //Capture 
                if (currentNode instanceof Direction && !currentNode.areForcesBalanced()) {
                    //OG:
                    causalNodes.push(currentNode);
                    const oppositeDirection = currentNode.getOppositeDirectionObject();
                    queue.push(oppositeDirection);
                    // console.log(`Direction ${currentNode.id} adding direction target to queue`);
                    //This doesn't seem to be necessary at all.
                    // throw new Error("WHOA I GOT CALLED")
                    // spatialNodes.push(target)
                    const target = currentNode.target;
                    if(target instanceof Direction) return;
                    if(!visitedNodes.has(target.id)){
                        queue.push(target);
                    }
                    // queue.push(target);
                }
                if(currentNode instanceof Direction && currentNode.areForcesBalanced()){
                    if (visitedEdges.has(currentNode.id)) {
                        edges.push(currentNode);
                        visitedEdges.add(currentNode.id);
                    }
                    const target = currentNode.target;
                    if(!visitedNodes.has(target.id)){
                        queue.push(target);
                    }
                }

                // Iterate over the "correlating with" edges of the current node
                // console.log(`Current node ${currentNode.id} correlating with: `, currentNode.correlatingWith);
                currentNode.correlatingWith.forEach((edgeId: string) => {
                    const direction = this.GOE.causalField.getDirectionById(edgeId);
                    if (!direction || !direction.target) return;

                    const receiverNode = direction.source;
                    // If the receiver node has not been visited, add it to the queue
                    if (!visitedNodes.has(receiverNode.id)) {
                        queue.push(receiverNode);
                    }

                    if (direction && !visitedEdges.has(direction.id)) {
                        edges.push(direction);
                        visitedEdges.add(direction.id);
                    }

                    if(direction.correlatingWith.length){
                        // console.log(`Direction ${direction.id} is a correlator that is being correlated`);
                        direction.correlatingWith.forEach((edgeId: string) => {
                            const direction = this.GOE.causalField.getDirectionById(edgeId);
                            if (!direction || !direction.target || !direction.source) return;
                            if(visitedNodes.has(direction.id)) return;
                            queue.push(direction);
                        });

                    }

                });
            }
        }

        return { spatialNodes, causalNodes, edges };
    }
    
    private getNewDirectionArray = (directionArray: string[], mover: Direction, units: number)=>{
        const newDirectionSourceIndex = this.getNewDirectionIndex(directionArray[0], mover, units);
        const newDirectionTargetIndex = this.getNewDirectionIndex(directionArray[1], mover, units);
        return [newDirectionSourceIndex, newDirectionTargetIndex];
    }
    private getNewDirectionIndex = (directionIndex: string, mover: Direction, units: number)=>{
        // console.log("Direction Index: ", directionIndex);
        const vectorsInIndex = parseCoordinateString(directionIndex);
        // console.log("Vectors in index: ", vectorsInIndex);
        const shiftedVectorsInIndex = vectorsInIndex.map((vector: Vector3)=>{
            const shiftedPosition = mover.positionManager.getShiftedPositionByUnits(vector, units);
            return shiftedPosition
            // const diffVector = shiftedPosition.subtract(vector);
            // return wrapPosition(shiftedPosition, diffVector, this.GOE.grid);

        });
        // console.log("Vectors in index: ", vectorsInIndex);
        const newDirectionIndex: string = shiftedVectorsInIndex.reduce((a: any, b: any)=>{
            return `${a}(${b.x},${b.z})-`
        }, "").slice(0,-1);
        // console.log("New Direction Index: ", newDirectionIndex);
        // throw new Error("Just because yo")
        return newDirectionIndex
    }

    private getNewDataForObjects=(objArray: ExistingObject[], mover: Direction, units: number)=>{
        return objArray.map((obj)=>{
            const newPosition = mover.positionManager.getShiftedPositionOfObjByUnits(obj, units);
            
            // const diffVector = shiftedPosition.subtract(obj.position);
            // const newPosition = wrapPosition(shiftedPosition, diffVector, this.GOE.grid);

            const newDirectionArray = obj instanceof Direction ? this.getNewDirectionArray(obj.getDirectionArray(), mover, units) : null;
            const newDirectionIndex = newDirectionArray ? convertDirectionArrayToString(newDirectionArray) : null;
            const newId = newDirectionIndex ? newDirectionIndex : `(${newPosition.x},${newPosition.z})`;
            if(newDirectionArray && (newDirectionArray[0] === newDirectionArray[1])){
                if(!(obj instanceof Direction)) return null;
                console.log("Mover: ", mover);
                console.log("New Direction Array: ", newDirectionArray);    
                console.log("Old direction array: ", obj.getDirectionArray());
                console.warn("Tried to create direction with same source");
                // throw new Error("Tried to create direction with same source");
                return null;
            }
            //Every object needs something called "reset properties";
            // const newDirectionArray = obj instanceof Direction ? 
            
            return{
                oldId: obj.id,
                id: newId,
                directionArray: newDirectionArray,
                directionIndex: newDirectionIndex,
                energyLevel: obj.getEnergyLevel(),
                position: newPosition,
            }
        })
    }

    private createNewObjectsOnMovement=(obj: ExistingObject, newData: any, mover: Direction): Task=>{
        if(obj instanceof GridCell) return this.createNewGridCellOnMovement(obj, newData, mover);
        if(obj instanceof Direction) return this.createNewDirectionOnMovement(obj, newData, mover);
    }

    private createNewGridCellOnMovement=(obj: GridCell, newData: any, mover: Direction): Task=>{
        const { position } = newData;

        // console.log("Old Id: ", obj.id);
        // console.log("Stored Old Id: ", newData.oldId);
        // console.log("Attempting to create grid cell with Id: ", newData.id);
        const isExistingGridCell = this.GOE.grid.cellData[newData.id];
        if(isExistingGridCell){
            // console.log("Grid cell already exists");
            // console.log(`Grid cell ${newData.id} already exists!: `, isExistingGridCell);
            // console.log("Creaitng animation settings: ")
            const objEnergy = obj.getEnergyLevel();
            if(objEnergy === 0){
                // console.log(`Prev Grid cell ${obj.id} had no energy, returning`)
                return null;
            }
            //Send energy to that existing grid cell.
            const animationSettings: Task = {
                caller: `${mover.id}`,
                source: obj.getPotentialObjectSettings(),
                target: isExistingGridCell.getPotentialObjectSettings(),
                energyToTransfer: objEnergy,
                type: momentumTypes[1],
            }
            // this.GOE.particleVisuals.startEnergyTransferAnimation(animationSettings);
            // return null;
            return animationSettings;
        }

        const result = this.isPointInSubspace(position);
        if(!result){
            // console.log(`New Grid cell ${newData.id} would be out of bounds on movement`);
            // console.log("Position: ", position);
            return;
        }

        // console.log(`Would create new grid cell ${newData.id}`);
        // console.log(`Grid cell ${newData.id} already exists!: `, isExistingGridCell);

        
        // TODO: AddNewGridCell function
        // const gridCell = new GridCell({
        //     GOE: this.GOE,
        //     index: newData.id,
        //     position: position,
        //     initEnergyLevel: 0
        // });
        // console.log(`Creating new grid cell ${newData.id}: `, gridCell);
        // gridCell.renderGridCell();
        // this.GOE.grid.cellData[newData.id] = gridCell;

        const objEnergy = obj.getEnergyLevel();
        if(objEnergy === 0){
            // console.log(`Original Grid cell ${obj.id} had no energy, returning`)
            return null;
        }
        //Send energy from old cell to new cell
        const animationSettings: Task = {
            caller: `${mover.id}`,
            source: obj.getPotentialObjectSettings(),
            target: {
                type: "GridCell",
                settings: {
                    index: newData.id,
                    position: position,
                    initEnergyLevel: 0
                }
            },
            energyToTransfer: objEnergy,
            type:  momentumTypes[0],
        }
        // this.GOE.particleVisuals.startEnergyTransferAnimation(animationSettings);
        // return null;
        return animationSettings;

        //If new subspace then new neighbors too!
    }  

    // oldId: obj.id,

    // id: newId,
    // directionArray: newDirectionArray,
    // directionIndex: newDirectionIndex,
    // energyLevel: obj.getEnergyLevel(),
    // position: newPosition,

    private createNewDirectionOnMovement=(obj: Direction, newData: any, mover: Direction)=>{
        const { position, directionArray } = newData;
        const isExistingDirection = this.GOE.causalField.getDirection(newData.directionArray);
        // console.log("Old Id: ", obj.id);
        // console.log("Stored Old Id: ", newData.oldId);
        
        // console.log(`Moving direction ${obj.id}`)
        // console.log("New Id: ", newData.id);
        if(isExistingDirection){
            const objEnergy = obj.getEnergyLevel();
            if(objEnergy === 0){
                // console.log(`Obj: ${obj.id} had no energy: ${objEnergy}`);
                // console.log("Request canceled");
                return;
            };
            //Send energy to that existing direction.
            const animationSettings: Task = {
                caller: `${mover.id}`,
                source: obj.getPotentialObjectSettings(),
                target: isExistingDirection.getPotentialObjectSettings(),
                energyToTransfer: objEnergy,
                type: momentumTypes[4],
            }
            // console.log("Moving energy to existing direction");
            // console.log("Direction: ", isExistingDirection);
            // this.GOE.particleVisuals.startEnergyTransferAnimation(animationSettings);
            return animationSettings;
        }

        const isDirectionInBounds = this.isPointInSubspace(position);
        if(!isDirectionInBounds){
            // console.log(`New direction ${newData.id} would be out of bounds on movement`);
            // console.log("Position: ", position);
            return;
        }
        // const directionSource = this.GOE.getObjectById(directionArray[0]);
        // const directionTarget = this.GOE.getObjectById(directionArray[1]);
        // if(!directionSource){
        //     console.log(`Source of direction ${newData.id} doesn't exist yet failed to move`);
        //     console.log("SourceId: ", directionArray[0]);
        //     return;
        // }
        // if(!directionTarget){
        //     console.log(`Target of direction ${newData.id} doesn't exist yet failed to move`);
        //     console.log("TargetId: ", directionArray[1]);
        //     return;
        // }
        // // console.log("Checking if target is in bounds of direction: ", newData.id);
        // const isTargetInBounds = this.isPointInSubspace(directionTarget.position);
        // if(!isTargetInBounds){
        //     // console.log(`Target of new direction ${newData.id} would be out of bounds on movement`);
        //     // console.log("Position: ", directionTarget.position);
        //     return;
        // }
        // const isSourceInBounds = this.isPointInSubspace(directionSource.position);
        // if(!isSourceInBounds){
        //     // console.log(`Target of new direction ${newData.id} would be out of bounds on movement`);
        //     // console.log("Position: ", directionSource.position);
        //     return;
        // }

        // console.log(`Creating new direction with array on movement: `, directionArray);
        const isIdentical = isSourceAndTargetIdentical(directionArray);
        if(isIdentical) throw new Error("createNewDirectionOnMovement - Source and target identical")

        // const newDirection = this.GOE.causalField.getOrCreateDirection(directionArray);
        // console.log("Old Direction: ", obj);
        // console.log("New Direction: ", newDirection);
        const amountToMove = obj.getEnergyLevel();
        if(amountToMove === 0){
            // console.log("Obj had no energy! Request canceled");
            return;
        }
        // console.log("Amount to move: ", amountToMove)
        //Send energy from old cell to new cell
        const animationSettings: Task = {
            caller: `${mover.id}`,
            source: obj.getPotentialObjectSettings(),
            target: {
                type: "Direction",
                settings: {
                    position: position,
                    directionArray: directionArray,
                }
            },
            energyToTransfer: amountToMove,
            type: momentumTypes[3],
        }
        // this.GOE.particleVisuals.startEnergyTransferAnimation(animationSettings);
        return animationSettings;

        //If new subspace then new neighbors too!

    }

    public calculateInverseMass=(energyOfObject: number)=>{
        const mass = this.calculateMass(energyOfObject);
        return mass>0 ? 1/mass : 0;
    }

    private calculateMass = (energyOfObject: number)=>{
        return energyOfObject/Math.pow(SPEED_OF_LIGHT,2)
    }

    public calculateEnergyRequiredToMoveMass = (mass: number, distance: number)=>{
        return mass * distance;
    }

    public getMassEnergyOfBond = (objectPart: Direction)=>{
        if(!objectPart.areForcesBalanced()) return null;
        // console.log("Returning balanced energies")
        const objEnergy = objectPart.getEnergyLevel();
        const oppEnergy = objectPart.getOppEnergyLevel();
        return objEnergy + oppEnergy;
        // if(!objectPart.correlatingWith.length){
        //     if(!objectPart.areForcesBalanced()) return objectPart.energyLevel;
        //     // console.log("Returning balanced energies")
        //     const objEnergy = objectPart.getEnergyLevel();
        //     const oppEnergy = objectPart.getOppEnergyLevel();
        //     return objEnergy + oppEnergy;
        // }
        // const data =  this.getConnectedObjects(objectPart);
        // if(!data) return;
        // // console.log("Fetching collective mass energy");

        // const { spatialNodes, causalNodes, edges } = data;

        // const objectEnergy = [
        //     ...spatialNodes,
        //     ...causalNodes,
        //     ...edges,
        // ].reduce((a: any, b: any)=>{
        //     return a+b.getEnergyLevel()
        // }, 0);

        // const objectMass = this.calculateMass(objectEnergy);
        // return objectMass
    }

    public calculateMassEnergyFromObject = (data: ConnectedObjectData)=>{
        const { spatialNodes, causalNodes, edges } = data;

        const objectEnergy = [
            ...spatialNodes,
            ...causalNodes,
            ...edges,
        ].reduce((a: any, b: any)=>{
            return a+b.getEnergyLevel()
        }, 0);

        const objectMass = this.calculateMass(objectEnergy);
        return objectMass
    }

    public getConnectedObjects=(pulledObject: ExistingObject)=>{
        // console.log("Object selected: ", pulledObject);
        const isCorrelating = pulledObject.correlatingWith.length
        const isBalanced = pulledObject instanceof Direction ? pulledObject.areForcesBalanced() : false;
        // console.log(`"pulled" Obj: ${pulledObject.id} correlating with: `, pulledObject.correlatingWith)
        // console.log("Pulled Obj is balanced? ", isBalanced);
        if(!isCorrelating && !isBalanced) return false;

        // if(isBalanced){
        //     if(pulledObject.target instanceof Direction){
        //         console.log("Balanced and not correlating, target is direction");
        //         pulledObject = pulledObject.target;
        //         // return;
        //     }
        //     if(pulledObject.source instanceof Direction){
        //         console.log("Balanced and not correlating, source is direction");
        //         pulledObject = pulledObject.source;
        //         // return;
        //     }
        // }

        // const data =  isBalanced ? this.findUniqueNodesAndEdgesFromBalancedNode(pulledObject) : this.ogFindUniqueNodesAndEdgesV2(pulledObject);
        const data = this.findUniqueNodesAndEdgesWorkingButSusClean(pulledObject);
        if(!data){
            console.warn(`Unique nodes and edges were undefined when getting connected objects`);
            return false;
        }

        // console.log("Unique Nodes and Edges Bulk Data: ", data);
        // const spatialNodes = data.spatialNodes;
        return data;   
    }

    public getIdsOfConnectedObjects=(data: ConnectedObjectData)=>{
        if(!data){
            console.warn(`Unique nodes and edges were undefined when moving connected objects`);
            return false;
        }
        const { spatialNodes, causalNodes, edges } = data;
        const collectiveData = [
            // ...spatialNodes,
            ...causalNodes,
            ...edges
        ]

        return collectiveData.map((obj: ExistingObject)=>{
            return obj.id;
        });
    }

    private calculateDistanceToMove=(amount, energyRequiredToMove)=>{
        return amount/energyRequiredToMove;
    }

    public moveConnectedObjects=(data: ConnectedObjectData, mover: Direction, force: number)=>{
        if(!data){
            console.warn(`Unique nodes and edges were undefined when moving connected objects`);
            return false;
        }
        const { spatialNodes, causalNodes, edges } = data;
        const objectEnergy = [
            ...spatialNodes,
            ...causalNodes,
            ...edges,
        ].reduce((a: any, b: any)=>{
            return a+b.getEnergyLevel()
        }, 0);

        const objectMass = this.calculateMass(objectEnergy);
        const distance = mover.distanceBetweenSourceAndTarget;
        const energyRequiredToMove = this.calculateEnergyRequiredToMoveMass(objectMass, distance);
        // console.log("Energy required to move: ", energyRequiredToMove);
        // console.log("Force: ", force);
        // console.log("Condition: ", force < energyRequiredToMove);
        if(force < energyRequiredToMove){
            console.log(`Mover ${mover.id} failed to move composite object of total energy ${objectEnergy}`)
            return false;
        }

        const units = this.calculateDistanceToMove(force, energyRequiredToMove);
        const newGridData = this.getNewDataForObjects(spatialNodes, mover, units);
        const newDirectionData = this.getNewDataForObjects(causalNodes, mover, units);
        const newCorrelationData = this.getNewDataForObjects(edges, mover, units);

        // console.log("Spatial Nodes: ", spatialNodes);
        // console.log("Causal Nodes: ", causalNodes);
        // console.log("Edges: ", edges);
        // console.log("newGridData: ", newGridData);
        // console.log("newDirectionData: ", newDirectionData);
        // console.log("newCorrelationData: ", newCorrelationData);

        // console.log("MOVER: ", mover);
        // Process spatial nodes
        const gridAnimations = this.processObjectsSequentially(newGridData, mover);

        
        // Process causal nodes
        const directionAnimations = this.processObjectsSequentially(newDirectionData, mover);
        
        // Process edges
        const edgeAnimations = this.processObjectsSequentially(newCorrelationData, mover);

        const animations =   [
            ...gridAnimations,
            ...directionAnimations,
            ...edgeAnimations
        ]

        // console.log("Animation length: ", animations.length)
        // console.log("Animations: ", animations);

        animations.forEach((animation: Task)=>{
            if(!animation) return;
            // console.log("Animation: ", animation);
            this.GOE.mechanics.attemptTask(animation);
            // this.GOE.particleVisuals.startEnergyTransferAnimation(animation);
        });

        return true;
        
        // throw new Error("Got connected objects!");
    }

    private processObjectsSequentially=(newDataArray: any[], mover: Direction): Task[]=> {
        let animations = [];
        for (const newObjData of newDataArray) {
            if(!newObjData) continue;
            const obj = this.GOE.getObjectById(newObjData.oldId);
            if (!(obj instanceof Direction) && !(obj instanceof GridCell)) {
                console.log("Obj: ", obj);
                console.warn("Object wasn't grid cell or direction when getting connected objects");
                continue;
            }
            const animation = this.createNewObjectsOnMovement(obj, newObjData, mover);
            animations.push(animation);
        }
        return animations;
    }
    public createMomentumBasedDirection=(propagatingObj: ExistingObject, momentum: MomentumSettings)=>{
        if(!momentum) return;

        // console.log(`Object ${propagatingObj.id} Initiating momentum based causal power`)
        const {GOE: GOE} = this;
        const { force, newTargetPosition } = momentum;
        if(!newTargetPosition) console.warn(`No new target position found for ${propagatingObj.id}`);
        if(!newTargetPosition) return;
        // if(force === 0) console.log(`Force equal to zero for ${propagatingObj.id}`);
        if(force === 0) return;
        let { animationSettings } = momentum;
        const { source } = animationSettings;

        const isTargetInBounds = this.isPointInSubspace(newTargetPosition);
        if(!isTargetInBounds){
            console.log(`Target not in bounds when attempting to create momentum based direction`);
            console.log("Position: ", newTargetPosition);
            return;
        }
        

        const bootstrapGridCell: GridCellSettings = {
            index: `(${newTargetPosition.x},${newTargetPosition.z})`,
            position: newTargetPosition,
            initEnergyLevel: 0,
        }
        // throw new Error("Grid cell not created for momentum based direction")
        // if(!bootstrapGridCell) console.log(`Object ${propagatingObj.id} failed to create BOOTSTRAP bootstrapGridCell for momentum`);
        // console.log("Bootstap Direction: ", bootstrapDirection.getDirectionArray())
        // animationSettings.directionOfTargetEnergy[1] = bootstrapDirection.id

        if (isLessThanMinimumDistance(source.settings.position, newTargetPosition)){
            console.warn("Momentum based direction would be smaller than minimum distance!");
            return null;
        }


        const newDirectionSettings = <PotentialDirectionSettings>animationSettings.target.settings
        const newDirectionSourceId = newDirectionSettings.directionArray[0];
        const newDirectionArray = [newDirectionSourceId, bootstrapGridCell.index];
        // console.log(`Creating New Direction on momentum based direction: `,newDirectionArray);
        const isIdentical = isSourceAndTargetIdentical([newDirectionSourceId, bootstrapGridCell.index]);
        if(isIdentical) throw new Error("createMomentumBasedDirection - Source and target identical")

        //TODO: Create this object even later ideally
        const isExistingGridCell = this.GOE.grid.cellData[bootstrapGridCell.index]
        const gridCell = isExistingGridCell ? isExistingGridCell : new GridCell({
            ...bootstrapGridCell,
            GOE: this.GOE,
            visibility: true,
        })
        if(!isExistingGridCell) this.GOE.grid.cellData[gridCell.index] = gridCell;

        const newDirectionSource = this.GOE.getObjectById(newDirectionSourceId);
        if(!newDirectionSource){
            console.warn("Something wrong with direction sourceId")
            console.log("New Direction Source: ", newDirectionSource);
            return;
        }
        // const newDirection = GOE.causalField.getOrCreateDirection([newDirectionSourceId, bootstrapGridCell.id]);
        animationSettings.target = {
            type:"Direction",
            settings: {
                position: calculateDirectionPosition(newDirectionSource, gridCell),
                directionArray: newDirectionArray,
            }
        };
        // if(!newDirection) console.log(`Object ${propagatingObj.id} failed to create new direction for momentum!`);
        // if(!newDirection) return;
        // const animationTarget = {
        //     ...
        //     settings: newDirectionArray
        // }
        // if(!newDirection) return console.log("Failed to create new direction for momentum!");

        // animationSettings.directionOfTargetEnergy = newDirection.getDirectionArray();

        const propgatingObjEnergy = propagatingObj.getEnergyLevel();
        // console.log(`Propgating Obj ${propagatingObj.id} energy level: ${propgatingObjEnergy}`)
        const amountToPropagate = propgatingObjEnergy < force ? propgatingObjEnergy : force;
        // animationSettings.target =  animationTarget;
        // console.log("Force: ", force);
        // console.log("Propgating obj energy: ", propgatingObjEnergy);
        // console.log("Amount to propagate: ", amountToPropagate);
        animationSettings.energyToTransfer = amountToPropagate
        // console.log(`Object ${propagatingObj.id} creating new direction`, newDirection);
        // console.log("Animation settings: ", animationSettings);
        // console.log("Target: ", animationSettings.target);
        // if(!animationSettings.target) return;
        // console.log("Data: ", {animationSettings, causalEnergySettings})
        // GOE.particleVisuals.startEnergyTransferAnimation(animationSettings);
        GOE.mechanics.attemptTask(animationSettings);
        // throw new Error("Creating momentum direction");
    }
    private isPointInSubspace=(position: Vector3)=>{
        const result = this.GOE.spatialPartioner.getIdOfSubspacePointIsIn([position.x, position.y, position.z]);
        if (!result) return false;
        return true;
    }
    public getMomentumData=(direction: Direction, force: number): MomentumSettings=>{
        const { target } = direction;
        // console.log(`Direction ${direction.id} initiating get momentum data for new direction`);
        // console.log("Would be source: ", target.id);
        // console.log("Is target grid cell?: ", target instanceof GridCell)
        // console.log("Is target direction: ", target instanceof Direction)
        // return null;
        let newDirectionPosition = direction.positionManager.getNewPosition();
        // // If new midpoint is out of bounds, then return;
        const result = this.isPointInSubspace(newDirectionPosition);
        // makeDebugBox(new Color3(1, 0, 0), { GOE: this.GOE, id: "Nothing", position: newDirectionPosition });
        
        if (!result) {
            // console.log(`New direction would be out of bounds`);
            // console.log("Position: ", newDirectionPosition);
            //throw new Error("Direction is out of bounds for some reason");
            return;
        }

        //Target will be direction of newly created causal energy.
        const animationSettings: Task = {
            caller: `${direction.id}`,
            source: target.getPotentialObjectSettings(),
            // directionOfSourceEnergy: target instanceof Direction ? target.getDirectionArray() : [null,null],
            target: {
                type:"Direction",
                settings: {
                    directionArray: [target.id, undefined],
                    position: undefined,
                }
            },
            // directionOfTargetEnergy: [target.id, undefined],
            energyToTransfer: undefined,
            type: "momentum direction",
            // type: momentumTypes[2],
        }

        // console.log("Getting momentum data for new direction from old: ", direction.id)
        const newTargetPosition = direction.positionManager.getShiftedPositionOfObj(target);

        // makeDebugBox(new Color3(1, 0, 0), { GOE: this.GOE, id: "Nothing", position: newDirectionPosition });

        const targetInBounds = this.isPointInSubspace(newTargetPosition);

        if (!targetInBounds) {
            // console.log(`New target would be out of bounds`);
            // console.log("Position: ", newTargetPosition);
            //throw new Error("Direction is out of bounds for some reason");
            return;
        }
        
        // console.log("Shifted target position: ", shiftedTargetPosition);
        // const diffVector = shiftedTargetPosition.subtract(target.position);
        // const newTargetPosition = wrapPosition(shiftedTargetPosition, diffVector, this.GOE.grid);
        // const newTargetPosition = wrapPosition(shiftedTargetPosition, diffVector, this.GOE.grid);
        const targetPosition = target.getPosition();
        if(`(${targetPosition.x},${targetPosition.z})` === `(${newTargetPosition.x},${newTargetPosition.z})`){
            console.log("New target position is old target position");
            // console.log(`Old Target Position: (${target.position.x},${target.position.z})`);
            // console.log(`New Target Position: (${newTargetPosition.x},${newTargetPosition.z})`);
            return;
        }
        // var box = MeshBuilder.CreateBox(this.id, { size: .1 }, this.GOE.scene);
        // changeBoxColor(box, new Color4(.1,.1,1,1))
        // const {x,y,z} = newTargetPosition
        // box.position = new Vector3(x,y,z);
        // console.log(`Direction ${direction.id} is returning momentum data for new direction`);
        return { force, newTargetPosition, animationSettings };
    }
}

export default Momentum;