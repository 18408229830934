// export const NOVELTY = 0.0125;

// export const NOVELTY = 0;
// export let LIGHT_MOMENTUM = true;
export const MASS_MOMENTUM = true;
export const NOVELTY = 0;
// export const NOVELTY = 0;
export const TUTORIAL_SCALE_FACTOR = 1.2;
// export const MINIMUM_DISTANCE = .75
export const MINIMUM_DISTANCE = .125;
export const MINIMUM_MOVE_DISTANCE = 1;

export const SPEED_OF_LIGHT = 7;
// export const SPEED_OF_LIGHT = .25;
// export const SPEED_OF_LIGHT = 1;
// export const PLAYER_SPEED_LIMIT = 1/10000;
export const PLAYER_SPEED_LIMIT = 0;
// export const PLAYER_SPEED_LIMIT = 0.1;

export const DIRECTION_NEVER_GOT_ENERGY_SPEED = .025;
export const DIRECTION_NEIGHBORHOOD_REMOVAL_SPEED = .025;
export const DIRECTION_DELETION_SPEED = 2.5;

export const NO_TIME = false;

//Green
export const windowStartColor = '0x00FF00';
//Red
export const windowEndColor = '0xFF0000';

export const DIRECTION_HOVER_HEIGHT = .2;

export const momentumTypes = [
    // 'momentum pull',
    'momentum based grid cell creation',
    'momentum based energy transfer to existing grid cell',
    'momentum based direction creation',
    'momentum based energy transfer to existing direction',
]

export const DEMO_NAMES = [
    'correlatorDemo',
    'constructionGateDemoDirectionEqual',
    'constructionGateDemoDirectionUnequal',
    'breakBond1',
    'couplerDemo',
    'correlatorDemoOutArrow',
    'correlatorDemoInArrow',
    'couplerDemoFlipLeft',
    'couplerDemoFlipRight',
    'NAND',
    'constructionGateDemoGridCellEqual',
    'constructionGateDemoGridCellEqual',
    'constructionGateDemoGridCellEqual',
    'constructionGateDemoGridCellUnequal',
    'runConstructionGateDemoGridCellEqual',
    'NANDgateMover',
    'bondBreakDemo',
    'breakBondOne',
    'breakBondTwo',
    'breakBondThree', 
]