import { PLAYER_SPEED_LIMIT } from "../constants";
import GameOfExistence from "./GameOfExistence";
import SpatialAction from "./SpatialAction";


class SpatialActionCreator {

    spatialActionsQueue: Map<number, SpatialAction[]> = new Map()
    creatingSpatialActions: boolean = false;
    GOE: GameOfExistence = null;
    lastTimeSpatialActionCreated: number = 0;
    currentMemory: SpatialAction[] = [];

    constructor(GOE: GameOfExistence){
        this.GOE = GOE;
    }

    addActionToQueue=(timestamp: number, action: SpatialAction[])=>{
        if(this.isActionInQueue(timestamp)) throw new Error("Action already stored!")
        this.spatialActionsQueue.set(timestamp, action);
    }
    getCurrentSpatialActionsQueue = ()=>{
        const timestamps = Array.from(this.spatialActionsQueue.keys()).sort((a, b) => b - a);
        const mostRecentTimestamp = timestamps[0];
        const actions = this.spatialActionsQueue.get(mostRecentTimestamp);
        return actions;
    }
    isActionInQueue = (timestamp: number): boolean =>{
        return this.spatialActionsQueue.has(timestamp)
    }
    getObjectById = (id: string)=>{
        const possibleObj = this.GOE.getObjectById(id);
        if(possibleObj) return possibleObj;
        const actions = this.getCurrentSpatialActionsQueue();
        if(!actions) return;
        for (let action of actions){
            if(action.id === id) return action;
        }
        // console.log("Current memory: ", this.currentMemory);
       for (let action of this.currentMemory){
            if(action.id === id) return action;
       }
    }

    attemptToCreateSpatialAction = () => {
        if (this.creatingSpatialActions && this.spatialActionsQueue && this.spatialActionsQueue.size > 0) {
            const deltaTime = this.GOE.gameTime - this.lastTimeSpatialActionCreated;
            if (deltaTime >= PLAYER_SPEED_LIMIT) {
                // Get the most recent timestamp
                const timestamps = Array.from(this.spatialActionsQueue.keys()).sort((a, b) => b - a);
                const mostRecentTimestamp = timestamps[0];
                const actions = this.spatialActionsQueue.get(mostRecentTimestamp);

                if (actions) {
                    const nextAction = actions.shift(); // Get and remove the first action
                    this.currentMemory.push(nextAction)
                    if (nextAction) {
                        nextAction.createAction();
                        // const oppAction = nextAction.getOppositeAction();
                        // this.currentMemory.push(oppAction)
                        // console.log(`Calling remove self -- attemptToCreateSpatialAction`)
                        // oppAction && oppAction.removeSelf();
                        this.lastTimeSpatialActionCreated = this.GOE.gameTime;
                    }

                    if (actions.length === 0) {
                        this.spatialActionsQueue.delete(mostRecentTimestamp); // Remove the entry if no more actions
                    } else {
                        this.spatialActionsQueue.set(mostRecentTimestamp, actions); // Update the entry with remaining actions
                    }

                    // If all actions are created, exit spatial mode
                    if (this.spatialActionsQueue.size === 0) {
                        this.creatingSpatialActions = false;
                        this.currentMemory = [];
                        this.GOE.spatialEnergyManager.clearReservations();
                    }
                }
            }
        }
    }
    // //Called during render loop, adapt this function to handle the map object
    // attemptToCreateSpatialAction = () => {
    //     if (this.creatingSpatialActions && this.spatialActionsQueue && this.spatialActionsQueue.size > 0) {
    //         const deltaTime = this.GOE.gameTime - this.lastTimeSpatialActionCreated;
    //         if (deltaTime >= PLAYER_SPEED_LIMIT) {
    //             // Create the next spatial action
    //             const nextAction = this.spatialActionsQueue.shift(); // Remove and get the first action
    //             if (nextAction) {
    //                 nextAction.createAction();
    //                 const oppAction = nextAction.getOppositeAction();
    //                 oppAction && oppAction.removeSelf();
    //                 this.lastTimeSpatialActionCreated = this.GOE.gameTime;
    //             }
                
    //             // Update the last action creation time
                
    //             // If all actions are created, exit spatial mode
    //             if (this.spatialActionsQueue.size === 0) {
    //                 this.creatingSpatialActions = false;
    //                 this.GOE.spatialEnergyManager.clearReservations();
    //             }
    //         }
    //     }
    // }
}

export default SpatialActionCreator