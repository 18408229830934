function loadActionsFromFile(): Promise<{ actions: Record<string, any>, error: Error | null }> {
    return fetch('spatialActions.json')
        .then(response => {
            if (!response.ok) {
                throw new Error(`Failed to fetch spatial actions from file: ${response.statusText}`);
            }
            return response.json();
        })
        .then(data => {
            return { actions: data, error: null };
        })
        .catch(error => {
            return { actions: {}, error: error };
        });
}

export default loadActionsFromFile;