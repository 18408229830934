import { ActionManager, Color3, ExecuteCodeAction, Mesh, MeshBuilder, Scene, StandardMaterial, Vector3 } from "@babylonjs/core";
import getMidpoint from "../helpers/getMidpoint";
import { ExistingObject, PositionObject, PossibleObject, SpatialActionSettings } from "../types";
import Direction from "./Direction";
import GameOfExistence from "./GameOfExistence"
import GridCell from "./GridCell";
import getOppositeDirectionStringFromString from "../helpers/getOppositeDirectionStringFromString";
import SpatialAction from "./SpatialAction";
import isObjEmpty from "../helpers/isObjEmpty";


class SpatialActionManager {

    GOE: GameOfExistence
    spatialActions: {[key: string]: SpatialAction} = null;
    movedSpatialActions: {[key: string]: SpatialAction} = null;

    constructor(GOE: GameOfExistence){
        this.GOE = GOE;
        this.spatialActions = {};
        this.movedSpatialActions = {};
    }

    getSpatialActionById=(id: string)=>{
        if(this.spatialActions[id]) return this.spatialActions[id]
        if(this.movedSpatialActions[id]) return this.movedSpatialActions[id];
    }

    getSpatialActionMeshes=()=>{
        const spatialDirections = Object.values(this.spatialActions);
        if(!spatialDirections.length) return [];
        return spatialDirections.reduce((Og: [], spatialB: SpatialAction)=>{
            return [...Og, ...spatialB.getMeshesToMove()]
        }, [])
    }
    transferMovedActionsToSpatialActions=()=>{
        const oldActions = {...this.spatialActions};
        const newActions = {...this.movedSpatialActions};
        // console.log("Spatial actions: ", oldActions)
        this.spatialActions = {...oldActions, ...newActions};
        this.movedSpatialActions={};
    }
    public isMovedAction=(id: string)=>{
        if(this.movedSpatialActions[id]) return true;
        else false;
    }

    setSpatialBoundingBox = (arrow: Mesh, source: PositionObject, target: PositionObject, scene?: Scene)=>{
        // After the arrow creation
        // let arrowHitbox = this.GOE.createArrowHitbox(arrow);
        // arrowHitbox.parent = arrow;

        // Compute the bounding box of the arrow
        const boundingInfo = arrow.getBoundingInfo();

        // Calculate the center of the bounding box
        const boundingBoxCenterWorld = boundingInfo.boundingBox.centerWorld;

        // Calculate the difference between the current position and the center of the bounding box
        const offset = boundingBoxCenterWorld.subtract(arrow.position);

        // Update the arrow's position to the center of the bounding box
        arrow.setPivotPoint(offset);

        // inside your createArrow function, after the hitbox has been created:
        arrow.actionManager = new ActionManager(scene ? scene : this.GOE.scene);

        ((self, source, target)=>{
            //TODO: 
            arrow.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnDoublePickTrigger, function(ev){	
                // console.log("Double tapped");
                if(self.GOE.controls.isDragging || self.GOE.controls.selectionMode) return;
                // console.log("Checking for spatial action")
                const {x,y,z} = getMidpoint(source.position, target.position);
                // const actionId = `[${source.id}->${target.id}]-SA`;
                const actionId = `${source.id}-${target.id}-SA`;
                // if(!isDirectionId(this.id)) throw new Error(`Action id ${actionId} isn't formatted correctly`)
                const spatialAction = self.spatialActions[actionId];
                if(!spatialAction) return;
                // let objectsInSpatialUse = [];
                
                // console.log("Spatial Action: ", spatialAction);
                // const oppId = getOppositeDirectionStringFromString(spatialAction.id);
                // //Using this to ensure we don't select actions that are currently in use
                // const filteredActions = Object.values(self.spatialActions).filter((spatialAction: any)=>{
                //     if(spatialAction.id === actionId) return true;
                // })

                // // console.log("Filtered actions: ", filteredActions);
                // filteredActions.forEach((spatialAction: any)=>{
                //     const energyObjIndex = spatialAction.involvedObjects.energy.id;
                //     const sourceObjIndex = spatialAction.involvedObjects.source.id;
                //     const targetObjIndex = spatialAction.involvedObjects.target.id;
                //     const numberOfActions = Object.keys(self.spatialActions).length;
                //     const onlyOneArrowLeft = (numberOfActions > 1 && spatialAction.balanced) || numberOfActions === 1;
                //     const involvedIndexes =  onlyOneArrowLeft ?  [energyObjIndex, sourceObjIndex, targetObjIndex] : [sourceObjIndex, targetObjIndex];
                //     objectsInSpatialUse = objectsInSpatialUse.concat(involvedIndexes);
                //     // const oppObjIndexes = oppAction ? Object.values(oppAction.involvedObjects).map((obj: any)=>{obj.id}) : [];
                //     // if(oppObjIndexes.length) objectsInSpatialUse = objectsInSpatialUse.concat(oppObjIndexes)
                // })

                //Set objects back to normal and delete arrow
                //We know which object was which, so we can do other cool stuff too.
                // console.log("Involved objects: ", spatialAction.involvedObjects);
                // console.log("Objects In Spatial Use: ", objectsInSpatialUse);

                // const {energyId, sourceId, targetId} = spatialAction;
                // const involvedObjects = 
                // Object.values(spatialAction.involvedObjects).forEach((object: ExistingObject)=>{
                //     if(!objectsInSpatialUse.includes(object.id)) return;
                //     object.switchColor("default");
                // });

                console.log(`Calling remove self -- OnDoublePickTrigger`)
                const oppAction = spatialAction.getOppositeAction();
                spatialAction.removeSelf();
                if(oppAction) oppAction.removeSelf();
            }));
            // when mouse pointer is over the mesh, scale it up
            arrow.actionManager.registerAction(
                new ExecuteCodeAction(
                    ActionManager.OnPointerOverTrigger,
                    (ev)=>{
                        if(self.GOE.controls.selectionMode) return;
                        arrow.scaling = new Vector3(1.2, 1.2, 1.2); // Increase size by 20%
                    }
            ));
            
            // when mouse pointer is out, scale it back down
            arrow.actionManager.registerAction(
                new ExecuteCodeAction(
                    ActionManager.OnPointerOutTrigger,
                    (ev)=>{
                        if(self.GOE.controls.selectionMode) return;
                        arrow.scaling = new Vector3(1, 1, 1); // Reset size
                    }
                ));

        })(this, source, target)
    }

    createDoubleEndedSpatialArrow = (source: PositionObject, target: PositionObject, scene?: Scene): [Mesh, Vector3]=>{
        const [arrow, midpoint] = this.GOE.arrowVisuals.createDoubleEndedArrow(source,target, scene);
        // console.log("Creating double ended spatial arrow");
        let material = new StandardMaterial("mat", scene ? scene : this.GOE.scene);
        material.diffuseColor = new Color3(0, 1, 0); // RGB for red
        material.specularColor = new Color3(0, 1, 0); // Specular color for shiny surfaces
        arrow.material = material;
        this.setSpatialBoundingBox(arrow, source, target);
        return [arrow, midpoint];
    }

    createSpatialArrow = (source: PositionObject, target: PositionObject, scene?: Scene):[Mesh, Vector3] => {
        const [arrow, midpoint] = this.GOE.arrowVisuals.createArrow(source, target, scene);
        let material = new StandardMaterial("mat", scene ? scene : this.GOE.scene);
        material.diffuseColor = new Color3(0, 1, 0); // RGB for red
        material.specularColor = new Color3(0, 1, 0); // Specular color for shiny surfaces
        arrow.material = material;
        this.setSpatialBoundingBox(arrow, source, target);
        return [arrow, midpoint];
    };

    addSpatialAction = (spatialAction: SpatialAction)=>{
        const isExistingSpatialAction = this.spatialActions[spatialAction.id];
        if(isExistingSpatialAction){
            console.log("Existing spatial action when adding spatial action");
            const existingAction = {...isExistingSpatialAction}
            console.log("Existing Action Id: ", existingAction.id);
            console.log("Existing Action Energy Level: ", existingAction.getEnergyLevel());
            console.log("New Spatial Action: ", spatialAction);
            console.log("New Spatial Action Energy: ", spatialAction.getEnergyLevel());

        }
        this.spatialActions[spatialAction.id] = spatialAction;
    }

    addMovedSpatialAction = (spatialAction: SpatialAction)=>{
        const isExistingSpatialAction = this.movedSpatialActions[spatialAction.id];
        if(isExistingSpatialAction){
            console.log("Existing spatial action when adding spatial action");
            const existingAction = {...isExistingSpatialAction}
            console.log("Existing Action Id: ", existingAction.id);
            console.log("Existing Action Energy Level: ", existingAction.getEnergyLevel());
            console.log("New Spatial Action: ", spatialAction);
            console.log("New Spatial Action Energy: ", spatialAction.getEnergyLevel());

        }
        this.movedSpatialActions[spatialAction.id] = spatialAction;
    }

    reserveEnergyUsed = (spatialAction: SpatialAction): boolean=>{
        const energyUsed = spatialAction.getEnergyLevel();
        if(energyUsed === 0) return true;
        const energySource = this.GOE.getObjectById(spatialAction.energyId);
        return this.GOE.spatialEnergyManager.reserveEnergy(energyUsed, energySource)
    }

    createViewerSpatialAction=(settings: SpatialActionSettings, id: string)=>{
        // const actionCollective = this.GOE.spatialActionViewer.savedActions[id];
        // // console.log("Active collective: ", actionCollective);
        // const existingSpatialAction = actionCollective ? actionCollective.filter((action)=>action.id === settings.id)[0] : null;
        // if(existingSpatialAction){
        //     // console.log(`Calling remove self -- createSpatialActionInViewer`)
        //     // existingSpatialAction.removeSelf();
        //     // console.log(`Calling remove objects -- createSpatialActionInViewer`)
        //     existingSpatialAction.removeObjects();
        // }

        const newSpatialAction = new SpatialAction(this.GOE, this, settings, null, id)
        this.GOE.spatialActionViewer.addDirectionToSavedAction(newSpatialAction, id);
        // if(existingSpatialAction) newSpatialAction.timestamp = existingSpatialAction.timestamp
        // const oppAction = newSpatialAction.createZeroEnergyOppAction(this.GOE.spatialActionViewer.previewScene);
        // this.GOE.spatialActionViewer.addDirectionToSavedAction(oppAction, id);
        return newSpatialAction
    }

    createSpatialActionFromMeshDragger = (spatialActionId: string, settings: SpatialActionSettings)=>{
        // const existingSpatialAction = this.getSpatialActionById[spatialActionId];
        // if(existingSpatialAction){
        //     // console.log("Existing spatial action when adding spatial action");
        //     const isExistingSpatialAction = this.spatialActions[spatialActionId];
        //     const existingAction = {...isExistingSpatialAction}
        //     const energyInNewAction = settings.animationSettings.energyToTransfer;
        //     // if(energyInNewAction < existingAction.getEnergyLevel()) existingSpatialAction.removeObjects();
        //     if(energyInNewAction > existingAction.getEnergyLevel()) existingSpatialAction.removeSelfFromMovedDirection();
        // }
        const newSpatialAction = new SpatialAction(this.GOE, this, settings)
        const canReserveEnergy = this.reserveEnergyUsed(newSpatialAction);
        if(!canReserveEnergy) return false;
        // if(existingSpatialAction) newSpatialAction.timestamp = existingSpatialAction.timestamp
        // newSpatialAction.createZeroEnergyOppAction(this.GOE.scene, true);
        this.movedSpatialActions[spatialActionId] = newSpatialAction
        this.highlightSpatialAction(newSpatialAction);
        return newSpatialAction;
    }

    createSpatialAction = (spatialActionId: string, settings: SpatialActionSettings)=>{
        const existingSpatialAction = this.spatialActions[spatialActionId];
        if(existingSpatialAction){
            const isExistingSpatialAction = this.spatialActions[spatialActionId];
            const existingAction = {...isExistingSpatialAction}
            const energyInNewAction = settings.animationSettings.energyToTransfer;
            // if(energyInNewAction > existingAction.getEnergyLevel()) existingSpatialAction.removeObjects();
            if(energyInNewAction > existingAction.getEnergyLevel()) existingSpatialAction.removeSelf();
            // existingAction.removeSelf();
        }
        const newSpatialAction = new SpatialAction(this.GOE, this, settings)
        const canReserveEnergy = this.reserveEnergyUsed(newSpatialAction);
        if(!canReserveEnergy) return false;
        if(existingSpatialAction) newSpatialAction.timestamp = existingSpatialAction.timestamp
        newSpatialAction.createZeroEnergyOppAction(this.GOE.scene);
        this.spatialActions[spatialActionId] = newSpatialAction
        this.highlightSpatialAction(newSpatialAction);
        return newSpatialAction;
    }

    highlightSpatialAction = (newSpatialAction: SpatialAction)=>{
        const involvedObjects = newSpatialAction.getInvolvedObjects();
        for (let object of involvedObjects){
            if(!object){
                // console.log("Error: ", object);
                // throw new Error("Invalid object in spatial action");
                // console.warn("Invalid object in spatial action");
                continue;
            }
            this.GOE.particleVisuals.endHoverAnimation(object);
            object instanceof Direction && this.GOE.controls.switchSpatialComponentColor(object,"spatial")
            object.switchColor("spatial");
        }
        this.GOE.arrowVisuals.deleteSelectArrow();
        this.GOE.controls.selectedObject = null;
        this.GOE.controls.selectedSource = null;
        this.GOE.controls.selectedTarget = null;
        this.GOE.controls.selectionMode = false;
    }

    calculateSelectorPosition = (action: SpatialAction)=>{
        const parentPosition = action.directionSettings.position;
        const target = this.GOE.getObjectById(action.targetId);
        const targetPosition = target.getPosition();
        return getMidpoint(parentPosition, targetPosition);
    }

    calculateOppSelectorPosition = (action: SpatialAction)=>{
        const parentPosition = action.directionSettings.position;
        const source = this.GOE.getObjectById(action.sourceId);
        const targetPosition = source.getPosition();
        return getMidpoint(parentPosition, targetPosition);
    }

    createOppSpatialActionSelector=(action: SpatialAction)=>{
        const oppId = getOppositeDirectionStringFromString(action.id);
        const oppAction = this.spatialActions[oppId];
        if(oppAction) return;

        var box = MeshBuilder.CreateBox(action.id+'opp', { size: .1 }, this.GOE.scene);
        const {x,y,z} = this.calculateOppSelectorPosition(action);
        box.position = new Vector3(x,0,z);
        box.actionManager = new ActionManager(this.GOE.scene);
        box.parent = action.arrowObject
    }

    createSpatialActionSelector=(action: SpatialAction)=>{
        var box = MeshBuilder.CreateBox(action.id, { size: .1 }, this.GOE.scene);
        const {x,y,z} = this.calculateSelectorPosition(action);
        box.position = new Vector3(x,0,z);
        box.actionManager = new ActionManager(this.GOE.scene);
        box.parent = action.arrowObject
    }

    removeSpatialActionsFromMainScene=()=>{
        if(isObjEmpty(this.spatialActions)) return;
        Object.values(this.spatialActions).forEach((action: SpatialAction)=>{
            action.removeSelf();
        })
    }
}

export default SpatialActionManager