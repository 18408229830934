import universe from '../../assets/universe.png';

const PhysicsExplanation = (props: any) => {

  const { isMobile } = props;
  const Text = (props)=>{
    return(
      <div style={{marginTop: '2.5%', marginBottom: '2.5%', marginLeft: '2.5%', marginRight: '2.5%', ...props.style}}>
        {props.children}
      </div>
    )
  }
  const PhysicsResearchProgram = ({ isMobile }: any) => {
    return (
      <div>
        <Text style={{ fontSize: '1.2em', fontWeight: '600', marginTop: 0, textAlign: 'center' }}>
          Program in short
        </Text>
        <ol style={{marginRight: '1em'}}>
          <li>Conjecture physical principles in the form of game rules</li>
          <li>Create artificially intelligent agents, or simple programs, to simulate their consequences at scale</li>
          <li>Analyze simulation results for connections with known phenomena</li>
        </ol>
      </div>
    );
  };
  const Explanation=()=>{
    return(
    <div style={{
      width: isMobile ? '100%' : '50%',
      // maxWidth: '850px',
      textAlign: 'justify',
      fontWeight: '500',
      backgroundColor: '#E6CCA1',
      color: 'black',
      // paddingLeft: '5%',
      // paddingRight: '5%',
    }}>
        <PhysicsResearchProgram/>
        <Text style={{fontSize: '1.2em', fontWeight: '600', marginTop: 0,marginBottom: 0, textAlign: 'center',}}>
          Motivation
        </Text>
        <Text>
          Eleatic Game Design can be used as a novel framework for developing physical theories. In particular, Eleatic Game Design extends <a href="https://www.constructortheory.org/">“Constructor Theory”</a>, an approach to unraveling the mysteries of fundamental physics, by using universal physical principles that all objects must obey as the rules of the Eleatic Game.
        </Text>
        <Text>
          For instance, by taking seriously the principle of conservation of energy within a game, all subsequent rules about how energy can move must consider this restriction.
        </Text>
        <Text>
          Incorporating physical principles into Eleatic Games results in the creation of virtual worlds that are more likely to resemble our own universe.
        </Text>
        <Text>
          These Eleatic Games can be used to quickly test the consequences of different toy models of physics, serving as an intuition pump for developing more refined theories, or games.
        </Text>
        <Text>
          {`While only a toy physics model, the demonstration found on this page and detailed in the paper, Hello Game of Existence (HGoE), is capable of demonstrating universal computation and programmable construction`}
        </Text>
        <Text>
          Importantly, these properties arise from simple assumptions about the dynamics of the particles and may hint at deeper underlying physical mechanisms that make them possible.
        </Text>
        <Text>
          Eleatic Games were invented for the purpose of quantifying the energetic cost to build, run and maintain a circuit for indefinite use. As such, HGoE can be seen as an early approximate theory to a "Thermodynamics of Construction".
        </Text>
        <Text>
          The Thermodynamics of Construction would be a theory extends the <a href={'https://arxiv.org/abs/1905.05669'}>Thermodynamics of Computation</a> by including considerations about the physical hardware that the system performing the computation is made up of.
        </Text>
        <Text>
          For an introduction on the relationship between information and physical energy see <a style={{fontWeight: '600'}} href={'https://www.youtube.com/watch?v=XY-mbr-aAZE'}>Youtube: Pure Information Gives Off Heat</a>
        </Text>
    </div>
    )
  }
  const NetworkImage = ()=>{
    return(
    <div style={{
        // height: '100%',
        minHeight: isMobile ? '250px' : 'none',
        // minHeight: isMobile ? '250px' : '500px' ,
        width: isMobile ? '100%' : '35%',
        backgroundPositionY: isMobile ? '40%' : 0,
        // marginBottom: '10px',
        position: 'relative',
        bottom: '10px',
        backgroundImage: `url(${universe})`,
        backgroundSize: isMobile ? 'cover' : 'contain',
        backgroundRepeat:'no-repeat',

      }}/>
    )
}
  return (
    <div style={{
      
      display: 'flex',
      flexDirection: isMobile ? 'column-reverse' : 'row',
      justifyContent: isMobile ? 'center' : 'space-around',

    }}>
      <Explanation/>
      <NetworkImage/>
    </div>
  );
};



export default PhysicsExplanation