import { ActionManager, Color4, ExecuteCodeAction, Mesh, MeshBuilder, Scene, Vector3 } from "@babylonjs/core"
import Direction from "./Direction"
import GridCell from "./GridCell"
import { DirectionSettings, EnergyTransferAnimation, ExistingObject, ObjectSelectionStates, PossibleObject, SpatialActionSettings, Subspace, PotentialObjectSettings, Task, SpatialActionsJson, SerializedPotentialObjectSettings } from "../types"
import GameOfExistence from "./GameOfExistence"
import SpatialActionManager from "./SpatialActionManager"
import getOppositeDirectionStringFromString from "../helpers/getOppositeDirectionStringFromString"
import getMidpoint from "../helpers/getMidpoint"
import changeBoxColor from "../helpers/changeBoxColor"
import EnergyScroller from "./EnergyScroller"
import isPointNeighborOfCell from "../helpers/isPointNeighborOfCell"
import isObjEmpty from "../helpers/isObjEmpty"
import EnergyDisplayer from "./EnergyDisplayer"
import calculateDistanceBetweenPoints from "../helpers/calculateDistanceBetweenPoints"
import stripSpatialTag from "../helpers/stripSpatialTag"
import { DIRECTION_HOVER_HEIGHT } from "../constants"
import convertDirectionArrayToString from "../helpers/convertDirectionArrayToString"
import Neighborhood from "./Neighborhood"
import isSourceAndTargetIdentical from "../helpers/isSourceAndTargetIdentical"
import PotentialObject from "./PotentialObject"
import calculateDirectionPosition from "../helpers/calculateDirectionPosition"
import serializeVector3 from "../helpers/serializeVector3"
import isMainScene from "../helpers/isMainScene"
// import modifyIdForSpatialAction from "../helpers/modifyIdForSpatialAction"


class SpatialAction {

    id: string;//causalEnergyPositionIndex-directionString-SA
    directionId: string;//causalEnergyPositionIndex-directionString

    isVisible: boolean = true;

    oppId: string;
    index: string;
    positionIndex: string;
    directionIndex: string; //directionString

    energyId: string;
    sourceId: string;
    targetId: string;



    directionSettings: DirectionSettings;
    animationSettings: Task
    animation: Task
    balanced: boolean = false;
    arrowObject: Mesh;
    midpoint: Vector3;

    parentPosition: Vector3;
    visualPosition: Vector3;
    position: Vector3;
    sourcePosition: Vector3;
    targetPosition: Vector3;


    GOE: GameOfExistence = null;
    parent: SpatialActionManager = null;

    selector: Mesh = null;

    energyScroller: EnergyScroller = null;
    energyLevel: number = 0;
    unit: number = .5;
    subspaceId: any = null;

    neighborIds: string[];
    neighbors: (SpatialAction | GridCell | Direction)[];

    timestamp: number;

    savedActionId: string;
    parentId: string;

    energyDisplayer: EnergyDisplayer;

    distanceBetweenSourceAndTarget: number = 0;

    neighborhood: Neighborhood = null;

    oppAction: SpatialAction = null;

    inMainScene: boolean = true;
    settings: SpatialActionSettings = null;

    constructor(GOE: GameOfExistence, parent: SpatialActionManager, settings: SpatialActionSettings, oppAction?: SpatialAction, savedActionId?: string){
        this.GOE = GOE;
        this.parent = parent;
        this.settings = settings;
        const {
            id,
            directionId,
            directionSettings,
            animationSettings,
            energyId,
            sourceId,
            targetId,
            position,
            sourcePosition,
            targetPosition
        } = settings

        this.savedActionId = savedActionId;
        this.sourcePosition = sourcePosition;
        this.targetPosition = targetPosition;
      
        this.timestamp = this.GOE.gameTime;

        // console.log(`Creating spatial action with id ${id}`)
        // this.sourceId = modifyIdForSpatialAction(sourceId);
        // this.targetId = modifyIdForSpatialAction(targetId);
        this.energyId = energyId;
        this.sourceId = sourceId;
        this.targetId = targetId;
        this.directionIndex = `${sourceId}-${targetId}`
        this.id = id;
        this.directionId = directionId;
        this.directionSettings = directionSettings;
        this.animationSettings = animationSettings;
        
        this.oppId = this.getOppositeId();
        
        // const {source, target} = involvedObjects;
        // console.log("Spatial Action - SourceId: ", this.sourceId);
        // console.log("Spatial Action - TargetId: ", this.targetId);
        // console.log("Spatial Action - Source: ", source);
        // console.log("Spatial Action - Target: ", target);
        // this.target = target;
        // this.unit = calculateDistanceBetweenPoints(source.position, target.position);
        this.unit = this.GOE.grid.cellSize;

        // console.log("Unit: ", this.unit);
        // console.log("Opp Id: ", this.oppId);
        this.oppAction = oppAction ? oppAction: this.getOppositeAction();
        const oppEnergy = oppAction ? oppAction.getEnergyLevel() : 0;
        // console.log(`Spatial Action: ${this.id}`)
        // console.log("Generated Opp ID: ", this.oppId);
        // console.log("Energy: ", this.getEnergyLevel());
        // console.log("oppEnergy: ", oppEnergy);
        this.balanced = this.getEnergyLevel() === oppEnergy;

        this.position = this.calculatePosition();
        this.distanceBetweenSourceAndTarget = this.calculateDistanceBetweenSourceAndTarget();
        this.parentPosition = getMidpoint(sourcePosition, targetPosition);
        
        // this.selector = this.createSelector();
        // this.neighborhood = new Neighborhood(this);

        // this.addSelfToSubSpace();
        // const existingNeighbors = this.getExistingNeighbors();
        // this.neighbors = existingNeighbors;
        // this.neighborIds = this.getNeighborIds();


  
        // console.log(`Spatial Action ${this.id} has been created`)
        if(!this.sourcePosition){
            console.log(`Source position invalid for ${this.id}`)
        }
        if(!this.targetPosition){
            console.log(`Target position invalid for ${this.id}`)
        }
    }


    createVisuals=(scene: Scene): Mesh=>{
        // console.log(`Action ${this.id} creating visuals: `, scene);
        // console.log(`Checking is main scene in createVisuals`)
        const isMain = isMainScene(scene);
        // if( (this.arrowObject && this.selector && this.energyDisplayer ) && isMain){
        if( (this.arrowObject || this.selector || this.energyDisplayer ) && isMain){
            // console.log(`Action ${this.id} is returning old object in ${isMain ? "main" : "second"} scene`)
            return this.arrowObject
        }

        // if(!isMain && this.arrowObject && !this.arrowObject.isDisposed()){
        //     console.log(`Action ${this.id} is creating returning old non-disposed visual in ${isMain ? "main" : "second"} scene`);
        //     return this.arrowObject;
        // }
        // console.log(`Action ${this.id} is creating new visual in ${isMain ? "main" : "second"} scene`);

        // console.log("Arrow Object: ", this.arrowObject);
        // console.log("Selector: ", this.selector);
        // console.log("energyDisplayer: ", this.energyDisplayer);
        const source = {
            position: this.sourcePosition,
            id: this.sourceId,
        }
        const target = {
            position: this.targetPosition,
            id: this.targetId,
        }
        // console.log(`Spatial Action: ${this.id}`)
        // console.log("Generated Opp ID: ", this.oppId);
        // console.log("Energy: ", this.getEnergyLevel());
        // console.log("oppEnergy: ", oppEnergy);

        const [arrow, midpoint] = this.createArrow(scene);
        // if(arrow && isMain) this.parent.setSpatialBoundingBox(arrow, source, target);
        if(arrow){
            arrow.metadata = {};
            arrow.metadata.id = this.id;
            arrow.metadata.type = "arrow";
            // arrow.metadata.spatialDirectionSettings = this.settings;
            // this.sourcePosition
            // arrow.metadata.targetPosition = this.targetPosition
            // arrow.metadata.sourceId = this.sourcePosition
            // arrow.metadata.targetId = this.targetPosition
        }
        // if(arrow) arrow.metadata = {};
        // if(arrow) arrow.metadata.id = this.id;
        this.arrowObject = arrow;
        this.midpoint = midpoint;
        let selector = this.createSelector(scene);
        if(!selector.metadata) selector.metadata = {}
        selector.metadata.id = this.id;
        selector.metadata.type = "SpatialAction";
        selector.metadata.spatialDirectionSettings = this.settings;
        this.assignSelector(selector);
        this.energyDisplayer = new EnergyDisplayer({GOE: this.GOE,energyValue: this.getEnergyLevel(), parent: this})
        this.energyDisplayer.setScene(scene);
        this.energyDisplayer.createDisplay();
        if(!this.energyDisplayer.energyScroller.metadata) this.energyDisplayer.energyScroller.metadata = {};
        this.energyDisplayer.energyScroller.metadata.id = this.id;

        // const idDisplayer = new EnergyDisplayer({GOE: this.GOE,energyValue: this.id, parent: this})
        // idDisplayer.setScene(scene);
        // idDisplayer.createDisplay();

        // console.log("Energy level: ", this.getEnergyLevel());
        // console.log("Scene: ", scene);
        // this.createEnergyScroller();
        // this.createOppositeSelector(scene);
        return arrow;
    }

    addMetaDataToSelector=()=>{
        if(!this.selector) return;
        if(!this.selector.metadata) this.selector.metadata = {}
        this.selector.metadata.id = this.id;
        this.selector.metadata.type = "SpatialAction";
        this.selector.metadata.spatialDirectionSettings = this.settings;
    }

    addMetaDataToArrow=()=>{
        if(!this.arrowObject) return;
        if(!this.arrowObject.metadata) this.arrowObject.metadata = {};
        this.arrowObject.metadata.id = this.id;
        this.arrowObject.metadata.type = "arrow";
    }

    addMetaDataToEnergyDisplayer=()=>{
        if(!this.energyDisplayer.energyScroller) return;
        if(!this.energyDisplayer.energyScroller.metadata) this.energyDisplayer.energyScroller.metadata = {};
        this.energyDisplayer.energyScroller.metadata.id = this.id;
    }

    getMeshesToMove=()=>{
        this.addMetaDataToSelector();
        this.addMetaDataToArrow();
        this.addMetaDataToEnergyDisplayer();
        const energyScroller = this.energyDisplayer && this.energyDisplayer.energyScroller ? this.energyDisplayer.energyScroller : null;
        const meshes = [ this.selector, this.arrowObject, energyScroller]
        const filteredMeshes = meshes.filter((mesh: Mesh)=>mesh);
        return filteredMeshes;
    }

    assignSelector=(selector: Mesh)=>{
        this.selector = selector;
    }

    createArrow=(scene: Scene): [Mesh, Vector3]=>{
        const source = {
            position: this.sourcePosition,
            id: this.sourceId,
        }
        const target = {
            position: this.targetPosition,
            id: this.targetId,
        }
        return ((self)=>{

            const oppAction =  self.getOppositeAction();
            const oppEnergy = oppAction ? oppAction.getEnergyLevel() : 0;
            const isBalanced = self.getEnergyLevel() === oppEnergy;
            const isForceGreater = self.getEnergyLevel() > oppEnergy;
            self.balanced = isBalanced;
            // console.warn(`SpatialAction ${this.id}`);
            // console.log(`Energy: `, self.getEnergyLevel());
            // console.log(`Is Balanced? ${this.id}: `, isBalanced);
            // console.log(`isForce Greater? ${this.id}`, isForceGreater);
            // console.log(`Opp Id ${this.oppId}`);
            // console.log(`Opp Energy`, oppEnergy);
            // console.log(`Opp Balanced`, oppAction.balanced);
            ( (isBalanced || isForceGreater) && oppAction) && oppAction.arrowObject && oppAction.arrowObject.dispose()
            if(!self.getEnergyLevel() || (!isForceGreater && !isBalanced)){

                // console.log("Source Id: ", sourceId)
                // console.log("Target Id: ", targetId)
                // console.log("Animation Source: ", source);
                // console.log("Animation Target: ", target);
                return [null, getMidpoint(source.position, target.position)];
            }
            else{
                // console.log("Rendering an arrow");
                return (
                    isBalanced ?
                    self.parent.createDoubleEndedSpatialArrow(source, target, scene) :
                    self.parent.createSpatialArrow(source, target, scene)
                );
            }

        })(this);
    }


    // getCurrentNeighbors = (): ExistingObject[] =>{
    //     return <ExistingObject[]>this.neighborhood.getNeighbors(['gridCells', 'directions']).filter((obj: ExistingObject)=>obj.id!==this.id);
    // }
    getDirectionArray = ()=>{
        return stripSpatialTag([this.sourceId, this.targetId]);
    }
    getPotentialObjectSettings(): PotentialObjectSettings{
        return{
            type: "Direction",
            settings: {
                directionArray: this.getDirectionArray(),
                position: this.directionSettings.position
            }
        }
    }
    

    // createOppositeVisuals = (scene?: Scene)=>{
    //     const oppAction = this.getOppositeAction();
    //     if(!oppAction) return;
    //     if(oppAction.arrowObject && !oppAction.arrowObject.isDisposed()) return;
    //     console.log("Creating visuals - createOppositeVisuals")
    //     return oppAction.createVisuals(scene);
    // }
    createZeroEnergyOppAction = (scene: Scene, fromMeshDragger: boolean = false)=>{
        const oppActionExists = this.getOppositeAction() ? true : false;
        // console.log(`Checking is main scene in createZeroEnergyOppAction`)
        const isMain = isMainScene(scene);
        // console.warn(`Action ${this.id} checked if opp action exists: `, oppActionExists)
        if(oppActionExists) return;
        // console.warn(`Action ${this.id} is now creating opp action `, this.oppId);
        // const source = this.GOE.getObjectById(this.sourceId);
        // const target = this.GOE.getObjectById(this.targetId);
        const directionSettings: DirectionSettings = {
            ...this.directionSettings,
            // sourceId: this.targetId,
            // targetId: this.sourceId,
        }
        const oppAnimationSettings: Task = { 
            ...this.animationSettings,
            energyToTransfer: 0,
        }
        const oppPosition = this.calculateOppPosition();
        const spatialActionSettings: SpatialActionSettings = {
            id: `${this.oppId}`,
            directionId: this.oppId,
            energyId: this.energyId,
            sourceId: this.targetId,
            targetId: this.sourceId,
            sourcePosition: this.targetPosition,
            targetPosition: this.sourcePosition,
            position: oppPosition,
            directionSettings: directionSettings,
            animationSettings: oppAnimationSettings,
        }
        //  console.log(`Opp Action ${this.oppId} created`)
        const newSpatialAction = new SpatialAction(this.GOE, this.parent, spatialActionSettings, this)
        if(isMain && newSpatialAction) newSpatialAction.createVisuals(scene)
        if(isMain) fromMeshDragger ? this.parent.addMovedSpatialAction(newSpatialAction) : this.parent.addSpatialAction(newSpatialAction)
        // console.log("Scene: ", scene);
        // console.log("AFTER OPP CREATE - This.spatialActions: ", {...this.parent.spatialActions})
        return newSpatialAction;
    }
    createAction=()=>{
        // console.log(`Spatial action ${this.id} creating direction`)
        const direction = this.getOrCreateDirection();
        if(!direction) throw new Error(`Action ${this.id} failed to create direction`)
        //TODO: This is kinda weird, but no need to transfer 0 energy! Ran into an issue here.
        //Makes one think it is a good idea to combine directions, but I want to leave it open
        // console.log("Energy to transfer: ", this.animationSettings.energyToTransfer)
        if(this.animationSettings.energyToTransfer) this.GOE.mechanics.attemptTask(this.animationSettings)
        const involvedObjects = this.getInvolvedObjects();
        // console.log("Involved Objects: ", involvedObjects);
        for (let object of involvedObjects){
            if(!object){
                // console.log("Error: ", object);
                // console.warn("Invalid object in spatial action");
                continue;
            }
            object.switchColor("default");
        }
        // involvedObjects.forEach((object: any)=>{
        //     object.switchColor("default");
        // })
        // console.log(`Spatial action ${this.id} is removing self in createAction`);
        this.removeSelf()
        // this.removeObjects()
    }
    getExistingInvolvedObjectIds=()=>{
        return stripSpatialTag([this.energyId, this.sourceId, this.targetId]);
    }
    getExistingInvolvedObjects=()=>{
        const existingIds = this.getExistingInvolvedObjectIds();
        // console.log("Existing Ids: ", existingIds);
        return existingIds.map((id: string)=>{
            return this.GOE.getObjectById(id);
        });
    }
    getInvolvedObjectIds=()=>{
        return [this.energyId, this.sourceId, this.targetId];
    }
    getInvolvedObjects=()=>{
        const involvedObjIds = this.getInvolvedObjectIds();
        // console.warn(`Action ${this.id} Involved obj ids: `,involvedObjIds);
        return involvedObjIds.map((id: string)=>{
            const obj = this.GOE.spatialActionCreator.getObjectById(id);
            // if(obj) console.log(`Action ${this.id} found obj with ${id}: `, obj);
            if(!obj){
                // console.log(`Action ${this.id} found that obj with ${id} is undefined`);
                // console.log("Obj: ", obj);
            }
            return this.GOE.spatialActionCreator.getObjectById(id);
        });
    }
    calculateDistanceBetweenSourceAndTarget = ()=>{
        const distance = calculateDistanceBetweenPoints(this.sourcePosition, this.targetPosition);
        return distance;
    }
    // calculateSelectorPosition = ()=>{
    //     const parentPosition = this.directionSettings.position;
    //     // console.log(`Target Id for Spatial Action ${this.id}: `, this.targetId);
    //     const target = this.GOE.getObjectById(this.targetId);
    //     // console.log("Target: ", target);
    //     const targetPosition = target.position;
    //     return getMidpoint(parentPosition, targetPosition);
    // }
    // calculateOppSelectorPosition = ()=>{
    //     const parentPosition = this.directionSettings.position;
    //     const sourcePosition = this.involvedObjects.source.position;
    //     return getMidpoint(parentPosition, sourcePosition);
    // }
    calculatePosition = ()=>{
        // const {source, target} = this.involvedObjects;
        const anchorPosition = getMidpoint(this.sourcePosition, this.targetPosition);
        const position = getMidpoint(anchorPosition, this.targetPosition);
        return position;
    }
    calculateOppPosition = ()=>{
        const anchorPosition = getMidpoint(this.targetPosition, this.sourcePosition);
        const position = getMidpoint(anchorPosition, this.sourcePosition);
        return position;
    }
    doesObjectMatch = (objId: string)=>{
        return objId === this.id || objId === this.directionId;
    }
    getDistanceBetweenSourceAndTarget = ()=>{
        return this.distanceBetweenSourceAndTarget;
    }
    getPosition = ()=>{
        return this.position;
    }
    createSelector=(scene?: Scene)=>{
        // console.log(`Creating selector for Action ${this.id}`)
        const distanceBetweenSourceAndTarget = this.getDistanceBetweenSourceAndTarget();
        const potentialSize = distanceBetweenSourceAndTarget*.1;
        const sceneToUse = scene ? scene : this.GOE.scene;
        const size = potentialSize > 1 ? 1 : potentialSize 
        var box = MeshBuilder.CreateBox(this.id, { size: .05 }, sceneToUse);
        box.metadata = {}
        box.metadata.id = this.id;
        const {x,y,z} = this.getPosition();
        // console.log(`Position for ${this.id} - : ${x},${y},${z}`);
        // console.log("distanceBetweenSourceAndTarget: ", distanceBetweenSourceAndTarget);
        // console.log("Size: ", size);
        //TODO: Why can't I set direction position for Y :|
        box.position = new Vector3(x,DIRECTION_HOVER_HEIGHT*1.5,z);
        box.actionManager = new ActionManager(sceneToUse);
        ((GOE, self)=>{
            box.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function(ev){	
                GOE.controls.onObjectSelect(self);
            }));
            box.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function(ev){	
                // if(!direction.isVisible) return;
                const {selectedObject, selectedSource, selectedTarget} = GOE.controls;
                const isSelectedCell = selectedObject && self.doesObjectMatch(selectedObject.id);
                const isSelectedSource = selectedSource && self.doesObjectMatch(selectedSource.id);
                const isSelectedTarget = selectedTarget && self.doesObjectMatch(selectedTarget.id);

                const isSelected = isSelectedCell || isSelectedSource || isSelectedTarget
                if(!isSelected){
                    // direction.switchColor('hover');
                    self.selector.scaling = new Vector3(1.2, 1.2, 1.2); // Increase size by 20%
                }
            }));
            box.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function(ev){	
                // if(!self.isVisible) return;
                const {selectedObject, selectedSource, selectedTarget} = GOE.controls;
                const isSelectedCell = selectedObject && self.doesObjectMatch(selectedObject.id);
                const isSelectedSource = selectedSource && self.doesObjectMatch(selectedSource.id);
                const isSelectedTarget = selectedTarget && self.doesObjectMatch(selectedTarget.id);

                const isSelected = isSelectedCell || isSelectedSource || isSelectedTarget
                if(!isSelected){
                    // direction.switchColor('default');
                    self.selector.scaling = new Vector3(1, 1, 1); // Reset to original size
                }
            }));
        })(this.GOE, this)
        return box;
    }
    switchColor=(state: ObjectSelectionStates)=>{
        if(!this.selector) return;
        //Switching the folor of the selector
        if(state === "default"){
            changeBoxColor(this.selector, new Color4(1,1,1,1))
            this.energyScroller && this.energyScroller.dispose();
            this.energyScroller = null;
            // this.spatialMode = false;
        }
        if(state === "cell"){
            const color = new Color4(0.721568627, 0.125490196, 0.145098039, 1);
            changeBoxColor(this.selector, color);
            this.createEnergyScroller();

        }
        if(state === "source"){
            const color = new Color4(0.0862745098,0.501960784,0.564705882, 1);
            changeBoxColor(this.selector, color);
        }
        if(state === "target"){
            const color = new Color4(0.874509804,1,0, 1);
            changeBoxColor(this.selector, color);
        }
        if(state === "spatial"){
            // console.log("Switching to spatial: ", this.id)
            //Slate Gray
            const color = new Color4(0.44, 0.5, 0.56, 1);
            changeBoxColor(this.selector, color);
            // this.spatialMode = true;
        }
        // this.setOppSelectorClicked(false);
    }
    createEnergyScroller=()=>{
        const energy = this.getEnergyLevel();
        this.energyScroller = new EnergyScroller({GOE: this.GOE, energyScrollerValue: energy, parent: this})
    }
    getSpatialDirectionArray=()=>{
        return [this.sourceId, this.targetId]
    }
    getOppositeSpatialDirectionArray=()=>{
        return [this.targetId, this.sourceId]
    }
    getOppositeId = ()=>{
        const oppArray = this.getOppositeSpatialDirectionArray();
        const oppId = convertDirectionArrayToString(oppArray);
        return `${oppId}-SA`;
    }
    getDirectionIndex = ()=>{
        return this.directionIndex;
    }
    getOppositeDirectionIndex = ()=>{
        return getOppositeDirectionStringFromString(this.getDirectionIndex());
    }
    getOrCreateDirection = ()=>{
        const possibleDirection = this.GOE.causalField.getDirection(this.directionSettings.directionArray);
        if(possibleDirection) return possibleDirection;
        // if(!possibleDirection) throw new Error(`Direction ${this.directionSettings.id} doesn't exist yet!`)
        // console.log(`Get direction for spatial action ${this.id}: `, this.getDirectionArray())
        const directionArray = this.getDirectionArray();
        const isIdentical = isSourceAndTargetIdentical(directionArray);
        if(isIdentical) throw new Error("SpatialAction createDirection - Source and target identical")
        return this.GOE.causalField.getOrCreateDirection(this.getDirectionArray())
    }
    // getOppositeAction = ()=>{
    //     // console.log("Spatial Actions: ", this.parent.spatialActions)
    //     if(this.oppAction) return this.oppAction;
    //     return this.parent.spatialActions[this.oppId] || this.getOppFromSavedAction();
    // }
    getOppositeAction = ()=>{
        // console.log("Spatial Actions: ", this.parent.spatialActions)
        if(this.oppAction){
            // console.warn(`Action ${this.id} getting opp action from property`)
            // console.log("Opp Action: ", this.oppAction);
            return this.oppAction;
        }
        const fromSavedAction = this.getOppFromSavedAction();
        if(this.savedActionId && fromSavedAction){
            // console.warn(`Action ${this.id} is getting opp action from saved actions`);
            // console.log("Opp Action: ", fromSavedAction)
            return fromSavedAction;
        }
        const fromMovedSpatialActions = this.parent.movedSpatialActions[this.oppId];
        if(fromMovedSpatialActions){
            // console.warn(`Action ${this.id} getting opp action from PARENT`);
            // console.log("Opp Action: ", fromParent);
            return fromMovedSpatialActions
        }
        const fromParent = this.parent.spatialActions[this.oppId];
        if(fromParent){
            // console.warn(`Action ${this.id} getting opp action from PARENT`);
            // console.log("Opp Action: ", fromParent);
            return fromParent
        }
        // console.warn(`Action ${this.id} failed to get opp action`)
        return undefined;
        // return  || this.getOppFromSavedAction();
    }
    getParentSavedAction=()=>{
        // console.log("GOE: ", this.GOE)
        // console.log("Spatial action viewer: ", this.GOE.spatialActionViewer)
        if(this.GOE.spatialActionViewer.savedActions) return this.GOE.spatialActionViewer.savedActions[this.savedActionId];
        else return [];
    }
    getOppFromSavedAction=()=>{
        const action = this.getParentSavedAction();
        // console.log("Saved action id: ", this.savedActionId);
        // console.log("Saved Action: ", action);
        if(!action) return undefined;
        const filteredArray = action.filter((action: SpatialAction)=> action.id === this.oppId);
        if(filteredArray.length) return filteredArray[0];
        else return undefined;
    }
    setSavedActionId = (id: string)=>{
        this.savedActionId = id;
    }
    getEnergyLevel = ()=>{
        return this.animationSettings.energyToTransfer;
        // return this.animationSettings.amount;
    }
    removeSelf = (viewerMode?: boolean)=>{
        // console.log("Removing self: ", this.id);
        // console.log("Viewer Mode?: ", !!viewerMode);
        this.removeObjects();
        if(viewerMode) return;
        const energyObj = this.GOE.getObjectById(this.energyId);
        this.GOE.spatialEnergyManager.releaseReservation(this.getEnergyLevel(), energyObj);
        // this.GOE.spatialPartioner.removeObjectFromSubspace(this);
        if(this.parent.spatialActions[this.id]){
            return delete this.parent.spatialActions[this.id];
        }
        // delete this.parent.spatialActions[this.id];
        // console.warn(`Action ${this.id} not found on deletion`)
    }
    removeSelfFromMovedDirection = (viewerMode?: boolean)=>{
        // console.log("Removing self: ", this.id);
        // console.log("Viewer Mode?: ", !!viewerMode);
        this.removeObjects();
        if(viewerMode) return;
        const energyObj = this.GOE.getObjectById(this.energyId);
        this.GOE.spatialEnergyManager.releaseReservation(this.getEnergyLevel(), energyObj);
        // this.GOE.spatialPartioner.removeObjectFromSubspace(this);
        if(this.parent.movedSpatialActions[this.id]) return delete this.parent.movedSpatialActions[this.id];
    }
    removeObjects = ()=>{
        // console.log(`Direction ${this.id} removing objects`);
        this.selector && this.selector.dispose();
        this.arrowObject && this.arrowObject.dispose()
        this.energyScroller && this.energyScroller.dispose()
        this.energyDisplayer && this.energyDisplayer.dispose()

        this.energyDisplayer = null;
        this.arrowObject = null;
        this.selector = null;
        this.energyScroller = null;

        // console.log("Energy Displayer: ", this.energyDisplayer);
        // console.log("Selector: ", this.selector);
        // console.log("Energy Scroller: ", this.energyScroller);
        // console.log("arrow object: ", this.arrowObject);
        // console.log("Energy Displayer: ", this.energyDisplayer.energyScroller.isDisposed());
        // console.log("Selector: ", this.selector.isDisposed());
        // console.log("Energy Scroller: ", this.energyScroller.energyScroller.isDisposed());
        // console.log("arrow object: ", this.arrowObject.isDisposed());
    }
    setSubspaceId(id: any){
        this.subspaceId = id;
    }
    toJSON=():SpatialActionsJson=>{
        // Only include essential properties
        const serializedSourceSettings: SerializedPotentialObjectSettings = {
            type: this.animationSettings.source.type,
            //@ts-ignore
            settings: {
                ...this.animationSettings.source.settings,
                position: serializeVector3(this.animationSettings.source.settings.position)
            }
        };
        const serializedTargeteSettings: SerializedPotentialObjectSettings = {
            type: this.animationSettings.target.type,
            //@ts-ignore
            settings: {
                ...this.animationSettings.target.settings,
                position: serializeVector3(this.animationSettings.target.settings.position)
            }
        };
        return {
            directionId: this.directionId,
            id: this.id,
            timestamp: this.timestamp,
            positionIndex: this.positionIndex,
            position: serializeVector3(this.position),
            sourcePosition: serializeVector3(this.sourcePosition),
            targetPosition: serializeVector3(this.targetPosition),
            directionIndex: this.directionIndex,
            energyId: this.energyId,
            sourceId: this.sourceId,
            targetId: this.targetId,
            directionSettings: {
                ...this.directionSettings,
                GOE: null,
                causalField: null,
                position: serializeVector3(this.directionSettings.position)
            },
            // animationSourceId: this.animationSettings.source.id,
            //TODO: Types
            animationSettings:{
                ...this.animationSettings,
                source: serializedSourceSettings,
                target: serializedTargeteSettings,
            }
        };
    }
}

export default SpatialAction;

