import { COLORS } from "../constants"
import chessboardImage from '../assets/ChessBoard.jpg';

const EleaticGamesAnalogy = ({isMobile, children}: any)=>{
    const ChessboardImage = ()=>{
        return(
        <div style={{
            minHeight: isMobile ? '150px' : 'none',
            width: isMobile ? '100%' : '50%',
            backgroundImage: `url(${chessboardImage})`,
            backgroundSize: 'cover',
          }}/>
        )
    }
  
    const ChessBoardExplanation=()=>{
    return(
      <div style={{
        // flex: 1,
        width: isMobile ? '100%' : '50%',
        textAlign: 'left',
        fontWeight: '500',
        minHeight: isMobile ? '150px' : 'none',
        // textAlign: isMobile ? 'center' : 'left',
        // borderTop: 'solid black 2px',
        // justifyContent: 'center',
        // fontSize: '.8em',
        backgroundColor: '#E6CCA1',
        color: 'black',
      }}>
        <div style={{margin: '5%', marginTop: '5%', marginBottom: '5%'}}>
            Eleatic Game Design makes an analogy between the way that matter moves and the actions of a player in a game.
        </div>
        <div style={{ margin: '5%', marginTop: '5%', marginBottom: '5%'}}>
            Any game works, but let's consider chess.
        </div>
        <div style={{margin: '5%', marginTop: '5%', marginBottom: '5%'}}>
            Professional chess players would be far more likely than novices to play a game of chess that remains brilliant and exciting to watch over a long period of time. Whereas novices may not even properly obey the rules of chess.
        </div>
        <div style={{margin: '5%', marginTop: '5%', marginBottom: '5%'}}>
            Thus, the brilliance in the way chess pieces move depends on the skilled actions of the players. Similarly, the "brilliance" in the way that physical matter moves depends on "something" about the dynamics of the forces.
        </div>
        <div style={{margin: '5%', marginTop: '5%', marginBottom: '5%'}}>
            The physical laws that govern the way matter moves in our universe are unique. So much so, that the universe appears <a href={"https://en.wikipedia.org/wiki/Fine-tuned_universe"}>"fine-tuned"</a> specifically so that we can exist.
        </div>
        <div style={{margin: '5%', marginTop: '5%', marginBottom: '5%'}}>
            Eleatic Games are designed so that the rules of the game correspond to physical laws, the pieces to fundamental particles, and the actions of one or more players as forces that, in part, drive the movement of the pieces.
        </div>
        <div style={{margin: '5%', marginTop: '5%', marginBottom: '5%'}}>
            This can make it easier to conceptualize physical laws, their implications, and to construct Eleatic Games that can help us understand our universe
        </div>
        {children}
      </div>
    )
    }

    return(
        <div style={{
            //   flex: 1,
              // height: '100%',
              width: '100%',
              color: 'black',
              borderTop: `solid ${COLORS.pastelGreen} 1px`,
              display: 'flex',
              flexDirection: isMobile ? 'column-reverse' : 'row',
              justifyContent: 'flex-start'
            }}>
                <ChessBoardExplanation/>
                <ChessboardImage/>
            </div>
    )
}

export default EleaticGamesAnalogy;