import { Vector3 } from "@babylonjs/core";
import { DIRECTION_HOVER_HEIGHT } from "../constants";
import { PossibleObject } from "../types";
import getMidpoint from "./getMidpoint";
import calculateDistanceBetweenPoints from "./calculateDistanceBetweenPoints";

const calculateDirectionPosition = (source: PossibleObject, target: PossibleObject)=>{
        const anchorPosition = getMidpoint(source.getPosition(), target.getPosition());
        let position = getMidpoint(anchorPosition, target.getPosition());
        // if(position.y > 0){
        //         console.warn("Position was larger than 0")
        //         console.log("Source Position: ", source.position);
        //         console.log("Target Position: ", target.position);
        // }
        // console.log(`${source.id}-${target.id} y position: `, position.y);
        // return new Vector3(0,0,0)
        return new Vector3(position.x,0,position.z)
        // const distance = calculateDistanceBetweenPoints(source.position, target.position);
        // return new Vector3(position.x,DIRECTION_HOVER_HEIGHT+(1/distance),position.z)
        // return position;
}

export default calculateDirectionPosition;