// import { Dimensions, Platform } from "react-native"
// import { deviceYearClass } from "expo-device";
// export const isDeviceOld = deviceYearClass && (deviceYearClass <= 2015);

export const COLORS ={
    primaryRed: '#DD222E',
    primaryGray: '#F8FAFC',
    bgGray: '#1F1F1F',
    slateGray: '#232323',
    deepRed: '#AA1010',
    pastelGreen: '#64a460',
    darkPastelGreen: '#3e673b',
    // darkPastelGreen: '#467543',
    ivonaBrown: '#593715',
    primaryBlack: '#000',
    cream: '#E6CCA1',
    darkCream: '#6b4e1d',
    // darkCream: '#a97b2d',
    // primaryGray: '#3B4044',
    // light: {
    //   text: '#000',
    //   background: '#fff',
    //   tint: tintColorLight,
    //   tabIconDefault: '#ccc',
    //   tabIconSelected: tintColorLight,
    // },
    // dark: {
    //   text: '#fff',
    //   background: '#000',
    //   tint: tintColorDark,
    //   tabIconDefault: '#ccc',
    //   tabIconSelected: tintColorDark,
    // },
    inactiveTabColor: '#D0CECE',
    primaryTextColor: '#011C42',
    primaryBgColor: '#F5F5F5',
    primaryOrange: '#FF9026',
  
    greenAccent: '#25b820',
  
    //This is from Flow Arts Meetup
    primaryBlue: '#4285f4',
    // primaryRed: '#B82025',
    primaryPurple: '#901680',
    primaryGreen: '#25b820',
    // primaryGray: '#f2f2f2',
    grayText: '#617D8A',
}

// export const SCREEN_SIZE: any = (()=>Platform.OS === 'web' ? Dimensions.get('window') : Dimensions.get('screen'))()


