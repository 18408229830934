import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import EleaticFieldRelations from '../components/EleaticFieldRelations';
import DisplayExplanation from '../components/DisplayExplanation';
import EleaticGamesIntroduction from '../components/EleaticGamesIntroduction';
import EleaticGamesAnalogy from '../components/EleaticGamesAnalogy';
import { COLORS } from '../constants';
import chessboard from '../assets/ChessBoard.jpg'
import Card from '../components/Card';


const Home = (props: any) => {
  const { isMobile } = props;

  const [explanationView, setExplanationView] = useState('Neuroscience & Biology');
  const [showEleaticGameAnalogy, setShowEleaticGameAnalogy] = useState(false);
  const [isSwiping, setIsSwiping] = useState(false);
  const eleaticFieldRelationsRef = useRef(null);
  

  const EleaticGamePointOne = () => {
    const showText = 'Eleatic Game Design is a novel framework for developing physically plausible simulations';
    const hideText = 'Eleatic Game Design makes an analogy between the way that matter moves and the actions of a player in a game.';
    return (
      <div style={{
        width: '100%',
        backgroundColor: COLORS.primaryBlack,
        display: 'flex',
        justifyContent: 'center',
        backgroundImage: showEleaticGameAnalogy ? 'none' : `url(${chessboard})`,
        backgroundSize: 'cover',
        backgroundPositionY: '50%',

      }}>
        <div style={{
          width: '100%',
          backgroundColor: 'rgba(0,0,0,.3)',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'space-around',
          flexDirection: 'column',
          height: isMobile ? '15vh' : '12.5vh',
          paddingTop: 5,
          paddingBottom: 5,
        }}>
          <div style={{
            // height: '100%',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            width: '60%',
            minWidth: '300px',
            textAlign: 'center',
            fontWeight: '600',
            color: 'white',
          }}>
            {showEleaticGameAnalogy ? showText : hideText}
          </div>
          <Card
          onClick={()=>{
            setShowEleaticGameAnalogy(!showEleaticGameAnalogy)
          }}
          style={{
            borderRadius: showEleaticGameAnalogy ? 0 : 15,
            backgroundColor: showEleaticGameAnalogy ? 'rgba(255,255,255,.5)' : 'rgba(62,103,59,.9)',
            color: showEleaticGameAnalogy ? 'black' : 'white',
            padding: 10,
            // border: 'solid #fff 1px',
          }}>
            {showEleaticGameAnalogy ? `Hide text` : `${isMobile ? 'Tap' : 'Click'} to learn more`}
          </Card>
        </div>
      </div>
    )
  }

  const EleaticGamePointTwo = () => {
    return (
      <div style={{
        width: '100%',
        backgroundColor: COLORS.primaryBlack,
        display: 'flex',
        justifyContent: 'center',
      }}>
        <div style={{
          paddingTop: 10,
          paddingBottom: 10,
          width: '60%',
          minWidth: '300px',
          textAlign: 'center',
          fontWeight: '600',
          color: 'white',
        }}>
          Proposals for research programs around Eleatic Game Design in Neuroscience & Biology, Artificial Life & Intelligence and Physics
        </div>
      </div>
    )
  }
  const EleaticGamePointThree = () => {
    return (
      <div style={{
        width: '100%',
        backgroundColor: COLORS.primaryBlack,
        display: 'flex',
        justifyContent: 'center',
      }}>
        <div style={{
          paddingTop: 10,
          paddingBottom: 10,
          width: '60%',
          minWidth: '300px',
          textAlign: 'center',
          fontWeight: '600',
          color: 'white',
        }}>
          Purpose
        </div>
      </div>
    )
  }

  return (
    <div style={{
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center', alignItems: 'center',
    }}>
      <Helmet>
        <title>Eleatic Games</title>
        <meta name="description" content="Eleatic Games - Open-ended evolutionary games framework" />
        <meta name="keywords" content="Eleatic Games, evolutionary games, open-ended evolution, Ty Roachford" />
        <meta name="author" content="Ty Roachford" />
        <meta property="og:title" content="Eleatic Games" />
        <meta property="og:description" content="A framework for the creation of open-ended evolutionary games" />
        <meta property="og:image" content="%PUBLIC_URL%/images/image63.png" />
        <meta property="og:url" content="https://www.eleaticgames.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Eleatic Games" />
        <meta name="twitter:description" content="A framework for the creation of open-ended evolutionary games" />
        <meta name="twitter:image" content="%PUBLIC_URL%/images/image63.png" />
      </Helmet>

      <EleaticGamesIntroduction isMobile={isMobile} />
      <EleaticGamePointOne />
      {
        showEleaticGameAnalogy &&
        <EleaticGamesAnalogy isMobile={isMobile}>
          <div
            onClick={()=>{
              setShowEleaticGameAnalogy(!showEleaticGameAnalogy)
            }}
            style={{
              borderRadius: showEleaticGameAnalogy ? 0 : 15,
              backgroundColor: 'rgba(0,0,0,.9)',
              color: 'white',
              padding: 10,
              textAlign: 'center',
            }}>
              {`Hide previous text`}
          </div>
        </EleaticGamesAnalogy>
      }
      <EleaticGamePointTwo />

      <EleaticFieldRelations
        isSwiping={isSwiping}
        setIsSwiping={setIsSwiping}
        ref={eleaticFieldRelationsRef}
        isMobile={isMobile}
        explanationView={explanationView}
        setExplanationView={setExplanationView}
      />
      <DisplayExplanation
        isMobile={isMobile}
        isSwiping={isSwiping}
        setIsSwiping={setIsSwiping}
        explanationView={explanationView}
        setExplanationView={setExplanationView}
        eleaticFieldRelationsRef={eleaticFieldRelationsRef} // Pass the ref to DisplayExplanation
      />
      {/* <EleaticGamePointThree /> */}

    </div>
  )
}

export default Home;





