import React, { useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Game from './components/Game';
import BabyljonScene from './components/BabyljonScene';
import { COLORS } from './constants';
import woodImage from './assets/wood.jpg';
import japStones from './assets/japStones.webp';
import japRings from './assets/japRings.png';
import japWall from './assets/japWall.png';
import GoogleDocumentViewer from './components/GoogleDocumentViewer';
import Card from './components/Card';
import Home from './pages/Home';
import { FaXTwitter } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa";
import openInNewTab from './helpers/openInNewTab';



function App() {

  const [currentPage, setCurrentPage] = useState<string | "Home" | "Paper" | "Game">("Home");
  const documentURL = 'https://docs.google.com/document/d/1X9QrhDENhB72yHhh2y4bG0H5B0JZu9f7yPea7ZcT_zI/edit?usp=sharing';
  const documentId = '1X9QrhDENhB72yHhh2y4bG0H5B0JZu9f7yPea7ZcT_zI';
  
  const getScreenWidth = () => {
    // console.log("Inner width: ", window.innerWidth)
    // console.log("Screen width: ", window.screen.width)
    // return window.screen.width
    return window.innerWidth
  };
  const getScreenHeight = () => {
    // console.log("Inner height: ", window.innerHeight)
    // console.log("Screen height: ", window.screen.height)
    // return window.screen.height
    return window.innerHeight
  };

  const [fullScreen, setFullScreen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [screenHeight, setScreenHeight] = useState(getScreenHeight());
  const isMobile = screenWidth < 980;

  useEffect(() => {
    const onChange = () => {
      const width = getScreenWidth();
      setScreenWidth(width);

      const height = getScreenHeight();
      setScreenHeight(height);
    };

      window.addEventListener('resize', onChange);
      // window.addEventListener('keydown', onFullScreen);

    return () => {
        window.removeEventListener('resize', onChange);
        // window.removeEventListener('keydown', onFullScreen);
    };
  }, []);

  // const onFullScreen = (event: any)=>{
  //   if(event.key === 'f'){
  //     console.log("Full Screen: ", fullScreen);
  //     console.log(`Turning full screen ${!fullScreen ? "on" : "off"}`);
  //     setFullScreen(!fullScreen);
  //   }
  // }

  const Footer = ()=>{
    return(
      <div style={{
        // flex: 1,
        // height: '10%',
        // padding: 5,
        // position: 'sticky',
        // bottom: 0,
        // boxShadow: '0px -15px 5px rgba(0, 0, 0, .7)',
        // position: isMobile ? 'sticky' : 'absolute',
        position: 'sticky',
        // position: isMobile ? 'sticky' : 'absolute',
        bottom: 0,
        paddingTop: 10,
        paddingBottom: 10,
        // height: '100px',
        
        width: isMobile ? '100vw' : '85vw', 
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: COLORS.primaryBlack,

        // border: "solid white 5px",

        // borderBottom: currentPage === 'Home' ? 'none' : "solid white 2px"
        // borderBottom: currentPage === 'Home' ? 'none' : "solid white 2px"
      }}>
        {/* <EleaticPrincipleBanner/> */}
        <div
          onClick={()=>openInNewTab('https://x.com/Tyfoods4Thought')}
          style={{alignItems: 'center', alignContent: 'center', display: 'flex', flexDirection: 'row'}}>
          <FaXTwitter color={COLORS.pastelGreen} />
          <div style={{marginLeft: '5px', color: 'white', justifyContent: 'center'}}>@Tyfoods4Thought</div>
        </div>
        <div
          onClick={()=>openInNewTab('https://github.com/tyfoods')}
          style={{ alignItems: 'center', alignContent: 'center', display: 'flex', flexDirection: 'row'}}>
          <FaGithub color={COLORS.pastelGreen} />
          <div style={{marginLeft: '5px', color: 'white', justifyContent: 'center'}}>@Tyfoods</div>
        </div>
      </div>
    )
  }

  const Header = ()=>{
    const borderRadius = isMobile ? 0 : 5;
    return(
      <Card style={{
        // flex: 1,
        // height: '300px',
        // padding: 5,
        // position: isMobile ? 'sticky' : 'relative',
        position: 'sticky',
        top: isMobile ? 0 : '1vh',
        zIndex: 1000,
        paddingTop: 10,
        paddingBottom: 10,
        width: isMobile ? '100vw' : '85vw', 
        display: 'flex',
        flexDirection: isMobile ? 'column-reverse' : 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: isMobile ? 'center' : 'space-around',
        backgroundColor: COLORS.primaryBlack,
        borderRadius: borderRadius,
        // borderBottomRightRadius: borderRadius,
        // borderBottomLeftRadius: borderRadius,

        border: isMobile ? 'none' : `solid ${COLORS.pastelGreen} 1px`,
        borderBottom: `solid ${COLORS.pastelGreen} 1px`,


        // border: "solid white 2px",

        // borderBottom: currentPage === 'Home' ? 'none' : "solid white 2px"
        // borderBottom: currentPage === 'Home' ? 'none' : "solid white 2px"
      }}>
        {currentPage === 'Home' && <EleaticPrincipleBanner/>}
        <HeaderButtons/>
      </Card>
    )
  }

  const EleaticPrincipleBanner = ()=>{
    return(
      <div style={{
        flex: .625,
        // height: '62.5%',
        // height: '100vh',
        // border: 'solid pink 3px',
        borderTop: isMobile ? `solid ${COLORS.pastelGreen} 2px` : 'none',
      }}>
        <div style={{
          textAlign: isMobile ? 'center' : 'left',
          width: isMobile ? '100vw' : 'auto',
          paddingTop: isMobile ? '1vh' : '0vh',
          paddingBottom: isMobile ? '1vh' : '0vh',
          color: 'white', fontWeight: '600', fontSize: '1em',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          
          }}>
          "To exist is to be the cause of an effect" - Plato's Eleatic Principle
        </div>
        <div style={{
          fontSize: '.8em',
          color: 'red',
        }}>
          *This website is still in construction, but it exists
        </div>
      </div>
    )
  }

  const HeaderButtons = () => {
    const buttons = [
        { title: "Home", route: "Home" },
        { title: "Paper", route: "Paper" },
        { title: "Game", route: "Game" },
        // Add more routes as needed
    ];

    return (
        <div style={{
          flex: .30,
          // height: '30%',
          minWidth: '200px',
          // minHeight: '100px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignContent: 'center',
          alignItems: 'center',
          paddingTop: '10px',
          paddingBottom: '10px',

          // border: "solid yellow 3px"
        }}>
            {buttons.map((button) => (
                <div
                    style={{display: 'flex', alignContent: 'center', alignItems: 'center',}}
                    key={button.title}
                    onClick={() =>setCurrentPage(button.title)}
                >
                    <div style={{
                        fontSize: '1em',
                        textAlign: 'center',
                        fontWeight: button.title === currentPage ? '700' : '600',
                        // color: '#838383'
                        // color: button.title === currentPage ? COLORS.primaryBlue : 'gray'
                        color: button.title === currentPage ? COLORS.pastelGreen : '#E4E8E7',
                        borderBottom: button.title === currentPage ? `solid ${COLORS.pastelGreen} 1px` : "none",
                        // textDecorationColor: "#AA1010"
                        // color: button.title === currentPage ? 'white' : COLORS.slateGray
                        // color: button.title === currentPage ? COLORS.primaryBlue : '#838383'
                    }}>
                        {button.title}
                    </div>
                </div>
            ))}
        </div>
    );
};

  

  const PageContent = (props: any)=>{
      const { currentPage } = props;
      return(
        <Card className="page-content" style={{
          // flex: 1,
          // height: '100%',
          paddingTop: isMobile ? 0 : '2.5vh',
          // borderRadius: 10,
          width: (isMobile || currentPage === 'Game') ? '100%' : '85%',
          alignSelf: 'center',
          // borderTop: `solid ${COLORS.pastelGreen} 2px`,
          // border: `solid ${COLORS.pastelGreen} 1px`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',

          // border: 'solid pink 3px' 
        }}>
          {(currentPage === "Home") && <Home isMobile={isMobile}/>}
          {(currentPage === "Paper") && <GoogleDocumentViewer documentId={documentId}/>}
          {(currentPage === "Game") && <BabyljonScene fullScreen={fullScreen} setFullScreen={setFullScreen}/>}
        </Card>
      )
  }


  return (
    <div className="App" style={{
        // background: 'linear-gradient( rgba(0,0,0, .1), rgba(0,0,0, 1));',
        // paddingTop: '10vh',
        minHeight: '100vh',
        width: '100vw',
        backgroundAttachment: 'fixed',
        // backgroundImage: `url(${japRings})`,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, .2)), url(${japRings})`,
        // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, .5)), url(${woodImage})`,
        // filter: 'blur(8px)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}>
      {!fullScreen && <Header/>}
      <PageContent currentPage={currentPage} />
      {!fullScreen && <Footer/>}
    </div>
  );
}

export default App;
