import { PossibleObject } from "../types";
import GameOfExistence from "./GameOfExistence";

// interface EnergyObject {
//     id: string;
//     energyLevel: number;
// }

// interface Reservation {
//     objectId: string;
//     reservedEnergy: number;
// }

class SpatialEnergyManager {
    private reservations: Map<string, number>;
    GOE: GameOfExistence;

    constructor(GOE: GameOfExistence) {
        this.GOE = GOE;
        this.reservations = new Map<string, number>();
    }

    clearReservations = ()=>[
        this.reservations = new Map()
    ]
    getReservations = ()=>{
        return this.reservations;
    }
    isObjectStored = (id: string): boolean =>{
        return this.reservations.has(id)
    }
    canReserveEnergy=(demand: number, object: PossibleObject): boolean=> {
        if(this.isObjectStored(object.id)){
            const freeEnergy = this.getFreeEnergy(object);
            const isSufficient = this.isEnergySufficient(demand, freeEnergy)
            return isSufficient;
        }
        const goeObject = this.GOE.getObjectById(object.id);
        // console.log("Goe Object: ", goeObject);
        const freeEnergy = goeObject.getEnergyLevel();
        if(this.isEnergySufficient(demand, freeEnergy)){
            return true;
        }
        else return false;
    }
    isEnergySufficient=(demand: number, energySourceAmount: number)=>{
        if(demand>energySourceAmount) return false;
        return true;
    }
    reserveEnergy=(demand: number, object: PossibleObject)=>{
        //Just going to store the object if energy is sufficient, makes sense.
        // if(!this.isObjectStored(object.id)) throw new Error(`Object ${object.id} calling reserveEnergy before being stored`)
        // const stillPossible = this.canReserveEnergy(demand, object);
        // if(!stillPossible) alert("There isn't enough energy for this action");
        const freeEnergy = this.getFreeEnergy(object);
        if(this.isEnergySufficient(demand, freeEnergy)){
            const currentlyReserverdEnergy = this.isObjectStored(object.id) ? this.reservations.get(object.id) : 0;
            // console.log(`Reserving ${demand} energy for ${object.id}`);
            this.reservations.set(object.id, currentlyReserverdEnergy + demand);
            return true;
        }
        else return false;
        // else alert("There isn't enough energy for this action");
    }
    releaseReservation=(releasedFreeEnergy: number, object: PossibleObject)=>{
        //TODO: I think this is not important
        if(!this.isObjectStored(object.id)) return;
        // if(!this.isObjectStored(object.id)) return console.warn(`Release reservation of non-stored obj ${object.id}`)
        // if(!this.isObjectStored(object.id)) throw new Error(`Release reservation of non-stored obj ${object.id}`)
        const currentlyReserverdEnergy = this.reservations.get(object.id);
        // console.log(`Releasing ${releasedFreeEnergy} energy for ${object.id}`);
        this.reservations.set(object.id, currentlyReserverdEnergy - releasedFreeEnergy);
    }
    getFreeEnergy=(object: PossibleObject): number=> {
        const goeObject = this.GOE.getObjectById(object.id);
        const potentialFreeEnergy = goeObject.getEnergyLevel();
        if(this.isObjectStored(object.id)){
            const currentlyReserverdEnergy = this.reservations.get(object.id);
            return potentialFreeEnergy - currentlyReserverdEnergy;
        }
        return potentialFreeEnergy;
    }
}

export default SpatialEnergyManager;