import { ActionManager, Color3, DynamicTexture, ExecuteCodeAction, Mesh, MeshBuilder, StandardMaterial, Vector3 } from "@babylonjs/core";
import GameOfExistence from "./GameOfExistence";
// import { GridMaterial } from "@babylonjs/materials";
import getConsecutiveRanges from "../helpers/getConsecutiveRanges";
import isPointNeighborOfCell from "../helpers/isPointNeighborOfCell";
import EnergyScroller from "./EnergyScroller";
import { CorrelateEnergyTransfer, EnergyTransferAnimation, ExistingObject, GridCellSettings, ObjectSelectionStates, PossibleObject, Subspace, PotentialObjectSettings, Task } from "../types";
import Direction from "./Direction";
import isObjEmpty from "../helpers/isObjEmpty";
import SpatialAction from "./SpatialAction";
import Neighborhood from "./Neighborhood";
import { GridMaterial } from "@babylonjs/materials";

export default class GridCell {
    id: string = ""
    positionIndex: string;
    isDissipating: boolean = false;
    private position: Vector3;
    index: string;
    mesh: Mesh;
    GOE: GameOfExistence
    particleAddresses: number[] = [];
    particleQueue: number[] = [];
    neighbors: ExistingObject[] = [];
    isVisible: boolean;
    spatialMode: boolean = false;
    unit: number;
    type: "GridCell";

    directionalEnergy: any = {};
    dynamicTexture: DynamicTexture;
    energyScroller: EnergyScroller = null;
    correlatingWith: string[] = [];
    subspaceId: any = null;
    neighborIds: string[] = [];
    initEnergyLevel: number = 0;

    neighborhood: Neighborhood = null;
    inNeighborhood: boolean = false;

    shouldUpdateVisibility: boolean = true;

    constructor(settings: any){
        const {isOnOutSideOfGrid, GOE, initEnergyLevel, position, index, visibility} = settings;
        this.initEnergyLevel = initEnergyLevel;
        this.GOE = GOE;
        this.position = position;
        this.directionalEnergy[index] = 0;
        this.unit  = GOE.grid.cellSize;
        this.index = index;
        this.positionIndex = index;
        this.id = index;
        this.neighborhood = new Neighborhood(this);

        const neighborIds = this.computeNeighborIds();
        // console.log("Neighbor Ids: ", neighborIds);
        // console.log("This id: ", this.id);
        this.neighborIds = neighborIds;
        this.isVisible = visibility ? visibility : this.isIn3x3Grid();
        // console.log("Is visible? : ", this.isVisible);
        // this.isVisible = true;

        // console.log("Position: ", position);
        // this.addSelfToSubSpace();
        // this.setNeighbors();
        this.addSelfToNeighborhood();
        this.renderGridCell();

        // if(this.isVisible){
        //     console.log(`Neighbors of ${this.id}`, this.neighbors);
        // }
        // this.createPositionMarker();

    }

    isIn3x3Grid=()=>{
        return (this.neighborIds.includes('(0,0)') || this.id === '(0,0)')
    }
    setId=(id: string)=>{
        this.positionIndex = id;
        this.id = id;
        this.index = id;
    }
    createPositionMarker = ()=>{
        var box = MeshBuilder.CreateBox(this.id, { size: .1 }, this.GOE.scene);
        // changeBoxColor(box, new Color4(1,.01,.01,.8))
        var material = new StandardMaterial(`cause-${this.id}`, this.GOE.scene);
        material.alpha = 1;
        material.diffuseColor = new Color3(1, 1, 0);
        material.specularColor = new Color3(1, 1, 0); // Specular color for shiny surfaces

        box.material = material;
        const {x,y,z} = this.position
        box.position = new Vector3(x,y,z);
    }

    getNeighborIds = ()=>{
        return this.neighborIds;
    }
    getCurrentNeighbors = (): ExistingObject[] =>{
        return <ExistingObject[]>this.neighborhood.getNeighbors(['gridCells', 'directions']).filter((obj: ExistingObject)=>obj.id!==this.id);
    }
    // setNeighbors = ()=>{
    //     this.neighbors = <ExistingObject[]>this.neighborhood.getNeighbors(['gridCells', 'directions']);
    //     // this.neighbors = this.neighborIds.map((id)=>this.GOE.grid.cellData[id]);
    // }
    addSelfToNeighborhood = ()=>{
        this.neighborhood.addSelfToNeighborhood();
    }
    removeSelfFromNeighborhood = ()=>{
        this.neighborhood.removeSelfFromNeighborhood()
    }
    // addNewNeighbor = (obj: ExistingObject)=>{
    //     const isExistingNeighbor = this.neighbors.map((obj)=>{
    //         if(obj) return obj.id;
    //         return undefined
    //     }).includes(this.id);
    //     if(isExistingNeighbor) return false;
    //     this.neighbors.push(obj);
    // }
    switchColor=(state: ObjectSelectionStates)=>{
        if(state === "default"){
            //@ts-ignore
            // this.mesh.material.mainColor = new Color3(0,0,0);
            //@ts-ignore
            this.mesh.material.lineColor = new Color3(0,1,1);
            // @ts-ignore
            this.mesh.material.opacity = this.isVisible ? 1 : 0;
            this.energyScroller && this.energyScroller.dispose();
            this.energyScroller = null;
            this.spatialMode = false;
        }
        //@ts-ignore
        if(state === "hover") this.mesh.material.opacity = this.isVisible ? .9999999 : 0;
        
        if(state === "cell"){
            //@ts-ignore - PDC RED!
            // this.mesh.material.mainColor = new Color3(0.721568627,0.125490196,0.145098039);
            //@ts-ignore - PDC RED!
            this.mesh.material.lineColor = new Color3(0.721568627,0.125490196,0.145098039);
            // @ts-ignore
            // this.mesh.material.opacity = .5;
            this.createEnergyScroller();
        }
        if(state === "source"){
            //@ts-ignore  - PDC BLUE!
            // this.mesh.material.mainColor = new Color3(0.0862745098,0.501960784,0.564705882);
            //@ts-ignore  - PDC BLUE!
            // this.mesh.material.lineColor = new Color3(0.0862745098,0.501960784,0.564705882);
            this.mesh.material.lineColor = new Color3(1,1,1);
            // @ts-ignore
            // this.mesh.material.opacity = .5;
        }
        if(state === "target"){
            //@ts-ignore - PDC YELLOW?!
            // this.mesh.material.mainColor = new Color3(0.874509804,1,0);
            //@ts-ignore - PDC YELLOW?!
            this.mesh.material.lineColor = new Color3(0.874509804,1,0);
            // @ts-ignore
            // this.mesh.material.opacity = .5;
        }
        if(state === "spatial"){
            //@ts-ignore - Slate Gray
            this.mesh.material.lineColor = new Color3(0.44, 0.5, 0.56);
            this.spatialMode = true;
        }
    }
    getOppositeId=()=>{
        return this.id;
    }
    computeNeighborIds=()=>{
        const {cellSize: unit} = this.GOE.grid;
        const {x,z} = this.position;

        const deltas = [
            {dx: 0, dz: unit}, //Left
            {dx: 0, dz: -unit}, //Right
            {dx: unit, dz: 0}, //Above
            {dx: -unit, dz: 0}, //Below
            {dx: unit, dz: unit}, //Top right
            {dx: unit, dz: -unit}, //Bottom right
            {dx: -unit, dz: unit}, //Top Left
            {dx: -unit, dz: -unit}, //Bottom left
        ];

        return deltas.map(({dx, dz}) =>{
            return `(${x+dx},${z+dz})`
        });

    }
    
    deselectAllObjectsIfIsSelected=()=>{
        const {selectedObject, selectedSource, selectedTarget} = this.GOE.controls;
        // if(!selectedObject) console.warn("Selected object is glitched on deselect: ", {...selectedObject});
        if(!selectedObject) return
        // if(!selectedObject.id) console.log(`No id on selected object: `, selectedObject);
        // if(!selectedSource.id) console.log(`No id on selected source: `, selectedSource);
        // if(!selectedTarget.id) console.log(`No id on selected target: `, selectedTarget);
        if( (selectedObject && selectedObject.id) === this.id) return this.GOE.controls.deselectAllObjects();
        if( (selectedSource && selectedSource.id) === this.id) return this.GOE.controls.deselectAllObjects();
        if( (selectedTarget && selectedTarget.id) === this.id) return this.GOE.controls.deselectAllObjects();
    }
    updateParticleVisibility=(isVisible: boolean)=>{
        const consecutiveRanges = getConsecutiveRanges([...this.particleAddresses, ...this.particleQueue]);
        consecutiveRanges.forEach((range) => {
            const {start, end} = range;
            for (let i = start; i <= end; i++) {
                this.GOE.SPS.particles[i].isVisible = isVisible;
                // this.GOE.SPS.particles[i].set = isVisible;
            }
            //TODO: Resulted in stack overflow.
            // this.GOE.SPS.setParticles(start, end);
        });
        // @ts-ignore
        // this.mesh.material.opacity = isVisible ? 1 : 0;
    }
    setVisibility = (isVisible: boolean) => {
        //@ts-ignore
        this.mesh.material.opacity = isVisible ? 1 : 0;
        this.isVisible = isVisible; 
        if(!isVisible) this.deselectAllObjectsIfIsSelected();
    }
    updateVisibility = (type?) => {
        if(!this.shouldUpdateVisibility) return;
        // return;
        if(type === "dissipation"){
            // console.log(`Grid cell ${this.id} received energy with type: `, type);
            return;
        };
        // if(type === "energy transfer") return;
        // console.log(`Grid cell ${this.id} received energy with type: `, type);
        // return;
        const isVisible = this.getEnergyLevel() > 0
        // this.updateParticleVisibility(isVisible);
        //@ts-ignore
        this.mesh.material.opacity = isVisible ? 1 : 0;
        this.isVisible = isVisible; 
        // console.log(`Setting grid cell ${this.id} to ${isVisible ? 'visible' : 'hidden'}`)
        if(!isVisible) this.deselectAllObjectsIfIsSelected();
    }
    setParticleAddresses=(addresses: number[])=>{
        this.particleAddresses = addresses;
    }
    addInitParticleAddress=(address:number)=>{
        this.particleAddresses = [...this.particleAddresses, address];
    }
    addParticleAddressToQueue=(address:number)=>{
        this.particleQueue = [...this.particleQueue, address];
    }
    addParticleAddressesToQueue = (addresses: number[])=>{
        this.particleQueue = [... this.particleQueue, ...addresses];
    }
    loseParticleAddresses=(settings: Task): number[]=>{
        const { energyToTransfer } = settings;
        //Get numbers of particles to transfer
        let particlesToTransfer = energyToTransfer > this.particleAddresses.length ? this.particleAddresses.length : energyToTransfer;
        //Store addresses you're going to transfer
        const addressesToTransfer = this.particleAddresses.splice(0, particlesToTransfer);
        //Remove particles that you're transfering
        this.particleAddresses = this.particleAddresses.filter((address: number)=>{
            if(addressesToTransfer.includes(address)) return false;
            else return true;
        });
        this.reactToEnergyLoss(settings);
        return addressesToTransfer;
    }

    transferParticlesOutOfQueue = ()=>{
        const numberOfParticles = [...this.particleQueue].length
        this.particleAddresses = [...this.particleAddresses, ...this.particleQueue];
        this.particleQueue = [];
        return numberOfParticles
    }

    getPotentialObjectSettings=(): PotentialObjectSettings=>{
        return {
            type: "GridCell",
            settings:{
                index: this.index,
                initEnergyLevel: 0,
                position: this.position
            }
        }
    }

    reactToEnergyGain = (settings: Task)=>{
        const { momentumSettings, type } = settings;
        const amount = this.transferParticlesOutOfQueue();
        if(!amount){
            console.warn(`Grid Cell ${this.id} gained ${amount}=0 energy`);
            return;
        }

        // if(momentumSettings) console.log(`Grid cell ${this.id} gained ${amount} energy with momentum!`);
        if(momentumSettings) this.GOE.momentum.createMomentumBasedDirection(this, momentumSettings);
        
        // console.log(`Grid cell ${this.id} gained energy ${amount}`)
        // console.log("settings: ", settings)
        // console.log("momentumSettings: ", momentumSettings)
        
        this.triggerCorrelates();
        this.updateVisibility(type);

    }
    reactToEnergyLoss=(settings: EnergyTransferAnimation | Task)=>{
        const energy = this.getEnergyLevel();
        if(energy < 0) throw new Error("GRID ENERGY IS LESS THAN ZERO!");
        const { type } = settings;
        //Get dark if your energy hits zero.
        // console.log(`Grid cell ${this.id} lost energy: ${settings.amount}`);
        this.updateVisibility(type);
        if(energy > 0) this.triggerCorrelates();
        // console.log(`Grid cell ${this.id} lost energy`)
    }


    getEnergyLevel=()=>{
        return this.particleAddresses.length + this.particleQueue.length;
    }

    // illuminateNeighbors=()=>{
    //     this.neighborIds.forEach((index: string)=>{
    //         const neighborCell = this.GOE.grid.cellData[index];
    //         console.log("Neighbor: ", neighborCell);
    //         if(neighborCell && !neighborCell.isVisible) neighborCell.updateVisibility(true);
    //     })
    // }
    createEnergyScroller=()=>{
        const {GOE} = this;
        this.energyScroller = new EnergyScroller({GOE, energyScrollerValue: 0, parent: this})
    }

    filterNonExistantCorrelates=()=>{
        this.correlatingWith = Object.values(this.correlatingWith).filter((correlationIndex: string)=>{
            const correlate = this.GOE.causalField.directions[correlationIndex];
            if(correlate) return true;
            return false;
        });
    }

    triggerCorrelates=()=>{
        if(!this.correlatingWith.length) return;
        this.filterNonExistantCorrelates();
        // console.log("Grid Cell Triggering Bit Flip");
        // console.log("Correlations: ", this.correlatingWith);
        // console.log(`Grid cell ${this.id} triggering correlates`)
        this.correlatingWith.forEach((correlationIndex: string)=>{
            const correlate = this.GOE.getObjectById(correlationIndex)
            //Weeding out dead correlative bits.
            if(!correlate) return this.correlatingWith = this.correlatingWith.filter((index)=>index!==correlationIndex)
            // correlate.activate(this.id);
            // if(correlate instanceof  || correlate instanceof GridCell) correlate.activate(this.id);
            if(! (correlate instanceof Direction) ) throw new Error("Correlate to trigger wasn't a direction!")
            correlate.correlator.reactToEnergyChange();
        })
    }

    correlateWith=(newCorrelate: ExistingObject )=>{
        // console.log(`Grid cell ${this.id} is correlating with: `, newCorrelate);
        if(this.correlatingWith.includes(newCorrelate.id)){
            // console.log("Already correlating with!");
            return;
        }
        this.correlatingWith = this.correlatingWith.concat([newCorrelate.id]);
    }

    removeCorrelation=(newCorrelate: ExistingObject )=>{
        // console.log(`Grid Cell ${this.id} removing correlation with: `, newCorrelate);
        this.correlatingWith = this.correlatingWith.filter((index)=>index!==newCorrelate.id);
    }

    renderGridCell=()=>{
            const { scene, grid } = this.GOE;
            const { cellSize }  = grid;
            var plane = MeshBuilder.CreatePlane(this.id, { size: cellSize/5, sideOrientation: Mesh.DOUBLESIDE }, scene);
            this.mesh = plane;
            plane.actionManager = new ActionManager(scene);
            ((GOE, cell)=>{
                plane.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function(ev){	
                    GOE.controls.onObjectSelect(cell);
                }));
                plane.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function(ev){	
                    if(!cell.isVisible || cell.spatialMode) return;
                    const {selectedObject, selectedSource, selectedTarget} = GOE.controls;
                    const isSelectedCell = selectedObject && (selectedObject.id === cell.id);
                    const isSelectedSource = selectedSource && (selectedSource.id === cell.id)
                    const isSelectedTarget = selectedTarget && (selectedTarget.id === cell.id)
                    const isSelected = isSelectedCell || isSelectedSource || isSelectedTarget
                    if(!isSelected) GOE.particleVisuals.startHoverAnimation(cell);
                }));
                plane.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function(ev){	
                    if(!cell.isVisible || cell.spatialMode) return;
                    const {selectedObject, selectedSource, selectedTarget} = GOE.controls;
                    const isSelectedCell = selectedObject && (selectedObject.id === cell.id);
                    const isSelectedSource = selectedSource && (selectedSource.id === cell.id)
                    const isSelectedTarget = selectedTarget && (selectedTarget.id === cell.id)
                    const isSelected = isSelectedCell || isSelectedSource || isSelectedTarget
                    if(!isSelected) GOE.particleVisuals.endHoverAnimation(cell);
                }));
            })(this.GOE, this)
            
            // plane.renderOutline = true;
            // plane.outlineWidth = CELL_BORDER_WIDTH;
            // plane.outlineColor = new Color3(0.125490196, 0.501960784, 1.0);
            // plane.outlineColor = this.gridMaterial.lineColor;
            plane.position.x = this.position.x;
            plane.position.z = this.position.z;
            // Rotate the plane so it's flat
            plane.rotation.x = Math.PI / 2;
            // Assign the material to the plane
            const materialObj = this.GOE.tools && this.GOE.tools.gridMaterial ? this.GOE.tools.gridMaterial : GridMaterial
            const mat = new materialObj('groundMaterial', scene);
            mat.gridRatio = 0.1;
            plane.material = mat;
            //@ts-ignore
            plane.material.opacity = this.isVisible ? 1 : 0;
    }
    setSubspaceId(id: any){
        this.subspaceId = id;
    }
    addSelfToSubSpace = ()=>{
        this.GOE.spatialPartioner.storeObjectinSubspace(this);
    }
    removeSelfFromSubSpace = ()=>{
        this.GOE.spatialPartioner.removeObjectFromSubspace(this);
    }
    getSubspaceId=()=>{
        return this.subspaceId;
    }
    moveConnectedObjects=(settings: Task)=>{
        const { caller, energyToTransfer } =  settings;
        // console.log(`Direction ${this.id} reacting to energy loss`);
        // if(this.getEnergyLevel() > 0) return this.triggerCorrelates();
        const callerObj = typeof caller === "string" ? this.GOE.getObjectById(caller) : null;

        if(! (callerObj instanceof Direction)) return false;
        const hadConnectedObjects = this.GOE.momentum.getConnectedObjects(this);
        if(!hadConnectedObjects) console.warn(`Grid Cell ${this.id} had no connected objects`);
        if(!hadConnectedObjects) return false;
        const ids = this.GOE.momentum.getIdsOfConnectedObjects(hadConnectedObjects);
        if(!ids) throw new Error(`Direction ${this.id} found no ids`);
        if(!ids) return false;
        const wasForceInternal = ids.includes(callerObj.id)
        // if(wasForceInternal) console.warn("Force was internal");;
        if(wasForceInternal) return false;

        const massEnergy = this.GOE.momentum.calculateMassEnergyFromObject(hadConnectedObjects);
        const distance = callerObj.distanceBetweenSourceAndTarget;
        const energyRequiredToMoveOneUnit = this.GOE.momentum.calculateEnergyRequiredToMoveMass(massEnergy, distance);
        //TODO: This is a lame bug! It happens when directions have the same position as grid cells
        //Solution is simply to give directions their accurate y positions, not sure why it fails atm.
        //Therefore, a direction will just dissipate into the direction without momentum I suppose.
        if(energyRequiredToMoveOneUnit === 0) return false;
        const isEnergySufficient = energyToTransfer >= energyRequiredToMoveOneUnit;
        // console.log("Amount of force: ", amount);
        // console.log("Distance: ", distance);
        // console.log("Mass energy: ", massEnergy);
        // console.log("Energy required to move: ", energyRequiredToMoveOneUnit);
        // console.log("Units: ", amount/energyRequiredToMoveOneUnit);
        // console.log("Distance * Units: ", (amount/energyRequiredToMoveOneUnit)*distance) 
        // console.log("Is energy sufficient: ", isEnergySufficient);
        if(!isEnergySufficient) return false;
        // console.log(`Direction ${this.id} has been pulled by outside object and is getting connected objects`);
        // console.log("Caller Obj: ", callerObj);
        this.GOE.momentum.moveConnectedObjects(hadConnectedObjects, callerObj, energyToTransfer);
        return true;
    }
    public getPosition =()=>{
        // return this.position;
        return new Vector3(
            this.position.x,
            this.position.y,
            this.position.z
        );
    }
    // getExistingNeighbors = ()=>{
    //     return this.getNeighborsFromSubSpaces().filter((neighbor: PossibleObject)=>{
    //         return !(neighbor instanceof SpatialAction) && (neighbor.id !== this.id);
    //     })
    // }
    // getNeighborsFromSubSpaces = ()=>{
    //     const subspaceId = this.getSubspaceId();
    //     const subspace = this.GOE.spatialPartioner.subspaces[this.getSubspaceId()];
    //     const subspaceNeighbors = subspace.neighbors ? subspace.neighbors : [];
    //     let allNeighbors = []; 
    //     [subspace, ...subspaceNeighbors].forEach((subspace: Subspace)=>{
    //         const neighborsFromSubspace = this.getNeighborsFromSubSpace(subspace);
    //         allNeighbors = allNeighbors.concat(neighborsFromSubspace);
    //     });
    //     return allNeighbors;
    // }
    // getNeighborsFromSubSpace = (subspace: Subspace)=>{
    //     const { gridCells, directions, spatialActions } = subspace;
    //     const validNeighborTypes = [gridCells, directions, spatialActions].filter((potentialNeighborType: any)=>{
    //         return !isObjEmpty(potentialNeighborType);
    //     });
    //     // console.log("Valid Neighbor Types: ", validNeighborTypes);
    //     const potentialNeighbors = validNeighborTypes.reduce((flattenedArray: any, element: any)=>{
    //         return flattenedArray.concat(Object.values(element));
    //     }, []);
    //     const neighbors = potentialNeighbors.filter((neighbor: PossibleObject)=>{
    //         // console.log("This Position: ", this.position);
    //         // console.log("Neighbor Position: ", neighbor.position);
    //         return isPointNeighborOfCell(this.position, neighbor.position, this.GOE.grid.cellSize)
    //     });
    //     return neighbors;
    // }
    
}