import { Vector3 } from "@babylonjs/core";
import getMidpoint from "../../helpers/getMidpoint";
import Direction from ".";
import { PossibleObject } from "../../types";
import calculateDistanceBetweenPoints from "../../helpers/calculateDistanceBetweenPoints";

class DirectionPositionManager {
    parent: Direction = null;
    constructor(parent: Direction){
        this.parent = parent;
    }

    //Calculators
    calculateDistanceBetweenSourceAndTarget = ()=>{
        const distance = calculateDistanceBetweenPoints(this.parent.source.getPosition(), this.parent.target.getPosition());
        return distance;
    }

    // Function to calculate the distance between source and target
    newGetDistanceBetweenSourceAndTarget=()=>{
        return this.parent.source.getPosition().subtract(this.parent.target.getPosition()).length();
    }

    // Function to get the direction from source to target
    getDirectionVectorBetweenSourceAndTarget=()=>{
        const {x,y,z} = this.parent.source.getPosition();
        const sourcePosition = new Vector3(x,y,z);
        return this.parent.target.getPosition().subtract(sourcePosition).normalize();
    }

    // Function to calculate the new midpoint
    getNewMidpoint=()=>{
        const midpoint = getMidpoint(this.parent.source.getPosition(), this.parent.target.getPosition())
        const distance = this.parent.getDistanceBetweenSourceAndTarget();
        const direction = this.getDirectionVectorBetweenSourceAndTarget();
        const newMidpoint = midpoint.add(direction.scale(distance));
        return this.setPositionToDefault(newMidpoint);
    }

    getNewPosition=()=>{
        return this.getShiftedPositionOfObj(this.parent);
    }

    setPositionToDefault=(position: Vector3)=>{
        // return new Vector3(position.x, 0, position.y);
        return position;
    }

    getShiftedPositionOfObjByUnits=(obj: PossibleObject, units: number)=>{
        const distance = this.parent.getDistanceBetweenSourceAndTarget();
        const direction = this.getDirectionVectorBetweenSourceAndTarget();
        // const ogPosition = objgetPosition().add(direction.scale(distance));
        // const newGposition = objgetPosition().add(direction.scale(distance*units));
        // console.log("OG Distance: ", ogPosition)
        // console.log("New Distance: ", newGposition)
        const newPosition = obj.getPosition().add(direction.scale(distance*units));
        return this.setPositionToDefault(newPosition);
    }

    getShiftedPositionOfObj=(obj: PossibleObject)=>{
        const distance = this.parent.getDistanceBetweenSourceAndTarget();
        // console.log("Distance: ", distance);
        // console.log("OG Position: ", objgetPosition()); 
        const direction = this.getDirectionVectorBetweenSourceAndTarget();
        const newPosition = obj.getPosition().add(direction.scale(distance));
        return this.setPositionToDefault(newPosition);

    }
    //Shifted position in general
    getShiftedPosition=(position: Vector3)=>{
        const distance = this.parent.getDistanceBetweenSourceAndTarget();
        // console.log(`Distance of ${this.id}: `, distance);
        // console.log("OG Position: ", objgetPosition()); 
        const direction = this.getDirectionVectorBetweenSourceAndTarget();
        const newPosition = position.add(direction.scale(distance));
        return this.setPositionToDefault(newPosition);
    }
    //Shifted position in general
    getShiftedPositionByUnits=(position: Vector3, units: number)=>{
        const distance = this.parent.getDistanceBetweenSourceAndTarget();
        // console.log(`Distance of ${this.id}: `, distance);
        // console.log("OG Position: ", objgetPosition()); 
        const direction = this.getDirectionVectorBetweenSourceAndTarget();
        const newPosition =  position.add(direction.scale(distance*units));
        return this.setPositionToDefault(newPosition);
    }
}

export default DirectionPositionManager;
