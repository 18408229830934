import React, { useEffect, useState } from "react";
import { FreeCamera, Vector3, HemisphericLight, MeshBuilder, Scene, Mesh, UniversalCamera, Color4, Engine, RenderTargetTexture, AbstractEngine, ActionManager } from "@babylonjs/core";
// import SceneComponent from "./SceneComponent"; // uses above component in same directory
import SceneComponent from '../components/BabylonHook/babylonjs-hook'; // if you install 'babylonjs-hook' NPM.
import SecondaryScene from '../components/CustomBabylonHook/babylonjs-hook'; // if you install 'babylonjs-hook' NPM.
import { AdvancedDynamicTexture, Button, Control, Image, Rectangle, ScrollViewer, Slider, StackPanel, TextBlock } from "@babylonjs/gui";
import gameOfExistence from '../hgoe/src/classes/GameOfExistence';
import { GridMaterial } from "@babylonjs/materials";
import isObjEmpty from "../hgoe/src/helpers/isObjEmpty";
import onWindowResize from "../hgoe/src/helpers/onWindowResize";

let box: Mesh;

export default (props: any) => {
    const { fullScreen, setFullScreen } = props;
    const [engine, setEngine] = useState<Engine | null>();
    const [scene, setScene] = useState<Scene | null>();
    const [camera, setCamera] = useState<UniversalCamera | null>();
    const [canvas, setCanvas] = useState<any | null>();
    const [gameStarted, setGameStarted] = useState(false);
    const [game, setGame] = useState<any>();
    // const [fullScreen, setFullScreen] = useState(false);
    const [secondaryTools, setSecondaryTools] = useState<{
        image, previewScene, previewCamera, renderTexture
    }>();
    const gridSettings = {
        rows: 10,
        columns: 10,
        cellSize: 1,
    }

    useEffect(() => {
    
        //   window.addEventListener('keydown', onFullScreen);
    
        // return () => {
        //     window.removeEventListener('keydown', onFullScreen);
        // };
      }, []);
    
      const onFullScreen = (event: any)=>{
        if(event.key === 'f'){
          console.log("Full Screen: ", fullScreen);
          console.log(`Turning full screen ${!fullScreen ? "on" : "off"}`);
          setFullScreen(!fullScreen);
        }
      }

    // const [previewScene, setPreviewScene] = useState<Scene | null>();
    // const [previewCamera, setPreviewCamera] = useState<UniversalCamera | null>();
    // const [renderTexture, setRenderTexture] = useState<RenderTargetTexture | null>();
    // const [previewImage, setPreviewImage] = useState<Image | null>();
    // image, previewScene, previewCamera, renderTexture
    const onSceneReady = (scene: Scene, engine: Engine) => {
        const { rows, columns, cellSize } = gridSettings;
        const canvas = engine.getRenderingCanvas();
    
        scene.clearColor = new Color4(0, 0, 0, 1);
        scene.metadata = {};
        scene.metadata.isMain = true;
        // scene.actionManager = new ActionManager(scene);
        
        // const camera = new UniversalCamera("UniversalCamera", new Vector3(0, 5, -10), scene);
        const camera = new UniversalCamera("UniversalCamera", new Vector3(0, 5, 0), scene);
        camera.setTarget(new Vector3(0, 0, 0.05));
        camera.attachControl(canvas, true);
        // camera.setTarget(Vector3.Zero());
    
        const light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
        light.intensity = 0.4;
        const width = rows * cellSize;
        const height = columns * cellSize;
        const lightOne = new HemisphericLight("lightOne", new Vector3(-width, 1, 0), scene);
        const lightTwo = new HemisphericLight("lightTwo", new Vector3(width, 1, 0), scene);
        const lightThree = new HemisphericLight("lightThree", new Vector3(0, 1, -height), scene);
        const lightFour = new HemisphericLight("lightFour", new Vector3(0, 1, +height), scene);
        lightOne.intensity = 0.4;
        lightTwo.intensity = 0.4;
        lightThree.intensity = 0.4;
        lightFour.intensity = 0.4;
        const originVector = new Vector3(0,0,0)
        lightOne.setDirectionToTarget(originVector);
        lightTwo.setDirectionToTarget(originVector);
        lightThree.setDirectionToTarget(originVector);
        lightFour.setDirectionToTarget(originVector);


    
        // // Our built-in 'box' shape.
        // box = MeshBuilder.CreateBox("box", { size: 2 }, scene);
    
        // // Move the box upward 1/2 its height
        // box.position.y = 1;
    
        // // Our built-in 'ground' shape.
        // MeshBuilder.CreateGround("ground", { width: 6, height: 6 }, scene);
    
        setEngine(engine);
        setCamera(camera);
        setScene(scene);
        setCanvas(canvas);
    };
    
    /**
     * Will run on every frame render.  We are spinning the box on y-axis.
     */
    const onRender = (scene: Scene) => {
    //   if (box !== undefined) {
    //     const deltaTimeInMillis = scene.getEngine().getDeltaTime();
    
    //     const rpm = 10;
    //     box.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
    //   }
    };

    const onSecondarySceneReady = (scene: Scene)=>{
        // if(!secondaryScene) throw new Error("No secondary scene available")

        // console.log("Creating secondary Scene from BABYLON SCENE");
        // console.log("Scene: ", scene);

        scene.metadata = {};
        scene.metadata.isMain = false;
        scene.autoClear = true; // Ensure it clears the entire buffer
        scene.clearColor = new Color4(0, 0, 0, 1); // r,g,b,a
    
        const engine = scene.getEngine();
        const previewCamera = new UniversalCamera("SecondUniversalCamera", new Vector3(0, -2.5, 0), scene);
        previewCamera.setTarget(new Vector3(0, 0, -0.05));
        previewCamera.attachControl(engine.getRenderingCanvas(), true); // Attach control to preview scene canvas
        previewCamera.inputs.clear(); // Clear existing inputs to prevent conflicts
    
        // Rotate the camera 180 degrees around the Z-axis
        previewCamera.rotation.z = Math.PI; // Rotate 180 degrees
        // previewCamera.rotation.y = Math.PI * 3; // Rotate 180 degrees
        // previewCamera.rotation.x = Math.PI; // Rotate 180 degrees
    
        const light = new HemisphericLight("Secondlight", new Vector3(0, -1, 0), scene);
    
        // Create a RenderTargetTexture
        const renderTexture = new RenderTargetTexture("SecondrenderTexture", { width: 512, height: 512 }, scene, false);
        renderTexture.activeCamera = previewCamera;
    
        scene.customRenderTargets.push(renderTexture);
    
        // Create an image control to display the render texture
        const image = new Image("previewImage", null);
        image.width = "100%"; // Adjust this to control the size
        image.height = "55%"; // Adjust this to control the size
        // image.top = "-15%";
        image.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
        image.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        image.isPointerBlocker = true; // Ensure the image blocks pointer events
        
        console.warn("Setting secondary tools");
        // console.log("Scene: ", scene)
        // console.log("camera: ", previewCamera)
        // console.log("texture: ", renderTexture)
        // console.log("image: ", image)
        setSecondaryTools({
            previewScene: scene, 
            previewCamera, 
            renderTexture,
            image,
        });
        // setPreviewScene(scene);
        // setPreviewScene(scene);
        // setPreviewScene(scene);
    }

    useEffect(() => {
        const settings = {
          grid: {...gridSettings
          },
          tools:{
            view: canvas,
            scene: scene,
            engine: engine,
            camera: camera,
            createSecondaryScene: createSecondaryScene,
            gridMaterial: GridMaterial,
            classes:{
                StackPanel: StackPanel,
                TextBlock: TextBlock,
                Slider: Slider,
                ScrollViewer: ScrollViewer,
                Button: Button,
                Control: Control,
                Rectangle: Rectangle,
                Image: Image,
            },
            // control: Control.constructor,
            // stackPanel: StackPanel.constructor,
            // textBlock: TextBlock.constructor,
            // slider: Slider.constructor,
            // scrollviewer: ScrollViewer.constructor,
            // button: Button.constructor,
            // controlObj: Control,
            // rectangle: Rectangle.constructor,
            createUserInterface: ()=>{
                // console.log("Creating user interface");
                // console.log("Scene: ", scene);
                return AdvancedDynamicTexture.CreateFullscreenUI("UI", true, scene);
            },

          }
        };
        // console.log("canvas: ", canvas);
        // console.log("scene: ", scene);
        // console.log("engine: ", engine);
        // console.log("camera: ", camera);
        if (canvas && scene && engine && camera && !gameStarted) {
        if(!scene.metadata.isMain) return;
        // const mat = new GridMaterial('groundMaterial', scene);
            // console.log("Starting GOE");
          const GOE = new gameOfExistence(settings);
        //   console.log("GOE: ", GOE);
          // onWindowResize(GOE);
          setGame(GOE);
          GOE.start();
          setGameStarted(true);
        }
      }, [canvas, scene, engine, camera]);
    
      const createSecondaryScene=()=>{
        // console.log("Engine: ", engine);
        if(!engine) return;
        if(!canvas && scene && engine && camera && !gameStarted) return;
        if(isObjEmpty(secondaryTools)){
            console.log("Secondary Tools: ", secondaryTools);
            console.warn("Secondary tools are empty");
            return;
        }
        // console.log("Creating secondary scene");

        const { image, previewScene, previewCamera, renderTexture} = secondaryTools
        return { image, previewScene, previewCamera, renderTexture }
      }

    return(
        <div key={fullScreen} id="canvas-container" style={{
            // flex: 1,
            width: '100%',
            height: '100%',
            // border: "solid yellow 5px"
        }}>
            <SceneComponent
                style={{
                    // flex: 2,
                    width: '100%',
                    // height: '100%',
                    height: fullScreen ? '94.5vh' : '80vh',
                    // border: "solid white 5px",
                }}
                engineOptions={{
                    preserveDrawingBuffer: true,
                }}
                antialias
                onSceneReady={onSceneReady}
                onRender={onRender}
                id="my-canvas"
            />
            <SecondaryScene
                canvas={canvas}
                engine={engine}
                antialias
                onSceneReady={onSecondarySceneReady}
                onRender={()=>{}}
            />
        </div>
    )
};
// export default () => (
//   <div>
//     <SceneComponent antialias onSceneReady={onSceneReady} onRender={onRender} id="my-canvas" />
//   </div>
// );