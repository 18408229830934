import isObjEmpty from "../helpers/isObjEmpty";
import isPointNeighborOfCell from "../helpers/isPointNeighborOfCell";
import { ExistingObject, PossibleObject, Subspace } from "../types";
import Direction from "./Direction";
import GameOfExistence from "./GameOfExistence";
import GridCell from "./GridCell";
import SpatialAction from "./SpatialAction";

type NeighborTypes = ("gridCells" | "spatialActions" | "causalEnergies" | "directions")[]

class Neighborhood {
    parent: ExistingObject;
    GOE: GameOfExistence

    // neighborhood: Set<PossibleObject> = new Set()
    neighborhood: ExistingObject[];
    // inNeighborhood: boolean = false;
    timeNeighborhoodWasLeft: number = 0;
    lastNeighborUpdate: number = 0;

    constructor(parent: ExistingObject){
        this.parent = parent;
        this.GOE = parent.GOE;
    }



    public updateNeighbors=()=>{
        const NEIGHBORHOOD_UPDATE_TIME = 2;
        const deltaTime  = this.GOE.gameTime - this.lastNeighborUpdate 
        if(deltaTime < NEIGHBORHOOD_UPDATE_TIME) return;
        this.parent.neighbors = this.parent.getCurrentNeighbors();
    }

    public getNeighbors=(neighborTypes: NeighborTypes = ['gridCells', 'directions'])=>{
        return this.getNeighborsFromSubSpaces(neighborTypes)
    }
    public addSelfToNeighborhood=()=>{
        this.parent.addSelfToSubSpace();
        if(!this.parent.inNeighborhood) this.parent.inNeighborhood = true;
    }
    // public addSelfToNeighborhood=()=>{
    //     // console.log(`Object ${this.parent.id} adding self to neighbors`);
    //     this.parent.neighbors.forEach((neighbor: GridCell | Direction)=>{
    //         neighbor.neighborhood.addNewNeighbor(this.parent);
    //     })
    //     if(!this.parent.inNeighborhood) this.parent.inNeighborhood = true;
    // }
    public removeSelfFromNeighborhood=()=>{
        if(!this.parent.inNeighborhood) return;
        this.parent.inNeighborhood = false;
        this.GOE.spatialPartioner.removeObjectFromSubspace(this.parent);
        this.timeNeighborhoodWasLeft = this.GOE.gameTime;
    }
    // public removeSelfFromNeighborhood=()=>{
    //     if(!this.parent.inNeighborhood) return;
    //     this.parent.inNeighborhood = false;
    //     // throw new Error("Tried to remove object from neighborhood when visual objects exist")
    //     this.timeNeighborhoodWasLeft = this.GOE.gameTime;
    //     // console.log(`Object ${this.parent.id} removing self from neighbors`)
    //     this.parent.neighbors.forEach((neighbor)=>{
    //         neighbor.neighborhood.removeNeighbor(this.parent);
    //     })
    // }
    public addNewNeighbor = (newNeighbor: ExistingObject)=>{
        const isExistingNeighbor = this.parent.neighbors.map((obj)=>{
            if(obj) return obj.id;
            return undefined
        }).includes(newNeighbor.id);
        if(isExistingNeighbor) return false;
        this.parent.neighbors.push(newNeighbor);
    }
    public removeNeighbor=(oldNeighbor: ExistingObject)=>{
        this.parent.neighbors = this.parent.neighbors.filter((neighbor: ExistingObject)=>{
            if(!neighbor) return true;
            if(neighbor.id !== oldNeighbor.id) return true;
        })
        return true;
    }
    private getNeighborsFromSubSpaces = (neighborTypes?: NeighborTypes): PossibleObject[] =>{
        // console.log("Subspaces: ", this.GOE.spatialPartioner.subspaces);
        const subspaceId = this.getSubspaceId();
        // console.log("subspaceId: ", subspaceId);
        const subspace = this.GOE.spatialPartioner.subspaces[subspaceId];
        if(!subspace) throw new Error("Subspace does not exist")
        const subspaceNeighbors = subspace.neighbors ? subspace.neighbors : [];
        let allNeighbors = []; 
        [subspace, ...subspaceNeighbors].forEach((subspace: Subspace)=>{
            const neighborsFromSubspace = this.getNeighborsFromSubSpace(subspace, neighborTypes);
            allNeighbors = allNeighbors.concat(neighborsFromSubspace);
        });
        return allNeighbors;
    }
    private getNeighborsFromSubSpace = (subspace: Subspace, neighborTypes?: NeighborTypes): PossibleObject[]=>{
        const neighborObjects = neighborTypes
            ? neighborTypes.map(type => subspace[type]).filter(obj => obj)
            : [];

        const validNeighborTypes = neighborObjects.filter((potentialNeighborType: any)=>{
            return !isObjEmpty(potentialNeighborType);
        });
        // console.log("Valid Neighbor Types: ", validNeighborTypes);
        const potentialNeighbors = validNeighborTypes.reduce((flattenedArray: any, element: any)=>{
            return flattenedArray.concat(Object.values(element));
        }, []);
        const neighbors = potentialNeighbors.filter((neighbor: PossibleObject)=>{
            // console.log("This Position: ", this.position);
            // console.log("Neighbor Position: ", neighbor.position);
            return isPointNeighborOfCell(this.parent.getPosition(), neighbor.getPosition())
        });
        return neighbors;
    }
    private getSubspaceId=()=>{
        return this.parent.subspaceId;
    }

}

export default Neighborhood;