
const Text = ({ children: text, style }: any)=>{
    return(
        <div style={{
            marginLeft: '5%',
            marginTop: '15px',
            marginBottom: '15px',
            ...style
        }}>
            {text}
        </div>
    )
}
export default Text;