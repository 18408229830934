

//Its responsible for a relationship between two objects.

import { ActionManager, Color3, Color4, ExecuteCodeAction, Mesh, MeshBuilder, StandardMaterial, Vector3 } from "@babylonjs/core";
import GridCell from "../GridCell";
import getOppositeDirectionArrayFromArray from "../../helpers/getOppositeDirectionArrayFromArray";
import convertDirectionArrayToString from "../../helpers/convertDirectionArrayToString";
import GameOfExistence from "../GameOfExistence";
import { DIRECTION_DELETION_SPEED, DIRECTION_HOVER_HEIGHT, DIRECTION_NEIGHBORHOOD_REMOVAL_SPEED, DIRECTION_NEVER_GOT_ENERGY_SPEED, MASS_MOMENTUM, MINIMUM_DISTANCE, NOVELTY, NO_TIME, SPEED_OF_LIGHT, momentumTypes } from "../../constants";
import isPointNeighborOfCell from "../../helpers/isPointNeighborOfCell";
import returnTrueWithProbability from "../../helpers/returnWithProbability";
import getMidpoint from "../../helpers/getMidpoint";
import { CorrelateEnergyTransfer, DirectionSettings, EnergyDirection, EnergyTransferAnimation, ExistingObject, MomentumSettings, ObjectSelectionStates, PossibleObject, Subspace, PotentialObjectSettings, Task } from "../../types";
import changeBoxColor from "../../helpers/changeBoxColor";
import EnergyScroller from "../EnergyScroller";
import SpatialAction from "../SpatialAction";
import isObjEmpty from "../../helpers/isObjEmpty";
import calculateDistanceBetweenPoints from "../../helpers/calculateDistanceBetweenPoints";
import EnergyCapacity from "../EnergyCapacity";
import Correlator from "../Correlator";
import Neighborhood from "../Neighborhood";
import getDirectionIdFromArray from "../../helpers/getDirectionIdFromArray";
import calculateDirectionPosition from "../../helpers/calculateDirectionPosition";
import DirectionVisuals from "../DirectionVisuals";
import makeDebugBox from "../../helpers/makeDebugBox";
import isSourceAndTargetIdentical from "../../helpers/isSourceAndTargetIdentical";
import PotentialObject from "../PotentialObject";
import DirectionPositionManager from "./PositionManager";
import DirectionDissipation from "./Dissipation";

//
class Direction {

    id: string = ""; //parentIndex-directionString
    // positionIndex: string; //parentPositionIndex
    index: string; //parentIndex-directionString
    directionIndex: string; //directionString
    oppDirectionIndex: string;
    energyLevel: number = 0;
    sourceId: string;
    source: GridCell | Direction;
    targetId: string;
    target: GridCell | Direction;
    GOE: GameOfExistence;
    justCreated: boolean = true;
    particleAddresses: number[] = [];
    particleQueue: number[] = [];
    correlatingWith: string[] = [];
    lastBitFlipTime: number = 0;
    numberDisplayer: any;

    isVisible: boolean = true;
    private position: Vector3;
    unit: number = 0;
    lastTimeEnergyMoved: number = 0;

    energyScroller: EnergyScroller = null;
    spatialMode: boolean = false;
    neighbors: ExistingObject[] = [];
    type: "Direction";

    subspaceId: any = null;
    
    // causalEnergyId: string;

    distanceBetweenSourceAndTarget: number = null;

    lastActive: number = 0;

    active: boolean = true;
    inNeighborhood: boolean = false;
    timeNeighborhoodWasLeft: number = null;

    timeCreated: number = null;

    energyCapacity: EnergyCapacity = null;
    correlator: Correlator = null;
    neighborhood: Neighborhood = null;

    clockTickLength: number = null;

    visuals: DirectionVisuals = null;
    positionManager: DirectionPositionManager = null;
    dissipation: DirectionDissipation = null;

    constructor(settings: DirectionSettings){
        const { id, GOE, causalField, directionArray, directionIndex, position } =settings;
        this.id = directionIndex;
        this.position = position;
        // console.log(`Direction ${this.id} position y: `, position.y)
        this.sourceId = directionArray[0];
        this.targetId = directionArray[1];
        this.GOE = GOE
        this.source = this.GOE.getExistingObjectById(this.sourceId);
        this.target = this.GOE.getExistingObjectById(this.targetId); 
        this.correlator = new Correlator(GOE, this);
        this.visuals = new DirectionVisuals(this);
        this.positionManager = new DirectionPositionManager(this);
        this.dissipation = new DirectionDissipation(this);
        this.energyCapacity = this.GOE.energyCapacity;
        this.timeCreated = this.GOE.gameTime;
        // console.log(`Direction ${this.id} has been created`);
        // console.log("Direction array: ", directionArray);
        // console.log("Direction index: ", directionIndex);


       

        this.distanceBetweenSourceAndTarget = this.positionManager.calculateDistanceBetweenSourceAndTarget();
        if(this.distanceBetweenSourceAndTarget === 0){
            console.log("SourceId: ", this.sourceId);
            console.log("TargetId: ", this.targetId);
            console.log("Source: ", this.source);
            console.log("Target: ", this.target);
            if(this.source instanceof GridCell && this.target instanceof GridCell){
                throw new Error("Distance between source and target is zero, AND BOTH GRID CELLS!");
            }
            if(this.source instanceof Direction && this.target instanceof Direction){
                if(this.source.id === this.target.id){
                    throw new Error("Distance between source and target is zero, and BOTH DIRECTIONS")
                }
            }
        }
        const lessThanMinimumDistance = this.distanceBetweenSourceAndTarget < MINIMUM_DISTANCE;
        if(lessThanMinimumDistance){
            throw new Error(`Distance of direction ${this.id} is less than minimum ${this.distanceBetweenSourceAndTarget}<${MINIMUM_DISTANCE}`)
        }
        //Clock Tick Length = (Unit * 2=RoundTrip)/Light Speed;
        this.clockTickLength = ( (1 * 2) / SPEED_OF_LIGHT );
        // this.clockTickLength = ( (this.distanceBetweenSourceAndTarget * 2) / SPEED_OF_LIGHT );
        // console.log(`Direction ${this.id} distance: `, this.distanceBetweenSourceAndTarget)
        // console.log("Universal Light Speed: ", SPEED_OF_LIGHT);
        // console.log("Clock Tick Time: ", this.clockTickLength);

     
        this.index = directionIndex;
        this.directionIndex = directionIndex;

        this.unit = this.distanceBetweenSourceAndTarget;
        this.neighborhood = new Neighborhood(this);
        
        this.neighborhood.addSelfToNeighborhood();
        this.neighbors = this.getCurrentNeighbors();
    }

    getCurrentNeighbors = (): ExistingObject[] =>{
        return <ExistingObject[]>this.neighborhood.getNeighbors(['gridCells', 'directions']).filter((obj: ExistingObject)=>obj.id!==this.id);
    }
    
    updateVisibility = () => {
        const isSourceVisible = this.source ? this.source.isVisible : false;
        const isTargetVisible = this.target ? this.target.isVisible : false;
        const visible = isSourceVisible && isTargetVisible;
        this.visuals.arrowVisualsOff = !visible;
        this.visuals.otherVisualsOff = !visible;
        // const consecutiveRanges = getConsecutiveRanges(this.particleAddresses);
        // consecutiveRanges.forEach((range) => {
        //     const {start, end} = range;
        //     for (let i = start; i <= end; i++) {
        //         this.GOE.SPS.particles[i].isVisible = visible;
        //     }
        // });
        // this.mesh.material.opacity = visible ? 1 : 0;
        this.isVisible = visible;
        if(!visible) this.deselectAllObjectsIfIsSelected();
    }

    removalProcess=()=>{
        const shouldBeRemovedFromNeighboorhood = this.shouldBeRemovedFromNeighborhood();
        if(shouldBeRemovedFromNeighboorhood) this.removeSelfFromNeighborhood();

        const shouldbeDeletedFromCausalField = this.shouldbeDeletedFromCausalField();
        if(shouldbeDeletedFromCausalField) this.removeSelfFromCausalField();

        // if(shouldbeDeletedFromCausalField || shouldBeRemovedFromNeighboorhood) this.cleanUpVisuals(true);
        // this.testCleanUp(result);
    }

    isForceGreaterThanOpposite = ()=>{
        const oppEnergy = this.getOppEnergyLevel();
        if(this.dissipation.isOppositeDissipating() && !this.isEnergyLevelZero()){
            // console.log(`Opp dissipating and direction ${this.id} has above zero energy ${this.getEnergyLevel()}`);
            return true;
        }
        if(this.dissipation.isOppositeDissipating() && this.isEnergyLevelZero()){
            // console.log(`Opp dissipating and direction ${this.id} has zero energy ${this.getEnergyLevel()}`);
            return false;
        }

        const currentEnergy = this.getEnergyLevel();
        // console.log("this direction: ", this);
        // console.log("opp Direction: ", this.getOppositeDirectionObject());
        // console.log("current energy: ", currentEnergy);
        // console.log("oppEnergy: ", oppEnergy);
        // console.log("Current>oppEnergy: ", currentEnergy>oppEnergy);
        return currentEnergy>oppEnergy;
    }
    isForceLessThanOpposite = ()=>{
        const oppEnergy = this.getOppEnergyLevel();
        if(this.dissipation.isOppositeDissipating() && !this.isEnergyLevelZero()) return false;
        if(this.dissipation.isOppositeDissipating() && this.isEnergyLevelZero()) return false;

        const currentEnergy = this.getEnergyLevel();
        return currentEnergy<oppEnergy;
    }

    getParticlesOutOfqueue = ()=>{
        return this.particleAddresses.length;
    }
    hasParticlesOutOfQueue = ()=>{
        return this.particleAddresses.length > 0;
    }
    hasParticlesInQueue = ()=>{
        return this.particleQueue.length > 0;
    }

    updateSelf=()=>{
        if(this.getEnergyLevel() < 0) throw new Error(`Direction ${this.id} has less than 0 energy`);
        const isCurrentlyActive = this.updateLastActiveTime();
        //Not currently active means both you and your opp have no particles...
        if(!isCurrentlyActive) return this.removalProcess();
        // if(this.isEnergyLevelZero()) return;
        
        this.neighborhood.updateNeighbors();
        
        if(!this.dissipation.isDissipating) this.rejoinNeighborPoolAndCausalField();
        if(!this.dissipation.isDissipating) this.reactToEnergyChange();
        // this.updateVisibility();
        this.visuals.updateVisuals();
        // this.visuals.updateOppositeVisuals();

        // return;
        if(this.activeButOnlyHoldingSpace()) return;
        if(this.dissipation.isDissipatingQueue) this.dissipation.dissipateEnergyInQueue();
        if(!this.hasClockTicked()) return //Obey light speed
        if(this.dissipation.isDissipating) return this.dissipation.dissipate("");

        if(this.GOE.noTime) return; //Toggle time, can be before, or after, dissipation

        // if(!this.isForceGreaterThanOpposite()) return console.log("Force is not greater than opposite");

        // if(this.dissipated) return console.log(`Direction ${this.id} Is dissipated`);
        if(this.dissipation.dissipated) return;
        // // console.log(`Direction ${this.id} checking if task is possible on move energy`);
        if(this.isForceLessThanOpposite() || this.areForcesBalanced()){
            if(this.areForcesBalanced()){
                // console.warn(`Direction ${this.id} Has balanced forces, or is less than opposite`);
                // console.log("This energy: ", this.getEnergyLevel());
                // const oppObj = this.getOppositeDirectionObject();
                // const oppEnergy = this.getOppEnergyLevel();
                // console.log("Opp Energy: ", oppEnergy)
                // console.log("Opposite action: ", oppObj)
                return;
            }
            if(this.isForceLessThanOpposite()){
                // console.warn(`Direction ${this.id} IS LESS THAN OPPOSITE`);
                return;
            }
            // console.log("This energy: ", this.getEnergyLevel());
            // const oppObj = this.getOppositeDirectionObject();
            // const oppEnergy = this.getOppEnergyLevel();
            // console.log("Opp Energy: ", oppEnergy)
            // console.log("Opposite action: ", oppObj)
            return;
        }
        // if(this.areForcesBalanced()) return;
        // if(this.justCreated) return console.log("Is just created");
        if(this.justCreated) return;
        // if(!this.hasParticlesOutOfQueue()) return;
        if(!this.hasParticlesOutOfQueue()) return console.log("Has particles out of queue");
        // if(this.energyCapacity.isTaskImpossible(this)) return this.dissipation.dissipate();
        if(this.energyCapacity.isTaskImpossible(this)) return this.dissipation.dissipate("because task is impossible for some reason - updateSelf");
        if(this.getEnergyLevel() === this.getNumberOfParticleInOppQueue()){
            // console.log("Opposite is **certain** to receive particles");
            return;
        }

        // console.log(`Direction ${this.id} is moving energy`)
        this.moveEnergy();

        // this.visuals.updateVisuals();
    }

    getNumberOfParticlesInQueue=()=>{
        return this.particleQueue.length;
    }
    getNumberOfParticleInOppQueue=()=>{
        const oppObj = this.getOppositeDirectionObject();
        if(!oppObj) return 0;
        return oppObj.particleQueue.length;
    }

    moveEnergy = ()=>{
        const force = this.energyCapacity.calculateParticlesToMove(this);
        // console.warn(`Direction ${this.id} requesting to move ${force} particles`);
        const oppDirection = {...this.getOppositeDirectionObject()};
        // const oppEnergy = oppDirection.getEnergyLevel();
        // const energy = this.getEnergyLevel();
        // console.log("Opp direction: ", oppDirection);
        // console.log("Opp energy: ", oppEnergy);
        // console.log("This Energy: ", energy);
        // console.log("Are forces balanced: ", this.areForcesBalanced());

        // const momentumCondition = this.source instanceof Direction && !this.source.isEnergyLevelZero();
        const momentumCondition = this.source.getEnergyLevel() !== 0;
        this.GOE.mechanics.attemptTask({
            caller: `${this.id}`,
            source: this.source.getPotentialObjectSettings(),
            target: this.target.getPotentialObjectSettings(),
            // momentumSettings: returnTrueWithProbability(NOVELTY) ? this.GOE.momentum.getMomentumData(this, force) : null,
            momentumSettings:  this.GOE.LIGHT_MOMENTUM ? this.GOE.momentum.getMomentumData(this, force) : null,
            energyToTransfer: force,
            type: momentumCondition && MASS_MOMENTUM ? "momentum pull" : "energy transfer"
            // type: "energy transfer",
        });


        this.lastTimeEnergyMoved = this.GOE.gameTime;
    }

    reactToEnergyChange = ()=>{
        // console.log(`Direction ${this.id} reacting to energy change`)
        if(this.dissipation.isDissipating) return;
        const oppEnergy = this.dissipation.isOppositeDissipating() ? 0 : this.getOppEnergyLevel()
        if(this.getEnergyLevel() > oppEnergy){ this.onForceGreaterThanOpposite(); return; }
        if(this.getEnergyLevel() < oppEnergy){ this.onForceLessThanOpposite(); return; }
        if(this.areForcesBalanced()){ this.onForceEqualToOpposite(); return; }
    }

    isEnergySufficientToBreakBond=(incomingParticles: number)=>{
        const massEnergy = this.GOE.momentum.getMassEnergyOfBond(this);
        if(!massEnergy) return true;
        // console.log("Mass energy: ", massEnergy);
        const dissociationEnergy = this.energyCapacity.calculateDissociationEnergy(this, massEnergy);
        const isEnergySufficient = incomingParticles > dissociationEnergy;
        console.log(`Direction ${this.id}`);
        console.log("incoming particles: ", incomingParticles);
        console.log("Dissociation Energy: ", dissociationEnergy);
        console.log("is energy sufficient: ", isEnergySufficient);
        // const { type } = animationSettings;
        // console.log("Type: ", type)
        // const isPlayerControlled = type === 'init cell to ce' || type === 'cell to ce';
        // const isPlayerOrGameControlled = 'init cell to ce' || 'cell to ce' || 'dissipation';
        // const isGameControlled = type === 'dissipation';
        // if(this.areForcesBalanced() && !isEnergySufficient) return this.dissipateEnergyInQueue(animationSettings);
        if(this.areForcesBalanced() && !isEnergySufficient) return false
        if(this.areForcesBalanced() && isEnergySufficient) return true
    }

    getEnergyGained=()=>{
        return [...this.particleQueue].length
    }

    shouldRepelEnergyGain=()=>{
        // console.warn(`Direction ${this.id} checking if should repel energy gain`)
        // console.warn(`Not Just Created? `, !this.justCreated)
        // console.warn(`Not dissipating queue? `, !this.dissipation.isDissipatingQueue)
        // console.warn(`Forces are not balanced?`, this.areForcesBalanced())
        return !this.justCreated && !this.dissipation.isDissipatingQueue && this.areForcesBalanced()
    }

    reactToEnergyGain=(animationSettings: Task)=>{
        // this.visuals.updateVisuals();
        
        if(this.shouldRepelEnergyGain()){
            const energyGained = this.getEnergyGained();
            // console.log("Energy gained");
            if(!this.isEnergySufficientToBreakBond(energyGained)) return this.dissipation.dissipateEnergyInQueue();
        };
        // console.log("Shouldn't repel energy gain");

        const amount = this.transferParticlesOutOfQueue();
        if(!amount) return console.warn(`Direction gained ${amount}=0 energy`);
        
        const { momentumSettings } = animationSettings;

        // console.log(`Direction ${this.id} is triggering correlates etc...`);
        
        // if(this.isDissipating) throw new Error(`Direction ${this.id} received energy while dissipating`);
        // if(momentumSettings) console.log(`Direction ${this.id} received ${amount} energy with momentum ${momentumSettings.force}`);
        // console.log(`Direction ${this.id} animation settings: `, animationSettings)
        this.triggerCorrelates();
        if(momentumSettings) this.GOE.momentum.createMomentumBasedDirection(this, momentumSettings);

        // console.log(`Direction ${this.id} reacting to energy gain`);
        if(this.justCreated){
            this.visuals.enableAndUpdateStressBar();
            this.justCreated = false;
        }
        if(!this.dissipation.isDissipating && this.dissipation.dissipated){
            // if(this.energyCapacity.isTaskImpossible(this)){
            //     // console.log(`Dissipating direction ${this.id} from energyGain `)
            //     // return this.dissipate("");
            //     return this.dissipate("gained energy but task is impossible!");
            // }
            this.dissipation.setOppositeDissipated(false);
            this.dissipation.setDissipated(false);
        }
        
        if(this.getEnergyLevel() < 0) throw new Error(`Direction ${this.id} has negative energy!`);
    }

    reactToEnergyLoss=(settings: Task)=>{
        // this.visuals.updateVisuals();
        // const { caller, amount, } =  settings;
        // console.log(`Direction ${this.id} reacting to energy loss`);
        // if(this.getEnergyLevel() > 0) return this.triggerCorrelates();
        // const callerObj = typeof caller === "string" ? this.GOE.getObjectById(caller) : null;
        // const isMomentumBased = (settings && settings.type) ? momentumTypes.includes(settings.type) : false
        // if(this.isEnergyLevelZero() && !isMomentumBased){
        if(this.isEnergyLevelZero()){
            this.dissipation.onManualDissipation();
            //Why would this be done? Hm.
            // this.dissipation.setOppositeDirectionAsDissipated();
            this.visuals.updateVisuals()
            this.visuals.updateOppositeVisuals();
        }

    }



    moveConnectedObjects=(settings: Task)=>{
        const { caller, energyToTransfer } =  settings;
        // console.log(`Direction ${this.id} reacting to energy loss`);
        // if(this.getEnergyLevel() > 0) return this.triggerCorrelates();
        const callerObj = typeof caller === "string" ? this.GOE.getObjectById(caller) : null;

        if(! (callerObj instanceof Direction)) return false;
        const hadConnectedObjects = this.GOE.momentum.getConnectedObjects(this);
        // if(!hadConnectedObjects) console.warn(`Direction ${this.id} had no connected objects`);
        if(!hadConnectedObjects) return false;
        const ids = this.GOE.momentum.getIdsOfConnectedObjects(hadConnectedObjects);
        if(!ids) throw new Error(`Direction ${this.id} found no ids`);
        if(!ids) return false;
        const wasForceInternal = ids.includes(callerObj.id)
        // if(wasForceInternal) console.warn("Force was internal");;
        if(wasForceInternal) return false;

        const massEnergy = this.GOE.momentum.calculateMassEnergyFromObject(hadConnectedObjects);
        const distance = callerObj.distanceBetweenSourceAndTarget;
        const energyRequiredToMoveOneUnit = this.GOE.momentum.calculateEnergyRequiredToMoveMass(massEnergy, distance);
        //TODO: This is a lame bug! It happens when directions have the same position as grid cells
        //Solution is simply to give directions their accurate y positions, not sure why it fails atm.
        //Therefore, a direction will just dissipate into the direction without momentum I suppose.
        if(energyRequiredToMoveOneUnit === 0) return false;
        const isEnergySufficient = energyToTransfer >= energyRequiredToMoveOneUnit;
        // console.log("Amount of force: ", amount);
        // console.log("Distance: ", distance);
        // console.log("Mass energy: ", massEnergy);
        // console.log("Energy required to move: ", energyRequiredToMoveOneUnit);
        // console.log("Units: ", amount/energyRequiredToMoveOneUnit);
        // console.log("Distance * Units: ", (amount/energyRequiredToMoveOneUnit)*distance) 
        // console.log("Is energy sufficient: ", isEnergySufficient);
        if(!isEnergySufficient) return false;
        // console.log(`Direction ${this.id} has been pulled by outside object and is getting connected objects`);
        // console.log("Caller Obj: ", callerObj);
        this.GOE.momentum.moveConnectedObjects(hadConnectedObjects, callerObj, energyToTransfer);
        return true;
    }



    removeSelfFromNeighborhood=()=>{
        this.visuals.cleanUpVisuals(true);

        
        if(!this.visuals.areVisualObjectsDisabled()) return;
        // console.log(`Direction ${this.id} removing self from neighbor pool`)
        
        this.neighborhood.removeSelfFromNeighborhood();
    }
    
    removeSelfFromCausalField=()=>{
        
        this.visuals.disposeAllVisualObjects();
        // console.warn(`Direction ${this.id} cleaned up visuals and should be removing self from neighborhood`);
        
        if(!this.visuals.areVisualObjectsDisposed()) return
        // console.log(`Deleting direction ${this.id} from causal field`);

        delete this.GOE.causalField.directions[this.directionIndex];
        const oppObj = this.getOppositeDirectionObject();
        if(!oppObj) return;
        if(oppObj.shouldBeRemovedFromNeighborhood() || oppObj.shouldbeDeletedFromCausalField()){
            delete this.GOE.causalField.directions[oppObj.directionIndex];
            return;
        }
        // // const shouldOppObjBeRe
        // if(oppObj){
        //     console.warn(`Opp direction ${oppObj.id} exists when removing ${this.id}`)
        //     if(oppObj.isEnergyLevelZero()){
        //         console.log("Opp energy is zero");
        //     }
        //     if(oppObj.dissipation.isDissipating){
        //         console.log("Opp obj is dissipating");
        //     }
        //     console.log("Opp Obj: ", oppObj);
        //     console.log("Opp Obj should be removed from neighborhood: ", oppObj.shouldBeRemovedFromNeighborhood());
        //     console.log("Opp Obj should be removed from CAUSAL FIELD: ", oppObj.shouldbeDeletedFromCausalField());
        //     throw new Error("Should remove causal field from opposite, no?")
        // }
    }

    rejoinNeighborPoolAndCausalField=()=>{
        if(this.inNeighborhood) return;
        // console.log(`Direction ${this.id} adding self to neighbors on reactivation`);
        this.neighborhood.addSelfToNeighborhood();
        this.GOE.causalField.directions[this.directionIndex] = this;

        const oppDirectionArray = this.getOppDirectionArray();
        const newDirection = this.GOE.causalField.getOrCreateDirection(oppDirectionArray);
    }

    shouldbeDeletedFromCausalField=()=>{
        const now = this.GOE.gameTime;
        const longerThanThreshold = (now - this.timeNeighborhoodWasLeft > DIRECTION_DELETION_SPEED);
        if(!this.inNeighborhood && longerThanThreshold && !this.justCreated && !this.active){
            if(this.getEnergyLevel() > 0) throw new Error(`Tried to remove energy having direction ${this.id} from causal energy ${this.getEnergyLevel()}`)
            // console.log(`Direction ${this.id} should be deleted`);
            return true;
        }
        else return false;
    }

    shouldBeRemovedFromNeighborhood=():boolean => {
        const now = this.GOE.gameTime;
        const longerThanThreshold_neverGotEnergy = (now - this.timeCreated > DIRECTION_NEVER_GOT_ENERGY_SPEED);
        const longerThanThreshold = (now - this.lastActive > DIRECTION_NEIGHBORHOOD_REMOVAL_SPEED);
        // const isSpatialMode = this.GOE.spatialMode;
        if(this.justCreated && longerThanThreshold_neverGotEnergy){
            // console.log(`Removing direction due to never receiving energy ${this.id}`)
            this.justCreated = false;
            // this.dissipate("because never got energy and should be removed");
            if(this.getEnergyLevel() > 0) throw new Error(`Tried to remove active energy having direction ${this.id} from neighborhood ${this.getEnergyLevel()}`)
                // console.log(`Direction ${this.id} never received energy and should be removed from neighborhood`);
            return true;
        }
        if(!this.active && this.inNeighborhood && longerThanThreshold){
            // console.log(`Removing direction due to inactivity ${this.id}`)
            if(this.getEnergyLevel() > 0) throw new Error(`Tried to remove inactive energy having direction ${this.id} from neighborhood ${this.getEnergyLevel()}`)
            // console.log(`Direction ${this.id} should be removed from neighborhood due to subsequent inactivity`);
            return true;
        }
        return false;
    }

    setActive=(value: boolean)=>{
        this.active = value;
    }
 
    setLastActiveTime = (value: number)=>{
        this.lastActive = value;
    }

    isOppJustCreated=(): boolean=> {
        const oppObj = this.getOppositeDirectionObject();
        const justCreated = oppObj ? oppObj.justCreated : false;
        return justCreated;
    }
    activeButOnlyHoldingSpace=()=>{
        const bothJustCreated = this.justCreated && this.isOppJustCreated();
        if(this.dissipation.isDissipating || this.dissipation.isOppositeDissipating()) return false;
        //Both are in play, one direction has energy, but it's not this direction
        if(!bothJustCreated && (this.oneDirectionNotZeroEnergy() && this.isEnergyLevelZero())) return true;
        else return false;
    }
    isCurrentlyActive=(): boolean=> {
        const bothJustCreated = this.justCreated && this.isOppJustCreated();
        if(this.dissipation.isDissipating || this.dissipation.isOppositeDissipating()) return true;
        //Both are not yet in play
        if(bothJustCreated) return true;
        //Both are in play, and at least one has energy.
        if(!bothJustCreated && this.oneDirectionNotZeroEnergy()) return true;
        //Both are in play, but have no energy.
        if(!bothJustCreated && this.isDirectionAndOppositeZeroEnergy()) return false;
        throw new Error("Condition not found when checking if direction is currently active");
    }

    updateLastActiveTime = (): boolean =>{
        //When beliefs are contradicatory take action else, return current situation.
        if(this.isCurrentlyActive() && !this.active){
            // console.log(`Setting ACTIVE STATUS to TRUE for ${this.id}`)
            this.setActive(true);
            return true;
        }
        if(!this.isCurrentlyActive() && this.active){
            // console.log(`Setting ACTIVE STATUS of direction ${this.id} to FALSE`)
            this.setActive(false);
            this.setLastActiveTime(this.GOE.gameTime);
            return false;
        }
        const status = this.isCurrentlyActive();
        // console.log("Returning status: ", status)
        return status
    }

    isOppQueueEmpty=()=>{
        const oppObj = this.getOppositeDirectionObject();
        const hasParticles = oppObj ? !oppObj.hasParticlesInQueue() : true;
        return hasParticles;
    }
    isOppEnergyZero=()=>{
        const oppObj = this.getOppositeDirectionObject();
        const oppEnergyIsZero = oppObj ? oppObj.isEnergyLevelZero() : true;
        return oppEnergyIsZero;
    }
    isDirectionAndOppositeZeroEnergy = ()=>{
        return ( (this.isEnergyLevelZero()) && (this.isOppEnergyZero()) )
    }
    oneDirectionNotZeroEnergy = ()=>{
        return ( !(this.isEnergyLevelZero()) || !(this.isOppEnergyZero()) )
    }
    isDirectionAndOppositeQueueEmpty= ()=>{
        return ( !(this.hasParticlesInQueue()) && (this.isOppQueueEmpty()) )
    }
    oneDirectionQueueNotEmpty= ()=>{
        return ( (this.hasParticlesInQueue()) || !(this.isOppQueueEmpty()) )
    }
    isDirectionAndOppositeParticleFree=()=>{
        const oppObj = this.getOppositeDirectionObject();
        const isOppObjParticleFree = oppObj ? oppObj.hasNoParticles() : true;
        const isThisParticleFree = this.hasNoParticles();
        return isOppObjParticleFree && isThisParticleFree
    }
    hasNoParticles=()=>{
        const hasParticlesInQueue = this.hasParticlesInQueue();
        const hasParticleAddresses = this.hasParticlesOutOfQueue()
        return !hasParticleAddresses && !hasParticlesInQueue;
    }
    hasClockTicked = ()=>{
        const timePassedSinceEnergyMoved = this.GOE.gameTime - this.lastTimeEnergyMoved;
        if ( timePassedSinceEnergyMoved > this.clockTickLength ) return true;
        return false;
    }
    getPotentialObjectSettings=(): PotentialObjectSettings=>{
        return{
            type: "Direction",
            settings:{
                position: this.getPosition(),
                directionArray: this.getDirectionArray(),
            }
        }
    }
    


    onForceEqualToOpposite = ()=>{
        this.correlateTarget();
    }

    onForceGreaterThanOpposite = ()=>{
        this.decorrelateTarget();
    }

    onForceLessThanOpposite = ()=>{
        this.decorrelateTarget();
    }

    removeAllCorrelations = ()=>{
        for (let correlateId of this.correlatingWith){
            const correlate = this.GOE.causalField.getDirectionById(correlateId);
            if(!correlate) return;
            if(this.GOE.tutorial.inProgress() || this.GOE.clearingDirections) return;
            correlate.dissipation.dissipate("because target object dissipated");
        }
        this.correlatingWith = [];
    }

    addParticleAddressToQueue=(address:number, directionOfEnergy: EnergyDirection)=>{
        this.particleQueue = [...this.particleQueue, address];
        // console.log(`Direction ${this.id} adding address to queue: `, address)
        // console.log("Queue: ", this.particleQueue)
    }
    addParticleAddressesToQueue = (addresses: number[])=>{
        // console.log(`Direction ${this.id} adding addresses to queue: `, addresses)
        this.particleQueue = [...this.particleQueue, ...addresses];
        // console.log("Queue: ", this.particleQueue)
    }
    transferParticlesOutOfQueue = ()=>{
        const numberOfParticles = [...this.particleQueue].length
        this.particleAddresses = [...this.particleAddresses, ...this.particleQueue];
        this.particleQueue = [];
        // console.log(`Direction ${this.id} particles after transfer: `, this.particleAddresses);
        return numberOfParticles
    }
    loseParticleAddressesFromQueue=(settings: Task): number[]=>{
        const { energyToTransfer } = settings;
        //Get numbers of particles to transfer
        let particlesToTransfer = energyToTransfer > this.particleQueue.length ? this.particleQueue.length : energyToTransfer;
        //Store addresses to transfer
        const addressesToTransfer = this.particleQueue.splice(0, particlesToTransfer);
        //Remove these addresses from queue
        this.particleQueue = this.particleQueue.filter((address: number)=>{
            if(addressesToTransfer.includes(address)) return false;
            else return true;
        });
        return addressesToTransfer;
    }
    loseParticleAddresses=(settings: Task): number[]=>{
        const { energyToTransfer, type } = settings;
        // console.log("energyToTransfer: ", energyToTransfer);
        if(!this.isEnergySufficientToBreakBond(energyToTransfer) && !momentumTypes.includes(type)){
            const { caller } = settings;
            if(typeof caller !== "string") return [];
            const callerObj = this.GOE.getObjectById(caller);
            if(!callerObj) return [];
            if(callerObj instanceof Direction) callerObj.dissipation.dissipate("because energy wasn't sufficient to break bond - loseParticleAddresses");
            // if(callerObj instanceof Direction) callerObj.dissipation.dissipate("");
            return [];
        }
        // console.log(`Direction ${this.id} is losing particles ${amount} from pool of ${this.particleAddresses.length} energy`);
        // console.log("Amount demanded: ", amount);
        //Get numbers of particles to transfer
        let particlesToTransfer = energyToTransfer > this.particleAddresses.length ? this.particleAddresses.length : energyToTransfer;
        // console.log("Particles to transfer: ", particlesToTransfer);
        //Store addresses you're going to transfer
        const addressesToTransfer = this.particleAddresses.splice(0, particlesToTransfer);
        // console.log("Addresses to transfer: ", addressesToTransfer);
        //Remove particles that you're transfering
        this.particleAddresses = this.particleAddresses.filter((address: number)=>{
            if(addressesToTransfer.includes(address)) return false;
            else return true;
        });
        // this.reactToEnergyLoss();
        return addressesToTransfer;
    }


    //Main Functionality Helpers
    setLastBitFlipTime = (time: number)=>{
        this.lastBitFlipTime = time;
    }

    deselectAllObjectsIfIsSelected=()=>{
        const {selectedObject, selectedSource, selectedTarget} = this.GOE.controls;
        // if(!selectedObject) console.warn("Selected object is glitched on deselect: ", {...selectedObject});
        if(!selectedObject) return
        // if(!selectedObject.id) console.log(`No id on selected object: `, selectedObject);
        // if(!selectedSource.id) console.log(`No id on selected source: `, selectedSource);
        // if(!selectedTarget.id) console.log(`No id on selected target: `, selectedTarget);
        if( (selectedObject && selectedObject.id) === this.id) return this.GOE.controls.deselectAllObjects();
        if( (selectedSource && selectedSource.id) === this.id) return this.GOE.controls.deselectAllObjects();
        if( (selectedTarget && selectedTarget.id) === this.id) return this.GOE.controls.deselectAllObjects();
    }

    //Correlation Business
    correlateWith=(newCorrelate: ExistingObject)=>{
        // console.log(`Direction ${this.id} is correlating with: `, newCorrelate);
        if(this.correlatingWith.includes(newCorrelate.id)){
            // console.log("Already correlating with!");
            return;
        }
        this.correlatingWith = this.correlatingWith.concat([newCorrelate.id]);
    }

    removeCorrelation=(newCorrelate: ExistingObject )=>{
        // console.log(`Direction ${this.id} removing correlation with: `, newCorrelate);
        this.correlatingWith = this.correlatingWith.filter((id)=>id!==newCorrelate.id);
    }

    correlateTarget = (isOpposite: boolean = false)=>{
        //Each direction listens to its target.
        if(isOpposite || !this.target) return;
        if(this.target.correlatingWith.includes(this.id)) return;
        // console.log(`Direction ${this.id} has initiated correlation with ${this.target.id}`);
        //Tell opposite direction to correlate its target.
        this.target.correlateWith(this);
        // this.correlateOppositeDirection();
        // this.createBalancedArrow();
    }

    correlateOppositeDirection = (isOpposite: boolean = true)=>{
        const oppObj = this.getOppositeDirectionObject()
        if(oppObj) oppObj.correlateTarget(isOpposite);
    }

    decorrelateTarget = ()=>{
        // console.log(`Direction ${this.id} is decorrelating target`);
        this.target.removeCorrelation(this);
    }

    reactToBitFlip = ()=>{
        // console.log(`Direction ${this.id} reacting to bit flip`);
        return;
    }

    triggerCorrelates=()=>{
        // return;
        // if(!this.correlatingWith.length) return;
        if(!this.correlatingWith.length || this.justCreated) return;
        // console.log(`Direction`)
        // const box = makeDebugBox(new Color3(.2,.2,1), this);
        // box.getPosition().y = 1.5;
        // this.filterNonExistantCorrelates();
        // console.log("Grid Cell Triggering Bit Flip");
        // console.log(`Direction ${this.id} triggering correlates at time ${this.GOE.gameTime}`);
        // this.makeDebugBox(new Color3(1,0,1))
        // console.log("Correlations: ", this.correlatingWith);
        // this.correlator.reactToEnergyChange();
        this.correlatingWith.forEach((correlationIndex: string)=>{
            const correlate = this.GOE.getObjectById(correlationIndex)
            //Weeding out dead correlative bits.
            // if(!correlate) return this.correlatingWith = this.correlatingWith.filter((index)=>index!==correlationIndex);
            if(!correlate) return;
            // correlate.activate(this.id);
            if( !(correlate instanceof Direction) ) throw new Error("Correlate to trigger wasn't a direction!");
            // console.log(`Triggering correlate ${correlate.correlator.direction.id}`)
            correlate.correlator.reactToEnergyChange();
        })
    }

    setSubspaceId(id: any){
        this.subspaceId = id;
    }
    addSelfToSubSpace = ()=>{
        this.GOE.spatialPartioner.storeObjectinSubspace(this);
    }
    removeSelfFromSubSpace = ()=>{
        this.GOE.spatialPartioner.removeObjectFromSubspace(this);
    }

    //CHECKERS
    isCorrelating = ()=>{
        return this.target ? ( this.target.correlatingWith.includes(this.id) && this.areForcesBalanced() ) : false;
    }

    isEnergyTooHigh = (sourceEnergy, targetEnergy) =>{
        return false;
    }

    areForcesBalanced = ()=>{
        if(this.dissipation.isDissipating || this.dissipation.isOppositeDissipating()) return false;
        const oppEnergy = this.getOppEnergyLevel();
        const energy = this.getEnergyLevel();
        if(oppEnergy === 0 && energy === 0) return false;
        return energy === oppEnergy;
    }

    isEnergyLevelZero = ()=>{
        return (this.particleAddresses.length === 0);
    }

    //GETTERS
    getOppEnergyLevel=()=>{
        const oppObj = this.getOppositeDirectionObject();
        const oppEnergy = oppObj ? oppObj.getEnergyLevel() : 0;
        return oppEnergy;
    }
    getSubspaceId=()=>{
        return this.subspaceId;
    }

    getNeighborIds = ()=>{
        return this.getCurrentNeighbors().map((obj: any)=>obj.id);
    }

    getOppDirectionArray = ()=>{
        return [this.targetId, this.sourceId];
    }
    getDirectionArray = ()=>{
        return [this.sourceId, this.targetId];
    }

    public getPosition =()=>{
        // return this.getPosition();
        return new Vector3(
            this.position.x,
            this.position.y,
            this.position.z
        );
    }

    getDirectionIndex=()=>{
        return convertDirectionArrayToString([this.sourceId, this.targetId])
    }

    getOppositeDirectionIndex=()=>{
        const oppositeDirectionArray = getOppositeDirectionArrayFromArray([this.sourceId, this.targetId]);
        return convertDirectionArrayToString(oppositeDirectionArray);
    }

    getOppositeDirectionObject=()=>{
        const oppIndex = this.getOppositeDirectionIndex();
        // console.log("Opp Index: ", oppIndex);
        // console.log("Parent directions: ", this.causalEnergy.directions);
        return this.GOE.causalField.directions[oppIndex];
    }

    getEnergyLevel = ()=>{
        return this.particleAddresses.length
    }
    getDistanceBetweenSourceAndTarget = ()=>{
        return this.distanceBetweenSourceAndTarget;
    }

    //VISUAL ELEMENTS



    switchColor=(state: ObjectSelectionStates)=>{
        this.visuals.switchColor(state);
    }
  

}

export default Direction;