import { SPEED_OF_LIGHT, momentumTypes } from "../constants";
import { ExistingObject, Task } from "../types";
import Direction from "./Direction";
import GameOfExistence from "./GameOfExistence";
import GridCell from "./GridCell";
import PotentialObject from "./PotentialObject";

class EnergyCapacity {
    // Eba: number = 2;
    // Eab: number = 5;
    // Ea: number = 10;
    // Eb: number = 5;
    GOE: GameOfExistence = null;
    version: "1" | "2" | "3" = "1";
    constructor(GOE: GameOfExistence){
        this.GOE = GOE
        // const result = this.simulateTimeWithReverse(Ea,Eb,Eab, Eba);
    }
    //Distance may be included as future parameter
    isTaskImpossible=(direction: Direction, settings?: Task)=>{
        if(this.version === "1") return this.isTaskImpossibleV1(direction, settings)
        if(this.version === "2") return this.isTaskImpossibleV2(direction)
        throw new Error("Version for energy capacity not found");
        //Load version depending on set version
    }
    private isTaskImpossibleV1 = (direction: Direction, settings?: Task)=>{
        const {source, target} = direction;
        const isMomentumBased = (settings && settings.type) ? momentumTypes.includes(settings.type) : false
        if(isMomentumBased) return false;
        // console.log(`Direction ${direction.id} checking if task is possible`)
        // if(this.justCreated && direction.isEnergyLevelZero()) return false;
        const energyInSourceIsZero = source && !source.getEnergyLevel();
        const objectsDontExist = !source || !target

        if(objectsDontExist && isMomentumBased) console.log(`Task is IMPOSSIBLE because Objects don't exist for ${direction.id}`)
        if(objectsDontExist) return true;

        if(energyInSourceIsZero && !direction.areForcesBalanced() && isMomentumBased){
            // console.log("Source: ", source)
            // console.log("Source Energy: ", source.getEnergyLevel())
            console.log(`Task is IMPOSSIBLE because energy in source is zero for ${direction.id} and not balanced`);
            const potentialSource = new PotentialObject(this.GOE, settings.source);
            const potentialTarget = new PotentialObject(this.GOE, settings.target);
            const source = potentialSource.getObject();
            const target = potentialTarget.getObject();
            console.log("Source: ", source);
            console.log("Target: ", target);
            console.log("SourceId: ", source ? source.id : "Source id undefined");
            console.log("TargetId: ", target ? target.id : "Target Id undefined");
            // console.log("This Obj: ", direction)
            // console.log("Opp Obj: ", direction.getOppositeDirectionObject())
        }
        if(energyInSourceIsZero  && !direction.areForcesBalanced()) return true;
        if(!energyInSourceIsZero  && !direction.areForcesBalanced()) return false;

        // if(this.justCreated) return false;
        // if(!this.justCreated && !this.isEnergyLevelZero()) return false;
        // else{
            //If source and target exists
            //If justCreated
            //
            // console.log("Energy level: ", direction.getEnergyLevel())
            // console.log("Source energy: ", source.getEnergyLevel())
            // console.log("Just created? : ", direction.justCreated);
            // console.log("Direction: ", direction);
            // console.log("OppObj: ", direction.getOppositeDirectionObject());
            // throw new Error('Condition not found for current task')
        // }
        // else return false;
        return false;
    }
    private isTaskImpossibleV2 = (direction: Direction)=>{
        const {source, target} = direction;
        const isBalanced = direction.areForcesBalanced();
        const energyInSourceIsZero = source && !source.getEnergyLevel();
        const objectsDontExist = !source || !target
        // console.log(`Direction ${direction.id} checking if task is possible`)

        if(energyInSourceIsZero) console.log(`Task is IMPOSSIBLE because energy in source is zero for ${direction.id}`)
        if(objectsDontExist) console.log(`Task is IMPOSSIBLE because Objects don't exist for ${direction.id}`)
        if( (energyInSourceIsZero && !isBalanced) || objectsDontExist) return true;
        const sourceEnergy = source.getEnergyLevel();
        console.log("Source energy: ", sourceEnergy);

        const targetEnergy = target.getEnergyLevel();
        console.log("Target energy: ", targetEnergy);

        const EC = this.calculateEnergyCapacity(sourceEnergy, targetEnergy);
        console.log("Energy Capacity: ", EC);

        console.log("Energy of direction: ", direction.getEnergyLevel());
        const possibleWork = this.calculateWork(sourceEnergy, targetEnergy, direction.getEnergyLevel());
        console.log("Work that can be done: ", possibleWork);

        if(!possibleWork) console.log("Task is IMPOSSIBLE because possibleWork is zero");
        if(possibleWork === Infinity || possibleWork === -Infinity) console.log("Task is IMPOSSIBLE because possibleWork is +/- INFINITY");
        if(!possibleWork || possibleWork === Infinity || possibleWork === -Infinity) return true;
        else return false;
        // else return false;
    }

    private checkForPracticalImpossibilities = (source: ExistingObject, target: ExistingObject, energyOfDirection: number)=>{
        const objectsDontExist = !source || !target

        // if(amount <= 0) console.log(`Task would be impossible because amount less than or equal to zero: ${amount}`)
        if(energyOfDirection <= 0) return true;

        // if(objectsDontExist) console.log(`Task would be IMPOSSIBLE because objects don't exist`)
        if(objectsDontExist) return true;

        // if(target instanceof Direction) console.log(`Task would be IMPOSSIBLE because target is dissipating!`);
        if(target instanceof Direction && target.dissipation.isDissipating) return true;
        
    }
    public wouldTaskBeImpossible=(dissipatingDirection: Direction, energyForNovelDirection: number, source: ExistingObject, target: ExistingObject)=>{
        const isPracticallyImpossible = this.checkForPracticalImpossibilities(source, target, energyForNovelDirection);
        if(isPracticallyImpossible) return true;
        if(this.version === "1") return this.wouldTaskBeImpossibleV1(dissipatingDirection, energyForNovelDirection, source, target)
        if(this.version === "2") return this.wouldTaskBeImpossibleV2(dissipatingDirection, energyForNovelDirection, source, target)
        throw new Error("Version for energy capacity not found");
        //Load version depending on set version
    }
    private wouldTaskBeImpossibleV1 = (dissipatingDirection: Direction, energyForNovelDirection: number, source: ExistingObject, target: ExistingObject)=>{
        

        const energyInSourceIsZero = source && source.getEnergyLevel();
        const oppObj = this.GOE.causalField.getDirection([target.id, source.id]);
        const oppEnergy = oppObj ? oppObj.getEnergyLevel() : 0;
        const wouldBalance = energyForNovelDirection === oppEnergy;
        const wouldBeGreaterThanOpposite = energyForNovelDirection>oppEnergy;
        const wouldBeLessThanOpposite = energyForNovelDirection<oppEnergy;
        const oppEnergyIsZero = oppEnergy == 0;

        // if(wouldBalance && !oppEnergyIsZero) console.log(`Task impossible because would be greater and source is zero`)
        if(wouldBalance && oppEnergyIsZero) return true; //Can't balance with an oppEnergy of 0
        if(wouldBalance && !oppEnergyIsZero) return false;

        // if(wouldBeGreaterThanOpposite && energyInSourceIsZero) console.log(`Task "impossible" because would be greater and source is zero`)
        if(wouldBeGreaterThanOpposite && energyInSourceIsZero) return false; // Attempting this task causes immediate dissipation.
        if(wouldBeGreaterThanOpposite && !energyInSourceIsZero) return false;


        // if(wouldBeLessThanOpposite && oppEnergyIsZero) console.log(`Task would be impossible because opp energy is zero and ${amount} would be less than it`)
        if(wouldBeLessThanOpposite && oppEnergyIsZero) return true;
        if(wouldBeLessThanOpposite && !oppEnergyIsZero) return false;


        // console.log(`Direction ${this.id} checking if task is possible`)
        // if(energyInSourceIsZero) console.log(`Task is IMPOSSIBLE because energy in source is zero for ${this.id}`)
        // if(objectsDontExist) console.log(`Task is IMPOSSIBLE because Objects don't exist for ${this.id}`)
        throw new Error('wouldTaskBeImpossibleV1 - Condition not found for would be task')
        // else return false;
    }
    private wouldTaskBeImpossibleV2 = (dissipatingDirection: Direction, energyForNovelDirection: number, source: ExistingObject, target: ExistingObject)=>{
        
        //All the constraints from V1
        if(this.wouldTaskBeImpossibleV1(dissipatingDirection, energyForNovelDirection, source, target)) return true;

        //However, now considering the actual work to be done.
        const sourceEnergy = source.getEnergyLevel();
        console.log("WouldBe - Source energy: ", sourceEnergy);

        const targetEnergy = target.getEnergyLevel();
        console.log("WouldBe - Target energy: ", targetEnergy);

        const EC = this.calculateEnergyCapacity(sourceEnergy, targetEnergy);
        console.log("WouldBe - Energy Capacity: ", EC);

        const workToBeDone = this.calculateWork(sourceEnergy, targetEnergy, energyForNovelDirection);
        console.log("WouldBe - Work to be done: ", workToBeDone);

        const workIsInfinity = (workToBeDone === Infinity) || (workToBeDone === -Infinity);
        if(!workToBeDone) console.log("Task would be IMPOSSIBLE because direction wouldn't be able to do work i.e. work = 0");
        if( (workToBeDone === Infinity) || (workToBeDone === -Infinity) ) console.log("Task is IMPOSSIBLE because work to be done is +/- INFINITY");
        if(workToBeDone && !workIsInfinity) return false;
        if(!workToBeDone || workIsInfinity) return true;

        // else return false;

        // console.log(`Direction ${this.id} checking if task is possible`)
        // if(energyInSourceIsZero) console.log(`Task is IMPOSSIBLE because energy in source is zero for ${this.id}`)
        // if(objectsDontExist) console.log(`Task is IMPOSSIBLE because Objects don't exist for ${this.id}`)
        console.log("Dissipating direction: ", dissipatingDirection)
        console.log("energyToDissipate: ", energyForNovelDirection)
        console.log("source: ", source)
        console.log("target: ", target)
        throw new Error('wouldTaskBeImpossibleV2 - Condition not found for would be task')
        // else return false;
    }
    calculateParticlesToMove = (direction: Direction)=>{
        if(this.version === "1") return this.calculateParticlesToMoveV1(direction)
        if(this.version === "2") return this.calculateParticlesToMoveV2(direction)
    }
    private calculateParticlesToMoveV1=(direction: Direction)=>{
        const oppEnergy = direction.getOppEnergyLevel();
        const currentEnergy = direction.getEnergyLevel();
        // console.log("Current energy: ", currentEnergy);
        // console.log("Opp energy: ", oppEnergy);
        const forceInIsolation = ( currentEnergy > oppEnergy) ? currentEnergy - oppEnergy : 0
        return forceInIsolation;
    }
    private calculateParticlesToMoveV2 = (direction: Direction)=>{
        const {source, target} = direction;
        const directionEnergyLevel = direction.getEnergyLevel();
        if(!source || !target) throw new Error(`Direction ${direction.id} failed to calculate particles to move`);
        const sourceEnergy = source.getEnergyLevel();
        const targetEnergy = target.getEnergyLevel();
        const workToBeDone = this.calculateWork(sourceEnergy, targetEnergy, directionEnergyLevel)
        return workToBeDone
    }
    //The energy released during bond formation is the
    //same amount of energy that would need to be supplied to break the bond.
    //This is known as the bond dissociation energy. 
    //Inspired by this name
    public calculateDissociationEnergy = (direction: Direction, energy: number)=>{
        const distance = direction.getDistanceBetweenSourceAndTarget();
        const de =  energy/distance;
        console.log("Dissociation Energy: ", de)
        return de;
        // return (energy/distance) * SPEED_OF_LIGHT;
    }
    // public calculateDissociationEnergy = (direction: Direction)=>{
    //     const energyLevel = direction.getEnergyLevel();
    //     if(!direction.areForcesBalanced()) return energyLevel;
    //     const oppEnergy = direction.getOppEnergyLevel();
    //     const totalEnergy = energyLevel + oppEnergy;
    //     const dissociationEnergy = this.GOE.momentum.calculateInverseMass(totalEnergy)
    //     return dissociationEnergy;
    // }

    private calculateDissipationRate =  (direction: Direction)=>{
        const energyLevel = direction.getEnergyLevel();
        const oppEnergy = direction.getOppEnergyLevel();
        return Math.round(this.calculateEnergyCapacity(energyLevel, oppEnergy));
    }
    
    calculateMomentum = (direction: Direction)=>{
        const forceInIsolation = this.calculateParticlesToMove(direction);
        // console.log("Force in isolation: ", forceInIsolation);
        const energyInSource = direction.source.getEnergyLevel();
        // console.log("Energy in source: ", energyInSource);
        return energyInSource > forceInIsolation ? 0 : forceInIsolation - energyInSource;
    }
    calculateImpactMomentum = (obj: Direction | GridCell, force: number)=>{
        return obj.getEnergyLevel() < force ? obj.getEnergyLevel() : force;
    }
    private calculateEnergyCapacity=(Ea,Eb)=>{
        const deltaE = Math.abs(Ea-Eb);
      return ( deltaE * Ea ) / (Ea+Eb);
    //   return ( deltaE * Eb ) / (Ea+Eb);
    //   return  ( deltaE * (Ea/Eb) ) / (Ea+Eb);
    }
    //This is the work actually done to keep things discrete
    private calculateParticlesTransfered=(EC, Eab)=>{
        return Math.round(Eab/EC);
    }
    private calculateWork=(Ea: number,Eb: number, Eab: number)=>{
        const EC = this.calculateEnergyCapacity(Ea,Eb);
        // console.log("Energy Capacity: ", EC);
        const work = this.calculateParticlesTransfered(EC,Eab);
        return work;
    }
    
}

export default EnergyCapacity;