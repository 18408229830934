import { COLORS } from "../constants";

const GoogleDocumentViewer = (props: any) => {
    const { documentURL, documentId } = props;
    return (
      <iframe
        src={`${process.env.PUBLIC_URL}/hello.html`}
        // style={{ width: '100%', height: '100%', border: 'none' }}
        style={{
          // border: 'none',
          backgroundColor: COLORS.cream,
          height: '85vh', width: '100%'
        }}

    />
    );
};

export default GoogleDocumentViewer;