import enerstaticNetwork from '../../assets/EnerstaticNetwork.png';


const NeurobiologyExplanation = (props: any) => {

    const { isMobile } = props;
    const Text = (props)=>{
      return(
        <div style={{marginTop: '1.50%', marginBottom: '1.50%', marginLeft: '2.5%', marginRight: '2.5%', ...props.style}}>
          {props.children}
        </div>
      )
    }
    const BiologyResearchProgram = ({ isMobile }: any) => {
      return (
        <div style={{  }}>
        <Text style={{fontSize: '1.2em', fontWeight: '600', marginTop: 0, textAlign: 'center'}}>
        Program in short
        </Text>
        <ol style={{marginRight: '1em'}}>
            <li>Create an Eleatic game, or use HGoE</li>
            <li>Create, or use existing, models that describe the behavior of various biological cell parts</li>
            <li>Calculate energetic cost of each model using an Eleatic Game</li>
            <li>Use <a href='https://en.wikipedia.org/wiki/Free_energy_principle#Active_inference'>Active Inference</a> to model biological cells as agents that reorganize their cellular parts based on the energetic cost of each part.</li>
            <li>Simulate behavior of collectives of these cells, and evolve them to solve behavioral tasks.</li>
          </ol>
        </div>
      );
    };
    
    const Explanation=()=>{
      return(
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: isMobile ? '100%' : '50%',
        // maxWidth: '850px',
        textAlign: 'justify',
        fontWeight: '500',
        backgroundColor: '#E6CCA1',
        color: 'black',
        // paddingLeft: '5%',
        // paddingRight: '5%',
      }}>
        <BiologyResearchProgram/>
        <Text style={{fontSize: '1.2em', fontWeight: '600', marginTop: 0,marginBottom: 0, textAlign: 'center',}}>
          Motivation
        </Text>
        <Text>
          Eleatic Games are the ultimate generalization of a hypothesis about brain cells.
        </Text>
        <Text>
          Put simply, the hypothesis is that brain cells are “energy management systems” first and “information processing systems” second.
        </Text>
        <Text>
          Often, brain cells are modeled as if they are parts of a computer responsible for interacting with the world. Energy considerations are ignored as if brains are simply “plugged” into a power supply.
        </Text>
        <Text>
          However, brain cells do not in fact receive a steady power supply that always meets their energy demands and failure in meeting this demand can lead to the death of the cell. Therefore, the hypothesis suggests that brain cells must “strategize” about how to use their available energy to strike an appropriate balance between information processing and energy management.
        </Text>
        <Text>
          In other words, it is reasonable to model brain cells as if they are playing a game in which they can only exist by finding the right balance between information processing and energy management. Interestingly, the information processing that a brain cell can do depends on its particular cellular architecture. This is like how the purpose of a building strongly depends on the way that it is built – You wouldn’t really want to live in a supermarket!
        </Text>
        <Text>
          Similarly, brain cells remodel their cellular architecture such that their energy demands match the rate of energy flowing into them, which then affects the information processing that they do. This is akin to a supermarket modifying the food contents they sell according to the demand of the community around them. This can even require the supermarket to acquire additional equipment to handle storing particular foods.
        </Text>
        <Text>
          Understanding the strategies that brain cells use to remodel their cellular architecture is of utmost importance to finding new therapies for neurodegenerative diseases and disorders as well as creating new technology that is as efficient as biology.
        </Text>
        <Text>
          Eleatic Games provide a novel way for modelers to quantify the amount of energy required to perform any given information processing that a brain cell might do so that models of brain cells that “make decisions” under energetic constraints can be created.
        </Text>
        <Text>
          While they have their origins in neuroscience, Eleatic Games can quantify the amount of energy required to perform information processing in any system whether it is biological, digital, or hybrid in origin. Therefore, the same methodology can be applied to different fields.
        </Text>
        <Text>
          <strong>A detailed paper explaining the pipeline for developing biological experiments around Eleatic Game Design is on the way.</strong>
        </Text>
      </div>
      )
    }
    const NetworkImage = ()=>{
      return(
      <div style={{
        minHeight: isMobile ? '250px' : 'none',
          // minHeight: isMobile ? '250px' : '500px',
          width: isMobile ? '100%' : '35%',
          backgroundPositionY: isMobile ? '85%' : 0,
          position: 'relative',
          bottom: '10px',
          backgroundImage: `url(${enerstaticNetwork})`,
          backgroundSize: isMobile ? 'cover' : 'contain',
          backgroundRepeat:'no-repeat',
        }}/>
      )
  }
    return (
      <div style={{
        
        display: 'flex',
        flexDirection: isMobile ? 'column-reverse' : 'row',
        justifyContent: isMobile ? 'center' : 'space-around',

      }}>
        {/* <div style={{
          flex: 1,
          // flexDirection: 'column',
          // justifyContent: 'center',

        }}> */}
          {/* <BiologyResearchProgram/> */}
          <Explanation/>
        {/* </div> */}
        <NetworkImage/>
      </div>
    );
};

export default NeurobiologyExplanation