import evolution from '../../assets/evolution.png';
import vortex from '../../assets/vortex.png';


const ArtificialLifeExplanation = (props: any) => {

  const { isMobile } = props;
  const Text = (props)=>{
    return(
      <div style={{marginTop: '2.5%', marginBottom: '2.5%', marginLeft: '2.5%', marginRight: '2.5%', ...props.style}}>
        {props.children}
      </div>
    )
  }
  const ALIResearchProgram = ({ isMobile }: any) => {
    return (
      <div style={{}}>
        <Text style={{ fontSize: '1.2em', fontWeight: '600', marginTop: 0, textAlign: 'center' }}>
        Program in short
        </Text>
        <ol style={{marginRight: '1em'}}>
          <li>Create an Eleatic Game, or use HGoE</li>
          <li>Attempt to create AI agents capable of creating and maintaining an indefinite number of objects</li>
          <li>Study the relationship between Open-Ended Evolutionary Dynamics, the rules of the Eleatic Game, and the ability of the AI agent(s).</li>
          <li>Investigate how different Eleatic games affect the capacity of the AI agents to achieve open-ended evolution.</li>
        </ol>
      </div>
    );
  };
  const Explanation=()=>{
    return(
    <div style={{
      width: isMobile ? '100%' : '50%',
      // maxWidth: '850px',
      textAlign: 'justify',
      fontWeight: '500',
      backgroundColor: '#E6CCA1',
      color: 'black',
      // paddingLeft: '5%',
      // paddingRight: '5%',
    }}>
        <ALIResearchProgram/>
        <Text style={{fontSize: '1.2em', fontWeight: '600', marginTop: 0,marginBottom: 0, textAlign: 'center',}}>
          Motivation
        </Text>
        <Text>
          In Artificial Life, the problem of “Open-Ended Evolution” is a hot topic. Open-ended evolution concerns the problem of how to construct a simulation of artificial creatures that are capable of evolving to become more complex forever without limit.
        </Text>
        <Text>
          Eleatic Game Design approaches this problem by defining the physics that artificial creatures must obey, rather than the details of creatures themselves.
        </Text>
        <Text>
          For example, instead of defining a creature called “Rabbit” that has actions like “Hop, Eat, and Walk”, we define materials that when composed could exhibit “rabbit-like” behavior.
        </Text>
        <Text>
          This allows the diversity of creatures to be truly open-ended.
        </Text>
        <Text>
          Eleatic Game Design creates these materials under possible laws of physics by using a constructor-theoretic approach. This is detailed in the paper section titled <strong>"Eleatic Games: A constructor-theoretic approach to developing OEE systems"</strong>
        </Text>
        <Text>
          However, this comes with challenges of its own, because we can no longer be confident about what group of pixel correspond to the same "object" at any given time.
        </Text>
        <Text>
          Consider a vortex in a river
        </Text>
        <div style={{
            minHeight: '200px',
          // minHeight: isMobile ? '250px' : '500px',
            // width: isMobile ? '100%' : '35%',
            width: '100%',
            backgroundPositionY: isMobile ? '35%' : 0,
            // position: 'relative',
            // bottom: '10px',
            alignSelf: 'center',
            margin: '0 auto',
            backgroundImage: `url(${vortex})`,
            backgroundSize: isMobile ? 'cover' : 'contain',
            backgroundRepeat:'no-repeat',

          }}/>
          <Text>
            Clearly, the vortex is made out of the same "stuff" that the rest of the river is made out of namely, water. However, the water making up the vortex is clearly different in kind.
          </Text>
          <Text>
            The vortex is an individual distinguishable entity in the same way that objects, or organisms, become distinguished from their environment, by engaging in patterns that are capable of carrying information through time.
          </Text>
          <Text>
            The <a href={'https://link.springer.com/article/10.1007/s12064-020-00313-7'}>Interface Theory of Individuality (ITI)</a> is a theory dedicated to identifying such information carrying individuals, and constitutes a rich and principled framework for analyzing and creating metrics for Eleatic Games.
          </Text>
          <Text>
            Analytical methods for better understanding emergent informational individuals in Eleatic Games is discussed at length in the Eleatic Games paper
          </Text>
          <Text>
            This leads to a principled way for creating and driving open-ended evolutionary systems.
          </Text>
    </div>
    )
  }
  const NetworkImage = ()=>{
    return(
    <div style={{
      minHeight: isMobile ? '250px' : 'none',
      // minHeight: isMobile ? '250px' : '500px',
      width: isMobile ? '100%' : '35%',
        backgroundPositionY: isMobile ? '40%' : 0,
        position: 'relative',
        bottom: '10px',
        backgroundImage: `url(${evolution})`,
        backgroundSize: isMobile ? 'cover' : 'contain',
        backgroundRepeat:'no-repeat',

      }}/>
    )
}
  return (
    <div style={{
      
      display: 'flex',
      flexDirection: isMobile ? 'column-reverse' : 'row',
      justifyContent: isMobile ? 'center' : 'space-around',

    }}>
      <Explanation/>
      <NetworkImage/>
    </div>
  );
};



export default ArtificialLifeExplanation