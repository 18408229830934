import { ExecuteCodeAction, ActionManager, Color3, DynamicTexture, Mesh, MeshBuilder, StandardMaterial, Vector3, Scene } from "@babylonjs/core";
import GameOfExistence from "./GameOfExistence";
import GridCell from "./GridCell";
import { PossibleObject } from "../types";
import { DIRECTION_HOVER_HEIGHT } from "../constants";

class EnergyDisplayer {

    dynamicTexture: DynamicTexture;
    energyScroller: Mesh | null;
    energyScrollMaterial: StandardMaterial;
    energyValue: number = 0;
    energyControlOn = false;

    parent: PossibleObject= null;
    GOE: GameOfExistence = null;
    scene: Scene = null;

    constructor({GOE, energyValue, parent}){
        this.parent = parent;
        this.GOE = GOE
        this.energyValue = energyValue;
        this.scene = GOE.scene;
    }

    dispose=()=>{
        this.energyScroller && this.energyScroller.dispose();
    }
    setScene=(scene: Scene)=>{
        this.scene = scene;
    }
    createDisplay=()=>{
        const scene = this.scene;
        const width = 0.15;
        const height = 0.15;
        const energyScroller = MeshBuilder.CreatePlane(`Energy displayer ${this.parent.id}`, { width, height }, scene);
        energyScroller.metadata = {}
        energyScroller.metadata.id = this.parent.id;
        
        this.dynamicTexture = new DynamicTexture("dynamicTexture", 512, scene, true);
        const parentPosition = this.parent.getPosition();
        energyScroller.position = new Vector3(parentPosition.x, parentPosition.y + DIRECTION_HOVER_HEIGHT*2.5, parentPosition.z);
        energyScroller.billboardMode = Mesh.BILLBOARDMODE_ALL;
        energyScroller.isPickable = false;
        var material = new StandardMaterial("mat", scene);
        energyScroller.material = material;
        energyScroller.isVisible = this.energyValue === 0 ? false : true;
        material.diffuseTexture = this.dynamicTexture;
        material.diffuseTexture.hasAlpha = true;
        material.useAlphaFromDiffuseTexture
        material.specularColor = new Color3(0, 0, 0);
        material.emissiveColor = new Color3(1, 1, 1);
        material.backFaceCulling = false;
        // this.dynamicTexture.drawText("alpha", null, 140, "bold 140px verdana", "white", "#00000000")
        // material.disableLighting = true; // Disable lighting effects
        this.energyScroller = energyScroller;
        // Initialize energyScrollValue
        this.dynamicTexture.drawText(this.energyValue.toString(), null, null, "450px verdana", "white", null);
        this.dynamicTexture.update();
    }
    
    updateNumber=(newEnergyValue: number)=>{
        this.energyValue = newEnergyValue;
        
        if(newEnergyValue === 0) return this.energyScroller.isVisible = false;
        else this.energyScroller.isVisible = true;

        const context2d = this.dynamicTexture.getContext();
    
        // Clear the entire canvas
        context2d.clearRect(0, 0, this.dynamicTexture.getSize().width, this.dynamicTexture.getSize().height);
    
        // Draw the new text
        this.dynamicTexture.drawText(this.energyValue.toString(), null, null, "450px verdana", "blue", null);
        this.dynamicTexture.update();
    }
    
}

export default EnergyDisplayer;