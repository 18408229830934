import { AdvancedDynamicTexture, Button, Control, Rectangle, StackPanel } from "@babylonjs/gui";
import isObjEmpty from "../../helpers/isObjEmpty";
import Direction from "../Direction";
import GameControls from "../GameControls";
import GameOfExistence from "../GameOfExistence";
import GridCell from "../GridCell";
import TutorialManager from "./TutorialManager";
import Visuals from "./Visuals";
import { TUTORIAL_SCALE_FACTOR } from "../../constants";

class Tutorial {
    GOE: GameOfExistence = null;
    controls: GameControls = null;
    tutorialSteps: {
        fontSize?: string,
        instruction: string,
        cleanUp?: ()=>{},
        demoStart?: boolean,
        condition: () => boolean | Promise<boolean>,
        sideEffect?: () => {}
    }[];
    // currentStepIndex: number = 31;
    // currentStepIndex: number = 32;
    // currentStepIndex: number = 19;
    // currentStepIndex: number = 15;
    // currentStepIndex: number = 17;
    currentStepIndex: number = 0;
    mostAdvancedStep: number = 0;
    manager: TutorialManager;
    advancedTexture: AdvancedDynamicTexture;
    visuals: Visuals;
    skipCondition: boolean = false;
    skipSideEffect: boolean = false;
    blockInstructions: boolean = false;


    constructor(GOE: GameOfExistence, advancedTexture: AdvancedDynamicTexture) {
        this.advancedTexture = advancedTexture;
        this.GOE = GOE;
        this.controls = GOE.controls;
        this.manager = new TutorialManager(GOE, this);
        this.visuals = new Visuals(this);

        this.tutorialSteps = [];

        this.init();
    }

    init = async () => {
        await this.visuals.createInstructionUI(this.advancedTexture);
        this.tutorialSteps = [
            ...this.manager.introductionTutorial(),
            ...this.manager.stressTutorial(),
            ...this.manager.correlatorsTutorial(),
            ...this.manager.spatialActionsTutorial(),
            ...this.manager.bonusTipTutorial()
        ];
        this.showTutorialStep();
    }


    onNextButtonClick = () => {
        if (this.currentStepIndex < this.tutorialSteps.length - 1) {
            const step = this.tutorialSteps[this.currentStepIndex];
            const isPastAmount = this.currentStepIndex > 12
            if (this.skipCondition || this.currentStepIndex < this.mostAdvancedStep || step.condition()) {
            // if (this.currentStepIndex < this.mostAdvancedStep || (isPastAmount ? step.condition() : true)) {
                this.currentStepIndex++;
                if (this.currentStepIndex > this.mostAdvancedStep) {
                    this.mostAdvancedStep = this.currentStepIndex;
                }
                this.showTutorialStep();
            }
            else {
                alert("Please complete the previous step first!");
            }
        } else {
            this.switchToEndTutorialButton();
        }
    }

    onPreviousButtonClick = async () => {
        // console.log("Previous button clicked");
    
        if (this.currentStepIndex <= 0) return;
    
        const findPreviousDemoStartStep = () => {
            // console.log("Looking for previous demo step");
            for (let i = this.currentStepIndex - 1; i >= 0; i--) {
                // console.log("Checking: ", i);
                if (this.tutorialSteps[i].demoStart) {
                    // console.warn("Demo start found!");
                    return i;
                }
            }
            // console.log("No demo step found");
            return -1; // No previous demoStart step found
        };
    
        const currentStep = this.tutorialSteps[this.currentStepIndex];
        const potentialPrevStep = this.tutorialSteps[this.currentStepIndex - 1];
        // if (potentialPrevStep.demoStart){
            const previousDemoStartIndex = findPreviousDemoStartStep();
            if (previousDemoStartIndex !== -1) {
                if(currentStep.sideEffect && !this.skipSideEffect){
                    currentStep.cleanUp && currentStep.cleanUp()
                    await this.manager.clearTutorialScene();
                }
                this.currentStepIndex = previousDemoStartIndex;
                this.showTutorialStep();
                return;
            }
        // };
    
        if(currentStep.sideEffect && !this.skipSideEffect){
            currentStep.cleanUp && currentStep.cleanUp()
            await this.manager.clearTutorialScene();
        }

        this.currentStepIndex--;
        this.showTutorialStep();
    };
    

    // onPreviousButtonClick = () => {
    //     console.log("Previous button clicked");
    //     if(this.currentStepIndex <= 0) return;
    //     const potentialPrevStep = this.tutorialSteps[this.currentStepIndex - 1];
    //     if(potentialPrevStep.demoStart){
    //         //Find the next earliest demo start step and go to it
    //         return;
    //     }
    //     this.currentStepIndex--;
    //     this.showTutorialStep();
    // }

    onSkipButtonClick = () => {
        const confirmation = confirm("Are you sure you want to skip the tutorial?");
        if (confirmation) {
            this.endTutorial();
        }
    }

    switchToEndTutorialButton = () => {
        this.visuals.nextButton.textBlock.text = "End Tutorial";
        this.visuals.nextButton.background = "red";
        this.visuals.nextButton.onPointerUpObservable.clear();
        this.visuals.nextButton.onPointerUpObservable.add(() => {
            const confirmation = confirm("Are you sure you want to end the tutorial?");
            if (confirmation) {
                this.endTutorial();
            }
        });
    }

    disableStepButtons = (disabledBy?: string) => {
    //    this.disabledBy = disabledBy
       this.disableNextButton()
       this.disablePreviousButton();
    //    if(!disabledBy) this.blockInstructions = false;
    }
    enableStepButtons = (enabledBy?: string) => {
        this.enableNextButton()
        this.enablePreviousButton();
    }
    disablePreviousButton = () => {
        this.visuals.previousButton.textBlock.text = "Previous";
        this.visuals.previousButton.background = "gray";
        this.visuals.previousButton.onPointerUpObservable.clear();
        this.visuals.previousButton.onPointerUpObservable.add(() => {
            console.log("Previous is disabled");
        });
    }
    enablePreviousButton = () => {
        this.visuals.previousButton.textBlock.text = "Previous";
        this.visuals.previousButton.background = "red";
        this.visuals.previousButton.onPointerUpObservable.clear();
        this.visuals.previousButton.onPointerUpObservable.add(this.onPreviousButtonClick);
    }
    disableNextButton = () => {
        this.visuals.nextButton.textBlock.text = "Next";
        this.visuals.nextButton.background = "gray";
        this.visuals.nextButton.onPointerUpObservable.clear();
        this.visuals.nextButton.onPointerUpObservable.add(() => {
            console.log("Next is disabled");
        });
    }
    enableNextButton = () => {
        this.visuals.nextButton.textBlock.text = "Next";
        this.visuals.nextButton.background = "green";
        this.visuals.nextButton.onPointerUpObservable.clear();
        this.visuals.nextButton.onPointerUpObservable.add(this.onNextButtonClick);
    }

    showTutorialStep = async () => {
        if (this.currentStepIndex < this.tutorialSteps.length) {
            const step = this.tutorialSteps[this.currentStepIndex];

          
            this.disableStepButtons();
            // Update content and render MathJax
            await this.visuals.updateInstructions(step.instruction);
            this.visuals.instructionElement.style.fontSize = !!step.fontSize ? step.fontSize : `${1.5 * TUTORIAL_SCALE_FACTOR}em`;

            if(step.sideEffect){
                if(this.skipSideEffect) return;
                this.blockInstructions = true;
                step.sideEffect()
                // this.blockInstructions = false;
            }
    
            // Enable or disable buttons based on the step index
            // this.visuals.previousButton.isEnabled = this.currentStepIndex > 0;
    
            if (this.currentStepIndex === this.tutorialSteps.length - 1) return this.switchToEndTutorialButton();
            if(!step.sideEffect) return this.enableStepButtons();
            if(this.skipSideEffect) return;
            // this.visuals.nextButton.textBlock.text = "Next";
            // this.visuals.nextButton.background = "green";
            // this.visuals.nextButton.onPointerUpObservable.clear();
            // this.visuals.nextButton.onPointerUpObservable.add(this.onNextButtonClick);
        }
    }
    

    endTutorial = () => {
        this.visuals.disposeElements();
        this.visuals.modifyShowButton();
        this.manager.clearTutorialScene();
        this.currentStepIndex = 0;
    }

    startTutorial = () => {
        this.visuals.showButton.dispose();
        this.init();
    }

    inProgress = () => {
        const notAtStart = this.currentStepIndex > 0;
        const notAtEnd = this.currentStepIndex < this.tutorialSteps.length;
        return notAtStart && notAtEnd;
    }

    getEnergyScrollerValue = () => {
        let value = 0;
        Object.values(this.GOE.grid.cellData).forEach((cell: GridCell) => {
            if (!cell.energyScroller) return;
            value = cell.energyScroller.energyScrollerValue;
        });
        return value;
    }

    areDirectionsDissipated = () => {
        const directions = this.GOE.causalField.directions;
        if (isObjEmpty(directions)){
            console.log("No directions exist when checking dissipation")
            return true;
        }
        for (let direction of Object.values(directions)){
            if(!direction.dissipation.dissipated) return false;
        }
        console.log("No directions were found to be active")
        return true;
    }

    doesDirectionExist = () => {
        const directions = this.GOE.causalField.directions;
        if (isObjEmpty(directions)) return false;
        return true;
    }

    doesSpatialActionExist = () => {
        const spatialActions = this.GOE.spatialActionManager.spatialActions;
        if (isObjEmpty(spatialActions)) return false;
        return true;
    }

    doesSavedActionExist = () => {
        const spatialActions = this.GOE.spatialActionViewer.savedActions;
        if (isObjEmpty(spatialActions)) return false;
        return true;
    }

    triggerShowHealthBar = (checkVisibility: boolean = true) => {
        if (this.GOE.spatialActionViewer.isVisible() && checkVisibility) {
            return false;
        }
        this.controls.toggleStress();
        return true;
    }
}

export default Tutorial;
