import convertDirectionArrayToString from "./convertDirectionArrayToString";
import convertDirectionStringToArray from "./convertDirectionStringToArray";
import getOppositeDirectionArrayFromArray from "./getOppositeDirectionArrayFromArray";

const getOppositeDirectionStringFromString = (direction: string)=>{
    const directionArray = convertDirectionStringToArray(direction);
    const oppositeDirectionArray = getOppositeDirectionArrayFromArray(directionArray);
    return convertDirectionArrayToString(oppositeDirectionArray);
}

export default getOppositeDirectionStringFromString