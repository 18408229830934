const convertDirectionStringToArray = (direction: string): string[] => {
    // Split the string into individual coordinate pairs
    const pairs = direction.split(/-(?=\()/);

    // Determine the midpoint of the pairs array
    const midPoint = pairs.length / 2;

    // Split the array into two halves
    const firstHalf = pairs.slice(0, midPoint).join('-');
    const secondHalf = pairs.slice(midPoint).join('-');

    // Return the two halves as elements of an array
    return [firstHalf, secondHalf];
}

export default convertDirectionStringToArray;