import { Color4, Vector3 } from "@babylonjs/core";
import changeBoxColor from "../helpers/changeBoxColor";
import Direction from "./Direction";
import GameControls from "./GameControls";
import { EnergyDirection, GridCellSettings } from "../types";
import GridCell from "./GridCell";
import GameOfExistence from "./GameOfExistence";
import parseCoordinateString from "../helpers/parseCoordinateString";


class ObjectControl {
    obj: Direction = null;
    gameControls: GameControls = null;
    GOE: GameOfExistence = null;
    unitDistance: number = .5;
    futureDirectionIndex: string = "";
    directionIndex: string = ""
    constructor(GOE: GameOfExistence){
        this.GOE = GOE;
        this.gameControls = GOE.controls;
        this.setUpKeyboardControls();
    }

    updateControlledObject=(newObj: Direction)=>{
        this.obj = newObj;
        this.directionIndex = newObj.directionIndex;
    }

    switchCollectiveColor=()=>{
        const collective = this.getCollective();
        if(!collective){
            console.log(`Object ${this.obj.id} had no collective`);
        }
        else{
            const {causalNodes, edges } = collective;
            const collectiveData = [
                ...causalNodes,
                ...edges
            ]
            collectiveData.forEach((obj: Direction)=>{
                changeBoxColor(obj.visuals.selector, new Color4(0,0,1,1))
            })
        }
    }

    getCollective=()=>{
        const collective = this.GOE.momentum.getConnectedObjects(this.obj);
        return collective;
    }

    getShiftedPosition = (direction:string, oldPosition: Vector3)=>{
        switch(direction) {
            case 'up':
                const upDirection = new Vector3(0,0,1)
                return oldPosition.add(upDirection.scale(this.unitDistance));
            case 'left':
                const leftDirection = new Vector3(-1,0,0)
                return oldPosition.add(leftDirection.scale(this.unitDistance));
            case 'right':
                const rightDirection = new Vector3(1,0,0)
                return oldPosition.add(rightDirection.scale(this.unitDistance));
            case 'down':
                const downDirection = new Vector3(0,0,-1)
                return oldPosition.add(downDirection.scale(this.unitDistance));
        }
    }
    getNewPositionBasedOnDirection = (direction:string)=>{
        const oldPosition = this.obj.getPosition();
        switch(direction) {
            case 'up':
                const upDirection = new Vector3(0,0,1)
                return oldPosition.add(upDirection.scale(this.unitDistance));
            case 'left':
                const leftDirection = new Vector3(-1,0,0)
                return oldPosition.add(leftDirection.scale(this.unitDistance));
            case 'right':
                const rightDirection = new Vector3(1,0,0)
                return oldPosition.add(rightDirection.scale(this.unitDistance));
            case 'down':
                const downDirection = new Vector3(0,0,-1)
                return oldPosition.add(downDirection.scale(this.unitDistance));
        }
    }

    getNewControlledObjectIndex=(directionIndex: string, direction: string)=>{
        const vectorsInIndex = parseCoordinateString(directionIndex);
        const shiftedVectorsInIndex = vectorsInIndex.map((position)=>{
            return this.getShiftedPosition(direction, position)
        })
        const newDirectionIndex: string = shiftedVectorsInIndex.reduce((a: any, b: any)=>{
            return `${a}(${b.x},${b.z})-`
        }, "").slice(0,-1);
        // console.log("New Direction Index: ", newDirectionIndex);
        // throw new Error("Just because yo")
        return newDirectionIndex
    }

    isFutureObject=(obj: Direction)=>{
        if(obj.directionIndex === this.futureDirectionIndex) return true;
        return false;
    }
    
    move=(direction: "up" | "left" | "right" | "down")=>{
        if(!this.obj) return;
        if(this.futureDirectionIndex && !this.isFutureObject(this.obj)){
            const controlledObj = this.GOE.causalField.getDirectionById(this.futureDirectionIndex);
            this.updateControlledObject(controlledObj);
        }
        const newPosition = this.getNewPositionBasedOnDirection(direction);
        const gridCellSettings: GridCellSettings = {
            index: `(${newPosition.x},${newPosition.z})`,
            position: newPosition,
            initEnergyLevel: 0,
        };
        const isExistingGridCell = this.GOE.grid.cellData[gridCellSettings.index]
        const gridCell = isExistingGridCell ? isExistingGridCell : new GridCell({
            GOE: this.GOE,
            ...gridCellSettings,
            visibility: true,
        });
        if(!isExistingGridCell) this.GOE.grid.cellData[gridCell.index] = gridCell;
        
        const moverDirectionArray = [this.obj.id, gridCell.index];
        const mover = this.GOE.causalField.getOrCreateDirection(moverDirectionArray);
        if(!mover) throw new Error("Failed to create mover while moving direction via keyboard");
        const collective = this.getCollective();
        if(!collective) return console.warn("Failed to get collective while moving direction via keyboard");

        const massEnergy = this.GOE.momentum.calculateMassEnergyFromObject(collective);
        const distance = mover.distanceBetweenSourceAndTarget;
        const energyRequiredToMoveOneUnit = this.GOE.momentum.calculateEnergyRequiredToMoveMass(massEnergy, distance);
        
        this.futureDirectionIndex = this.getNewControlledObjectIndex(this.obj.directionIndex, direction);
        return this.GOE.momentum.moveConnectedObjects(collective, mover, energyRequiredToMoveOneUnit);

    }

    setUpKeyboardControls = () => {
        // let keyboardInput = new FreeCameraKeyboardMoveInput();
        // this.gameControls.keyboardInput = keyboardInput;

        document.addEventListener('keydown', (event) => {
            switch(event.key) {
                case 'i':
                    this.move("up")
                    break;
                case 'j':
                    this.move("left")
                    break;
                case 'l':
                    this.move("right");
                    break;
                case 'k':
                    this.move("down");
                    break;
            }
        });
    }
}

export default ObjectControl

