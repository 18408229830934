import { Vector3 } from "@babylonjs/core";
import GridCell from "./GridCell";
import GameOfExistence from "./GameOfExistence";
import { GridSettings } from "../types";

class Grid {
    rows: number;
    columns: number;
    cellSize: number;
    width: number;
    height: number;
    cellData: {[key: string]: GridCell} = {};
    initCellData: {[key: string]: any} = {};

    
    totalEnergy: number;

    GOE: GameOfExistence;

    constructor(GOE: GameOfExistence, settings: GridSettings){
        const {rows, columns, cellSize} = settings;
        this.rows = rows;
        this.columns = columns;
        this.cellSize = cellSize;
        this.width = columns * cellSize,
        this.height = rows * cellSize,
        this.GOE = GOE;
    }

    createGridCells=()=>{

        Object.values(this.initCellData).forEach((data: any)=>{
            const gridCell = new GridCell(data);
            this.cellData[data.index] = gridCell;
        })

        // Object.values(this.cellData).forEach((gridCell: GridCell)=>{
        //     gridCell.addSelfToSubSpace();
        //     gridCell.setNeighbors();
        //     gridCell.addSelfToNeighborhood();
        // })
    }

    toggleVisibilityUpdates=(val: boolean)=>{
        Object.values(this.cellData).forEach((cell: GridCell)=>{
            cell.shouldUpdateVisibility = val;
        });
    }

    resetCells=()=>{
        Object.values(this.cellData).forEach((cell: GridCell)=>{
            cell.shouldUpdateVisibility = true;
            cell.setVisibility(cell.isIn3x3Grid());
            cell.particleAddresses = [];
            cell.particleQueue = [];
        });
    }

    getMaxDimensions = ()=>{
        const { width, height } = this;
        return  {
            x: {
                min: -width,
                max: width,
            },
            y: {
                min: -3,
                max: 3,
            },
            z:{
                min: -height,
                max: height,
            }
        }
    }
    createGridData = () => {
        let data: { [key: string]: any } = {};
    
        const { width, height, cellSize } = this;
    
        const halfGridSizeX = width / 2;
        const halfGridSizeZ = height / 2;
    
        const isWidthEven = width % 2 === 0;
        const isHeightEven = height % 2 === 0;
    
        const startX = isWidthEven ? -halfGridSizeX : -Math.floor(halfGridSizeX);
        const startZ = isHeightEven ? -halfGridSizeZ : -Math.floor(halfGridSizeZ);
    
        let counter = 0;
    
        for (let z = startZ; z < halfGridSizeZ; z += cellSize) {
            for (let x = startX; x < halfGridSizeX; x += cellSize) {
                const cellPosition = new Vector3(x, 0, z);
    
                // const initEnergyLevel = 50;
                const initEnergyLevel = 400;
                const index = `(${cellPosition.x},${cellPosition.z})`;
                const gridCellData = {
                    GOE: this.GOE,
                    index,
                    position: cellPosition,
                    initEnergyLevel: initEnergyLevel
                }
                // const gridCell = new GridCell({
                //     GOE: this.GOE,
                //     index,
                //     position: cellPosition,
                //     initEnergyLevel: initEnergyLevel
                // });
    
                data[index] = gridCellData;
                counter += initEnergyLevel;
            }
        }
    
        this.totalEnergy = counter;
        this.initCellData = data;
    }
    // createGridData = ()=>{
    //     let data: { [key: string]: any } = {};

    //     // console.log("Creating grid data");
    //     const {width, height, cellSize} = this;
    //     // console.log("Width: ", width);
    //     // console.log("Height: ", height);
    //     // console.log("CellSize: ", cellSize);
        
    //     const halfGridSizeX = width / 2;
    //     const halfGridSizeZ = height / 2;
    //     let counter = 0;
    //     for(let z = -halfGridSizeZ; z < halfGridSizeZ; z+=cellSize) { //Row
    //         for(let x = -halfGridSizeX; x < halfGridSizeX; x+=cellSize) { //Column
    //             const cellPosition = new Vector3(
    //               x,
    //               0,
    //               z,
    //             );

    //             // const initEnergyLevel = 100 + 50*Math.round(Math.random());
    //             // const initEnergyLevel = counter*1 + 100*Math.round(Math.random());
    //             // const initEnergyLevel = 500*Math.round(Math.random());
    //             // const initEnergyLevel = 50 + Math.round(Math.random());
    //             // const initEnergyLevel = returnTrueWithProbability(50) ? 100 : 1;
    //             const initEnergyLevel = 200;
    //             // const initEnergyLevel = 150;
    //             const index = `(${cellPosition.x},${cellPosition.z})`;
    //             const gridCell = new GridCell({
    //                 GOE: this.GOE,
    //                 index,
    //                 position: cellPosition,
    //                 initEnergyLevel: initEnergyLevel
    //             });
    //             // console.log(`Cell: ${index} - Position: (${cellPosition.x},${cellPosition.y},${cellPosition.z}) `);
    //             data[index] = gridCell;
    //             counter+=initEnergyLevel;
    //         }
    //     }
    //     this.totalEnergy = counter;
    //     this.cellData = data;
    // }
    
    renderGridCells = ()=>{
        const { cellData } = this;
        for (let cell of Object.values(cellData)) cell.renderGridCell();
    }
}

export default Grid;