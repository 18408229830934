import { Color3, MeshBuilder, Scalar, SolidParticle, SolidParticleSystem, Vector3 } from "@babylonjs/core";
import GameOfExistence from "./GameOfExistence";
import Grid from "./Grid";
import { CorrelateEnergyTransfer, EnergyTransferAnimation, ExistingObject, PossibleObject, Task } from "../types";
import SpatialAction from "./SpatialAction";
import Direction from "./Direction";
import GridCell from "./GridCell";
import { DIRECTION_HOVER_HEIGHT, SPEED_OF_LIGHT, momentumTypes } from "../constants";
import lerp from "../helpers/lerp";
import getConsecutiveRanges from "../helpers/getConsecutiveRanges";
import getMidpoint from "../helpers/getMidpoint";



class ParticleVisuals {
    GOE: GameOfExistence;
    SPS: SolidParticleSystem;
    grid: Grid;
    currentlyAnimatedParticles = new Map();
    activeParticleAnimations: any = new Map();
    particleAnimationTimestamps = new Map();
    recentAnimations = new Map();
    particleLocks = new Map();


    constructor(GOE: GameOfExistence){
        this.GOE = GOE;
        this.grid = GOE.grid;

    }

    //TODO: Probably use this function, kek
    setMostRecentAnimationForAllParticles = (particleAddresses: number[], animation)=>{
        particleAddresses.forEach((address)=>{
            this.setMostRecentAnimation(address, animation)
        })
    }
    //TODO: Probably use this function lol.
    setMostRecentAnimation(particleId, animation) {
        const { timestamp, priority } = animation;
    
        const currentAnimation = this.recentAnimations.get(particleId);
        const currentTimestamp = this.particleAnimationTimestamps.get(particleId);
        const currentPriority = currentAnimation ? currentAnimation.priority : 0;
        if(currentAnimation && (typeof currentAnimation.priority === 'undefined')) throw new Error("Animation priority undefined!");
    
        if (!currentAnimation || timestamp > currentTimestamp || priority > currentPriority) {
            this.recentAnimations.set(particleId, animation);
            this.particleAnimationTimestamps.set(particleId, timestamp);
        }
    }
    animateParticles = () => {
        let animationsToEnd = [];
        let lastIndex = this.activeParticleAnimations.size - 1 || 0;
        let counter = 0;
    
        // Consolidated logic for active particles animation
        this.activeParticleAnimations.forEach((animation) => {
            const { particleAddresses, index, timestamp, ranges, type } = animation;
    
            particleAddresses.forEach((particleId) => {
                const lastAnimationTimestamp = this.particleAnimationTimestamps.get(particleId) || 0;
    
                // Only store the animation if it's more recent than the last one and if the particle is not locked by another animation
                if (timestamp > lastAnimationTimestamp && !this.particleLocks.has(particleId)) {
                    this.recentAnimations.set(particleId, animation);
                    this.particleAnimationTimestamps.set(particleId, timestamp);
                    this.particleLocks.set(particleId, timestamp); // Lock the particle with the current animation timestamp
                }
            });
    
            // Trigger particle updates for all ranges in all animations
            ranges.forEach(range => {
                this.SPS.setParticles(range.start, range.end, counter === lastIndex);
            });
    
            // If no more particles are left in the animation, mark the animation to be ended
            if (particleAddresses.length === 0) animationsToEnd.push(index);
            counter += 1;
        });
    
        // Now update the particles
        this.SPS.updateParticle = (particle: SolidParticle) => {
            const animation = this.recentAnimations.get(particle.id);
            if (animation) {
                return animation.updateParticle(particle);
            }
            return particle;
        };
    
        // End marked animations
        animationsToEnd.forEach(index => {
            this.endEnergyTransferAnimation(index);
        });
    
        // Prune old entries from the map to save memory
        this.particleAnimationTimestamps.forEach((timestamp, particleIdx) => {
            // If the particle has not been animated in more than 10 seconds (or whatever duration you prefer), remove it from the map and unlock the particle
            // if (Date.now() - timestamp > 10000) {
            if (this.GOE.gameTime - timestamp > 1) {
                this.particleAnimationTimestamps.delete(particleIdx);
                this.particleLocks.delete(particleIdx); // Unlock the particle
            }
        });
    };
    
    
    // animateParticles=()=>{
    //     let animationsToEnd = [];

    //     let lastIndex = this.activeParticleAnimations.size-1 || 0;
    //     let counter = 0;

    //     // Consolidated logic for active particles animation
    //     this.activeParticleAnimations.forEach((animation) => {
    //         const {particleAddresses, index, timestamp, ranges, type} = animation;
    
    //         // // Pre-compute recent animations for each particle
    //         // this.setMostRecentAnimationForAllParticles(particleAddresses, animation);
    //         particleAddresses.forEach((particleId) => {
    //             const lastAnimationTimestamp = this.particleAnimationTimestamps.get(particleId) || 0;
    
    //             // Only store the animation if it's more recent than the last one
    //             if (timestamp > lastAnimationTimestamp) {
    //                 this.recentAnimations.set(particleId, animation);
    //                 this.particleAnimationTimestamps.set(particleId, timestamp);
    //             }
    //         });
    //         // Trigger particle updates for all ranges in all animations
    //         ranges.forEach(range => {
    //             this.SPS.setParticles(range.start, range.end, counter === lastIndex);
    //         });
    
    //         // If no more particles are left in the animation, mark the animation to be ended
    //         if(particleAddresses.length === 0) animationsToEnd.push(index);
    //         counter+=1;
    //     });

    //     // Now update the particles
    //     this.SPS.updateParticle = (particle: SolidParticle) => {
    //         const animation = this.recentAnimations.get(particle.id);
    //         if (animation) {
    //             return animation.updateParticle(particle);
    //         }
    //         return particle;
    //     };
    
    //     // End marked animations
    //     animationsToEnd.forEach(index => {
    //         this.endEnergyTransferAnimation(index);
    //     });


    //     // Prune old entries from the map to save memory
    //     this.particleAnimationTimestamps.forEach((timestamp, particleIdx) => {
    //         // If the particle has not been animated in more than 10 seconds (or whatever duration you prefer), remove it from the map
    //         if (this.GOE.gameTime - timestamp > 1) {
    //             this.particleAnimationTimestamps.delete(particleIdx);
    //         }
    //     });
    // }
    createParticleSystem = ()=>{
        const SPS = new SolidParticleSystem("SPS", this.GOE.scene, {isPickable: false}); // scene is required
        this.SPS = SPS;
        // const sphere = MeshBuilder.CreateSphere("s", {diameter: .025});
        const tetra = MeshBuilder.CreatePolyhedron("tetra", {type: 0, size: 0.0075}, this.GOE.scene);
        // console.log("Number of particles: ", this.totalEnergy);
        SPS.addShape(tetra, this.grid.totalEnergy);
        tetra.dispose(); //free memory

        const mesh = SPS.buildMesh(); 

        const range = 1;

        
        // const mat = new StandardMaterial("mat");
        // mat.diffuseTexture = new Texture('/particleTexture.jpg', this.scene);
        // mat.transparencyMode=0
        
        // mesh.material = mat;
    }
    public checkParticleLocations = (particleIds: number[])=>{
        const particles = particleIds.map((id)=>this.SPS.particles[id]);
        particles.forEach((particle: SolidParticle)=>{
            console.log(`Particle ${particle.id} in position: `, particle.position);
        });
        return;
    }
    calculateParticlePositions = ()=>{
        const { cellData } = this.grid;
        const radius = .1; // Radius of sphere around the point

        const maxParticles = this.calculateMaxParticles(this.grid.totalEnergy);
        const length = maxParticles * 3;
        // const data = new Float32Array(length);
        //TODO: Is there a better storage method?
        // let dataPerCell:{[key: string]: number[]} = {};
        //For each grid cell create Float32Array for Vertex data using energy level
        //We'll also match which vertexes/particles belong to which cell.
        let particleId = 0;
        this.SPS.initParticles = () => {
            for (const [cellIndex, gridCell] of Object.entries(cellData)){
                const {initEnergyLevel, isVisible} = gridCell;
                const gridCellPosition = gridCell.getPosition();
                const {x,y,z} = gridCellPosition
                const numberOfParticles = this.calculateMaxParticles(initEnergyLevel);
                //Create vertex for every particle
                for (let i = 0; i < numberOfParticles; i += 1) {
                    const particle = this.SPS.particles[particleId];
                    this.grid.cellData[cellIndex].addInitParticleAddress(particleId);

                    const particlePosition = this.getRandomPointAroundPosition(new Vector3(x,y+DIRECTION_HOVER_HEIGHT,z), radius)
                
                    // Convert spherical to Cartesian coordinates
                    particle.position.x = particlePosition.x;
                    particle.position.y = particlePosition.y;
                    particle.position.z = particlePosition.z;
                    particle.isVisible = isVisible;
                    const angle = Scalar.RandomRange(0, Math.PI);
                    const range = Scalar.RandomRange(0, .1);
                    particle.props = {angle : angle, range: range};
                    //@ts-ignore
                    particle.color = new Color3(1.0,1.0,1.0);
                    particleId+=1;
                }
                    
            }
        };
    }

    renderParticles=()=>{
        this.SPS.initParticles();
        this.SPS.setParticles();
        this.SPS.refreshVisibleSize();
        // this.GOE.scene.onPointerDown = ((self)=>{
        //     return function(evt, pickResult) {
        //         var meshFaceId = pickResult.faceId; // get the mesh picked face
        //         if (meshFaceId == -1) {
        //           return;
        //         } // return if nothing picked
        //         var picked = self.SPS.pickedParticle(pickResult); // get the picked particle data : idx and faceId
        //         var idx = picked.idx;                         
        //         var p = self.SPS.particles[idx];                   // get the actual picked particle
        //         console.log(`Particle ${idx}`);
        //         const gridCells = Object.values(self.GOE.grid.cellData);
        //         const directions = Object.values(self.GOE.causalField.directions);
        //         const objects = [...gridCells, ...directions];
        //         for(let obj of objects){
        //             const inQueue = obj.particleQueue.includes(idx);
        //             const inAddresses = obj.particleAddresses.includes(idx);
        //             if(inQueue){
        //                 console.log(`Object ${obj.id} has particle in queue`)
        //                 console.log(`Object: `, obj)
        //                 return;
        //             }
        //             if(inAddresses){
        //                 console.log(`Object ${obj.id} has particle in address book`)
        //                 console.log(`Object: `, obj)
        //                 return;
        //             }
        //         }
        //         console.log(`No owner found for particle ${idx}`)
        //         // p.color.r = 1; // turn it red
        //         // p.color.b = 0;
        //         // p.color.g = 0;
        //         // p.velocity.y = -1; // drop it
        //         // this.SPS.setParticles();
        //       };
        // })(this)
        
        
        
        
        
    }
    getRandomPointAroundPosition = (position: Vector3, radius: number)=>{
        // Generate random spherical coordinates
        const phi = 2 * Math.PI * Math.random(); // Azimuthal angle
        const theta = Math.acos(2 * Math.random() - 1); // Polar angle
        const r = radius * Math.cbrt(Math.random()); // Radius, cube root to ensure uniform distribution
    
        // Convert spherical to Cartesian coordinates
        return new Vector3(
            r * Math.sin(theta) * Math.cos(phi) + position.x,
            r * Math.sin(theta) * Math.sin(phi) + position.y,
            r * Math.cos(theta) + position.z
        )
    }
 
    calculateMaxParticles = (energy: number)=>{
        //If no render constant then:
        //level = 2, doesn't show 2 particles, as with 3
        //Only, when energy level is 4, do we see 2 particles.
        //Thus, thus allows for accurate rendering of number of particles
        // const renderConstant = 4;
        // return energy*renderConstant;
        return energy;
    }
    isCorrelationImpossible=(settings: CorrelateEnergyTransfer)=>{
        let { sources, target, directionOfTargetEnergy, amount, force } = settings;
        if(!sources[0] || !sources[1]){
            // console.log("One of the sources is undefined!")
            return true;
        }
        if(target instanceof Direction && target.dissipation.isDissipating){
            console.log("Sources: ", sources);
            console.log(`Can't transfer energy because target cell ${target.id} is dissipating`);
            //@ts-ignore
            console.log("Time stamp: ", target.timestamp);
            return true;
        }
        if(sources[0].getEnergyLevel() === 0 || sources[1].getEnergyLevel() === 0){
            // console.log("Source directions don't have energy for this task: ", sources);
            return true;
        }
 
    }

    calculateFutureDirectionPosition = (source: PossibleObject, target: PossibleObject)=>{
        const anchorPosition = getMidpoint(source.getPosition(), target.getPosition());
        const position = getMidpoint(anchorPosition, target.getPosition());
        return position;
    }

    isTransformationImpossible=(source: PossibleObject, target: PossibleObject, directionOfSourceEnergy)=>{

        //Exchange is not possible!
        if (!source || !target){
            console.log("Source or target is invalid!");
            console.log("Source: ", source);
            console.log("Target: ", target);
            return true;
        }
        // console.log("Source on transfer: ", source);
        // console.log("Target on transfer: ", target);
        
        if(source.getEnergyLevel() < 0){
            console.log("Source cell doesn't have particles for this task: ", source);
            throw new Error(`There are no particles to transfer but energy level is ${source.getEnergyLevel()}`);
        }
        return false;
    }
    calculateEnergyToTransfer=(energyInSource, amount)=>{
        //Making sure not to move energy than is available
        // if(energyInSource < amount) return energyInSource;
        return energyInSource > amount ? amount : energyInSource;

    }

    removeAndGetParticleAddresses=(modifiedSettings, amount): number[]=>{
        const { source, type } = modifiedSettings;
        if(type === "repulsion" && source instanceof Direction){
            return source.loseParticleAddressesFromQueue({...modifiedSettings, amount})
        }
        return source.loseParticleAddresses({...modifiedSettings, amount})

    }

    createParticleUpdateFunction=(settings: any)=>{
        const { GOE } = this;
        let {
            modifiedSettings,
            startTime,
            particleAddresses,
            source,
            target,
            animationIndex,
            directionOfTargetEnergy,
            force,
            type
        } = settings;
        const {x,y: oldY,z} = target.position;
        //Set for .has function & get position
        const y = target instanceof GridCell ? oldY + DIRECTION_HOVER_HEIGHT : oldY;
        // if(type === 'energy transfer') console.log("Speed Of Light: ", SPEED_OF_LIGHT);
        // if(type === 'energy transfer') console.log("Game time at particle leave: ", this.GOE.gameTime);
        // if(type === 'energy transfer') console.log("Particles: ", particleAddresses);
        let particleStartingPositions = {};
        particleAddresses.forEach((id: number)=>{
            const particlePosition = this.SPS.getParticleById(id).position;
            const {x,y,z} = particlePosition;
            particleStartingPositions[id] = new Vector3(x,y,z);
        });
        const { width, height } = this.GOE.grid;
        const maxDimensions = {
            x: {
                min: -width,
                max: width,
            },
            y: {
                min: -3,
                max: 3,
            },
            z:{
                min: -height,
                max: height,
            }
        }
        return (particle: SolidParticle): SolidParticle => {
            const pointToApproach = this.getRandomPointAroundPosition(new Vector3(x,y,z), .1);
            if(particleAddresses.includes(particle.id)){
                // if(type === "energy transfer") console.log(`Particle ${particle.id} of animation ${animationIndex} en route`);
    
                const deltaTime = (GOE.gameTime - startTime);
                // Calculate the final position
                // const pointToApproach = new Vector3(x,y,z);
                const finalX = pointToApproach.x;
                const finalZ = pointToApproach.z;
                
                // const noisySourcePosition = this.getRandomPointAroundPosition(new Vector3(source.x,source.y,source.z), .05);
                // At the beginning of your animation:
                // const startX = particle.position.x;
                // const startZ = particle.position.z;
                const startX = particleStartingPositions[particle.id].x;
                const startZ = particleStartingPositions[particle.id].z;
                // console.log("Start X: ", startX)
                // console.log("Start Z: ", startZ)
                // const startX = source.position.x;
                // const startZ = source.position.z;
                // const startX = noisySourcePosition.x;
                // const startZ = noisySourcePosition.z;
    
                // And then in your updateParticle function
                // const t = deltaTime * SPEED_OF_LIGHT; // Normalized progress value (between 0 and 1)
                const t = deltaTime * SPEED_OF_LIGHT;
                
                // Calculate the new position using lerp
                particle.position.x = lerp(startX, finalX, t);
                particle.position.z = lerp(startZ, finalZ, t);
                //TODO: Would be cool to get particle to wiggle during transit.
                // particle.props.angle += Math.PI / 100;
                // particle.position.y = particle.props.range * (1 + Math.cos(particle.props.angle)) + DIRECTION_HOVER_HEIGHT;
                const distanceToTarget = Math.sqrt(
                    Math.pow(particle.position.x - finalX, 2) +
                    Math.pow(particle.position.z - finalZ, 2)
                );
                // if(deltaTime > .01 && type === "energy transfer") console.log("Distance to target: ", distanceToTarget);
                // if(deltaTime > .01 && type === "energy transfer") console.log("Time since leaving: ", this.GOE.gameTime);
                // if(type === "energy transfer") console.log("Delta Time: ", deltaTime);
                // if(type === "energy transfer") console.log("Distance to target: ", distanceToTarget);
                const particlePosition = [particle.position.x, particle.position.y, particle.position.z]
                const isPointInBox = this.GOE.spatialPartioner.checkIfPointIsInBox(particlePosition, maxDimensions)
                if(!isPointInBox){
                    // console.log(`Particle ${particle.id} left playing field`);
                    // console.log(`Particle Position: `, particlePosition);
                    // console.log(`Max dimensions: `, maxDimensions);
                    // console.log("Source: ", source);
                    // console.log("Target: ", target);
                    // throw new Error("Particle left playing field!");
                    console.warn(`Particle ${particle.id} left playing field!`);
                    console.warn(`Placing particle ${particle.id} where it needs to be`);
                    this.onParticleArrive({
                        animationIndex,
                        particle,
                        target,
                        directionOfTargetEnergy,
                        force,
                        type,
                        modifiedSettings,
                        finalX,
                        finalZ,
                    })
                }
                // checkIfPointIsInBox(point: number[], dimensionalMaxAndMin: any) {
                //     //xmin<=x<=xmax && ymin<=y<=ymax && zmin<=z<=zmax
                //     const { x, y, z } = dimensionalMaxAndMin;
                //     // console.log("Point: ", point);
                //     // console.log("Min Range: ", [x.min,y.min,z.min])
                //     // console.log("Max Range: ", [x.max,y.max,z.max])
                //     let isInXRange = isNumberInInterval(x.min, x.max, point[0]);
                //     // let isInYRange = isNumberInInterval(y.min, y.max, point[1]);
                //     let isInZRange = isNumberInInterval(z.min, z.max, point[2]);
                //     return isInXRange && isInZRange;
                // }
                if(target.isDissipating){
                    // console.log("Target: ", target);
                    console.warn(`Target ${target.id} receiving particle ${particle.id} is dissipating! `);
                    console.log("Passing to source: ", source.id)
                    target = source;
                }
                if(!target){
                    throw new Error(`Target didn't exist for ${particle.id} from ${source.id}`)
                }
                // if (distanceToTarget < 0.05) {
                if (distanceToTarget < 0.1) {
                    this.onParticleArrive({
                        animationIndex,
                        particle,
                        target,
                        directionOfTargetEnergy,
                        force,
                        type,
                        modifiedSettings,
                        finalX,
                        finalZ,
                    })
                }
            }
            return particle;      
        }
    }

    onParticleArrive=(settings: any)=>{
        const {
            animationIndex,
            particle,
            target,
            directionOfTargetEnergy,
            force,
            type,
            modifiedSettings,
            finalX,
            finalZ,
        } = settings;
        // if(type === "energy transfer") console.log(`Particle ${particle.id} of animation ${animationIndex} ARRIVED`);
        // if(type === "energy transfer") console.log(`Particle ${particle.id} of arrive at ${this.GOE.gameTime}`);
        // Only giving energy/particle ownership later on
        // console.log("Adding particle to: ", target);
        
        this.removeParticleFromAnimation(particle, animationIndex)
        const isLastParticle = this.isLastParticleInAnimation(animationIndex);
        target.addParticleAddressesToQueue([particle.id])
        if(isLastParticle) target.reactToEnergyGain(modifiedSettings);
        // if(target instanceof GridCell) target.updateVisibility();
        if(target instanceof GridCell){
            particle.isVisible = target.isVisible;
        }

        //Amount will be 1 unless bulk energy send by user, or by physics.
        // if(isLastParticle) target.gainEnergy(amount, directionOfTargetEnergy, force);
        // Ensure the particle is exactly at the destination
        particle.position.x = finalX;
        particle.position.z = finalZ;
    }


    isLastParticleInAnimation = (animationIndex: string)=>{

        let { particleAddresses } = this.activeParticleAnimations.get(animationIndex);
        // console.log("Particle address length: ", particleAddresses.length)
        return particleAddresses.length === 1;
        // return !particleAddresses.length
        // if(particleAddresses.length === 1) return true;
        // else return false;
    }

    removeParticleFromAnimation = (particle: SolidParticle, animationIndex: string)=>{
        let index = this.activeParticleAnimations.get(animationIndex).particleAddresses.indexOf(particle.id);
        if (index !== -1) {
          this.activeParticleAnimations.get(animationIndex).particleAddresses.splice(index, 1);
        }
    }
    endEnergyTransferAnimation = (id)=>{
        // console.log(`No more particles left in the animation ${this.activeParticleAnimations.get(id)}`);
        // console.log("Ending energy transfer animation: ",id);
        this.activeParticleAnimations.delete(id);
    }

    startHoverAnimation=(obj: PossibleObject)=>{
        const { GOE } = this;
        const gridCell = GOE.getCorrespondingObject(obj);
        if( !(gridCell instanceof GridCell) || !gridCell.isVisible) return;

        const { particleAddresses } = gridCell;  
        return;
        // Store the update function inside the animation object
        const animation = {
            type: "hover",
            timestamp: GOE.gameTime,
            priority: 1,
            index: `${gridCell.id}-${GOE.gameTime}-${Math.random()}`,
            updateParticle: (particle: SolidParticle): void => {
                particle.props.angle += Math.PI / 25;
                particle.position.y = particle.props.range * (1 + Math.cos(particle.props.angle)) + DIRECTION_HOVER_HEIGHT;
            },
            particleAddresses,
            ranges: getConsecutiveRanges(particleAddresses),
        };
        // this.setMostRecentAnimationForAllParticles(particleAddresses, animation);
        // Add the new animation to the active animations
        this.activeParticleAnimations.set(gridCell.id, animation);
        gridCell.switchColor("hover");
    };

    endHoverAnimation = (obj: PossibleObject)=>{
        // console.log("Hover animation ended: ",this.activeParticleAnimations.get(cellIndex));
        const gridCell = this.GOE.getCorrespondingObject(obj);
        if(!gridCell) return;
        this.activeParticleAnimations.delete(obj.id);
        gridCell.switchColor("default");
    }
 
    isParticleOrphan = (address: number)=>{
        const { GOE } = this;
        const objects = [ ...Object.values(GOE.grid.cellData), ...Object.values(GOE.causalField.directions)]
        for (let obj of objects){
            if(obj.particleAddresses.includes(address)) return false;
        }
        return true;
    }
}

export default ParticleVisuals;