import { MINIMUM_DISTANCE, NOVELTY } from "../../constants";
import calculateDirectionPosition from "../../helpers/calculateDirectionPosition";
import calculateDistanceBetweenPoints from "../../helpers/calculateDistanceBetweenPoints";
import isPointNeighborOfCell from "../../helpers/isPointNeighborOfCell";
import isSourceAndTargetIdentical from "../../helpers/isSourceAndTargetIdentical";
import { EnergyDirection, ExistingObject, PotentialObjectSettings } from "../../types";
import Direction from ".";
import SpatialAction from "../SpatialAction";
import GridCell from "../GridCell";


class DirectionDissipation {
    parent: Direction = null;
    public isDissipating: boolean = false;
    public isDissipatingQueue: boolean = false;
    public queueDissipated: boolean = false;
    public dissipated: boolean = false;

    constructor(parent: Direction){
        this.parent = parent;
    }
 

    onManualDissipation=()=>{
        this.setIsDissipating(false);
        this.setDissipated(true);
    }

    isOppositeDissipating = ()=>{
        const oppObj = this.parent.getOppositeDirectionObject();
        if(!oppObj) return false;
        return oppObj.dissipation.isDissipating;
    }

    dissipate=(why?: string)=>{
        const neighbors = this.parent.neighbors;
        if(!neighbors.length) throw new Error(`Direction ${this.parent.id} had no neighbors!`);
        // if(why) console.log(`Direction ${this.parent.id} is dissipating ${why}`)
        // throw new Error(`Direction ${this.parent.id} is dissipating ${why}`)
        // console.log(`Neighbors on dissipation for direction ${this.parent.id}: `, neighbors);
        if(!this.isDissipating && !this.dissipated) this.setIsDissipating(true);
        this.parent.removeSelfFromNeighborhood();
        this.parent.decorrelateTarget();
        this.parent.removeAllCorrelations();
        if(this.parent.id === '(-1,0)-(-1,1)-(0,0)-(0,1)') console.log(`Direction ${this.parent.id} is dissipating`);
        if(this.parent.id === '(0,0)-(0,1)-(-1,0)-(-1,1)') console.log(`Opp Direction ${this.parent.id} is dissipating`);
        this.setOppositeObjToDissipate();
        this.parent.visuals.updateVisuals();
        this.parent.visuals.updateOppositeVisuals();

        const isHeads = Math.random() > NOVELTY;
        // if(isHeads) console.log("Attempting to dissipate to existing object")
        // if(!isHeads) console.log("Attempting to dissipate to new direction")
        const result = isHeads ? this.dissipateToExistingObject(neighbors) : this.dissipateToNewDirection(neighbors);
        // if(!result) return console.log(`Direction ${this.parent.id} failed to get direction to dissipate to`)
        if(!result) return;
        // if(!result) return this.parent.lastTimeEnergyMoved = this.parent.GOE.gameTime;
        // console.log("Result: ", result);
        // if(isHeads) console.log("Dissipating to existing direction")
        // if(!isHeads) console.log("Dissipating to new direction")
        const {
            animationSource,
            animationTarget,
            energyToDissipate,
        } = result;
        // console.log("Dissipated to: ", animationTarget);
        // console.log("With direction: ", directionOfTargetEnergy);
        this.parent.GOE.mechanics.attemptTask({
            caller: `${this.parent.id}`,
            source: animationSource.getPotentialObjectSettings(),
            target: animationTarget,
            momentumSettings: null,
            energyToTransfer: energyToDissipate,
            type: "dissipation",
        });
        // this.parent.GOE.particleVisuals.startEnergyTransferAnimation({
        //     caller: `${this.parent.id}`,
        //     source: animationSource,
        //     target: animationTarget,
        //     directionOfSourceEnergy: this.parent.getDirectionArray(),
        //     directionOfTargetEnergy: directionOfTargetEnergy,
        //     amount: energyToDissipate,
            
        //     // force: {
        //     //     magnitude: this.parent.calculateForce(),
        //     //     mover: this,
        //     // },
        //     type: "dissipation"
        // });

        if(this.parent.isEnergyLevelZero()){
            if(this.parent.id === '(-1,0)-(-1,1)-(0,0)-(0,1)') console.log(`Direction ${this.parent.id} has dissipated`);
            // console.log("Particle Addresses: ", this.parent.particleAddresses);
            // console.log("Particle Queue: ", this.parent.particleQueue);
            this.setIsDissipating(false);
            this.setDissipated(true);
            this.parent.visuals.updateVisuals();
            this.parent.visuals.updateOppositeVisuals();
            // this.parent.cleanUpOppositeObjectVisuals();
            // const result = this.parent.disposeDissipationSignal();
            // this.parent.cleanUpVisuals();
            // console.log(`DISPOSAL of DISSIPATION SIGNAL has ${result ? "SUCCEEDED" : "FAILED"}`)
        }

        this.parent.lastTimeEnergyMoved = this.parent.GOE.gameTime;
    }

    setIsDissipatingQueue = (status: boolean)=>{
        this.isDissipatingQueue = status;
    }

    dissipateEnergyInQueue = ()=>{
        if(!this.parent.areForcesBalanced()) return;
        const neighbors = this.parent.getCurrentNeighbors()
        if(!neighbors.length) throw new Error(`Direction ${this.parent.id} had no neighbors!`);

        if(!this.isDissipatingQueue && !this.queueDissipated) this.setIsDissipatingQueue(true);
        //Dissipate it ALL at faster than light speed automatically
        // console.log(`Direction ${this.parent.id} repelling energy`);
        // console.log("Neighbors: ", neighbors)

        // while(this.parent.particleQueue.length){

        // const isHeads = Math.random() > NOVELTY;
        // if(isHeads) console.log("Attempting to dissipate to existing object")
        // if(!isHeads) console.log("Attempting to dissipate to new direction")
        // const result = isHeads ? this.parent.dissipateToExistingObject(neighbors) : this.parent.dissipateToNewDirection(neighbors);
        // if(!result) continue;
        const result = this.dissipateToExistingObject(neighbors);
        if(!result) return;
        // if(!result) return this.parent.lastTimeEnergyMoved = this.parent.GOE.gameTime;
        // console.log("Result: ", result);
        // if(isHeads) console.log("Dissipating to existing direction")
        // if(!isHeads) console.log("Dissipating to new direction")
        const {
            animationSource,
            animationTarget,
        } = result;
        // console.log("With direction: ", directionOfTargetEnergy);
        
   
        const energyToDissipate = Math.round(Math.random()*this.parent.particleQueue.length)+1;

        this.parent.GOE.mechanics.attemptTask({
            caller: `${this.parent.id}`,
            source: animationSource.getPotentialObjectSettings(),
            target: animationTarget,
            momentumSettings: null,
            energyToTransfer: energyToDissipate,
            type: "repulsion"
        });

        // console.log("Repelling energy to: ", animationTarget);
        // this.parent.GOE.particleVisuals.startEnergyTransferAnimation({
        //     caller: `${this.parent.id}`,
        //     source: animationSource,
        //     target: animationTarget,
        //     directionOfSourceEnergy: this.parent.getDirectionArray(),
        //     directionOfTargetEnergy: directionOfTargetEnergy,
        //     //Consider momentum!
        //     amount: energyToDissipate,
        //     type: "repulsion"
        // });
         
        // console.log(`Direction ${this.parent.id} repelled energy to target`)
        if(this.parent.particleQueue.length === 0){
            // console.log(`Direction ${this.parent.id} has dissipated it's queue`);
            this.isDissipatingQueue = false;
            this.queueDissipated = true;
            // this.parent.visuals.updateVisuals();
            // this.parent.visuals.updateOppositeVisuals();
        }
        // }
    }

    dissipateToNewDirection=(neighbors:ExistingObject[])=>{
        // console.log(`Direction ${this.parent.id} dissipating to new direction`)
        const newDirection = this.createNewDirectionToDissipateTo(neighbors);
        if(!newDirection){
            console.log(`Direction ${this.parent.id} trying to dissipate to existing direction but failed to find new direction`);
            return null;
        }
        // console.log("New Direction: ", newDirection);
        // const animationSource = this.parent.causalEnergy;
        // const animationTarget = this.parent.causalEnergy;
        const animationSource = this.parent;
        const directionOfTargetEnergy: EnergyDirection = newDirection;
        const source = this.parent.GOE.getObjectById(directionOfTargetEnergy[0]);
        const target = this.parent.GOE.getObjectById(directionOfTargetEnergy[1]);
        if(!source || !target) return null;
        const directionPosition = calculateDirectionPosition(source, target);
        const isInBounds = this.parent.GOE.spatialPartioner.isPointInSubspace(directionPosition);
        if(!isInBounds) return null;
        if(source instanceof SpatialAction || target instanceof SpatialAction){
            console.log("Source: ", source);
            console.log("Target: ", target);
            throw new Error("Spatial action was found as a neighbor during reg check")
        }
        //+1 so that it's never 0.
        const energyToDissipate = Math.round(Math.random()*this.parent.getEnergyLevel())+1;

        const sourceIsIdentical = newDirection[0] === this.parent.getDirectionArray()[0];
        const targetIsIdentical = newDirection[1] === this.parent.getDirectionArray()[1];
        const newDirectionSameAsOld = sourceIsIdentical && targetIsIdentical;

        if(newDirectionSameAsOld){
            // console.log(`Direction ${this.parent.id} trying to dissipate to existing new direction but was same as current direction`);
            return null;
        }
        // if(newDirectionSameAsOld) throw new Error('Tried to dissipate to self as new direction');
        if(this.parent.energyCapacity.wouldTaskBeImpossible(this.parent, energyToDissipate, source, target)){
            // console.log(`Direction ${this.parent.id} trying to dissipate to new direction but task would be impossible`);
            return null;
        }
        // console.log(`Direction ${this.parent.id} is creating new direction on move: `, newDirection)
        const isIdentical = isSourceAndTargetIdentical(newDirection);
        if(isIdentical) throw new Error("dissipateToNewDirection - Source and target identical")
        // const animationTarget = this.parent.GOE.causalField.getOrCreateDirection(newDirection);
        const animationTarget: PotentialObjectSettings = {type: "Direction", settings: {
            position: calculateDirectionPosition(source, target),
            directionArray: newDirection
        }}
        // const animationTarget = new PotentialObject(this.parent.GOE, {type: "Direction", settings: newDirection})
        // if(animationTarget.isDissipating) return null;
        return {
            animationSource,
            animationTarget,
            directionOfTargetEnergy,
            energyToDissipate,
        }

    }

    dissipateToExistingObject=(neighbors: ExistingObject[])=>{
        const randomObj = this.getExistingObjectToDissipateTo(neighbors);
        if(!randomObj || (randomObj.id === this.parent.id) || (randomObj.id === this.parent.getOppositeDirectionIndex()) ){
            // console.log(`Direction ${this.parent.id} couldn't find existing random obj`);
            return null;
        }
        const animationSource = this.parent;
        const directionOfTargetEnergy = randomObj instanceof Direction ? randomObj.getDirectionArray() : [null,null];
        const energyToDissipate = Math.round(Math.random()*this.parent.getEnergyLevel())+1;
        if(this.parent.energyCapacity.wouldTaskBeImpossible(this.parent, energyToDissipate, this.parent, randomObj)){
            // console.log(`Direction ${this.parent.id} trying to dissipate to existing neighbor but task would be impossible`);
            return null;
        };
        if(randomObj.id === this.parent.id) throw new Error('Tried to dissipate to self');
        const animationTarget = randomObj.getPotentialObjectSettings();
        // const animationTarget = new PotentialObject(this.parent.GOE, randomObj.getPotentialObjectSettings())
        return {
            animationSource,
            animationTarget,
            directionOfTargetEnergy,
            energyToDissipate,
        }
    }

    createNewDirectionToDissipateTo=(neighbors: ExistingObject[]): string[] | null=>{
        if(!neighbors.length) throw new Error("No objecs exist to dissipate to when creating new direction!");
        const checkedPairs = new Set<string>();

        while (true) {
            if (neighbors.length < 2) {
                // Single neighbor must be the object
                // return neighbors[0] || null;
                return null;
            }

            // Randomly pick two different neighbors
            const index1 = Math.floor(Math.random() * neighbors.length);
            let index2 = Math.floor(Math.random() * neighbors.length);

            //Ensure neighbors are different
            while (index1 === index2) {
                index2 = Math.floor(Math.random() * neighbors.length);
            }

            //Get neighbors
            const neighbor1 = neighbors[index1];
            const neighbor2 = neighbors[index2];

            // Create a unique key for the pair to track checked pairs
            const pairKey = [neighbor1.id, neighbor2.id].sort().join('-');

            if (checkedPairs.has(pairKey)) {
                // If all possible pairs have been checked, exit the loop
                if (checkedPairs.size === (neighbors.length * (neighbors.length - 1)) / 2) {
                    //No valid pairs, so just randomly pick a neighbor
                    // const index = Math.floor(Math.random() * neighbors.length);
                    // return neighbors[index]; // No valid pairs found
                    return null;
                }
                continue; // Skip already checked pairs
            }

            checkedPairs.add(pairKey);

            // Check if they are neighbors of each other
            const lessThanMinimumDistance = calculateDistanceBetweenPoints(neighbor1.getPosition(), neighbor2.getPosition()) < MINIMUM_DISTANCE;
            if (isPointNeighborOfCell(neighbor1.getPosition(), neighbor2.getPosition()) && !lessThanMinimumDistance) {
                const directionArray = [neighbor1.id, neighbor2.id];
                return directionArray;
            }
        }
    }

    getExistingObjectToDissipateTo(neighbors: ExistingObject[]): ExistingObject{
        if(!neighbors.length) throw new Error("No objecs exist to dissipate to when getting existing object!");
        if(this.parent.GOE.tutorial.inProgress() || this.parent.GOE.clearingDirections){
            neighbors = neighbors.filter((obj: ExistingObject)=>{
                return obj instanceof GridCell
            })
        }
        const index = Math.floor(Math.random() * neighbors.length);
        return neighbors[index]; // No valid pairs found
    }

    setOppositeDirectionAsDissipated=()=>{
        const oppObj = this.parent.getOppositeDirectionObject();
        if(!oppObj) return;
        oppObj.dissipation.onManualDissipation();
    }

    setIsDissipating=(value: boolean)=>{
        this.isDissipating = value;
    }
    setOppositeObjToDissipate=()=>{
        const oppObj = this.parent.getOppositeDirectionObject();
        if(!oppObj) return;
        if(oppObj && !oppObj.dissipation.isDissipating && !oppObj.dissipation.dissipated) oppObj.dissipation.setIsDissipating(true);
    }
    setDissipated=(val: boolean)=>{
        this.dissipated = val;
    }
    setOppositeDissipated=(val: boolean)=>{
        const oppObj = this.parent.getOppositeDirectionObject();
        if(!oppObj) return;
        oppObj.dissipation.setDissipated(val);
    }
}

export default DirectionDissipation