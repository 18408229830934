import { Vector3 } from "@babylonjs/core";
import convertDirectionArrayToString from "../helpers/convertDirectionArrayToString";
import { EnergyDirection, ExistingObject, GridCellSettings, PossibleObject, PotentialDirectionSettings, PotentialObjectSettings, SpatialActionSettings, Task } from "../types";
import Direction from "./Direction";
import GameOfExistence from "./GameOfExistence";
import GridCell from "./GridCell";
import calculateDirectionPosition from "../helpers/calculateDirectionPosition";
import isSourceAndTargetIdentical from "../helpers/isSourceAndTargetIdentical";
import SpatialAction from "./SpatialAction";
import serializeVector3 from "../helpers/serializeVector3";


class PotentialObject {
    gridCellSettings: GridCellSettings
    directionSettings: PotentialDirectionSettings
    spatialSettings: SpatialActionSettings
    settings: PotentialObjectSettings;
    GOE: GameOfExistence = null;
    id: string;
    index: string;
    taskSettings: Task = null;
    
    constructor(GOE: GameOfExistence, settings: PotentialObjectSettings, taskSettings?: Task){
        this.GOE = GOE;
        this.settings = settings;
        this.initSelf()
        this.id = this.getId();
        this.index = this.getId();
        this.taskSettings = taskSettings;
    
    }
    public setSettings=(settings: PotentialObjectSettings)=>{
        this.settings = settings;
    }
    private initSelf=()=>{
        const {type} = this.settings;
        if(type==='SpatialAction'){
            this.spatialSettings = <SpatialActionSettings>this.settings.settings;
        }
        if(type==='GridCell'){
            this.gridCellSettings = <GridCellSettings>this.settings.settings;
        }
        if(type==='Direction'){
            this.directionSettings = <PotentialDirectionSettings>this.settings.settings;
        }
    }
    public getId=(): string=>{
        if(this.gridCellSettings) return this.gridCellSettings.index;
        if(this.directionSettings) return convertDirectionArrayToString(this.directionSettings.directionArray)
    }

    public getSpatialId=()=>{
        if(this.spatialSettings) return this.spatialSettings.id
        return undefined;
    }
    
    public getSpatialObject=(): SpatialAction=>{
        // if(this.spatialSettings) return this.GOE.spatialActionManager.spatialActions[this.spatialSettings.id]
        if(this.spatialSettings) return <SpatialAction>this.GOE.spatialActionCreator.getObjectById(this.spatialSettings.id)
    }
    public getObject=(): PossibleObject=>{
        if(this.gridCellSettings) return this.GOE.getObjectById(this.gridCellSettings.index)
        if(this.directionSettings) return this.GOE.causalField.getDirection(this.directionSettings.directionArray)
        if(this.spatialSettings) return this.GOE.spatialActionCreator.getObjectById(this.spatialSettings.id)
    }
    public getExistingObject=(): ExistingObject=>{
        if(this.gridCellSettings) return <GridCell>this.GOE.getObjectById(this.gridCellSettings.index)
        if(this.directionSettings) return this.GOE.causalField.getDirection(this.directionSettings.directionArray)
    }
    public toJSON=(): any=>{
        if(this.spatialSettings) return {
            type: "SpatialAction",
            settings: this.spatialSettings,
        }
        if(this.gridCellSettings) return {
            type: "GridCell",
            settings: {
                ...this.gridCellSettings,
                position: serializeVector3(this.gridCellSettings.position)
            },
        }
        if(this.directionSettings) return {
            type: "Direction",
            settings: this.directionSettings,
        }
    }
    public getEnergyLevel=()=>{
        const sourceObj = this.getObject();
        const sourceEnergy = sourceObj ? sourceObj.getEnergyLevel() : 0;
        return sourceEnergy;
    }
    public getPosition=(): Vector3=>{
        const obj = this.getObject();
        if(obj instanceof GridCell || obj instanceof Direction || obj instanceof SpatialAction){
            // console.log("Existing obj returning: ", obj.position);
            return obj.getPosition();
        }
        if(this.gridCellSettings){
            // console.log("Grid cell position: ", this.gridCellSettings.position);
            return this.gridCellSettings.position;
        }
        if(this.directionSettings){
            const position = this.directionSettings.position;
            // console.log("Direction settings: ", this.directionSettings);
            // console.log("Direction settings position: ", position)
            return position;
        }
        if(this.spatialSettings){
            const position = this.calculateDirectionPositionFromArray(this.spatialSettings.directionSettings.directionArray)
            // console.log("Spatial settings position: ", position)
            return position;
        }
        
    }
    public getOrCreateTargetOnParticleArrival=()=>{
        const obj = this.getObject();
        if(obj instanceof GridCell || obj instanceof Direction){
            // console.log("Returning existing obj: ", obj)
            // console.log("obj.id: ", obj.id)
            return obj;
        }
        if(this.gridCellSettings){
            const gridCell = this.createNewGridCell();
            console.log("Creating new grid cell on particle arrival: ", gridCell.id);
            return gridCell;
        }
        if(this.directionSettings){
            const direction = this.createNewDirection();
            if(!direction){
                // const id = direction ? direction.id : direction.index;
                console.log("Creating new object (direction) on particle arrival: ", direction);
                // console.log("ID of object: ", id);
                throw new Error("Failed to create new object on arrival");
            }
            return direction;
        }
        if(this.spatialSettings){
            throw new Error("Looked for spatial action when creating target on particle arrival");
        }
    }

    private logCaller = ()=>{
        if(!this.taskSettings) return;
        const {caller} = this.taskSettings;
        const callerObj = this.GOE.getExistingObjectById(caller);
        if(caller) console.log(`Caller was was ${caller}`)
        if(callerObj) console.log(`Caller obj was ${callerObj}`)
        console.log("Task settings: ", this.taskSettings);
        console.log("Task settings type: ", this.taskSettings.type);
    }

    private isPointInSubspace=(position: Vector3)=>{
        const result = this.GOE.spatialPartioner.getIdOfSubspacePointIsIn([position.x, position.y, position.z]);
        if (!result) return false;
        return true;
    }

    private createNewGridCell=(): GridCell | null=>{
        const { position, index } = this.gridCellSettings;

        const result = this.isPointInSubspace(position);
        if(!result){
            // console.log(`New Grid cell ${newData.id} would be out of bounds on movement`);
            // console.log("Position: ", position);
            throw new Error(`Grid cell would be out of bounds on movement`)
            return null;
        }

        // console.log(`Would create new grid cell ${newData.id}`);
        // console.log(`Grid cell ${newData.id} already exists!: `, isExistingGridCell);

        
        // TODO: AddNewGridCell function
        const gridCell = new GridCell({
            GOE: this.GOE,
            index: index,
            position: position,
            initEnergyLevel: 0,
            visibility: true,
        });
        // console.log(`Created new grid cell on particle arrival ${index}: `, gridCell);
        // gridCell.renderGridCell();
        this.GOE.grid.cellData[index] = gridCell;
        return gridCell;
    }  

    private calculateDirectionPositionFromArray=(directionArray: EnergyDirection)=>{
        const directionSource = this.GOE.spatialActionCreator.getObjectById(directionArray[0]);
        const directionTarget = this.GOE.spatialActionCreator.getObjectById(directionArray[1]);

        if(!directionSource){
            // console.log(`Source of direction doesn't exist `);
            // console.log("SourceId: ", directionArray[0]);
            throw new Error("Couldn't get source position");
            return null;
        }
        if(!directionTarget){
            // console.log(`Target of direction doesn't exist `);
            // console.log("TargetId: ", directionArray[1]);
            throw new Error("Couldn't get target position")
            return null;
        }

        const position = calculateDirectionPosition(directionSource, directionTarget);
        return position;
    }
    private createNewDirection=()=>{
        const directionArray = this.directionSettings.directionArray;
        const position = this.directionSettings.position;
        const directionSource = this.GOE.getObjectById(directionArray[0]);
        const directionTarget = this.GOE.getObjectById(directionArray[1]);

        // if(!directionSource){
        //     console.log(`Source of direction to create doesn't eist yet`);
        //     console.log("SourceId: ", directionArray[0]);
        //     return;
        // }
        // if(!directionTarget){
        //     console.log(`Target of direction to create doesn't exist yet`);
        //     console.log("TargetId: ", directionArray[1]);
        //     return;
        // }

        // const position = calculateDirectionPosition(directionSource, directionTarget)
   

        const isDirectionInBounds = this.isPointInSubspace(position);
        if(!isDirectionInBounds){
            const maxDimensions = this.GOE.grid.getMaxDimensions();
            console.warn(`New direction would be out of bounds on movement`);
            console.log("Position: ", position);
            console.log("Max dimensions: ", maxDimensions)
            this.logCaller();
            throw new Error("Direction out of bounds")
            return;
        }
  
     

        const isIdentical = isSourceAndTargetIdentical(directionArray);
        if(isIdentical) throw new Error("createNewDirectionOnMovement - Source and target identical")
            
        const newDirection = this.GOE.causalField.getOrCreateDirection(directionArray);
        if(!newDirection){
            console.warn("Couldn't create new direction");
        }
        if(newDirection) return newDirection
        // console.log("Array: ", directionArray);
        // console.log(`Failed to create new direction with array on particle arrival: `, newDirection);
        // console.log(`Creating grid cell instead`);
        this.gridCellSettings = {
            index: `(${position.x},${position.z})`,
            position: new Vector3(position.x, 0, position.z),
            initEnergyLevel: 0,
        }
        const gridCell = this.createNewGridCell();
        if(gridCell) return gridCell;
        throw new Error("Target - CreateNewDirection --- Couldn't create direction");

        //If new subspace then new neighbors too!

    }
}

export default PotentialObject