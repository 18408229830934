import { ExecuteCodeAction, ActionManager, Color3, DynamicTexture, Mesh, MeshBuilder, StandardMaterial, Vector3 } from "@babylonjs/core";
import GameOfExistence from "./GameOfExistence";
import GridCell from "./GridCell";
import Direction from "./Direction";
import { ExistingObject } from "../types";
import { DIRECTION_HOVER_HEIGHT } from "../constants";

class EnergyScroller {

    dynamicTexture: DynamicTexture;
    energyScroller: Mesh | null;
    energyScrollMaterial: StandardMaterial;
    energyScrollerValue: number = 0;
    energyControlOn = false;

    parent: ExistingObject = null;
    GOE: GameOfExistence = null;

    constructor({GOE, energyScrollerValue, parent}){
        this.parent = parent;
        this.GOE = GOE
        this.energyScrollerValue = energyScrollerValue;
        this.createEnergyScroller();
    }

    dispose=()=>{
        this.energyScroller.dispose();
    }
    createEnergyScroller=()=>{
        const { scene } = this.GOE;
        const width = 0.15;
        const height = 0.15;
        const energyScroller = MeshBuilder.CreatePlane("numberPlane", { width, height }, scene);
        
        this.dynamicTexture = new DynamicTexture("dynamicTexture", 512, scene, true);
        const parentPosition = this.parent.getPosition();
        energyScroller.position = new Vector3(parentPosition.x, parentPosition.y + .75, parentPosition.z);
        energyScroller.billboardMode = Mesh.BILLBOARDMODE_ALL;
        var material = new StandardMaterial("mat", scene);
        energyScroller.material = material;
        material.diffuseTexture = this.dynamicTexture;
        material.diffuseTexture.hasAlpha = true;
        material.useAlphaFromDiffuseTexture
        material.specularColor = new Color3(0, 0, 0);
        material.emissiveColor = new Color3(1, 1, 1);
        material.backFaceCulling = false;
        // this.dynamicTexture.drawText("alpha", null, 140, "bold 140px verdana", "white", "#00000000")
        // material.disableLighting = true; // Disable lighting effects
        this.energyScroller = energyScroller;
        // Initialize energyScrollValue
        // console.log("Maximum energy: ", this.getMaximumEnergy());
        const energyValue = this.getMaximumEnergy() === 0 ? 0 : this.energyScrollerValue;
        this.dynamicTexture.drawText(energyValue.toString(), null, null, "450px verdana", "white", null);
        this.dynamicTexture.update();

        energyScroller.actionManager = new ActionManager(scene);
        ((GOE, cell, scroller)=>{
            const {selectedObject, selectedSource, selectedTarget} = GOE.controls;
            energyScroller.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function(ev){	
                if(!cell.isVisible) return;
                // console.log("Maximum energy: ", scroller.getMaximumEnergy());
                if(scroller.getMaximumEnergy() === 0) return;
                const isSelectedCell = selectedObject && (selectedObject.id === cell.id);
                if(!isSelectedCell) return;
                if(scroller.energyControlOn){
                    scroller.removeWheelEnergyControl();
                    GOE.controls.addWheelCameraControl();
                    // console.log("Turning off control");
                    return;
                }
                // console.log("Turning on control");
                GOE.controls.removeWheelCameraControl();
                scroller.addWheelEnergyControl();

            }));
        })(this.GOE, this.parent, this)
    }
    
    updateNumber=()=>{
        const context2d = this.dynamicTexture.getContext();
    
        // Clear the entire canvas
        context2d.clearRect(0, 0, this.dynamicTexture.getSize().width, this.dynamicTexture.getSize().height);
    
        // Draw the new text
        this.dynamicTexture.drawText(this.energyScrollerValue.toString(), null, null, "450px verdana", "yellow", null);
        this.dynamicTexture.update();
    }

    modifyEnergyScrollerValue = (event) => {
        let delta;
        event.preventDefault();
        if (event.type === 'wheel') {
            delta = event.deltaY > 0 ? -1 : 1;
        } else if (event.type === 'keydown') {
            if (event.key === 'ArrowUp') {
                delta = 1;
            } else if (event.key === 'ArrowDown') {
                delta = -1;
            } else {
                return; // Ignore other keys
            }
        } else {
            return; // Ignore other event types
        }
    
        const newValue = this.energyScrollerValue + delta;
       
        if (newValue < 0 || newValue > this.getMaximumEnergy()) return;
        this.energyScrollerValue = newValue;
        this.updateNumber();
    };
    getMaximumEnergy=()=>{
        // console.log("Getting maximum energy!")
        const SEM = this.GOE.spatialEnergyManager;
        const isSpatialModeOn = this.GOE.spatialMode;
        const useSpatialEnergy = isSpatialModeOn && SEM.isObjectStored(this.parent.id);
        // if(useSpatialEnergy) console.log("Using spatial energy");
        const maximumEnergy =  useSpatialEnergy ? SEM.getFreeEnergy(this.parent) : this.parent.getEnergyLevel();
        return maximumEnergy
    }
    addWheelEnergyControl=()=>{
        this.dynamicTexture.drawText(this.energyScrollerValue.toString(), null, null, "450px verdana", "yellow", null);
        this.dynamicTexture.update();
    
        this.energyControlOn = true;
        document.addEventListener('wheel', this.modifyEnergyScrollerValue, { passive: false });
        document.addEventListener('keydown', this.modifyEnergyScrollerValue);
    }
    
    removeWheelEnergyControl=()=>{
        this.dynamicTexture.drawText(this.energyScrollerValue.toString(), null, null, "450px verdana", "white", null);
        this.dynamicTexture.update();
    
        this.energyControlOn = false;
        document.removeEventListener('wheel', this.modifyEnergyScrollerValue);
        document.removeEventListener('keydown', this.modifyEnergyScrollerValue);
    }
    
}

export default EnergyScroller;