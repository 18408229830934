import { Camera, Engine, FreeCamera } from "@babylonjs/core";
import GameOfExistence from "../classes/GameOfExistence";

function onWindowResize(GOE: GameOfExistence, engine?: Engine) {
    const updateWindow = () => {
      engine && engine.resize();
    };
    window.addEventListener('resize', updateWindow, false);
  }

export default onWindowResize;
