import React from "react";

const cardStyle = {
  boxShadow: '0px 5px 5px rgba(0, 0, 0, .8)',
};

const Card = React.forwardRef((props: any, ref: any) => {
  const { onClick, children, style } = props;
  return (
    <div ref={ref} onClick={onClick} style={{...cardStyle, ...style}}>
      {children}
    </div>
  );
});

export default Card;
