import { AdvancedDynamicTexture, Rectangle, Control } from "@babylonjs/gui";
import GameOfExistence from "./GameOfExistence";
import Direction from "./Direction";
import GridCell from "./GridCell";

class Stress {
    private advancedTexture: AdvancedDynamicTexture;
    private stressElement: HTMLElement;
    private stressRect: Rectangle;
    GOE: GameOfExistence = null;

    constructor(GOE: GameOfExistence, advancedTexture) {
        this.advancedTexture = advancedTexture
        this.GOE = GOE;
        this.createStressUI();
        this.updateStressUI();
    }

    private createStressUI=()=>{
        // Create a background rectangle for the stress display
        let stressRect = new Rectangle();
        this.stressRect = stressRect;
        stressRect.width = "100%";
        stressRect.height = "12.5%";
        stressRect.color = "white";
        stressRect.thickness = 0;
        stressRect.background = "rgba(0, 0, 0, 0)"; 
        stressRect.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        this.advancedTexture.addControl(stressRect);

        // Create an HTML element for MathJax rendering
        this.stressElement = document.createElement("div");
        const canvasContainer = document.getElementById("canvas-container");
        this.stressElement.style.position = "absolute";
        this.stressElement.style.width = "100%";
        this.stressElement.style.textAlign = "center";
        this.stressElement.style.color = "white";
        this.stressElement.style.backgroundColor = "rgba(0, 0, 0, 0.5)"; 
        this.stressElement.style.fontSize = "18px";
        this.stressElement.style.display = "none";
        const parent = canvasContainer ? canvasContainer : document.body;
        // this.stressElement.innerHTML = `$$\\sigma_g = \\sum_i F_i - \\sum_i CE_i (1 - \\sigma_{CE})$$`;
        // parent.prepend(this.stressElement);
        canvasContainer ? parent.prepend(this.stressElement) : document.body.append(this.stressElement);

        // Render the equation using MathJax
        this.renderMathJax();
    }

    private updateStressUI=()=>{
        const { globalStress, totalCausalEnergy, observedFreeEnergy, stressWeightedCausalEnergy } = this.calculateGlobalStress();
        this.stressElement.innerHTML = `$$\\sigma_g = \\sum_i F_i - \\sum_i CE_i (1 - \\sigma_{CE}) = ${globalStress.toFixed(2)}$$`;
        
        // Create a container div to apply flex styles
        const container = document.createElement("div");
        container.style.display = "flex";
        container.style.justifyContent = "space-around";
        container.style.marginTop = "10px";
        container.style.color = "white";

        // Create individual span elements for each term
        const term1 = document.createElement("span");
        term1.innerHTML = `$$\\sum_i F_i: ${observedFreeEnergy}$$`;
        
        const term2 = document.createElement("span");
        term2.innerHTML = `$$\\sum_i CE_i: ${totalCausalEnergy}$$`;

        const term3 = document.createElement("span");
        term3.innerHTML = `$$\\sum_i CE_i (1 - \\sigma_{CE}): ${stressWeightedCausalEnergy.toFixed(2)}$$`;

        // Append the terms to the container
        container.appendChild(term1);
        container.appendChild(term2);
        container.appendChild(term3);

        // Clear previous content and append the container to stressElement
        this.stressElement.innerHTML = `$$\\sigma_g = \\sum_i F_i - \\sum_i CE_i (1 - \\sigma_{CE}) = ${globalStress.toFixed(2)}$$`;
        this.stressElement.appendChild(container);

        // Render the updated equation using MathJax
        this.renderMathJax();

        if(!this.stressRect.isVisible) return;
        // Update the stress UI regularly, e.g., every second
        setTimeout(() => this.updateStressUI(), 1000);
    }

    private renderMathJax=()=>{
        // Use MathJax to process the LaTeX code
        //@ts-ignore
        MathJax.typesetPromise([this.stressElement]);
    }

    private getObservedFreeEnergy=()=>{
        return this.getVisibleGridCells().reduce((a: any, b: any) => {
            return a + b.getEnergyLevel();
        }, 0);
    }

    private getVisibleGridCells=()=>{
        return Object.values(this.GOE.grid.cellData)
        .filter((obj)=>obj.isVisible);
    }
    private getVisibleDirections=()=>{
        return Object.values(this.GOE.causalField.directions)
        .filter((obj)=>obj.isVisible);
    };

    private getStressWeightedCausalEnergy=()=>{
        let totalEnergy = 0;
        const stressWeightedCausalEnergy = this.getVisibleDirections().reduce((a: any, b: any) => {
            const causalEnergyStress = this.calculateDirectionStress(b);
            const localStressWeightedCausalEnergy = b.getEnergyLevel() * (1 - causalEnergyStress);
            totalEnergy += b.getEnergyLevel();
            return a + localStressWeightedCausalEnergy;
        }, 0);
        return { totalEnergy, stressWeightedCausalEnergy };
    }

    private calculateGlobalStress=()=>{
        const observedFreeEnergy = this.getObservedFreeEnergy();
        const { stressWeightedCausalEnergy, totalEnergy: totalCausalEnergy } = this.getStressWeightedCausalEnergy();
        return {
            globalStress: observedFreeEnergy - stressWeightedCausalEnergy,
            totalCausalEnergy,
            observedFreeEnergy,
            stressWeightedCausalEnergy,
        };
    }

    public calculateDirectionStress=(direction: Direction)=>{
        const energyLevel = direction.getEnergyLevel();
        if(energyLevel === 0) return 1;
        const directionSource = this.GOE.getObjectById(direction.sourceId);
        if(!directionSource) return 1;
        const energyInSource = directionSource.getEnergyLevel();
        const stress = 1 / (energyInSource / energyLevel);
        return stress > 1 ? 1 : stress;
    }

    public isVisible=()=>{
        return this.stressElement.style.display === "";
    }

    public toggleStress =()=> {
        const isVisible = this.isVisible()
        if (isVisible) {
            this.stressElement.style.display = "none";
            return false;
        } else {
            this.stressElement.style.display = "";
            return true;
        }
    }
}

export default Stress;
