import { Color4, FloatArray, IndicesArray, Mesh, VertexBuffer } from "@babylonjs/core";

function changeBoxColor(boxMesh: Mesh, color: Color4): void {
    const indices: IndicesArray = boxMesh.getIndices();
    const positions: FloatArray | [] = boxMesh.getVerticesData(VertexBuffer.PositionKind) || [];
    let colors: FloatArray | null = boxMesh.getVerticesData(VertexBuffer.ColorKind) || null;
    const nbVertices: number = positions.length / 3;

    // If there are no vertices, return without changing the color
    if (nbVertices === 0) {
        // console.warn("Attempted to change color of a box with no vertices.");
        return;
    }
  
    if (!colors) {
      colors = new Float32Array(4 * nbVertices);
      colors.fill(1);
    }
  
    const clr: Color4 = new Color4(color.r, color.g, color.b, color.a);
    let facet: number, vertex: number;
  
    for (let i = 0; i < 6; i++) {
      facet = 2 * i;
      vertex = indices[3 * facet];
  
      const rOffset: number = 4 * vertex;
      const gOffset: number = rOffset + 1;
      const bOffset: number = rOffset + 2;
      const aOffset: number = rOffset + 3;
  
      colors[rOffset] = clr.r;
      colors[gOffset] = clr.g;
      colors[bOffset] = clr.b;
      colors[aOffset] = clr.a;
  
      colors[rOffset + 4] = clr.r;
      colors[gOffset + 4] = clr.g;
      colors[bOffset + 4] = clr.b;
      colors[aOffset + 4] = clr.a;
  
      colors[rOffset + 8] = clr.r;
      colors[gOffset + 8] = clr.g;
      colors[bOffset + 8] = clr.b;
      colors[aOffset + 8] = clr.a;
    }
  
    boxMesh.setVerticesData(VertexBuffer.ColorKind, colors);
}
  

export default changeBoxColor;
  