import { ActionManager, Color3, ExecuteCodeAction, Mesh, MeshBuilder, Scene, StandardMaterial, Vector3 } from "@babylonjs/core";
import GameOfExistence from "./GameOfExistence";
import { PositionObject, PossibleObject } from "../types";
import isMainScene from "../helpers/isMainScene";
import { DIRECTION_HOVER_HEIGHT } from "../constants";


class ArrowVisuals {
    GOE: GameOfExistence;
    selectArrow: Mesh;
    selectArrowHitbox;
    constructor(GOE: GameOfExistence){
        this.GOE = GOE;

    }

    createArrowHitbox = (arrow: Mesh) => {
        // First we create a bounding box around the arrow
        let boundingInfo = arrow.getBoundingInfo();
        let min = boundingInfo.minimum;
        let max = boundingInfo.maximum;
    
        // Create a box that encapsulates the arrow mesh, and make it a bit bigger
        let hitbox = MeshBuilder.CreateBox(
            'hitbox', {width: (max._x - min._x)*1.2, height: (max._y - min._y)*1.2, depth: (max._z - min._z)*1.2},
        this.GOE.scene);
    
        // Compute the bounding box's center in world coordinates
        let boundingBoxCenterWorld = boundingInfo.boundingBox.centerWorld;
    
        // Set the hitbox's position to the bounding box's center
        hitbox.position = boundingBoxCenterWorld;
    
        // Set the hitbox's parent to the arrow
        hitbox.parent = arrow;
    
        // Make the hitbox invisible
        hitbox.visibility = 0;
    
        // Add a property to the hitbox so we can easily access the arrow it's associated with
        hitbox.metadata = { associatedArrow: arrow };
    
        return hitbox;
    };
    
    createArrow = (source: PositionObject, target: PositionObject, scene: Scene): [Mesh, Vector3, Vector3]=>{
        // console.log("createArrow - Source: ", source);
        // console.log("createArrow - Target: ", target);
        const sourcePos = source.position;
        const targetPos = target.position;
        const {x: srcX, y: srcY, z: srcZ} = sourcePos;
        const {x: tgtX, y: tgtY, z: tgtZ} = targetPos;

        // Use the positions of the source and target as the start and end of the arrow
        let start = new Vector3(srcX, srcY >= DIRECTION_HOVER_HEIGHT? srcY : srcY + DIRECTION_HOVER_HEIGHT, srcZ);
        let end = new Vector3(tgtX, tgtY >= DIRECTION_HOVER_HEIGHT? tgtY : tgtY + DIRECTION_HOVER_HEIGHT, tgtZ);
        // Shape profile in XY plane
        const myShape = [];
        const arrowRadius = 0.015;
        const arrowScale = 0.65;
        const n = 30;
        const deltaAngle = 2 * Math.PI / n;
        for (let i = 0; i <= n; i++) {
            myShape.push(new Vector3(arrowRadius * Math.cos(i * deltaAngle), arrowRadius * Math.sin(i * deltaAngle), 0));
        }
        myShape.push(myShape[0]);  // Close profile
    
        const arrowHeadLength = .05;
        const arrowHeadMaxSize = .05;
    
        // Compute the vector from start to end
        let arrowDirection = end.subtract(start);
        const arrowLength = arrowDirection.length() * 1.25;
        arrowDirection.normalize();  // Normalize to get unit vector for direction
    
        // Compute the length to shift the start and end points (10% of the scaled length)
        let shiftLength = arrowLength * arrowScale * 0.125;
    
        // Shift the start and end points towards the target
        start = start.add(arrowDirection.scale(shiftLength));
        end = end.subtract(arrowDirection.scale(shiftLength));
    
        const midpoint = start.add(end).scale(0.5);  // Calculate the midpoint of the arrow
        const directionPosition = midpoint.add(targetPos).scale(0.5);

        // Scale down the arrow length
        const scaledArrowLength = arrowLength * arrowScale;
        const arrowBodyLength = scaledArrowLength - arrowHeadLength;  // The body of the arrow should be shorter by the head length
    
        // Now we use the start position and direction to compute the end of the arrow's body and the end of the arrow's head
        const arrowBodyEnd = start.add(arrowDirection.scale(arrowBodyLength));
        const arrowHeadEnd = arrowBodyEnd.add(arrowDirection.scale(arrowHeadLength));
    
        const myPath = [];
        myPath.push(start);
        myPath.push(arrowBodyEnd);
        myPath.push(arrowBodyEnd);
        myPath.push(arrowHeadEnd);
    
        const scaling = (index, distance) => {
            switch (index) {
                case 0:
                case 1:
                    return 1;
                    break;
                case 2:
                    return arrowHeadMaxSize / arrowRadius;
                    break;
                case 3:
                    return 0;
                    break;
            }
        };
    
        // let arrow = MeshBuilder.ExtrudeShapeCustom(`${source.id}-${target.id}`,{
        // console.log("Arrow name: ", `${source.id}-${target.id}${isMainScene(scene) ? "" : "-secondary"}`);
        // console.log(`Checking is main scene in create arrow`)
        let arrow = MeshBuilder.ExtrudeShapeCustom(`${source.id}-${target.id}${isMainScene(scene) ? "" : "-secondary"}`,{
            shape: myShape,
            path: myPath,
            updatable:true,
            scaleFunction: scaling,
            sideOrientation: Mesh.DOUBLESIDE
        }, scene);
        // arrow.position = midpoint;

        // Compute the bounding box of the arrow
        const boundingInfo = arrow.getBoundingInfo();

        // Calculate the center of the bounding box
        const boundingBoxCenterWorld = boundingInfo.boundingBox.centerWorld;

        // Calculate the difference between the current position and the center of the bounding box
        const offset = boundingBoxCenterWorld.subtract(arrow.position);

        // Update the arrow's position to the center of the bounding box
        arrow.setPivotPoint(offset);
        return [arrow, midpoint, directionPosition];
    }

    createDoubleEndedArrow = (source: PositionObject, target: PositionObject, scene: Scene): [Mesh, Vector3]=>{
        const sourcePos = source.position;
        const targetPos = target.position;
        // const sourcePos = source.position;
        // const targetPos = target.position;
        const {x: srcX, y: srcY, z: srcZ} = sourcePos;
        const {x: tgtX, y: tgtY, z: tgtZ} = targetPos;

        // Use the positions of the source and target as the start and end of the arrow
        let start = new Vector3(srcX, srcY >= DIRECTION_HOVER_HEIGHT? srcY : srcY + DIRECTION_HOVER_HEIGHT, srcZ);
        let end = new Vector3(tgtX, tgtY >= DIRECTION_HOVER_HEIGHT? tgtY : tgtY + DIRECTION_HOVER_HEIGHT, tgtZ);
        // Shape profile in XY plane
        const myShape = [];
        const arrowRadius = 0.015;
        const arrowScale = 0.65;
        const n = 30;
        const deltaAngle = 2 * Math.PI / n;
        for (let i = 0; i <= n; i++) {
            myShape.push(new Vector3(arrowRadius * Math.cos(i * deltaAngle), arrowRadius * Math.sin(i * deltaAngle), 0));
        }
        myShape.push(myShape[0]);  // Close profile
    
        const arrowHeadLength = .05;
        const arrowHeadMaxSize = .05;
    
        // Compute the vector from start to end
        let arrowDirection = end.subtract(start);
        const arrowLength = arrowDirection.length() * 1.25;
        arrowDirection.normalize();  // Normalize to get unit vector for direction
    
        // Compute the length to shift the start and end points (10% of the scaled length)
        let shiftLength = arrowLength * arrowScale * 0.135;
    
        // Shift the start and end points towards the target
        start = start.add(arrowDirection.scale(shiftLength));
        end = end.subtract(arrowDirection.scale(shiftLength));
    
        const midpoint = start.add(end).scale(0.5);  // Calculate the midpoint of the arrow

        // Scale down the arrow length
        const scaledArrowLength = arrowLength * arrowScale;
        const arrowBodyLength = scaledArrowLength - arrowHeadLength;  // The body of the arrow should be shorter by the head length
    
        // Now we use the start position and direction to compute the end of the arrow's body and the end of the arrow's head
        const arrowBodyEnd = start.add(arrowDirection.scale(arrowBodyLength));
        const arrowHeadEnd = arrowBodyEnd.add(arrowDirection.scale(arrowHeadLength));
    
        const myPath = [];
        myPath.push(start);
        myPath.push(arrowBodyEnd);
        myPath.push(arrowBodyEnd);
        myPath.push(arrowBodyEnd);
        // myPath.push(start);
        // myPath.push(arrowHeadEnd);
    
        const scaling = (index, distance) => {
            switch (index) {
                case 0:
                case 1:
                    return 1;
                    break;
                case 2:
                    return arrowHeadMaxSize / arrowRadius;
                    break;
                case 3:
                    return 0;
                    break;
            }
        };
    
        // console.log("Balanced arrow name: ", `${source.id}-${target.id}${isMainScene(scene) ? "" : "-balanced-secondary"}`);
        // console.log(`Checking is main scene in double ended arrow arrow`)
        let arrow = MeshBuilder.ExtrudeShapeCustom(`${source.id}-${target.id}${isMainScene(scene) ? "" : "-balanced-secondary"}`,{
            shape: myShape,
            path: myPath,
            updatable:true,
            scaleFunction: scaling,
            sideOrientation: Mesh.DOUBLESIDE
        }, scene);
        // arrow.position = midpoint;

        // Compute the bounding box of the arrow
        const boundingInfo = arrow.getBoundingInfo();

        // Calculate the center of the bounding box
        const boundingBoxCenterWorld = boundingInfo.boundingBox.centerWorld;

        // Calculate the difference between the current position and the center of the bounding box
        const offset = boundingBoxCenterWorld.subtract(arrow.position);

        // Update the arrow's position to the center of the bounding box
        arrow.setPivotPoint(offset);
        return [arrow, midpoint];
    }
    
    createSelectArrow = (source: PossibleObject, target: PossibleObject) => {
        const sourcePos = source.getPosition();
        const targetPos = target.getPosition();
        const {x: srcX, y: srcY, z: srcZ} = sourcePos;
        const {x: tgtX, y: tgtY, z: tgtZ} = targetPos;

        // Use the positions of the source and target as the start and end of the arrow
        let start = new Vector3(srcX, srcY + DIRECTION_HOVER_HEIGHT*3, srcZ);
        let end = new Vector3(tgtX, tgtY + DIRECTION_HOVER_HEIGHT*3, tgtZ);
        // Shape profile in XY plane
        const myShape = [];
        const arrowRadius = 0.020;
        const arrowScale = 0.65;
        const n = 30;
        const deltaAngle = 2 * Math.PI / n;
        for (let i = 0; i <= n; i++) {
            myShape.push(new Vector3(arrowRadius * Math.cos(i * deltaAngle), arrowRadius * Math.sin(i * deltaAngle), 0));
        }
        myShape.push(myShape[0]);  // Close profile
    
        const arrowHeadLength = .05;
        const arrowHeadMaxSize = .05;
    
        // Compute the vector from start to end
        let arrowDirection = end.subtract(start);
        const arrowLength = arrowDirection.length();
        arrowDirection.normalize();  // Normalize to get unit vector for direction
    
        // Compute the length to shift the start and end points (10% of the scaled length)
        let shiftLength = arrowLength * arrowScale * 0.25;
    
        // Shift the start and end points towards the target
        start = start.add(arrowDirection.scale(shiftLength));
        end = end.subtract(arrowDirection.scale(shiftLength));
    
        const midpoint = start.add(end).scale(0.5);  // Calculate the midpoint of the arrow

        // Scale down the arrow length
        const scaledArrowLength = arrowLength * arrowScale;
        const arrowBodyLength = scaledArrowLength - arrowHeadLength;  // The body of the arrow should be shorter by the head length
    
        // Now we use the start position and direction to compute the end of the arrow's body and the end of the arrow's head
        const arrowBodyEnd = start.add(arrowDirection.scale(arrowBodyLength));
        const arrowHeadEnd = arrowBodyEnd.add(arrowDirection.scale(arrowHeadLength));
    
        const myPath = [];
        myPath.push(start);
        myPath.push(arrowBodyEnd);
        myPath.push(arrowBodyEnd);
        myPath.push(arrowHeadEnd);
    
        const scaling = (index, distance) => {
            switch (index) {
                case 0:
                case 1:
                    return 1;
                    break;
                case 2:
                    return arrowHeadMaxSize / arrowRadius;
                    break;
                case 3:
                    return 0;
                    break;
            }
        };
    
        let arrow = MeshBuilder.ExtrudeShapeCustom("arrow",{
            shape: myShape,
            path: myPath,
            updatable:true,
            scaleFunction: scaling,
            sideOrientation: Mesh.DOUBLESIDE
        }, this.GOE.scene);
        let redMaterial = new StandardMaterial("redMat", this.GOE.scene);
        redMaterial.diffuseColor = new Color3(1, 0, 0); // RGB for red
        redMaterial.specularColor = new Color3(1, 0, 0); // Specular color for shiny surfaces

        // Assign the red material to the arrow mesh
        arrow.material = redMaterial;
        // After the arrow creation
        let arrowHitbox = this.createArrowHitbox(arrow);
        arrowHitbox.parent = arrow;

        // Compute the bounding box of the arrow
        const boundingInfo = arrow.getBoundingInfo();

        // Calculate the center of the bounding box
        const boundingBoxCenterWorld = boundingInfo.boundingBox.centerWorld;

        // Calculate the difference between the current position and the center of the bounding box
        const offset = boundingBoxCenterWorld.subtract(arrow.position);

        // Update the arrow's position to the center of the bounding box
        arrow.setPivotPoint(offset);

        // inside your createArrow function, after the hitbox has been created:
        arrowHitbox.actionManager = new ActionManager(this.GOE.scene);

        ((self, midpoint)=>{
            arrowHitbox.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function(ev){	
                self.GOE.controls.onClickSelectArrow(source, target, midpoint);
            }));
            // when mouse pointer is over the mesh, scale it up
            arrowHitbox.actionManager.registerAction(
                new ExecuteCodeAction(
                    ActionManager.OnPointerOverTrigger,
                    (ev)=>{
                        arrow.scaling = new Vector3(1.2, 1.2, 1.2); // Increase size by 20%
                    }
                ));

            // when mouse pointer is out, scale it back down
            arrowHitbox.actionManager.registerAction(
                new ExecuteCodeAction(
                    ActionManager.OnPointerOutTrigger,
                    (ev)=>{
                        arrow.scaling = new Vector3(1, 1, 1); // Reset size
                    }
                ));
        })(this, midpoint)
        this.selectArrow = arrow;
        this.selectArrowHitbox = arrowHitbox;  // Store the hitbox as well
    };

    deleteSelectArrow = ()=>{
        if(!this.selectArrow) return;
        this.selectArrow.dispose();
        this.selectArrowHitbox.dispose();
        this.selectArrow = null;
        this.selectArrowHitbox = null;
    }
}

export default ArrowVisuals;