import { MeshBuilder, Mesh, StandardMaterial, Color3, Scene } from "@babylonjs/core";
import Direction from "./Direction";
import Stress from "./Stress";

class StressBar {
    parent: Direction;
    stress: Stress;
    stressBarMesh: Mesh;
    stressFillMesh: Mesh;
    scene: Scene;
    // isVisible: boolean;
    stressFillMaterial: StandardMaterial;

    constructor(parent: Direction) {
        this.parent = parent;
        this.stress = this.parent.GOE.stress;
        this.scene = this.parent.GOE.scene;
        // this.isVisible = this.parent.getEnergyLevel() ? true : false;
    }

    createMesh=()=>{
        if(this.stressFillMesh) return;
        // Create the fill bar
        this.stressFillMesh = MeshBuilder.CreatePlane("stressFill", { width: 0, height: 0.05 }, this.scene);
        this.stressFillMesh.billboardMode = Mesh.BILLBOARDMODE_ALL;
        this.stressFillMesh.position = this.parent.getPosition();
        this.stressFillMesh.position.y = this.stressFillMesh.position.y + 0.5; // Adjust height above the object

        this.stressFillMaterial = new StandardMaterial("stressFillMat", this.scene);
        this.stressFillMesh.material = this.stressFillMaterial;

    }

    updateStressBar=()=>{
        if(!this.stressFillMesh) this.createMesh();
        if (this.isEnabled()) {
            const stressValue = this.calculateDirectionStress();
            const fillWidth = .5 * stressValue;
            this.stressFillMesh.scaling.x = fillWidth;

            // Interpolate between green and red based on stress value
            const green = new Color3(0.2, 1, 0.2);
            const red = new Color3(1, 0.2, 0.2);
            const interpolatedColor = Color3.Lerp(green, red, fillWidth);
            this.stressFillMaterial.diffuseColor = interpolatedColor;
        }
    }

    calculateDirectionStress=()=>{
        return this.stress.calculateDirectionStress(this.parent);
    }

    // hide=()=>{
    //     this.isVisible = false;
    //     this.stressFillMesh.isVisible = false;
    // }

    // show=()=>{
    //     this.isVisible = true;
    //     this.stressFillMesh.isVisible = true;
    // }

    dispose=()=>{
        if(!this.stressFillMesh) return false
        if(this.stressFillMesh && this.stressFillMesh.isDisposed()) return false;
        this.stressFillMesh.dispose();
        return true;
    }
    disableStressBar=()=>{
        if(!this.stressFillMesh) return false;
        if(this.stressFillMesh && !this.stressFillMesh.isEnabled()) return false;
        this.stressFillMesh.setEnabled(false);
        return true;
    }
    enable=()=>{
        if(!this.stressFillMesh) return false;
        if(this.stressFillMesh && this.stressFillMesh.isEnabled()) return false;
        this.stressFillMesh.setEnabled(true);
        return true;
    }
    isEnabled=()=>{
        if(!this.stressFillMesh) return false;
        return this.stressFillMesh.isEnabled()
    }

    isDisposed=()=>{
        if(!this.stressFillMesh) return true;
        return this.stressFillMesh.isDisposed();
    }
}

export default StressBar;
