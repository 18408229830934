import { Vector3 } from "@babylonjs/core";

function isPointNeighborOfCell(cell: Vector3, point: Vector3, cellSize: number = 1): boolean {
    // const halfCellSize = cellSize / 2;
    const maxDifference = cellSize;
    // console.log("Max Difference: ", maxDifference);
    // console.log("X Difference: ", Math.abs(point.x-cell.x));
    // console.log("Z Difference: ", Math.abs(point.z-cell.z));
    // console.log("X Difference: ", Math.abs(point.x-cell.x));
    // console.log("X Difference condition: ", (Math.abs(point.x-cell.x)<= maxDifference));
    // console.log("Z Difference condition: ", (Math.abs(point.z-cell.z)<= maxDifference));
    // console.log("Ranges Condition: ", isWithinXRange && isWithinZRange);
    const isWithinXRange = (Math.abs(point.x-cell.x)<= maxDifference);
    const isWithinZRange = (Math.abs(point.z-cell.z)<= maxDifference);
    return isWithinXRange && isWithinZRange;
}

export default isPointNeighborOfCell