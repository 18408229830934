import artificialIntelligence from '../../assets/artificialIntelligence.png';

const AIExplanation = (props: any) => {

  const { isMobile } = props;
  const Text = (props)=>{
    return(
      <div style={{marginTop: '2.5%', marginBottom: '2.5%', marginLeft: '2.5%', marginRight: '2.5%', ...props.style}}>
        {props.children}
      </div>
    )
  }
  const ALIResearchProgram = ({ isMobile }: any) => {
    return (
      <div style={{}}>
        <Text style={{ fontSize: '1.2em', fontWeight: '600', marginTop: 0, textAlign: 'center' }}>
        Program in short
        </Text>
        <ol style={{marginRight: '1em'}}>
          <li>Create an Eleatic Game, or use HGoE</li>
          <li>Attempt to create AI agents capable of creating and maintaining an indefinite number of objects</li>
          <li>Study the relationship between Open-Ended Evolutionary Dynamics, the rules of the Eleatic Game, and the ability of the AI agent(s).</li>
          <li>Investigate how different Eleatic games affect the capacity of the AI agents to achieve open-ended evolution.</li>
        </ol>
      </div>
    );
  };
  const Explanation=()=>{
    return(
    <div style={{
      width: isMobile ? '100%' : '50%',
      // maxWidth: '850px',
      textAlign: 'justify',
      fontWeight: '500',
      backgroundColor: '#E6CCA1',
      color: 'black',
      // paddingLeft: '5%',
      // paddingRight: '5%',
    }}>
      
        <ALIResearchProgram/>
        <Text style={{fontSize: '1.2em', fontWeight: '600', marginTop: 0,marginBottom: 0, textAlign: 'center',}}>
          Motivation
        </Text>
        <Text>
          A goal of many Artificial Intelligence researchers is to create an “AI Agent” that is capable of increasing its problem-solving capabilities indefinitely. This is often referred to as <a href={'https://en.wikipedia.org/wiki/Open-endedness'}>“Open-Endedness”</a> and less so as <a href='https://www.amazon.com/Open-Ended-Intelligence-Weaver-D-R-Weinbaum/dp/B09QP2318F'>“Open-Ended Intelligence”</a>.
        </Text>
        <Text>
          To do this, researchers often create an AI Agent by some means and put it inside a virtual environment in which it must solve some tasks. The dilemma is that as the AI Agent becomes more intelligent, it masters all the tasks in the environment, which requires the researcher to generate yet more complex tasks.
        </Text>
        <Text>
          Thus, arguments have been made for creating environments that become more difficult along with the AI Agent as it gets better. While seemingly straightforward, the problem of how to continuously complexify the environment along with the agent, without manual input from the researcher has been elusive.
        </Text>
        <Text>
          Eleatic Game Design provides a novel approach for creating environments that naturally generate more complex tasks as the AI Agent gets better. Eleatic games define the physics, or “stuff” that can make up objects in an environment, rather than the environment or objects themselves.
        </Text>
        <Text>
          Indeed, the “goal” of an Eleatic Game is only to persist one's existence by creating objects and maintaining the existence of those objects. An Eleatic Game can be turned into an increasingly complex game by giving the AI Agent the additional goal of maximizing the number of diverse objects.
        </Text>
        <Text>
          In this case, the AI Agent has to learn how to create objects, differentiate between them, and keep them in existence despite the capacity of the objects to interact with each other, reproduce, and even create other novel objects. The result is that an Eleatic Game is only as difficult as the player is skilled at learning.
        </Text>
        <Text>
          By pressuring the AI Agent player to create novel objects with hard-to-predict consequences, we force it to adapt to the ensuing circumstances. As such, AI Agent players of Eleatic Games have the capacity to attain and display open-ended intelligence by driving and controlling the open-ended evolutionary behavior within a virtual world.
        </Text>
        <Text>
          Specifics, such as how to go about creating such AI Agents, are discussed extensively in the paper section <strong>"Eleatic Players – Creating artificially intelligent players for eleatic games".</strong>
        </Text>
    </div>
    )
  }
  const NetworkImage = ()=>{
    return(
    <div style={{
      minHeight: isMobile ? '250px' : 'none',
      // minHeight: isMobile ? '250px' : '500px',
        width: isMobile ? '100%' : '35%',
        backgroundPositionY: isMobile ? '40%' : 0,
        position: 'relative',
        bottom: '10px',
        backgroundImage: `url(${artificialIntelligence})`,
        backgroundSize: isMobile ? 'cover' : 'contain',
        backgroundRepeat:'no-repeat',

      }}/>
    )
}
  return (
    <div style={{
      
      display: 'flex',
      flexDirection: isMobile ? 'column-reverse' : 'row',
      justifyContent: isMobile ? 'center' : 'space-around',

    }}>
      <Explanation/>
      <NetworkImage/>
    </div>
  );
};



export default AIExplanation
