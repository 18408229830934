import { Vector2, Vector3 } from "@babylonjs/core";

// const parseCoordinateString = (coordString: string): Vector3[] => {
//     const coordPairs = coordString.split('-');
//     const parsePair = (pair: string): Vector3 => {
//         const coords = pair.match(/\(([^)]+)\)/)[1].split(',');
//         const x = parseFloat(coords[0].trim());
//         const z = parseFloat(coords[1].trim());
//         return new Vector3(x, 0, z); // Assuming y = 0 as it is not specified
//     };
//     return coordPairs.map(parsePair);
// };
// function parseCoordinateString(coordString: string): Vector3[] {
//     // Split the string by '-'
//     const coordPairs: string[] = coordString.split('-');

//     // Function to extract x and z from the coordinate pair
//     const parsePair = (pair: string) => {
//         const matchArray = pair.match(/\(([^)]+)\)/);
//         if (!matchArray || matchArray.length < 2) {
//             console.error("Coordinate pair was probably single position:", pair);
//             console.error("Full coordinate string: ", coordString);
//             const coords = coordString.split(',');
//             const x = parseFloat(coords[0].trim());
//             const z = parseFloat(coords[1].trim());
//             return new Vector3(x,0,z);
//             throw new Error("Invalid coordinate pair format");
//         }
//         const coords = matchArray[1].split(',');
//         if (coords.length < 2) {
//             console.error("Failed to extract coordinates: ", matchArray[1]);
//             throw new Error("Coordinate pair does not contain two values");
//         }
//         const x = parseFloat(coords[0].trim());
//         const z = parseFloat(coords[1].trim());
//         if (isNaN(x) || isNaN(z)) {
//             console.error("Invalid number format in coordinate pair: ", coords);
//             throw new Error("Invalid number format in coordinates");
//         }
//         return new Vector3(x, 0, z); // Assuming y = 0 as it is not specified
//     };

//     // Map each coordinate pair to a Vector3 object
//     const vectors = coordPairs.map(parsePair);

//     return vectors;
// }

// function parseCoordinateString(input: string): Vector3[] {
//     const coordinatePattern = /^\((\-?\d+(\.\d+)?),(\-?\d+(\.\d+)?)\)(-\((\-?\d+(\.\d+)?),(\-?\d+(\.\d+)?)\))*$/;

//     if (!coordinatePattern.test(input)) {
//         throw new Error("Invalid format. The string must be in the form (x1,z1)-(x2,z2)-(x3,z3)...");
//     }

//     const vector3Array: Vector3[] = [];
//     const coordinatePairs = input.split('-');

//     coordinatePairs.forEach(pair => {
//         const match = pair.match(/\((\-?\d+(\.\d+)?),(\-?\d+(\.\d+)?)\)/);
//         if (match) {
//             const x = parseFloat(match[1]);
//             const z = parseFloat(match[3]);
//             vector3Array.push(new Vector3(x, 0, z));
//         }
//         else{
//             console.log("No match for input: ", input);
//         }
//     });

//     return vector3Array;
// }

function parseCoordinateString(input: string): Vector3[] {
    // Regular expression to match individual coordinate pairs including negative coordinates
    const pairPattern = /\((-?\d+(\.\d+)?),(-?\d+(\.\d+)?)\)/g;

    const vector3Array: Vector3[] = [];
    let match: RegExpExecArray | null;

    // Use a loop to find all matches in the input string
    while ((match = pairPattern.exec(input)) !== null) {
        const x = parseFloat(match[1]);
        const z = parseFloat(match[3]);
        vector3Array.push(new Vector3(x, 0, z));
    }

    // Check if no valid pairs were found and handle the error if needed
    if (vector3Array.length === 0) {
        throw new Error("Invalid format. The string must be in the form (x1,z1)-(x2,z2)-(x3,z3)...");
    }

    return vector3Array;
}


export default parseCoordinateString