import { Color3, Vector3 } from "@babylonjs/core";
import GameOfExistence from "./GameOfExistence";
import GridCell from "./GridCell";
import { EnergyDirection, ExistingObject } from "../types";
import Direction from "./Direction";
import convertDirectionArrayToString from "../helpers/convertDirectionArrayToString";
import getOppositeDirectionArrayFromArray from "../helpers/getOppositeDirectionArrayFromArray";
import isPointNeighborOfCell from "../helpers/isPointNeighborOfCell";
import getMidpoint from "../helpers/getMidpoint";
import makeDebugBox from "../helpers/makeDebugBox";
import calculateDirectionPosition from "../helpers/calculateDirectionPosition";
import parseCoordinateString from "../helpers/parseCoordinateString";


export default class CausalField {
    GOE: GameOfExistence;
  
    directions: {
        [key:string]: Direction
    } = {};

    constructor(GOE: GameOfExistence){
        this.GOE = GOE;
    }

    dissipateAllDirections(): Promise<void> {
        return new Promise((resolve, reject) => {

            Object.values(this.directions).forEach((direction: Direction) => {
                if (!direction) return;
                direction.dissipation.dissipate("because commanded to");
            });
    
            const checkDissipation = () => {
                const allDissipated = Object.values(this.directions).every(
                    (direction: Direction) => !direction || direction.dissipation.dissipated
                );
                if (allDissipated) {
                    resolve();
                } else {
                    setTimeout(checkDissipation, 10); // Check again after 100ms
                }
            };
    
            checkDissipation();
        });
    }
    

    getRandomPossibleDirection=(neighbors: ExistingObject[]): string[] | null=>{
        if(!neighbors.length) throw new Error("No objecs exist to dissipate to when creating new direction!");
        const checkedPairs = new Set<string>();

        while (true) {
            if (neighbors.length < 2) {
                // Single neighbor must be the object
                // return neighbors[0] || null;
                return null;
            }

            // Randomly pick two different neighbors
            const index1 = Math.floor(Math.random() * neighbors.length);
            let index2 = Math.floor(Math.random() * neighbors.length);

            //Ensure neighbors are different
            while (index1 === index2) {
                index2 = Math.floor(Math.random() * neighbors.length);
            }

            //Get neighbors
            const neighbor1 = neighbors[index1];
            const neighbor2 = neighbors[index2];

            // Create a unique key for the pair to track checked pairs
            const pairKey = [neighbor1.id, neighbor2.id].sort().join('-');

            if (checkedPairs.has(pairKey)) {
                // If all possible pairs have been checked, exit the loop
                if (checkedPairs.size === (neighbors.length * (neighbors.length - 1)) / 2) {
                    //No valid pairs, so just randomly pick a neighbor
                    // const index = Math.floor(Math.random() * neighbors.length);
                    // return neighbors[index]; // No valid pairs found
                    return null;
                }
                continue; // Skip already checked pairs
            }

            checkedPairs.add(pairKey);

            // Check if they are neighbors of each other
            if (isPointNeighborOfCell(neighbor1.getPosition(), neighbor2.getPosition())) {
                const directionArray = [neighbor1.id, neighbor2.id];
                return directionArray;
            }
        }
    }

    getOrCreateGridCellWithTargetPosition=(targetPosition: Vector3)=>{
        const index = `(${targetPosition.x},${targetPosition.z})`;
        const isExistingGridCell = this.GOE.grid.cellData[index]
        if(isExistingGridCell){
            // console.log(`Returning grid cell ${index} as existing grid cell`)
            return isExistingGridCell;
        }
        const gridCell = new GridCell({
            GOE: this.GOE,
            index,
            position: targetPosition,
            initEnergyLevel: 0,
            visibility: true,
        });
        // gridCell.renderGridCell();
        this.GOE.grid.cellData[index] = gridCell;
        // console.log(`Grid cell ${index} created from target position`);
        // makeDebugBox(new Color3(1,.30,0), gridCell)
        return gridCell;
    }

    getOrCreateGridCellForMomentum=(position: Vector3)=>{
        return this.getOrCreateGridCellWithTargetPosition(position);
    }
    
    updateSelf=()=>{
        this.updateDirections();
    }

    updateDirections = ()=>{
        // if(this.justCreated) return;
        const directions = Object.values(this.directions);
        if(!directions.length) return;
        Object.values(this.directions).forEach((direction: Direction)=>{
            direction.updateSelf();
        });
    }


    private createNovelDirectionAndItsOpposite=(directionArray: EnergyDirection)=>{
        // console.log("Creating direction array: ",  directionArray);
        // console.log("GOE: ", this.GOE);
        const forwardDirection = this.createNovelDirection(directionArray);
        if(!forwardDirection) return;
        const oppDirectionArray = getOppositeDirectionArrayFromArray(directionArray);
        const possibleOppositeDirection = this.getDirection(oppDirectionArray);
        if(!possibleOppositeDirection) this.createNovelDirection(oppDirectionArray);
        return forwardDirection
    }

    private createNovelDirection=(directionArray: EnergyDirection)=>{
        // console.log("Creating direction array: ",  directionArray);
        // console.log("GOE: ", this.GOE);
        const directionString = convertDirectionArrayToString(directionArray);
 
        const source = this.GOE.getObjectById(directionArray[0]);
        const target = this.GOE.getObjectById(directionArray[1]);

        if(!source || !target){
            // console.log("Source: ", source);
            // console.log("Target: ", target);
            console.warn(`Source ${directionArray[0]}`);
            console.warn(`Target: ${directionArray[1]}`);
            console.warn(`Source or target didn't exist creating ${directionArray[0]}-${directionArray[1]}`);
            return null;
        }
        const forwardPosition = calculateDirectionPosition(source, target);
        const forwardDirection = new Direction({
            id: directionString,
            GOE: this.GOE,
            causalField: this,
            directionIndex: directionString,
            directionArray,
            position:forwardPosition,
        });
        this.directions[directionString] = forwardDirection
        return forwardDirection
    }

    public getOrCreateDirection = (directionArray: EnergyDirection)=>{
        const directionIndex = convertDirectionArrayToString(directionArray);
        //This ensures that neighbors don't create the same directions.
        const direction = this.directions[directionIndex];
        // if(directionArray[0] === null) console.warn("Direction array is null: ", directionArray);
        // if(!direction) console.log("Direction doesn't exist yet!")
        if(directionArray[0] === null){
            console.log("Direction: ", directionArray);
            throw new Error("Direction array is null on getOrCreateDirection");
        }
        // if(directionArray[0] === null) return null;
        if(!direction) return this.createNovelDirectionAndItsOpposite(directionArray);
        return direction;
    }
    public getDirection = (directionArray: EnergyDirection): Direction | null =>{
        const directionIndex = convertDirectionArrayToString(directionArray);
        const direction = this.directions[directionIndex];
        // throw new Error("Direction array is NULL");
        // if(directionArray[0] === null) throw new Error("Direction array is NULL");
        if(directionArray[0] === null) return null;
        if(!direction) return null;
        return direction;
    }
    public getDirectionById = (id: string): Direction | null =>{
        const direction = this.directions[id];
        // throw new Error("Direction array is NULL");
        // if(directionArray[0] === null) throw new Error("Direction array is NULL");
        if(!id) return null;
        if(!direction) return null;
        return direction;
    }

}

