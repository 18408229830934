import { EnergyDirection } from "../types";
import stripSpatialTagFromString from "./stripSpatialTagFromString";


const stripSpatialTag = (arr: EnergyDirection): EnergyDirection=>{
    return arr.map((id: string)=>{
        if(!id) return id;
        return stripSpatialTagFromString(id)
        // return id.replace(/-SA/g, "");
    })
}

export default stripSpatialTag;