import { Color3, Color4, MeshBuilder, StandardMaterial, Vector3 } from "@babylonjs/core";
import { SPEED_OF_LIGHT } from "../constants";
import calculateDistanceBetweenPoints from "../helpers/calculateDistanceBetweenPoints";
import changeBoxColor from "../helpers/changeBoxColor";
import convertDirectionArrayToString from "../helpers/convertDirectionArrayToString";
import getMidpoint from "../helpers/getMidpoint";
import isPointNeighborOfCell from "../helpers/isPointNeighborOfCell";
import returnTrueWithProbability from "../helpers/returnWithProbability";
import { EnergyDirection, ExistingObject, Task } from "../types";
import Direction from "./Direction";
import GameOfExistence from "./GameOfExistence";
import GridCell from "./GridCell";
import makeDebugBox from "../helpers/makeDebugBox";
import isSourceAndTargetIdentical from "../helpers/isSourceAndTargetIdentical";
import isLessThanMinimumDistance from "../helpers/isLessThanMinimumDistance";

type NeighborSourcePair = {
    correlate: Direction,
    source: Direction | GridCell;
}

class Correlator {
    direction: Direction;
    GOE: GameOfExistence;
    lastTimeReacted: number = 0;
    constructor(GOE: GameOfExistence, direction: Direction){
        this.GOE = GOE;
        this.direction = direction;
    }

    private getEnergyLevel=()=>{
        return this.direction.getEnergyLevel();
    }
    private isCorrelating=()=>{
        const isCorrelating = this.direction.isCorrelating();
        const oppObj = this.direction.getOppositeDirectionObject();
        return isCorrelating && !this.direction.justCreated && !oppObj.justCreated;
    }
    private doesTargetHaveEnergy=()=>{
        const target = this.getDirectionTarget();
        return target.getEnergyLevel() > 0;
    }
    //If energy change is positive, gained energy, and trigger correlates
    //If change in energy is negative, lost energy, and don't trigger correlates.
    public reactToEnergyChange=()=>{
        if(!this.isCorrelating()){
            // console.log(`Direction ${this.direction.id} is not correlaing actually!`)
            return;
        }
        
        if(!this.hasClockTicked()){
            const deltaTime = this.GOE.gameTime - this.direction.lastTimeEnergyMoved;
            // console.log(`Clock hasn't ticked for ${this.direction.id}`)
            return;
            // console.log("CORRELATOR ENFORCED CLOCK TIME")
            // console.log(`Correlator ${this.direction.id} - Delta time: `, deltaTime);
            // console.log(`Correlator ${this.direction.id} - Clock tick length: `, this.direction.clockTickLength)
            // console.log(`Correlator ${this.direction.id} - Last Time Energy Moved: `, this.direction.lastTimeEnergyMoved)
            return;
        }
        // const deltaTime = this.GOE.gameTime - this.direction.lastTimeEnergyMoved;
        // console.log(`Correlator ${this.direction.id} - Delta time: `, deltaTime);
        // console.log(`Correlator ${this.direction.id} - Clock tick length: `, this.direction.clockTickLength)
        // console.log(`Correlator ${this.direction.id} - Last Time Energy Moved: `, this.direction.lastTimeEnergyMoved);

        if(!this.doesTargetHaveEnergy()){
            // console.log(`Direction ${this.direction.id} believes target has no energy!`)
            return;
        }
        // if(!this.doesTargetHaveEnergy()) return this.dissipateDirection();
        //TODO: Take all the criteria for moving energy and move it here via a function
        if(!this.direction.hasParticlesOutOfQueue && !this.direction.justCreated){
            // console.log(`Direction ${this.direction.id} had no particles out of queue and was not just created`)
            return;
        };
        if(!this.hasReactionClockTicked()){
            // console.log(`Correlator clock hasn't ticked for ${this.direction.id}`)
            return;
        };
        // console.warn(`Direction ${this.direction.id} react hasn't ticked - lastTimeReacted: `, this.lastTimeReacted);
        this.updateLastTimeReacted();

        // const { energyCapacity } = this.direction;
        // if(energyCapacity.isTaskImpossible(this.direction)) return this.dissipateDirection();
        // console.log("Reacting to energy change");
        // console.warn(`Direction ${this.direction.id} GameTime is: `, this.GOE.gameTime);
        // console.warn(`Direction ${this.direction.id} clock hasn't ticked - lastTimeEnergyMoved: `, this.direction.lastTimeEnergyMoved);
        if(this.newAttemptConstructionTask()){
            // makeDebugBox(new Color3(0,0,1), this)
            // console.log(`Direction ${this.direction.id} did construction task`);
            // createBitFlipSignal=()=>{
                // if( this.bitFlipSignal ) return;
                // console.log(`Creating dissipation signal for ${this.id}`)
                // var box = MeshBuilder.CreateBox(this.direction.id+'correlator', { size: .1 }, this.GOE.scene);
                // changeBoxColor(box, new Color4(.1,.1,1,1))
                // // this.bitFlipSignal = box;
                // const {x,y,z} = this.direction.getPosition();
                // box.position = new Vector3(x,y,z);
            // }
            // throw new Error("Construction task completed")
            return;
        };
        // console.warn(`Direction ${this.direction.id} doing normal task after failing construction`);
        this.moveEnergyFromTargetToSource();
    }
    //A constructor is by definition, two directions that share the same target like  ( < )
    //The type of target doesn't matter. At least, why not? 
    private getNeighborSourcePairs = ()=>{
        // console.log("Correlating with: ", this.direction.target.correlatingWith);
        return this.direction.target.correlatingWith.map((id: string)=>{
            const targetCorrelator = this.GOE.causalField.directions[id];
            if(!targetCorrelator) return null;
            if(targetCorrelator.id ===this.direction.id) return null;
            const sourceCorrelator = targetCorrelator.getOppositeDirectionObject();
            // console.log("Target Correlator: ", targetCorrelator);
            // console.log("Source Correlator: ", sourceCorrelator);
            if(!sourceCorrelator) return null;
            return {correlate: targetCorrelator, source: sourceCorrelator.target}
        }).filter((obj)=>obj);
    }

    private getNeighboringNeighborSourcePairs = (correlatedObjects: {[key: string]: GridCell | Direction}[])=>{
        const neighborPairs = [];
        // console.log("Ids Signaled To: ", idsSignaledTo);
        correlatedObjects.forEach((correlateObjectPair, index) => {
            const { target: correlatedObject } = correlateObjectPair
            for (let nextIndex = index + 1; nextIndex < correlatedObjects.length; nextIndex++) {
                const {target: potentialNeighbor} = correlatedObjects[nextIndex];
                // console.log("Correlated Object target: ", correlatedObject)
                // console.log("Potential neighbor target: ", potentialNeighbor)
                const isLocal = isPointNeighborOfCell(correlatedObject.getPosition(), potentialNeighbor.getPosition());
                if(isLocal) neighborPairs.push([correlateObjectPair, correlatedObjects[nextIndex]]);
            }
        });
        return neighborPairs;
    }

    private isConstructionGate=(neighbor: Direction)=>{
        const neighborSource = neighbor.source;
        const thisSource = this.direction.source;
        return isPointNeighborOfCell(thisSource.getPosition(), neighborSource.getPosition());
    }

    private getClosestNeighbors = (): NeighborSourcePair[]=>{
        const neighborSourcePairs = this.getNeighborSourcePairs();
        if (neighborSourcePairs.length === 0){
            // console.log(`Direction ${this.direction.id} has NO neighborSourcePairs`);
            return null;
        }
        if (neighborSourcePairs.length === 1){
            // console.log(`Direction ${this.direction.id} has one neighborSourcePair`);
            //Is Construction Gate
            const neighborSource = neighborSourcePairs[0].correlate;
            const isConstructionGate = this.isConstructionGate(neighborSource);
            if(!isConstructionGate) return null;
            return neighborSourcePairs;
        };
    
        let minDistance = Infinity;
        let closestPair = [];
    
        neighborSourcePairs.forEach((correlateObjectPair, index) => {
            const { correlate: correlatedObject } = correlateObjectPair;
            for (let nextIndex = index + 1; nextIndex < neighborSourcePairs.length; nextIndex++) {
                const { correlate: potentialNeighbor } = neighborSourcePairs[nextIndex];

                const distance = calculateDistanceBetweenPoints(correlatedObject.getPosition(), potentialNeighbor.getPosition());
                const isConstructionGateOne = this.isConstructionGate(correlatedObject);
                const isConstructionGateTwo = this.isConstructionGate(potentialNeighbor);

                if ( (distance < minDistance) && isConstructionGateOne && isConstructionGateTwo) {
                    minDistance = distance;
                    closestPair = [correlateObjectPair, neighborSourcePairs[nextIndex]];
                }
            }
        });
    
        if (closestPair.length === 0) {
            // console.warn(`Direction ${this.direction.id} has no closest pair!`)
            // console.warn("NeighborSourcePairs: ", neighborSourcePairs);

            const singularConstructionGates = neighborSourcePairs.filter((correlateObjectPair, index)=>{
                const { correlate: correlatedObject } = correlateObjectPair;
                const isConstructionGate = this.isConstructionGate(correlatedObject);
                // console.log(`Is construction gate with ${correlatedObject.id}? `, isConstructionGate)
                return isConstructionGate
            });
            if(!singularConstructionGates.length) return null;
            // console.log("Singular construction gates: ", singularConstructionGates);
            const randomSingularConstructionGate = singularConstructionGates[Math.floor(Math.random()*singularConstructionGates.length)];
            return [randomSingularConstructionGate];
            // console.log("Random singular construction gate: ", randomSingularConstructionGate);
            // alert("Created debug box for single construction")
            // return undefined;
            // console.log("NeighborSourcePairs: ", JSON.stringify(neighborSourcePairs));
            // return; //Psure this means a dual construction gate couldn't be found, but we could look for singles.
            // console.log("No valid pairs returning null");
            // return neighborSourcePairs;  // No valid pairs, return the first object
        }
    
        // console.log("Returning closest pairs")
        return closestPair;
    }

    // getClosestNeighbor = (referenceObject, neighbor1, neighbor2) => {
    //     const distanceToNeighbor1 = calculateDistanceBetweenPoints(referenceObject.getPosition(), neighbor1.getPosition());
    //     const distanceToNeighbor2 = calculateDistanceBetweenPoints(referenceObject.getPosition(), neighbor2.getPosition());
    
    //     return distanceToNeighbor1 < distanceToNeighbor2 ? neighbor1 : neighbor2;
    // }

    private onBalancedNeighborConstruction = (neighbors: NeighborSourcePair[])=>{
        // throw new Error("FIX BALANCED NEIGHBOR CONSTRUCTION");
        // return false;
        const thisEnergy = this.direction.getEnergyLevel();
        
        const neighborOne = neighbors[0]
        const neighborTwo = neighbors[1]
        const thisSource = this.getDirectionSource();
        if(!neighborOne || !neighborTwo || !thisSource) return false;
        const canNeighborOneConstruct = this.canNeighborConstruct(neighborOne.correlate);
        const canNeighborTwoConstruct = this.canNeighborConstruct(neighborTwo.correlate);
        if(!canNeighborOneConstruct || !canNeighborTwoConstruct) return false;
        // //If single neighbor can construct
        // if(canNeighborOneConstruct && !canNeighborTwoConstruct){
        //     console.log("Only neighbor ONE could construct");
        //     this.moveEnergyFromTargetToNovelDirection([neighborOne.source.id, thisSource.id], thisEnergy);
        //     const box = makeDebugBox(new Color3(1,0,0), this);
        //     box.position.y = 1;
    
        //     return true;
        // }
        // //If single neighbor can construct
        // if(canNeighborTwoConstruct && !canNeighborOneConstruct){
        //     console.log("Only neighbor TWO could construct")
        //     this.moveEnergyFromTargetToNovelDirection([neighborTwo.source.id, thisSource.id], thisEnergy);
        //     const box = makeDebugBox(new Color3(1,0,0), this);
        //     box.position.y = 1;
        //     return true;
        // }
        // //If both neighbors can construct
        // return false;
        const energyPerNeighbor = Math.floor(thisEnergy / 2);
        const remainder = thisEnergy % 2;
        const isHeads = Math.round(Math.random()) > .5 ? true : false;
        const energyForNeighborOne = isHeads ? energyPerNeighbor + remainder : energyPerNeighbor
        const energyForNeighborTwo = !isHeads ? energyPerNeighbor + remainder : energyPerNeighbor
        //Sending equal energy to each on average.
        this.moveEnergyFromTargetToNovelDirection([neighborOne.source.id, thisSource.id], energyForNeighborOne);
        this.moveEnergyFromTargetToNovelDirection([neighborTwo.source.id, thisSource.id], energyForNeighborTwo);
        return true;
    }

    private canNeighborConstruct=(obj: Direction): boolean=>{
        return true;
        // const neighborClockHasTicked = obj.hasClockTicked();
        // return neighborClockHasTicked;
        // const neighborHasReacted = obj.correlator.hasReactionClockTicked();
        // const neighborIsFresh = !neighborHasReacted && neighborClockHasTicked;
        // // const neighborCanBeReDirected = neighborHasReacted && !neighborClockHasTicked
        // return neighborIsFresh;
    }
    private newAttemptConstructionTask = ()=>{
        // console.warn(`Direction ${this.direction.id} attempting construction task`)
        const closestNeighbors = this.getClosestNeighbors();
        if(!closestNeighbors){
            // console.log(`Direction ${this.direction.id} failed construction task with no closest neighbors!`)
            return false;
        }
        // console.log("Closest Neighbors: ", closestNeighbors);
        // const moreThanOneNeighbor = closestNeighbors.length > 1;
        // const closestNeighbor =  moreThanOneNeighbor ? this.getClosestNeighbor(this.direction, closestNeighbors[0], closestNeighbors[1]) : closestNeighbors[1];

        const thisCorrelateObjPair = {correlate: this.direction, target: this.direction.target}
        const sortedEnergies = [thisCorrelateObjPair, ...closestNeighbors].sort((neighbor1: NeighborSourcePair, neighbor2: NeighborSourcePair)=>{
            return neighbor2.correlate.getEnergyLevel() - neighbor1.correlate.getEnergyLevel()
        });

        const largestEnergyValue = sortedEnergies[0].correlate.getEnergyLevel();
        const isAnchorEnergy = this.direction.getEnergyLevel() >= largestEnergyValue
        const equalEnergyNeighbors = closestNeighbors.filter(obj => obj.correlate.getEnergyLevel() === largestEnergyValue);
        // console.log(`Direction ${this.direction.id} energy: `, this.direction.getEnergyLevel());
        // console.log("Largest Energy: ", largestEnergyValue);
        
        // this.makeDebugBox(new Color3(0,0,1));
        // Determine the action based on energy levels and neighbor conditions
        if(isAnchorEnergy && !equalEnergyNeighbors.length){
            // console.log(`Anchor energy ${this.direction.id} doing normal activity`);
            this.moveEnergyFromTargetToSource();
            return true;
        }
        if (isAnchorEnergy && equalEnergyNeighbors.length) {
            const equalEnergyNeighbors = closestNeighbors.filter(obj => obj.correlate.getEnergyLevel() === largestEnergyValue);
            // console.log(`Direction ${this.direction.id} is anchor with ${equalEnergyNeighbors.length} equal energy neighbors!`);

            // console.log(`Direction ${this.direction.id} Equal energy neighbors: `, equalEnergyNeighbors);
            // console.log("largest energy: ", largestEnergyValue);
            // console.log("Neighbor: ", equalEnergyNeighbors[0].correlate.getEnergyLevel());

            if(equalEnergyNeighbors.length === 1){
                // console.log(`Anchor energy ${this.direction.id} has single equal neighbor`);
                const neighborTarget = equalEnergyNeighbors[0].source
                const thisSource = this.getDirectionSource();
                if(!neighborTarget || !thisSource){
                    // console.log("Failed because neighbor target, or this source is non existent")
                    return false;
                }
                if (isLessThanMinimumDistance(thisSource.getPosition(), neighborTarget.getPosition())) return false;

                const canNeighborConstruct = this.canNeighborConstruct(equalEnergyNeighbors[0].correlate);
                if(!canNeighborConstruct){
                    // console.log("Failed because neighbor could not construct");
                    return false;
                }
                this.moveEnergyFromTargetToNovelDirection([thisSource.id, neighborTarget.id]);
                return true;
            }

            if(equalEnergyNeighbors.length === 2){
                //Only capable of construction of correlated bit with one neighbor.
                //Multiple neighbors "balances them all out" so to speak
                // console.log(`Anchor energy ${this.direction.id} has two equal neighbors - causing failure`);
                return false;
                // return this.onBalancedNeighborConstruction(equalEnergyNeighbors);
            }
            throw new Error ("Condition not satisfied for anchor energy with equal neighbors");
        }
        if(!isAnchorEnergy){
            if(closestNeighbors.length === 1){
                // console.log(`Energy ${this.direction.id} has single unequal neighbor`);
                const neighborTarget = closestNeighbors[0].source
                const thisSource = this.getDirectionSource();
                if(!neighborTarget || !thisSource){
                    // console.log("Not neighbortarget or thissource")
                    return false;
                }
                const canNeighborConstruct = this.canNeighborConstruct(closestNeighbors[0].correlate);
                if(!canNeighborConstruct){
                    // console.log("Neighbor could not construct")
                    return false;
                }
                if (isLessThanMinimumDistance(thisSource.getPosition(), neighborTarget.getPosition())) return false;
                this.moveEnergyFromTargetToNovelDirection([neighborTarget.id, thisSource.id]);
                return true;
            }
            if (closestNeighbors.length === 2) {
                const neighborOne = closestNeighbors[0]
                const neighborTwo = closestNeighbors[1]
                const thisSource = this.getDirectionSource();
                if(!neighborOne || !neighborTwo || !thisSource) return false;
                const areBalanced = neighborOne.correlate.getEnergyLevel() === neighborTwo.correlate.getEnergyLevel();
                if(areBalanced){
                    // console.log(`Anchor energy ${this.direction.id} has two equal neighbors`);
                    return false;
                    // return this.onBalancedNeighborConstruction(closestNeighbors);
                }
                else{
                    // console.log(`Energy ${this.direction.id} has two unequal neighbors`);
                    const neighborOneEnergy = neighborOne.correlate.getEnergyLevel();
                    const neighborTwoEnergy = neighborTwo.correlate.getEnergyLevel();
                    const isNeighborOneGreater = neighborOneEnergy > neighborTwoEnergy;
                    const neighborToSendEnergyTo = isNeighborOneGreater ? neighborOne : neighborTwo;
                    const canNeighborConstruct = this.canNeighborConstruct(neighborToSendEnergyTo.correlate);
                    if(!canNeighborConstruct) return false;
                    if (isLessThanMinimumDistance(thisSource.getPosition(), neighborToSendEnergyTo.source.getPosition())) return false;
                    this.moveEnergyFromTargetToNovelDirection([neighborToSendEnergyTo.source.id, thisSource.id]);
                    return true;
                }
            }
        }
    }
  
    private hasReactionClockTicked=()=>{
        const deltaTime = this.GOE.gameTime - this.lastTimeReacted;
        if(deltaTime > this.direction.clockTickLength) return true;
        return false;
    }
    private hasClockTicked = ()=>{
        return this.direction.hasClockTicked();
    }
    private moveEnergyFromTargetToSource=()=>{
        // console.warn(`Direction ${this.direction.id} moving energy from target to source`);
        // makeDebugBox(new Color3(0,1,0), this);
        if(!this.isCorrelating()) return;
        const source = this.getDirectionSource();
        const target = this.getDirectionTarget();
        this.moveEnergy(target, source)
    }
    private moveEnergyFromTargetToNovelDirection=(directionArray: EnergyDirection, energyToDeliver?: number)=>{
        if(!this.isCorrelating()) return;
        // console.log(`Delivering energy: `, energyToDeliver);
        // const directionArray = this.getTargetDirectionArray();
        // console.log(`Direction ${this.direction.id} is creating direction on move: `, directionArray)
        const isIdentical = isSourceAndTargetIdentical(directionArray);
        if(isIdentical) throw new Error("moveEnergyFromTargetToNovelDirection - Source and target identical")
        const direction = this.GOE.causalField.getOrCreateDirection(directionArray);
        const target = this.getDirectionTarget();
        this.moveEnergy(target, direction, energyToDeliver);
    }
    private moveEnergy=(sourceObj: ExistingObject, targetObj: ExistingObject, energyToDeliver?: number)=>{
        if(!this.isCorrelating()) return;
        if(!sourceObj || !targetObj){
            return;
        }
        const animationSource = sourceObj.getPotentialObjectSettings();
        const animationTarget = targetObj.getPotentialObjectSettings();
      
        energyToDeliver = energyToDeliver ? energyToDeliver : this.getEnergyToDeliver()
        // console.log(`Correlator ${this.direction.id} - setting Last Time Energy Moved: `, this.GOE.gameTime);

        const animation: Task = {
            caller: `${this.direction.id}`,
            source: animationSource,
            target: animationTarget,
            energyToTransfer: energyToDeliver,
            type: "Correlative energy transfer"
        }
        this.GOE.mechanics.attemptTask(animation);
        this.updateLastTimeEnergyMoved();
    }
    private updateLastTimeReacted=()=>{
        this.lastTimeReacted = this.GOE.gameTime;
        const oppObj = this.direction.getOppositeDirectionObject();
        if(oppObj && oppObj.correlator) oppObj.correlator.lastTimeReacted = this.lastTimeReacted;
    }
    private updateLastTimeEnergyMoved=()=>{
        this.direction.lastTimeEnergyMoved = this.GOE.gameTime;
        // console.warn(`Last time ${this.direction.id} moved energy was: `, this.GOE.gameTime)
        const oppObj = this.direction.getOppositeDirectionObject();
        if(oppObj){
            // console.log(`${this.direction.id} set opposite obj: `, oppObj.id)
            oppObj.lastTimeEnergyMoved = this.GOE.gameTime;
            return;
        } else{
            // console.warn(`${this.direction.id} FAILED to set opp obj`)
        }
    }
    private getEnergyToDeliver=()=>{
        return this.getEnergyLevel();
    }
    private getDirectionTarget=()=>{
        return this.direction.target;
    }
    private getDirectionSource=()=>{
        return this.direction.source;
    }
    getTargetDirectionIndex=()=>{
        const source = this.getDirectionSource()
        const target = this.getDirectionTarget()
        return convertDirectionArrayToString([source.id, target.id])
    }
    


}

export default Correlator;

