import { CorrelateEnergyTransfer, DirectionSettings, EnergyTransferAnimation, ExistingObject, ObjectSelectionStates, PossibleObject, PotentialObjectSettings, Task } from '../types';
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera"
import { Engine } from "@babylonjs/core/Engines/engine"
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight"
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder"
import { Scene } from "@babylonjs/core/scene"
import { Vector3 } from "@babylonjs/core/Maths/math.vector"
import { stringify, parse } from 'flatted';

import {
    Mesh,
    Color4,
    Color3,
    SolidParticleSystem,
    Scalar,
    ExecuteCodeAction,
    ActionManager,
    SolidParticle,
    PointerEventTypes, 
    UniversalCamera,
    FreeCameraKeyboardMoveInput,
    StandardMaterial,
    PointLight,
    DirectionalLight,
    AxesViewer} from "@babylonjs/core"
import getMidpoint from "../helpers/getMidpoint"
import onWindowResize from "../helpers/onWindowResize"
import GridCell from "./GridCell"
import { PLAYER_SPEED_LIMIT, SPEED_OF_LIGHT } from "../constants"
import lerp from "../helpers/lerp"
import getConsecutiveRanges from "../helpers/getConsecutiveRanges"
import Direction from './Direction';
import SpatialActionManager from './SpatialActionManager';
import SpatialAction from './SpatialAction';
import SpatialPartioner from './SpatialPartioner';
import SpatialEnergyManager from './SpatialEnergyManager';
import EnergyCapacity from './EnergyCapacity';
import stripSpatialTag from '../helpers/stripSpatialTag';
import returnTrueWithProbability from '../helpers/returnWithProbability';
import Grid from './Grid';
import ParticleVisuals from './ParticleVisuals';
import ArrowVisuals from './ArrowVisuals';
import isPointNeighborOfCell from '../helpers/isPointNeighborOfCell';
import Momentum from './Momentum';
import CausalField from './CausalField';
import convertDirectionArrayToString from '../helpers/convertDirectionArrayToString';
import getDirectionIdFromArray from '../helpers/getDirectionIdFromArray';
import calculateDirectionPosition from '../helpers/calculateDirectionPosition';
import changeBoxColor from '../helpers/changeBoxColor';
import isSourceAndTargetIdentical from '../helpers/isSourceAndTargetIdentical';
import TaskMechanics from './TaskMechanics';
import Target from './PotentialObject';
import GameControls from './GameControls';
import PotentialObject from './PotentialObject';
import SpatialActionCreator from './SpatialActionCreator';
import SpatialActionViewer from './SpatialActionViewer';
import MeshSelector from './MeshSelector';
import Stress from './Stress';
import isObjEmpty from '../helpers/isObjEmpty';
import Tutorial from './Tutorial';


class GameOfExistence {
    id: "GOE";
    origin: any;
    grid: Grid;
    particleVisuals: ParticleVisuals;
    LIGHT_MOMENTUM: boolean = false;

    directionIndexes: {[key: string]: Direction} = {};

    horizontalPositions: any;
    verticalPositions: any;
    totalEnergy: number = 0;

    //BabylonJsStuff
    engine: Engine;
    scene: Scene;
    view: HTMLCanvasElement;
    actionManager: ActionManager;

    gameStartTime: number = 0;
    gameTime: number = 0;

    mouse: any;
    raycaster: any;
    camera:any;
    renderer: any;
    clock: any;

    isCameraLocked: boolean = true;
    lockedCameraHeight: number = 5;
    defaultSpeed = 0.05;
    
    hoverAnimation: any;
    reconveneAnimation: any;


    hoverHeight: number = .2;
    arrowGrid: Mesh[];
    selectArrow: Mesh;
    selectArrowHitbox: Mesh;

    SPS: SolidParticleSystem;

    gridMaterial: any;

    spaceBetweenHorizontalCells: number = 0;
    spaceBetweenVerticalCells: number = 0;

    lastTimeLeftPressed: number = 0;
    lastTimeRightPressed: number = 0;
    lastTimeUpPressed: number = 0;
    lastTimeDownPressed: number = 0;

    currentRange = 0; // Initialized to 0

    creatingSpatialActions: boolean = false;
    spatialMode: Boolean = false;
    spatialActions: any = {};
    possibleActions: any = {};
    spatialActionManager: SpatialActionManager;
    spatialEnergyManager: SpatialEnergyManager;
    spatialActionCreator: SpatialActionCreator;
    spatialActionViewer: SpatialActionViewer;
    spatialPartioner: SpatialPartioner;

    spatialActionsQueue: SpatialAction[] = []
    spatialActionsMemory: SpatialAction[] = []
    lastTimeSpatialActionCreated: number = 0; // Initialize the last ac

    noTime: boolean;
    energyCapacity: EnergyCapacity = null;

    arrowVisuals: ArrowVisuals = null;

    momentum: Momentum = null;

    causalField: CausalField = null;
    
    mechanics: TaskMechanics = null;
    
    controls: GameControls = null;

    moveForward: boolean = false;
    moveBackward: boolean = false;
    moveLeft: boolean = false;
    moveRight: boolean = false;

    stress: Stress = null;
    tools: any;

    tutorial: Tutorial = null;

    clearingDirections: boolean = false;

    constructor(settings: any){
        
        const tools = settings.tools ? settings.tools : this.setUpTools();
        const {view, engine, scene, camera} = tools

        // console.log("View: ", view);
        // console.log("engine: ", engine);
        // console.log("scene: ", scene);
        // console.log("camera: ", camera);
        this.tools = tools;
        this.view = view;
        this.engine = engine;
        this.origin = {x: 0, y:0,z:0};
        this.scene = scene;
        this.camera = camera;

        this.spatialActionManager = new SpatialActionManager(this);
        this.spatialEnergyManager = new SpatialEnergyManager(this);
        const scale = 1;
        const {rows, columns, cellSize} = settings.grid;
        this.spatialPartioner = new SpatialPartioner(
            this.scene,
            [this.origin.x,0,this.origin.z],
            // [this.origin.x-.5,-.5,this.origin.z-.5],
            [
                rows*cellSize*scale,
                cellSize*1,
                columns*cellSize*scale
            ],
            2,
            scale,
        )
        this.energyCapacity = new EnergyCapacity(this);
        this.grid = new Grid(this, settings.grid);
        this.particleVisuals = new ParticleVisuals(this);
        this.arrowVisuals = new ArrowVisuals(this);
        this.momentum = new Momentum(this);
        this.causalField = new CausalField(this);
        this.mechanics = new TaskMechanics(this);
        this.controls = new GameControls(this);
        this.controls.init();
        this.spatialActionCreator = new SpatialActionCreator(this);
        this.spatialActionViewer = new SpatialActionViewer(this, tools);
        this.spatialActionViewer.init()
        const advText = this.spatialActionViewer.getAdvancedTexture();
        const meshSeletor = new MeshSelector(this, this.scene, this.camera);
        this.stress = new Stress(this, advText);
        this.tutorial = new Tutorial(this, advText)
        //Just for fun


       
    };

    setLightMomentum=(val: boolean)=>{
        this.LIGHT_MOMENTUM = val;
    }

    getBabylonObject=(key: string, obj: any)=>{
        if (isObjEmpty(this.tools) || !this.tools.classes) return obj;
        const tool = this.tools.classes[key];
        return tool ? tool : obj;
        // if(!tool) return obj;
        // console.log("CHECKING OBJ: ", obj);
        // for (let key in this.tools.classes) {
        //     const tool = this.tools.classes[key];
        //     console.log("Obj prototype: ", obj.protoype);
        //     console.log("Tool prototype: ", tool.prototype);
        //     if (tool.prototype.isPrototypeOf(obj)) {
        //         console.log("RETURNING WINNER");
        //         return tool;
        //     }
        // }
        // throw new Error("Tool not found");

        // if(isObjEmpty(this.tools) || !this.tools.classes) return obj;

        // for (let key in this.tools.classes) {
        //     const tool = this.tools.classes[key];
        //     console.log("Obj Constructor name: ", obj.constructor.name);
        //     console.log("Tool name: ", tool.name);
        //     if (tool.name === obj.constructor.name) {
        //         console.log("RETURNING WINNER");
        //         return tool;
        //     }
        // }

        // for (let key in this.tools.classes) {
        //     const tool = this.tools.classes[key];
        //     console.log("Tool: ", tool);
        //     console.log("Obj: ", obj);
        //     if (tool == obj) {
        //         console.log("RETURNING WINNER");
        //         return tool;
        //     }
        // }
        // console.log("End loop");
        // for (let tool of Object.values(this.tools.classes)){
        //     // console.log("Tool constructor name: ", tool.constructor)
        //     // console.log("Tool: ", tool)
        //     // console.log("Instance of: ", tool instanceof obj)
        //     // console.log("Strict of: ", tool.constructor === obj.constructor)
        //     // console.log("Not Strict of: ", tool.constructor == obj.constructor)
        //     if(tool === obj){
        //         console.log("Returning constructor tool");
        //         console.log("Obj: ", obj);
        //         console.log("Tool: ", tool);
        //         return tool;
        //     }
        //     // if(tool === obj){
        //     //     console.log("Returning tool for: ", obj)
        //     //     return tool;
        //     // }
        // }
        // console.log("Obj: ", obj);
        // console.log("Obj constructor name: ", obj.constructor);
        throw new Error("Tool not found");
    }


    delay(ms: number): Promise<void> {
        // this.tutorial.disableNextButton();
        return new Promise<void>(resolve => {
            setTimeout(() => {
                // this.tutorial.enableNextButton();
                resolve();
            }, ms);
        });
    }

    resetParticles=async (internal?: boolean)=>{ //Convert to promise based
        if(this.clearingDirections) return;
        this.clearingDirections = true;
        this.noTime = true;

        await this.causalField.dissipateAllDirections(); //Convert to promise based
        //Will throw stack exceeded 
        // if(!isObjEmpty(this.causalField.directions)) return this.resetParticles(true);
        this.grid.resetCells(); //Convert to promise based?

        this.particleVisuals.SPS.dispose();
        this.particleVisuals.createParticleSystem();
        this.particleVisuals.calculateParticlePositions();
        this.particleVisuals.renderParticles();
        
        this.noTime = false;
        this.clearingDirections = false;
        return true;
    }

    start=()=>{
        this.grid.createGridData();
        this.grid.createGridCells();
        this.particleVisuals.createParticleSystem();
        this.particleVisuals.calculateParticlePositions();
        this.particleVisuals.renderParticles();

        // this.validateParticleAssignments();
        
        // Before every frame render
        // const reactToKeyboardPress = this.controls.moveLockedCamera.bind(this.controls);
        // console.log("moveLockedCamera: ", this.controls.moveLockedCamera);
        // console.log("reactToKeyboardPress: ", reactToKeyboardPress);
        this.scene.registerBeforeRender(this.controls.moveLockedCamera);

        // this.scene.onBeforeRenderObservable.add(()=>{
        //     this.spatialActionViewer.render()
        // })
        
        this.scene.onAfterRenderObservable.add(() => {
            
            let directionsCount = 0;
            this.spatialActionCreator.attemptToCreateSpatialAction();
            this.causalField.updateSelf();
            // console.log("Direction Count: ", directionsCount)

            this.particleVisuals.animateParticles();

        });
        this.gameStartTime=0;
        // this.scene.afterRender = ()=>{
        //     this.spatialActionViewer.previewScene.render()
        // }
        this.engine.runRenderLoop(() => {
            // getDeltaTime() is in milliseconds, so we convert to seconds
            this.gameTime += this.engine.getDeltaTime() / 1000;

               // Clear the depth buffer before rendering the secondary scene
            this.engine.clear(null, false, true, true);

            // Render the secondary scene to its texture
            // this.spatialActionViewer.render();
            // this.spatialActionViewer.renderSecondaryScene();

            // Render the main scene
            this.scene.render();

            // console.log("Game Time: ", this.gameTime)
            // this.scene.render();
            // this.spatialActionViewer.previewScene.render()
        });
 
    }

    validateParticleAssignments = () => {
        const particleSet = new Set<number>();
    
        // Check all directions
        Object.values(this.causalField.directions).forEach(direction => {
            console.log(`Checking ${direction.id}`)
            direction.particleAddresses.forEach(particleId => {
                if (particleSet.has(particleId)) {
                    throw new Error(`Duplicate particleId found: ${particleId} in particleAddresses of direction ${direction.id}`);
                }
                particleSet.add(particleId);
            });
    
            direction.particleQueue.forEach(particleId => {
                if (particleSet.has(particleId)) {
                    throw new Error(`Duplicate particleId found: ${particleId} in particleQueue of direction ${direction.id}`);
                }
                particleSet.add(particleId);
            });
        });
    
        // Check all grid cells
        Object.values(this.grid.cellData).forEach(gridCell => {
            console.log(`Checking ${gridCell.id}`)
            gridCell.particleAddresses.forEach(particleId => {
                if (particleSet.has(particleId)) {
                    throw new Error(`Duplicate particleId found: ${particleId} in particleAddresses of grid cell ${gridCell.id}`);
                }
                particleSet.add(particleId);
            });
    
            gridCell.particleQueue.forEach(particleId => {
                if (particleSet.has(particleId)) {
                    throw new Error(`Duplicate particleId found: ${particleId} in particleQueue of grid cell ${gridCell.id}`);
                }
                particleSet.add(particleId);
            });
        });
    };

    public getObjectType=(object: PossibleObject)=>{
        if(object instanceof GridCell) return GridCell
        if(object instanceof Direction) return Direction
        if(object instanceof SpatialAction) return SpatialAction
    }
    public getCorrespondingObject=(object: PossibleObject)=>{
        if(object instanceof GridCell) return this.grid.cellData[object.id]
        if(object instanceof Direction) return this.causalField.directions[object.id]
        if(object instanceof SpatialAction) return this.spatialActionManager.spatialActions[object.id]
    }
    public getExistingObjectById=(id: string)=>{
        // if(this.causalEnergyData[id]) return this.causalEnergyData[id];
        if(this.grid.cellData[id]) return this.grid.cellData[id];
        if(this.causalField.directions[id]) return this.causalField.directions[id];
        // console.warn(`Object ${id} did not exist!`);
        return undefined;
    }
    public getObjectById=(id: string): (PossibleObject | undefined) =>{
        if(this.causalField.directions[id]) return this.causalField.directions[id];
        if(this.grid.cellData[id]) return this.grid.cellData[id];
        if(this.spatialActionManager.spatialActions[id]) return this.spatialActionManager.spatialActions[id];
        // console.warn(`Object ${id} did not exist!`);
        return undefined;
    }
    public isExistingObject=(id: string): boolean =>{
        if(this.isExistingGridCell(id)) return true;
        if(this.isExistingDirection(id)) return true;
        // if(this.isExistingSpatialAction(id)) return true;
        return false;
    }  
    public isExistingGridCell = (id: string): boolean => {
        if(this.grid.cellData[id]) return true;
        return false;
    };

    public isExistingDirection = (id: string): boolean => {
        
        if(this.causalField.directions[id]) return true;
        return false;
    };

    public isExistingSpatialAction = (id: string): boolean =>{
        if(this.spatialActionManager.spatialActions[id]) return true;
        return false;
    }



    // public createPotentialObjectSettings = (obj: PossibleObject): PotentialObjectSettings=>{
    //     if(obj instanceof Direction){
    //         return {
    //             type: "Direction",
    //             settings: obj.getPotentialObjectSettings()
    //         }
    //     }
    // }


    public modifyAnimationSettings = (directionSettings: DirectionSettings, animationSettings: EnergyTransferAnimation | Task)=>{
        return animationSettings;
        // const { id, directionArray } = directionSettings;

        // const isIdentical = isSourceAndTargetIdentical(directionArray);
        // if(isIdentical) throw new Error("modifyAnimationSettings - Source and target identical")
        // //Intention
        // //If it already exists, don't create a new one!
        // const possibleDirection = this.causalField.getDirection(directionArray)

        // //Stripping away references to spatial actions
        // const directionArrayOfSourceEnergy = stripSpatialTag(animationSettings.directionOfSourceEnergy)
        // const directionArrayOfTargetEnergy = stripSpatialTag(animationSettings.directionOfTargetEnergy)
        // if(possibleDirection){
        //     // console.log(`Direction ${id} already exists`)
        //     animationSettings.target = possibleDirection
        //     animationSettings.directionOfSourceEnergy = directionArrayOfSourceEnergy;
        //     animationSettings.directionOfTargetEnergy = directionArrayOfTargetEnergy;
        //     return animationSettings;
        // }
        
        // // console.log(`Direction ${id} doesn't exist - creating`)
        // // console.log("Creaitng new direction in modify animation settings: ", directionArray);
        // const newDirection = this.causalField.getOrCreateDirection(directionArray);
        // animationSettings.target = newDirection
        // animationSettings.directionOfSourceEnergy = directionArrayOfSourceEnergy;
        // animationSettings.directionOfTargetEnergy = directionArrayOfTargetEnergy;
        // return animationSettings;
    }

    setUpTools=()=>{
        const view = this.view ? this.view: document.getElementById("view") as HTMLCanvasElement
        const engine = new Engine(view, true, {
            preserveDrawingBuffer: true,
            stencil: true,
            disableWebGL2Support: false
        })
        
        const scene = new Scene(engine)
        scene.metadata = {};
        scene.metadata.isMain = true;
        scene.actionManager = new ActionManager(this.scene);

        
        const light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
        // light.diffuse = new Color3(1,1,1)
        // light.intensity = .;
        // light.posit
        // light.setDirectionToTarget(new Vector3(0,.5,0))
        // const directionalLight = new DirectionalLight("directionalLight", new Vector3(0,1,0), scene);

        // directionalLight.intensity = 0.75; // Adjust the intensity
        // // directionalLight.position = new Vector3(20, 40, 20);
        // directionalLight.setDirectionToTarget(new Vector3(0,1,0))
    
        // const pointLight = new PointLight("pointLight", new Vector3(0, 1, 0), scene);
        // pointLight.intensity = 0.3; // Adjust the intensity
        // pointLight.setDirectionToTarget(new Vector3(0,1,0))
        
        // const axes = new AxesViewer(scene, 5);
        
        scene.clearColor = new Color4(0,0,0,1); // r,g,b,a

        const camera = new UniversalCamera("UniversalCamera", new Vector3(0, this.lockedCameraHeight, 0), scene);
        camera.rotation = new Vector3(0, Math.PI, 0); // Math.PI radians = 180 degrees
        // camera.target = new Vector3(0,0,0);
        this.camera = camera;
        // Clear the existing keyboard input (which defaults to arrow keys)

        // Targets the camera to a particular position. In this case the scene origin
        camera.setTarget(new Vector3(0, 0, 0.05));
        // camera.setTarget(Vector3.Zero());

        // Attach the camera to the canvas
        camera.attachControl(view, true);

        
        // const camera = new ArcRotateCamera(
        //     "camera",
        //     0, // 0 degrees about the Y-axis will position the camera along the positive X-axis.
        //     Math.PI / 3.2, // 90degrees about the horizontal will make the camera look towards the origin.
        //     5,
        //     Vector3.Zero(),
        //     scene
        // );
        
        // camera.attachControl(view);
        // camera.wheelPrecision = 10;

        onWindowResize(this, engine);

      

        return {view, engine, scene, camera}
    }

    

}

export default GameOfExistence;

