import { AdvancedDynamicTexture, Button, Control, Image, Rectangle, StackPanel, Slider, TextBlock, ScrollViewer } from "@babylonjs/gui";
import GameOfExistence from "./GameOfExistence";
import { BoundingInfo, Color4, HemisphericLight, Mesh, MeshBuilder, RenderTargetTexture, Scene, UniversalCamera, Vector3, KeyboardEventTypes, PointerEventTypes, Color3 } from "@babylonjs/core";
import { SpatialActionSettings, SpatialActionsJson } from "../types";
import { parse } from "flatted";
import deserializeVector3 from "../helpers/deserializeVector3";
import SpatialAction from "./SpatialAction";
import isPointerInsideRect from "../helpers/isPointerInsideRect";
import { stringify } from "querystring";
import { DEMO_NAMES } from "../constants";
import loadActionsFromFile from "../helpers/loadActionsFromFile";
import isObjEmpty from "../helpers/isObjEmpty";

class SpatialActionViewer {
    private advancedTexture: AdvancedDynamicTexture;
    private panel: StackPanel;
    GOE: GameOfExistence = null;
    windowRect: Rectangle = null;

    previewImage: Image = null;
    previewScene: Scene = null;
    previewCamera: UniversalCamera = null;
    renderTexture: RenderTargetTexture = null;

    private isDragging: boolean = false;
    private lastPointerPosition: Vector3 = new Vector3(0, 0, 0);

    
    demoActions: {[key: string]: string} = {}; // SpatialActionsJson as a string
    savedActions: {[key: string]: SpatialAction[]} = {};
    tools: any = null;

    constructor(GOE: GameOfExistence, tools: any) {
        this.tools = tools;
        this.GOE = GOE;
        // const advancedTextureObj = this.GOE.getBabylonObject(AdvancedDynamicTexture);
        this.advancedTexture = tools.createUserInterface ? tools.createUserInterface() : AdvancedDynamicTexture.CreateFullscreenUI("UI", true, this.GOE.scene);
        // this.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI", true, this.GOE.scene);
        

        // Create a background rectangle for the window
        const rectangleObj = this.GOE.getBabylonObject('Rectangle', Rectangle);
        const control = this.GOE.getBabylonObject('Control', Control);
        // let windowRect = new rectangleObj();
        let windowRect = new rectangleObj();
        // let windowRect = new Rectangle();
        // let notWindowRect = new Rectangle();
        // console.log("Window Rect: ", windowRect);
        // console.log("Not window rect: ", notWindowRect);
        this.windowRect = windowRect;
        windowRect.isVisible = false;
        windowRect.width = "35%";
        windowRect.height = "90%";
        windowRect.cornerRadius = 0;
        windowRect.color = "white";
        windowRect.thickness = 2;
        windowRect.background = "transparent";
        windowRect.verticalAlignment = control.VERTICAL_ALIGNMENT_TOP;
        windowRect.horizontalAlignment = control.HORIZONTAL_ALIGNMENT_RIGHT;
        windowRect.top = ".5%";
        windowRect.left = "-.5%";
        // // windowRect.top = "12px";
        // // windowRect.left = "-12px";
        // console.log("Advanced texture: ", this.advancedTexture);
        this.advancedTexture.addControl(windowRect);

        // // Create a stack panel to hold the shape buttons
        const stackPanelObj = this.GOE.getBabylonObject('StackPanel', StackPanel);
        this.panel = new stackPanelObj();
        windowRect.addControl(this.panel);
    }

    init=()=>{
        // console.warn(" saved actions not loaded")
        this.createSecondaryScene();
        this.loadSavedActions();
    }

    renderDefaultAction=()=>{
        if(!this.savedActions) return;
        // this.saved
        // console.warn("Rendering default action: ", 0)
        this.renderAction(this.savedActions[0]);
    }

    createSecondaryScene = () => {
        if(this.previewScene) return;
        const tools = this.tools && this.tools.createSecondaryScene ? this.tools.createSecondaryScene() : this.createSceneAndTools();
        // console.log("Tools in secondary scene: ", tools);
        const { image, previewScene, previewCamera, renderTexture } = tools;
        this.windowRect.addControl(image);
        this.previewImage = image;

        // Expose the preview scene and camera for adding meshes
        this.previewScene = previewScene;
        this.previewCamera = previewCamera;
        this.renderTexture = renderTexture;

        // Add render logic
        this.GOE.scene.onBeforeRenderObservable.add(() => {
            this.renderSecondaryScene();
        });
    }

    createSceneAndTools = ()=>{
        console.log("GOE engine: ", this.GOE.engine);
        const previewScene = new Scene(this.GOE.engine);
        previewScene.metadata = {};
        previewScene.metadata.isMain = false;
        previewScene.autoClear = true; // Ensure it clears the entire buffer
        previewScene.clearColor = new Color4(0, 0, 0, 1); // r,g,b,a

        const previewCamera = new UniversalCamera("UniversalCamera", new Vector3(0, -2.5, 0), previewScene);
        previewCamera.setTarget(new Vector3(0, 0, -0.05));
        previewCamera.attachControl(this.GOE.engine.getRenderingCanvas(), true); // Attach control to preview scene canvas
        previewCamera.inputs.clear(); // Clear existing inputs to prevent conflicts

        // Rotate the camera 180 degrees around the Z-axis
        previewCamera.rotation.z = Math.PI; // Rotate 180 degrees
        // previewCamera.rotation.y = Math.PI * 3; // Rotate 180 degrees
        // previewCamera.rotation.x = Math.PI; // Rotate 180 degrees

        const light = new HemisphericLight("light", new Vector3(0, -1, 0), previewScene);

        // Create a RenderTargetTexture
        const renderTexture = new RenderTargetTexture("renderTexture", { width: 512, height: 512 }, previewScene, false);
        renderTexture.activeCamera = previewCamera;

        previewScene.customRenderTargets.push(renderTexture);

        // Create an image control to display the render texture
        const image = new Image("previewImage", null);
        image.width = "100%"; // Adjust this to control the size
        image.height = "55%"; // Adjust this to control the size
        // image.top = "-15%";
        image.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
        image.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        image.isPointerBlocker = true; // Ensure the image blocks pointer events
        return { image, previewScene, previewCamera, renderTexture}
    }

    createZoomControls = () => {
        // Create a slider for zoom control
        const stackPanelObj = this.GOE.getBabylonObject('StackPanel', StackPanel);
        const zoomPanel = new stackPanelObj();
        // zoomPanel.color = "green";
        zoomPanel.width = "220px";
        zoomPanel.height = "50px";
        // zoomPanel.top = "-45%"
        // zoomPanel.left = "32.5%"
        // zoomPanel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
        // zoomPanel.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
        this.windowRect.addControl(zoomPanel);

        const textBlockObj = this.GOE.getBabylonObject('TextBlock', TextBlock);
        const zoomLabel = new textBlockObj();
        zoomLabel.text = "Zoom";
        zoomLabel.color = "white";
        zoomLabel.height = "20px";
        zoomPanel.addControl(zoomLabel);

        const sliderObj = this.GOE.getBabylonObject('Slider', Slider);
        const zoomSlider = new sliderObj();
        zoomSlider.minimum = 1; // Min zoom level (closer)
        zoomSlider.maximum = 10; // Max zoom level (farther)
        zoomSlider.value = 2.5; // Default zoom level (matches initial camera height)
        zoomSlider.height = "20px";
        zoomSlider.width = "200px";
        zoomSlider.color = "white";
        zoomSlider.borderColor = "lime";
        zoomSlider.thumbColor = "black";
        zoomSlider.isPointerBlocker = true; // Ensure the slider blocks pointer events
        zoomSlider.onValueChangedObservable.add((value) => {
            this.previewCamera.position.y = -value; // Adjust the camera height
        });
        zoomPanel.addControl(zoomSlider);
    }

    createKeyboardControls = () => {
        this.previewScene.onKeyboardObservable.add((kbInfo) => {
            switch (kbInfo.type) {
                case KeyboardEventTypes.KEYDOWN:
                    switch (kbInfo.event.key) {
                        case "i":
                            this.previewCamera.position.z -= 0.1; // Move forward
                            break;
                        case "k":
                            this.previewCamera.position.z += 0.1; // Move backward
                            break;
                        case "j":
                            this.previewCamera.position.x -= 0.1; // Move left
                            break;
                        case "l":
                            this.previewCamera.position.x += 0.1; // Move right
                            break;
                    }
                    break;
            }
        });
    }

    createScrollArea = () => {
        const scrollViewerObj = this.GOE.getBabylonObject('ScrollViewer', ScrollViewer);
        const scrollViewer = new scrollViewerObj();
        scrollViewer.width = "100%";
        scrollViewer.height = "45%";
        // scrollViewer.top = "68%";
        scrollViewer.color = "white";
        scrollViewer.background = "black";
        scrollViewer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
        scrollViewer.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
        scrollViewer.isPointerBlocker = true; // Ensure the scroll area blocks pointer events
        scrollViewer.scrollBackground = "gray";
        scrollViewer.barColor = "#4285f4";
        scrollViewer.barSize = 20;
        scrollViewer.barBackground = "black";
        // scrollViewer.highlightColor = "green"
        // scrollViewer.shadowColor = "green"
        // scrollViewer.barImageHeight = 3;
        this.windowRect.addControl(scrollViewer);

        const stackPanelObj = this.GOE.getBabylonObject('StackPanel', StackPanel);
        const scrollContent = new stackPanelObj();
        scrollContent.background = "black";
        scrollViewer.addControl(scrollContent);

        // Load saved actions from localStorage
        const savedActions = this.getSavedActions();
        const filteredActions = Object.values(savedActions)//.filter((obj=>obj instanceof SpatialAction));

        const buttonObj = this.GOE.getBabylonObject('Button', Button);
        if(!filteredActions.length){
            const actionButton = buttonObj.CreateSimpleButton(`Nothing found Nullasd`, `No spatial actions saved yet`);
            actionButton.width = "100%";
            actionButton.height = "40px";
            actionButton.color = "white";
            actionButton.background = "#B82025"; // Button background color
            scrollContent.addControl(actionButton);
            return;
        };
        for (let [id, spatialAction] of Object.entries(savedActions)){
            if(DEMO_NAMES.includes(id)) continue;
            const actionButton = buttonObj.CreateSimpleButton(`actionButton-${id}`, id);
            actionButton.width = "100%";
            actionButton.height = "40px";
            actionButton.color = "gray";
            actionButton.background = "black"; // Button background color
            actionButton.onPointerUpObservable.add(() => {
                this.onActionSelected(spatialAction, id);
            });
            scrollContent.addControl(actionButton);
        }
    }

    onActionSelected=(action, id)=>{
        // console.log("Selecting action: ", id)
        this.renderAction(action);
    }

    getSavedActions=(): {[key: string]: SpatialAction[]}=>{
        return this.savedActions;
    }

    getSavedActionIds=(): string[]=>{
        const savedIdsJson = localStorage.getItem('savedActionIds');
        const ids = JSON.parse(savedIdsJson);
        if(!ids) return [];
        // return ids.filter((id)=>{
        //     return !DEMO_NAMES.includes(id);
        // })
        return ids
    }

    loadSavedActions = async ()=> {
        // console.log("Loading saved actions");
        const ids = this.getSavedActionIds();
        let loadedActions = {};
        for( let id of ids){
            const spatialActionJson = localStorage.getItem(`spatialActions_${id}`);
            if (!spatialActionJson) return;
            const serializedAction: SpatialActionSettings[] = parse(spatialActionJson);
            const hasSourcePosition = serializedAction[0].sourcePosition;
            if(!hasSourcePosition) return;
            if(Object.keys(this.savedActions).includes(id)) return;
            // if(!Object.keys(DEMO_NAMES).includes(id)){
            //     loadedActions[id] = spatialActionJson;
            // }
            this.rehydrateSavedAction(serializedAction, id);
        }
        // ids.forEach(id => {
        // });
        // const loadedActionJson = JSON.stringify(loadedActions);
        // console.log("Loaded action json: ", loadedActionJson);

        try{
            const data = await loadActionsFromFile();
            // console.log("data from file: ", data);
            const actionsFromFile = data.actions && !isObjEmpty(data.actions) ? data.actions : null;

            let demoActions = {};
            if(!actionsFromFile) return;
            for (let [id, spatialActionJson] of Object.entries(actionsFromFile)){
                demoActions[id] = spatialActionJson
                // // console.log("Loading: ", id);
                // const serializedAction: SpatialActionSettings[] = parse(spatialActionJson);
                // const hasSourcePosition = serializedAction[0].sourcePosition;
                // if(!hasSourcePosition) return;
                // this.rehydrateSavedAction(serializedAction, id);
            }
            this.demoActions = demoActions
            // const saved =  {...this.savedActions};
            // console.log("Saved actions: ", saved);
            // console.log("Correlator demo: ", saved['correlatorDemo'] )
            // this.GOE.controls.recoverSpatialActionsAsync("correlatorDemo");
        }
        catch(error){
            console.warn("Error loading from file: ", error);
        }
        
    }

    

    //Called in createViewerSpatialAction
    addDirectionToSavedAction=(spatialAction: SpatialAction, id: string)=>{
        if(!spatialAction) return;
        if(!this.savedActions[id]) this.savedActions[id] = [];
        if(this.savedActions[id].map((obj)=>obj.id).includes(id)) return;
        this.savedActions[id] = [...this.savedActions[id], spatialAction]
    }

    rehydrateSavedAction=(action: SpatialActionSettings[], id)=>{
        action.forEach((spatialAction: SpatialActionSettings)=>{
            spatialAction.animationSettings.source.settings.position = deserializeVector3(spatialAction.animationSettings.source.settings.position);
            spatialAction.animationSettings.target.settings.position = deserializeVector3(spatialAction.animationSettings.target.settings.position);
            spatialAction.position = deserializeVector3(spatialAction.position);
            spatialAction.sourcePosition = deserializeVector3(spatialAction.sourcePosition);
            spatialAction.targetPosition = deserializeVector3(spatialAction.targetPosition);
            spatialAction.directionSettings.position = deserializeVector3(spatialAction.directionSettings.position);
            spatialAction.directionSettings.GOE = this.GOE;
            spatialAction.directionSettings.causalField = this.GOE.causalField;
            this.GOE.spatialActionManager.createViewerSpatialAction({
                directionId: spatialAction.directionId,
                position: spatialAction.position,
                id: spatialAction.id,
                energyId: spatialAction.energyId,
                sourceId: spatialAction.sourceId,
                targetId: spatialAction.targetId,
                sourcePosition: spatialAction.sourcePosition,
                targetPosition: spatialAction.targetPosition,
                directionSettings: spatialAction.directionSettings,
                animationSettings: spatialAction.animationSettings,
            }, id);
        })

    }

    createMouseControls = () => {
        this.previewScene.onPointerObservable.add((pointerInfo) => {
            const isPointerInRect = isPointerInsideRect(pointerInfo.event.clientX, pointerInfo.event.clientY, this.windowRect);
            if(!isPointerInRect) return;
            switch (pointerInfo.type) {
                case PointerEventTypes.POINTERDOWN:
                    this.isDragging = true;
                    this.lastPointerPosition.x = pointerInfo.event.clientX;
                    this.lastPointerPosition.z = pointerInfo.event.clientY;
                    break;
                case PointerEventTypes.POINTERUP:
                    this.isDragging = false;
                    break;
                case PointerEventTypes.POINTERMOVE:
                    if (this.isDragging) {
                        const deltaX = pointerInfo.event.clientX - this.lastPointerPosition.x;
                        const deltaZ = pointerInfo.event.clientY - this.lastPointerPosition.z;
                        this.previewCamera.position.x += deltaX * 0.01; // Adjust the multiplier for sensitivity
                        this.previewCamera.position.z += deltaZ * 0.01; // Adjust the multiplier for sensitivity
                        this.lastPointerPosition.x = pointerInfo.event.clientX;
                        this.lastPointerPosition.z = pointerInfo.event.clientY;
                    }
                    break;
            }
        });
    }

    renderSecondaryScene = () => {
        this.previewScene.render();
        this.updatePreviewImage();
    }

    clearPreviewScene = () => {
        for (let mesh of this.previewScene.meshes) {
            if (mesh) {
                mesh.dispose();
            }
        }
        this.renderTexture.renderList = [];
    }

    renderAction = (actions: SpatialAction[]) => {
        // const actions = this.GOE.controls.getSavedSpatialActions(7);
        console.log("Actions: ", actions);
        if(!actions || !actions.length) return console.warn("No actions present");
        this.clearPreviewScene();
        // console.log("Scene Meshes Before Action: ", this.previewScene.meshes);
        // console.log("renderList: ", this.renderTexture.renderList.length);
        let meshes = [];
        for (let action of actions){
            if (!action) continue;
            // console.log(`Creating visuals in render action for ${action.id}`);
            const mesh = action.createVisuals(this.previewScene); // Create visuals in the preview scene
            if(mesh) meshes.push(mesh);
        }
        // console.log("Meshes: ", meshes);
        // this.logMeshDetails(meshes);
        this.mergeAndCenterMeshes(meshes);
    }

    logMeshDetails = (meshes: Mesh[]) => {
        meshes.forEach(mesh => {
            console.log(`Mesh: ${mesh.name}, Position: ${mesh.position}, Bounding Info:`, mesh.getBoundingInfo());
        });
    }

    mergeAndCenterMeshes = (meshes: Mesh[]) => {
        // Filter out invalid meshes
        const validMeshes = meshes.filter(mesh => mesh && mesh.getVerticesData && mesh.getVerticesData("position"));
        
        if (validMeshes.length === 0){
            console.log("No valid meshes");
            return;
        };

        // Merge all valid meshes into a single mesh
        const mergedMesh = Mesh.MergeMeshes(validMeshes, true, true, undefined, false, true);
        if (mergedMesh) {
            // Compute the bounding box of the merged mesh
            const boundingInfo = mergedMesh.getBoundingInfo();
            const boundingBox = boundingInfo.boundingBox;
            const center = boundingBox.centerWorld;
            // Translate the merged mesh so that the center of the bounding box is at the origin
            mergedMesh.position.subtractInPlace(center);
            // Add merged mesh to the render list of the secondary scene
            this.renderTexture.renderList.push(mergedMesh);
        }
    }

    updatePreviewImage = async () => {
        const data = await this.renderTexture.readPixels();
        if (data) {
            const canvas = document.createElement("canvas");
            canvas.width = this.renderTexture.getSize().width;
            canvas.height = this.renderTexture.getSize().height;
            const context = canvas.getContext("2d");
            if (context) {
                const imageData = context.createImageData(canvas.width, canvas.height);
                imageData.data.set(new Uint8ClampedArray(data.buffer));
                context.putImageData(imageData, 0, 0);
                this.previewImage.source = canvas.toDataURL();
            }
        }
    }

    public isVisible = ()=>{
        if(!this.windowRect) return false;
        return this.windowRect.isVisible;
    }

    public toggleMenu = () => {
        if (this.isVisible()) {
            this.disposeScene();
            this.windowRect.isVisible = false;
            return false;
        } else {
            this.windowRect.isVisible = true;
            // this.createSecondaryScene();
            this.loadSavedActions();
            this.renderDefaultAction();
            this.createZoomControls();
            this.createKeyboardControls();
            this.createMouseControls();
            this.createScrollArea();
            this.renderDefaultAction();
            return true;
        }
    }
    
    private disposeScene = () => {
        // this.previewScene.dispose();
        // this.previewImage.dispose();
        // this.renderTexture.dispose();
    }

    public getAdvancedTexture = ()=>{
        return this.advancedTexture;
    }
}

export default SpatialActionViewer;
