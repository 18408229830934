import React, { useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import NeurobiologyExplanation from './Explanations/NeurobiologyExplanation';
import ArtificialLifeExplanation from './Explanations/ArtificialLifeExplanation';
import AIExplanation from './Explanations/AIExplanation';
import PhysicsExplanation from './Explanations/PhysicsExplanation';
import Text from './Text';

const explanations = [
  { title: 'Neuroscience & Biology', component: NeurobiologyExplanation },
  { title: 'Artificial Life', component: ArtificialLifeExplanation },
  { title: 'Artificial Intelligence', component: AIExplanation },
  { title: 'Physics', component: PhysicsExplanation },
];

const DisplayExplanation = ({ isMobile, isSwiping, setIsSwiping, explanationView, setExplanationView, eleaticFieldRelationsRef }: any) => {
  const currentIndex = explanations.findIndex(explanation => explanation.title === explanationView);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentIndex < explanations.length - 1) {
        // console.log("Swiped left, setting explanation to:", explanations[currentIndex + 1].title);
        setExplanationView(explanations[currentIndex + 1].title);
      }
    },
    onSwipedRight: () => {
      if (currentIndex > 0) {
        // console.log("Swiped right, setting explanation to:", explanations[currentIndex - 1].title);
        setExplanationView(explanations[currentIndex - 1].title);
      }
    },
    onSwiping: () => {
      if (!isSwiping) {
        // console.log("Set swiping true");
        setIsSwiping(true); // Set swiping to true when swiping
      }
    },
    onSwiped: () => {
      if (isSwiping) {
        // console.log("Set swiping false");
        setIsSwiping(false); // Set swiping to false after swiping
      }
    },
  });

  useEffect(() => {
    if (eleaticFieldRelationsRef.current) {
      // console.log("Calling scrollToButton for index:", currentIndex);
      eleaticFieldRelationsRef.current.scrollToButton(currentIndex);
    }
  }, [currentIndex, eleaticFieldRelationsRef]);

  const CurrentExplanationComponent = explanations[currentIndex].component;
  const CurrentExplanationTitle = explanations[currentIndex].title;

  return (
    <div {...handlers} style={{
      width: '100%',
      textAlign: 'left',
      fontWeight: '500',
      backgroundColor: '#E6CCA1',
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      alignItems: isMobile ? 'center' : 'flex-start',
      justifyContent: isMobile ? 'center' : 'flex-start',
    }}>
      <Text style={{
        width: '80vw',
        maxWidth: '850px',
        fontSize: '1.25em',
        fontWeight: '600',
        borderBottom: 'solid black 1px',
        lineHeight: '2em',
        marginBottom: '2em',
        marginLeft: '4%',
        textAlign: isMobile ? 'center' : 'left'
      }}>
        {CurrentExplanationTitle} Research Program
      </Text>
      <CurrentExplanationComponent isMobile={isMobile} />
    </div>
  );
};

export default DisplayExplanation;
