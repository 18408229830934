import { Color4, FreeCameraKeyboardMoveInput, PointerEventTypes, UniversalCamera, Vector3 } from "@babylonjs/core";
import stripSpatialTag from "../helpers/stripSpatialTag";
import { DirectionSettings, EnergyTransferAnimation, ObjectSelectionStates, PossibleObject, SpatialActionSettings, SpatialActionsJson, Task } from "../types";
import Direction from "./Direction";
import GameOfExistence from "./GameOfExistence";
import SpatialAction from "./SpatialAction";
import GridCell from "./GridCell";
import convertDirectionArrayToString from "../helpers/convertDirectionArrayToString";
import calculateDirectionPosition from "../helpers/calculateDirectionPosition";
import isSourceAndTargetIdentical from "../helpers/isSourceAndTargetIdentical";
import changeBoxColor from "../helpers/changeBoxColor";
import isPointNeighborOfCell from "../helpers/isPointNeighborOfCell";
import { parse, stringify } from "flatted";
import PotentialObject from "./PotentialObject";
import ObjectControl from "./ObjectControl";
import deserializeVector3 from "../helpers/deserializeVector3";
import Tutorial from "./Tutorial";
import { Control } from "@babylonjs/gui";
// import { LIGHT_MOMENTUM } from "../constants";


class GameControls {
    GOE: GameOfExistence = null;
    isDragging: boolean = false;
    isMouseDown: boolean = false;

    spatialMode: boolean = false;
    selectionMode: boolean = false;

    selectedObject: PossibleObject;
    selectedSource: PossibleObject;
    selectedTarget: PossibleObject;

    keyboardInput: FreeCameraKeyboardMoveInput = null;

    objectControl: ObjectControl = null;
    showSavedSpatialActions: boolean = false;

    constructor(GOE: GameOfExistence){
        this.GOE = GOE;
        this.objectControl = new ObjectControl(this.GOE)
    }

    init=()=>{
        let dragStartPosition = null;

        //Blocking non-intended clicks
        this.GOE.scene.onPointerObservable.add((pointerInfo) => {
            switch (pointerInfo.type) {
                case PointerEventTypes.POINTERDOWN:
                    this.isMouseDown = true;
                    this.isDragging = false;
                    dragStartPosition = pointerInfo.event.clientX; // Store the initial pointer position
                    break;
                case PointerEventTypes.POINTERUP:
                    if(this.isDragging){
                         this.isDragging = false;
                         this.isMouseDown = false;
                         return;
                    }
                    //Desired function will activate
                    break;
                case PointerEventTypes.POINTERMOVE:
                    if (this.isMouseDown) {
                        const currentX = pointerInfo.event.clientX;
                        const movementX = Math.abs(currentX - dragStartPosition);
                        if (movementX > 2) { // Adjust the threshold as needed
                            this.isDragging = true;
                        }
                    }
                    break;
            }
        });

        this.setUpCameraControls();
        this.setUpKeyboardCameraControls();
        this.setUpListeners();
    }

    public moveLockedCamera=()=>{
        if (!this.GOE.isCameraLocked) return;
        this.GOE.camera.position.y = this.GOE.lockedCameraHeight;
        const direction = new Vector3();
        this.GOE.camera.getDirectionToRef(Vector3.Forward(), direction);
        direction.y = 0; // to keep the y position locked
        direction.normalize(); // convert to unit vector
        // console.log("RUnning move locked camera");
        if (this.GOE.moveForward) {
            this.GOE.camera.position.addInPlace(direction.scale(this.GOE.camera.speed));
        }
        if (this.GOE.moveBackward) {
            this.GOE.camera.position.addInPlace(direction.scale(-this.GOE.camera.speed));
        }
        if (this.GOE.moveRight) {
            // Cross product of direction and up vector will give a vector pointing to the left
            const left = Vector3.Cross(Vector3.Up(), direction).normalize();
            this.GOE.camera.position.addInPlace(left.scale(this.GOE.camera.speed));
        }
        if (this.GOE.moveLeft) {
            // Cross product of direction and negative up vector will give a vector pointing to the right
            const right = Vector3.Cross(Vector3.Down(), direction).normalize();
            this.GOE.camera.position.addInPlace(right.scale(this.GOE.camera.speed));
        }
    };

    private doesObjectMatch=(selectedObj: PossibleObject, obj: PossibleObject)=>{
        if(selectedObj instanceof SpatialAction) obj.id === selectedObj.id || obj.id === selectedObj.directionId;
        return selectedObj.id === obj.id
    }

    public adjustCameraHeight=(event: any)=>{
        event.preventDefault();
        if (!this.GOE.isCameraLocked) return;
        
        if (event.deltaY < 0) {
            // scroll down, decrease y position
            this.GOE.lockedCameraHeight -= this.GOE.camera.speed*2;
        } else {
            // scroll up, increase y position
            this.GOE.lockedCameraHeight += this.GOE.camera.speed*2;
        }
    }

    toggleLightMomentum=()=>{
        alert(`"Light" momentum ${this.GOE.LIGHT_MOMENTUM ? 'off' : 'on'}`)
        // if(!this.GOE.tutorial.inProgress()) alert(`"Light" momentum ${this.GOE.LIGHT_MOMENTUM ? 'off' : 'on'}`)
        this.GOE.setLightMomentum(!this.GOE.LIGHT_MOMENTUM);
        // console.log(`${result ? "Showing" : "hiding"} stress bar`);
    }
    
    clearExistingDirections=()=>{
        this.GOE.causalField.dissipateAllDirections();
    }
    toggleStress=()=>{
        if(this.GOE.spatialActionViewer.isVisible()) this.toggleShowSavedSpatialActions()
        const result = this.GOE.stress.toggleStress();
        if(this.GOE.tutorial && this.GOE.tutorial.inProgress()){
            console.log("Toggling on stress");
            const newAlignment =  result ? Control.VERTICAL_ALIGNMENT_CENTER : Control.VERTICAL_ALIGNMENT_TOP;
            this.GOE.tutorial.visuals.instructionRectangle.verticalAlignment = newAlignment
        }
        return result;
        // console.log(`${result ? "Showing" : "hiding"} stress bar`);
    }

    toggleShowSavedSpatialActions=()=>{
        if(this.GOE.stress.isVisible()) this.toggleStress()
        const result = this.GOE.spatialActionViewer.toggleMenu();
        if(!result) this.toggleStress();
        // console.log(`${result ? "Showing" : "hiding"} spatial actions menu`);
    }
    toggleTime=()=>{
        console.log(`Turning ${!this.GOE.noTime ? "off" : "on"} time`);
        if(this.GOE.noTime) return this.GOE.noTime = false;
        return this.GOE.noTime = true;
    }

    toggleCameraLock=(event: any)=>{
        // Listen for the "L" key press to lock/unlock y value
        if (event.key === 'o' || event.key === 'O') {
            const shouldCameraLock = this.GOE.isCameraLocked ? false : true;
            this.toggleCameraControls(shouldCameraLock);
            console.log("Switching camera lock to : ", shouldCameraLock);
            this.GOE.isCameraLocked = shouldCameraLock;
            if(this.GOE.isCameraLocked) this.GOE.lockedCameraHeight = this.GOE.camera.position.y
        }
    }

    toggleCameraControls=(locked: boolean)=>{
        // console.log(`Camera is ${locked ? "locked" : "unlocked"}: `, this.camera);
        if(locked){
            // disable standard controls
            this.GOE.camera.keysUp.pop();
            this.GOE.camera.keysDown.pop();
            this.GOE.camera.keysLeft.pop();
            this.GOE.camera.keysRight.pop();
    
            // re-adding the custom controls
            document.addEventListener('keydown', this.moveCameraOnKeyDown);
            document.addEventListener('keyup', this.stopCameraOnKeyUp);
            document.addEventListener('wheel', this.adjustCameraHeight, { passive: false });
            
            // reset the camera speed
            this.GOE.camera.speed = this.GOE.defaultSpeed;
            return;
        }
        //Disable locked controls
        document.removeEventListener('keydown',this.moveCameraOnKeyDown);
        document.removeEventListener('keyup',this.stopCameraOnKeyUp);
        document.removeEventListener('wheel', this.adjustCameraHeight);
        // Enable standard controls
        this.keyboardInput.keysUp = [87]; // W
        this.keyboardInput.keysDown = [83]; // S
        this.keyboardInput.keysLeft = [65]; // A
        this.keyboardInput.keysRight = [68]; // D
    
        //Set unlocked speed
        this.GOE.camera.speed = 0.5;
    }

    setUpKeyboardCameraControls=()=>{
        this.GOE.camera.inputs.removeByType("FreeCameraKeyboardMoveInput");
        this.GOE.camera.speed = this.GOE.defaultSpeed;

        // Add a new keyboard input
        let keyboardInput = new FreeCameraKeyboardMoveInput();
        this.keyboardInput = keyboardInput;

        // Attach the modified keyboard input to your camera
        this.GOE.camera.inputs.add(keyboardInput);
    }

    setUpCameraControls=()=>{
        // setup custom controls
        document.addEventListener('keydown', this.toggleCameraLock);
        document.addEventListener('keydown', this.moveCameraOnKeyDown);
        document.addEventListener('keyup', this.stopCameraOnKeyUp);
        document.addEventListener('wheel', this.adjustCameraHeight, { passive: false });
    }

    moveCameraOnKeyDown=(event: any)=>{
        switch (event.key) {
            case 'w':
            case 'W':
                this.GOE.moveForward = true;
                break;
            case 's':
            case 'S':
                this.GOE.moveBackward = true;
                break;
            case 'a':
            case 'A':
                this.GOE.moveLeft = true;
                break;
            case 'd':
            case 'D':
                this.GOE.moveRight = true;
                break;
        }
    }

    stopCameraOnKeyUp=(event: any)=>{
        switch (event.key) {
            case 'w':
            case 'W':
                this.GOE.moveForward = false;
                break;
            case 's':
            case 'S':
                this.GOE.moveBackward = false;
                break;
            case 'a':
            case 'A':
                this.GOE.moveLeft = false;
                break;
            case 'd':
            case 'D':
                this.GOE.moveRight = false;
                break;
        }
    }

    setUpListeners=()=>{
        // console.log("Setting up listeners")
        document.addEventListener('keyup',this.toggleSpatialMode);
        document.addEventListener('keydown', (event) => {
            event.preventDefault();
            const key = event.key;
            if (/^[0-9]$/.test(key)) {
                this.recoverSpatialActions(key);
            } else if(key === `\``){
                this.toggleShowSavedSpatialActions()
            }
            else if (key === 'u') {
                this.toggleLightMomentum();
            }
            else if (key === 'h') {
                this.toggleStress();
            }
            else if (key === 'c') {
                // this.clearExistingDirections();
                this.GOE.resetParticles();
            }
            else if (key === 'm') {
                this.saveSpatialActionListener();
            }
            else if(key === 't'){
                this.toggleTime()
            }
        });
        // window.removeEventListener('wheel',this.modifyEnergyScrollerValue);
    }

    toggleSpatialMode = (event: any)=>{
        if(event.key == "" || event.code == "Space" || event.keyCode == 32){
            event.preventDefault();
            if(this.spatialMode) return this.exitSpatialMode();
            console.log("Entering Spatial Mode");
            this.spatialMode = true;
        }
    }

    exitSpatialMode=()=>{
        // console.log("Exiting spatial mode");
    
        // Check if there are any spatial actions to be created
        if(!this.GOE.spatialActionManager.spatialActions) return;
    
        console.log("Creating spatial actions");
    
        // Get the array of spatial actions
        // this.GOE.spatialActionsQueue.push(Object.values(this.GOE.spatialActionManager.spatialActions));
        const collectiveSpatialAction = Object.values(this.GOE.spatialActionManager.spatialActions);
        this.GOE.spatialActionCreator.addActionToQueue(this.GOE.gameTime, collectiveSpatialAction);

        // console.log("Spatial Actions Queue: ", stringify(this.spatialActionsQueue));
        this.GOE.lastTimeSpatialActionCreated = this.GOE.gameTime; // Initialize the last action creation time

        // Clear spatial actions in the manager
        this.GOE.spatialActionManager.spatialActions = {};
        this.GOE.spatialActionCreator.creatingSpatialActions = true;
        this.spatialMode = false;
    };

    quitSpatialActionRecovery=()=>{

    }

    getSavedSpatialActions(id){
        // this.spatialMode = true;
        const spatialActionsJson = localStorage.getItem(`spatialActions_${id}`);
        if (spatialActionsJson) {
            this.spatialMode = true;
            console.log("Recovering spatial actions");
            const deserializeVector3 = (obj) => {
                return new Vector3(obj.x, obj.y, obj.z);
            };
            const serializedActions = parse(spatialActionsJson);
            // console.log("Serialized Actions: ", serializedActions);
            // return;
            return serializedActions.sort((a, b) => a.timestamp - b.timestamp).map((json: any, index) => {
                json.animationSettings.source.settings.position = deserializeVector3(json.animationSettings.source.settings.position);
                json.animationSettings.target.settings.position = deserializeVector3(json.animationSettings.target.settings.position);
                json.position = deserializeVector3(json.position);
                json.sourcePosition = deserializeVector3(json.sourcePosition);
                json.targetPosition = deserializeVector3(json.targetPosition);
                json.directionSettings.position = deserializeVector3(json.directionSettings.position);
                json.directionSettings.GOE = this.GOE;
                json.directionSettings.causalField = this.GOE.causalField;
                return json;
            });
        } else {
            alert(`No spatial actions found for ID ${id}`);
        }
    };

    
    filterUniqueSpatialActions=(actions: SpatialAction[]): SpatialAction[]=> {
        const uniqueActions: SpatialAction[] = [];
        const uniqueIds = new Set<string>();
    
        for (const action of actions) {
            if (!uniqueIds.has(action.id)) {
                uniqueIds.add(action.id);
                uniqueActions.push(action);
            }
        }
    
        return uniqueActions;
    }
    
    recoverSpatialActionsAsync(id: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const demoActionJson = this.GOE.spatialActionViewer.demoActions[id];
                const spatialActionsJson = localStorage.getItem(`spatialActions_${id}`);
                console.log("Recovering spatial actions");
                const serializedActions: SpatialActionsJson[] = demoActionJson ? parse(demoActionJson) : (spatialActionsJson ? parse(spatialActionsJson) : null);
                if(!serializedActions){
                    alert(`No spatial actions found for ID ${id}`);
                    reject(`No spatial actions found for ID ${id}`);
                    return;
                };
                this.spatialMode = true;
    
                const hasSourcePosition = Object.values(serializedActions)[0].sourcePosition;
                if (!hasSourcePosition) {
                    return reject("No source position found in serialized actions.");
                }
    
                try {
                    serializedActions.sort((a, b) => a.timestamp - b.timestamp).forEach((json: any, index) => {
                        json.animationSettings.source.settings.position = deserializeVector3(json.animationSettings.source.settings.position);
                        json.animationSettings.target.settings.position = deserializeVector3(json.animationSettings.target.settings.position);
                        json.position = deserializeVector3(json.position);
                        json.sourcePosition = deserializeVector3(json.sourcePosition);
                        json.targetPosition = deserializeVector3(json.targetPosition);
                        json.directionSettings.position = deserializeVector3(json.directionSettings.position);
                        json.directionSettings.GOE = this.GOE;
                        json.directionSettings.causalField = this.GOE.causalField;
                        const energySource = this.GOE.getObjectById(json.energyId);
                        const result = this.GOE.spatialEnergyManager.canReserveEnergy(json.animationSettings.amount, energySource);
                        if (!result) throw new Error(`There isn't enough energy for action ${json.id}`);
                        const spatialAction = this.GOE.spatialActionManager.createSpatialAction(json.id, {
                            directionId: json.directionId,
                            position: json.position,
                            id: json.id,
                            energyId: json.energyId,
                            sourceId: json.sourceId,
                            targetId: json.targetId,
                            sourcePosition: json.sourcePosition,
                            targetPosition: json.targetPosition,
                            directionSettings: json.directionSettings,
                            animationSettings: json.animationSettings,
                        });
                        if (spatialAction) spatialAction.createVisuals(this.GOE.scene);
                    });
                    console.log("Recovery complete");
                    resolve("Recovery complete");
                } catch (error) {
                    reject(`Error recovering spatial actions: ${error.message}`);
                }
        });
    }

    recoverSpatialActions(id){
        // this.spatialMode = true;
        const spatialActionsJson = localStorage.getItem(`spatialActions_${id}`);
        if (spatialActionsJson) {
            this.spatialMode = true;
            console.log("Recovering spatial actions");
            const serializedActions: SpatialActionsJson[] = parse(spatialActionsJson);

            const hasSourcePosition = Object.values(serializedActions)[0].sourcePosition;
            // console.log("Serialized Action: ", serializedAction);
            if(!hasSourcePosition) return;

            // console.log("Serialized Actions: ", serializedActions);
            // return;
            serializedActions.sort((a, b) => a.timestamp - b.timestamp).map((json: any, index) => {
                json.animationSettings.source.settings.position = deserializeVector3(json.animationSettings.source.settings.position);
                json.animationSettings.target.settings.position = deserializeVector3(json.animationSettings.target.settings.position);
                json.position = deserializeVector3(json.position);
                json.sourcePosition = deserializeVector3(json.sourcePosition);
                json.targetPosition = deserializeVector3(json.targetPosition);
                json.directionSettings.position = deserializeVector3(json.directionSettings.position);
                json.directionSettings.GOE = this.GOE;
                json.directionSettings.causalField = this.GOE.causalField;
                const energySource = this.GOE.getObjectById(json.energyId);
                const result = this.GOE.spatialEnergyManager.canReserveEnergy(json.animationSettings.amount, energySource)
                if(!result) throw new Error(`There isn't enough energy for action ${json.id}`);
                const spatialAction = this.GOE.spatialActionManager.createSpatialAction(json.id, {
                    directionId: json.directionId,
                    position: json.position,
                    id: json.id,
                    energyId: json.energyId,
                    sourceId: json.sourceId,
                    targetId: json.targetId,
                    sourcePosition: json.sourcePosition,
                    targetPosition: json.targetPosition,
                    directionSettings: json.directionSettings,
                    animationSettings: json.animationSettings,
                });
                // console.log("Creating visuals when recovering spatial actions");
                if(spatialAction) spatialAction.createVisuals(this.GOE.scene)
                return spatialAction;
            });
            console.log("Recovery complete")
        } else {
            alert(`No spatial actions found for ID ${id}`);
        }
    };

    promptActionSave=()=>{
        const saveActionSet = confirm("Would you like to save this action?");
        if (saveActionSet) {
            let id = prompt("Please give this action a name:");
            if (id && this.isValidKey(id)) {
                const serializedActions = Object.values(this.GOE.spatialActionManager.spatialActions).map(action => action.toJSON());
                console.log("Serialized actions: ", serializedActions);
                const spatialActionsJson = stringify(serializedActions);
                console.log("Length of action encoding: ", spatialActionsJson.length);
                try {
                    localStorage.setItem(`spatialActions_${id}`, spatialActionsJson);
                    this.addIdToList(id);
                    alert(`Action set saved with ID ${id}`);
                } catch (e) {
                    alert('Failed to save action: Storage limit exceeded');
                }
            } else {
                alert("Invalid name. Action not saved.");
            }
        }
    }
    
    isValidKey=(key)=>{
        // Validate that the key is a non-empty string and safe for use in localStorage
        return typeof key === 'string' && key.trim() !== '' && /^[a-zA-Z0-9_-]+$/.test(key);
    }
    
    addIdToList=(id)=>{
        let savedIds = localStorage.getItem('savedActionIds');
        let idList = savedIds ? JSON.parse(savedIds) : [];
        if (!idList.includes(id)) {
            idList.push(id);
            localStorage.setItem('savedActionIds', JSON.stringify(idList));
        }
    }


    saveSpatialActionListener=()=>{
        if (this.GOE.spatialActionManager.spatialActions && Object.keys(this.GOE.spatialActionManager.spatialActions).length) {
            this.promptActionSave();
        } else {
            alert("No spatial actions to save.");
        }
    };

    public addWheelCameraControl=()=>{
        document.addEventListener('wheel', this.adjustCameraHeight, { passive: false });
    }

    public removeWheelCameraControl=()=>{
        document.removeEventListener('wheel', this.adjustCameraHeight);
    }

    public onObjectSelect=(obj: PossibleObject)=>{
        if(this.isDragging) return;
        if(obj.energyScroller && obj.energyScroller.energyControlOn){
            // console.log("Turning off energy control");
            obj.energyScroller.removeWheelEnergyControl();
            this.addWheelCameraControl();
            return;
        }
        const isSelectedCell = this.selectedObject && this.doesObjectMatch(this.selectedObject, obj);
        const isSelectedSource = this.selectedSource && this.doesObjectMatch(this.selectedSource, obj);
        const isSelectedTarget = this.selectedTarget && this.doesObjectMatch(this.selectedTarget, obj);
        if(!isSelectedCell && obj instanceof SpatialAction) console.log("Obj: ", obj);
        if(!isSelectedCell && obj instanceof Direction){
            console.log("Obj: ", obj);
            // console.log("GOE onObjectSelect - Calling get connected objects")
            // const oppObj = obj.getOppositeDirectionObject();
            // if(!oppObj){
            //     console.warn("Forward Obj: ", obj);
            //      console.log("Opp obj not found");
            //     return;
            // }
            // console.warn("Obj: ", obj);
            // console.log("Particle address locations");
            // console.warn("Opp Obj: ", oppObj);
            // this.GOE.particleVisuals.checkParticleLocations(oppObj.particleAddresses);
            // console.log("Particle queue locations");
            // this.GOE.particleVisuals.checkParticleLocations(oppObj.particleQueue);
            // this.objectControl.updateControlledObject(obj);
            // const collective = this.GOE.momentum.getConnectedObjects(obj);
            // if(!collective){
            //     console.log(`Object ${obj.id} had no collective`);
            // }
            // else{
            //     const {causalNodes, edges } = collective;
            //     const collectiveData = [
            //         ...causalNodes,
            //         ...edges
            //     ]
            //     collectiveData.forEach((obj: Direction)=>{
            //         changeBoxColor(obj.visuals.selector, new Color4(0,0,1,1))
            //     })
            // }

            // const scaffoldDirectionArray = ['(-1,0)', '(-1,1)'] //Move Left
            // // const scaffoldDirectionArray = ['(1,-1)', '(0,-1)']
            // const scaffoldDirection = this.causalField.getOrCreateDirection(scaffoldDirectionArray);
            // const scaffSource = <GridCell>this.getObjectById('(0,0)');
            // const scaffTarget: Direction = scaffoldDirection;
            // this.particleVisuals.startEnergyTransferAnimation({
            //     caller: scaffoldDirection.id,
            //     source: scaffSource,
            //     target: scaffTarget,
            //     amount: 1,
            //     directionOfSourceEnergy: [],
            //     directionOfTargetEnergy: [],
            //     type: "momentum",
            // });

            // const directionArray = ['(0,0)-(0,1)', '(-1,0)-(-1,1)'] //Move Left
            // // const directionArray = ['(1,1)-(0,0)', '(1,-1)-(0,-1)']
            // const newDirection = this.causalField.getOrCreateDirection(directionArray);
            // console.log("New direction: ", newDirection);
            // const animationSource = <GridCell>this.getObjectById('(0,0)');
            // const animationTarget: Direction = newDirection;
            // this.particleVisuals.startEnergyTransferAnimation({
            //     caller: newDirection.id,
            //     source: animationSource,
            //     target: animationTarget,
            //     amount: 1,
            //     directionOfSourceEnergy: [],
            //     directionOfTargetEnergy: [],
            //     type: "momentum",
            // });
              
          
            // obj.moveConnectedObjects({
            //     caller: newDirection.id,
            //     source: animationSource,
            //     target: animationTarget,
            //     amount: 1,
            //     directionOfSourceEnergy: [],
            //     directionOfTargetEnergy: [],
            //     type: "momentum",
            // })
        }
        //If selected cell is clicked again
        //Deselect it and all other currently selected objects
        if(isSelectedCell){
            // console.log("Deselecting all selected objects - calling getConnectedObjects");

            if(obj instanceof Direction){
                const collective = this.GOE.momentum.getConnectedObjects(obj);
                if(!collective){
                    console.log(`Object ${obj.id} had no collective`);
                }
                else{
                    const {causalNodes, edges } = collective;
                    const collectiveData = [
                        ...causalNodes,
                        ...edges
                    ]
                    collectiveData.forEach((obj: Direction)=>{
                        changeBoxColor(obj.visuals.selector, new Color4(1,1,1,1))
                        // obj.numberDisplayer && obj.numberDisplayer.dispose();
                    })
                }
            }

            this.deselectAllObjects();
            return;
        }
        //If source is clicked again deselect it
        if(isSelectedSource){
            // console.log("Deselecting source object");
            this.deselectSource();
            return;
        }
        //If target is clicked again deselect it
        if(isSelectedTarget){
            // console.log("Deselecting target object");
            this.deselectTarget();
            return;
        }
        if(this.selectionMode){
            if(!this.selectedObject) throw new Error("Selection mode engaged without selected cell");

            // console.log("GC Selected Source: ", this.selectedSource);
            if(!this.selectedSource && obj.isVisible){
                // Check if cell is a neighbor of the selected cell
                // const position = obj instanceof Direction ? obj.causalEnergy.position : obj.position;
                const position = obj.getPosition();
                const isNeighborOfSelectedObject = isPointNeighborOfCell(this.selectedObject.getPosition(), position)
                // console.log("Is direction neighbor of selected object? ", isNeighborOfSelectedObject);
                if (!isNeighborOfSelectedObject) return;
                // console.log("GC Selecting source: ", cell);
                this.selectSource(obj);
                return;
            }
            
            if(this.selectedSource && !this.selectedTarget){
                // Check if cell is a neighbor of both the selected cell and the source cell
                const isNeighborOfSelectedObject = isPointNeighborOfCell(this.selectedObject.getPosition(), obj.getPosition());
                const isNeighborOfSelectedSource = isPointNeighborOfCell(this.selectedSource.getPosition(), obj.getPosition());
                // console.log("isTargetNeighborOfEnergy: ", isTargetNeighborOfEnergy);
                if (!isNeighborOfSelectedObject) {
                    // console.log("Target is not a neighbor of the energy source object");
                    // console.log("Energy source object: ", this.selectedObject.position);
                    // console.log("Target position: ", cell.position);
                    return;
                }
                if (!isNeighborOfSelectedSource) {
                    // console.log("Target is not a neighbor of the source object");
                    // console.log("Selected source object: ", this.selectedSource.position);
                    // console.log("Target position: ", cell.position);
                    return;
                }
                // console.log("Energy source object: ", this.selectedObject.position);
                // console.log("Target position: ", cell.position);
                this.selectTarget(obj);
                return;
                // if (!this.selectedObject.neighbors.includes(cell.id) || !this.selectedSource.neighbors.includes(cell.id)) {
                //     return;
                // }
                // // console.log("Selecting target");
                // this.selectTarget(cell);
                // return;
            }
        }
        //Selecting cell
        if(!obj.isVisible) return this.deselectAllObjects();
        if(this.selectedObject) this.deselectAllObjects();
        // console.log("FE Energy source object: ", cell.position);
        this.selectCell(obj);
    }

    public onClickSelectArrow=(source: PossibleObject, target: PossibleObject, midpoint: Vector3)=>{
        if(this.isDragging) return;
        if(!source || !target){
            console.log("SourceCell: ", source)
            console.log("TargetCell: ", target)
            alert("Target or destination is invalid");
            return this.deselectAllObjects();
        }
        const energyForDirection = this.selectedObject.energyScroller.energyScrollerValue;
        if(energyForDirection === 0) return;

        if(!this.selectedObject.energyScroller) this.deselectAllObjects();

        // const currentTime = this.gameTime;
        // const positionIndex = `(${midpoint.x},${midpoint.z})`;
        const directionArrayOfEnergyCell = (this.selectedObject instanceof Direction || this.selectedObject instanceof SpatialAction) ? this.selectedObject.getDirectionArray() : [null, null]
        const directionArrayOfSourceEnergy = (source instanceof Direction || source instanceof SpatialAction) ? source.getDirectionArray() : [null, null]
        const directionArrayOfTargetEnergy = stripSpatialTag([source.id, target.id]);
        const possibleExistingDirection = this.GOE.causalField.getDirection(directionArrayOfTargetEnergy)
        // console.log("Did object already exist? ", possibleExistingDirection);

        // console.warn("Action by player")
        // console.log("Source: ", source);
        // console.log("Target: ", target);
        // console.log("Source position: ", source.getPosition());
        // console.log("Target target: ", target.getPosition());
        const position = calculateDirectionPosition(source,target);
        if((possibleExistingDirection) && !this.spatialMode){
            this.GOE.mechanics.attemptTask({
                caller: `GOE`,
                source: source.getPotentialObjectSettings(),
                target: {
                    type: "Direction",
                    settings: {
                        directionArray: directionArrayOfTargetEnergy,
                        position: position,
                    }
                },
                momentumSettings: null,
                energyToTransfer: energyForDirection,
                type: "cell to ce",
            });

            // this.GOE.particleVisuals.startEnergyTransferAnimation({
            //     caller: `Game of existence / player`,
            //     //@ts-ignore -- TODO, spatialAction will never be here I believe...
            //     source: this.selectedObject,
            //     directionOfSourceEnergy: directionArrayOfSourceEnergy,
            //     directionOfTargetEnergy: directionArrayOfTargetEnergy,
            //     target: possibleExistingDirection,
            //     amount: energyForDirection,
            //     type: "cell to ce",
            //     relative: false,
            // })
            return;
        }

        const directionId = convertDirectionArrayToString(directionArrayOfTargetEnergy);
        // const position = calculateDirectionPosition(source, target);
        // console.log("Calculating direction position: ", position);
        // console.log("Direction array: ", directionArrayOfTargetEnergy);
        const directionSettings: DirectionSettings = {
            id: directionId,
            GOE: undefined,
            causalField: undefined,
            directionIndex: convertDirectionArrayToString(directionArrayOfTargetEnergy),
            directionArray: directionArrayOfTargetEnergy,
            position,
        }
    
        let animationSettings: Task = {
            caller: `GOE`,
            source: this.selectedObject.getPotentialObjectSettings(),
            target: {
                type: "Direction",
                settings: {
                    directionArray: directionArrayOfTargetEnergy,
                    position: position,
                }
            },
            momentumSettings: null,
            energyToTransfer: energyForDirection,
            type: "init cell to ce",
        }

        

        if(this.spatialMode){
            const spatialActionId = `${source.id}-${target.id}-SA`;
            const result = this.GOE.spatialEnergyManager.canReserveEnergy(energyForDirection, this.selectedObject)
            if(!result) return alert("There isn't enough energy for this action");
            // console.log("Selected Object: ", this.selectedObject)
            // console.log("Selected Object ID: ", this.selectedObject.id)
            const spatialAction = this.GOE.spatialActionManager.createSpatialAction(spatialActionId, {
                id: spatialActionId,
                directionId: directionId,
                energyId: this.selectedObject.id,
                sourcePosition: this.selectedSource.getPosition(),
                targetPosition: this.selectedTarget.getPosition(),
                sourceId: source.id,
                targetId: target.id,
                position: position,
                directionSettings: directionSettings,
                animationSettings: animationSettings,
            })
            // console.log("Creating visuals - onClickSelectArrow")
            if(spatialAction) spatialAction.createVisuals(this.GOE.scene)
            return;
        }

        const isIdentical = isSourceAndTargetIdentical(directionArrayOfTargetEnergy);
        if(isIdentical) throw new Error("GOE onClickSelectArrow - Source and target identical")
        // const newDirection = this.GOE.causalField.getOrCreateDirection(directionArrayOfTargetEnergy)
        // animationSettings.target = newDirection;

        this.GOE.mechanics.attemptTask(animationSettings);
        // this.GOE.particleVisuals.startEnergyTransferAnimation(animationSettings);
    }

    deselectAllObjects=(colorSwitch: Boolean = true)=>{
        // console.log("Deselecting all objects");
        this.deselectCell(colorSwitch);
        this.deselectSource(colorSwitch);
        this.deselectTarget(colorSwitch);
        this.selectionMode = false;
    }

    switchSpatialComponentColor=( obj: PossibleObject, color: ObjectSelectionStates )=>{
        if(obj instanceof Direction){
            const actionId = `${obj.id}-SA`;
            const action = this.GOE.spatialActionManager.spatialActions[actionId];
            // console.log("Action Index: ", actionId);
            // console.log("Actions: ", this.spatialActionManager.spatialActions);
            // console.log("Switching spatial component to: ", color);
            // console.log("Component: ", action);
            if(!action) return;
            action.switchColor(color);
        }
    }

    deselectCell=(colorSwitch: Boolean = true)=>{
        if(!this.selectedObject) return;
        this.switchSpatialComponentColor(this.selectedObject, "default");
        this.GOE.particleVisuals.endHoverAnimation(this.selectedObject);
        colorSwitch && this.selectedObject.switchColor("default");
        this.selectedObject = null;
    }

    deselectSource=(colorSwitch: Boolean = true)=>{
        if(!this.selectedSource) return;
        this.switchSpatialComponentColor(this.selectedSource, "default");
        this.GOE.particleVisuals.endHoverAnimation(this.selectedSource);
        // @ts-ignore TODO - Think about how selecting causal energy works
        colorSwitch && this.selectedSource.switchColor("default");
        this.selectedSource = null;
        this.deselectTarget(colorSwitch);
        this.GOE.arrowVisuals.deleteSelectArrow();
    }

    deselectTarget=(colorSwitch: Boolean = true)=>{
        if(!this.selectedTarget) return;
        this.switchSpatialComponentColor(this.selectedTarget, "default");
        this.GOE.particleVisuals.endHoverAnimation(this.selectedTarget);
        colorSwitch && this.selectedTarget.switchColor("default");
        this.selectedTarget = null;
        this.GOE.arrowVisuals.deleteSelectArrow();
    }

    selectCell=(object: PossibleObject)=>{
        // console.log("Clicked Source: ", object);
        this.selectedObject = object;
        if(object instanceof GridCell) this.GOE.particleVisuals.startHoverAnimation(this.selectedObject);
        this.selectionMode = true;
        this.selectedObject.switchColor("cell");
    }

    selectSource=(source: PossibleObject)=>{
        this.selectedSource = source;
        // console.log("selectedSource: ", this.selectedSource);
        this.GOE.particleVisuals.startHoverAnimation(source);
        //@ts-ignore TODO - Think about how selecting causal energy works
        this.selectedSource.switchColor("source");
    }
    
    selectTarget=(target: PossibleObject)=>{
        // console.log("Clicked Target: ", target);
        this.selectedTarget = target;
        // console.log("selectedSource: ", this.selectedTarget);
        this.GOE.particleVisuals.startHoverAnimation(target);
        this.GOE.arrowVisuals.createSelectArrow(this.selectedSource, this.selectedTarget)
        //@ts-ignore TODO - Think about how selecting causal energy works
        this.selectedTarget.switchColor("target");
    }
}

export default GameControls;