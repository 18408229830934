import { ArcRotateCamera, Color3, Mesh, Scene, UniversalCamera, Vector3 } from "@babylonjs/core";
import GameOfExistence from "./GameOfExistence";
import { MINIMUM_MOVE_DISTANCE } from "../constants";
import MeshSelector from "./MeshSelector";
import { GridCellSettings, PotentialDirectionSettings, PotentialObjectSettings, SpatialActionSettings } from "../types";
import parseCoordinateString from "../helpers/parseCoordinateString";
import calculateDistanceBetweenPoints from "../helpers/calculateDistanceBetweenPoints";
import SpatialAction from "./SpatialAction";

class MeshDragger {
    private startingPoint: Vector3 | null = null;
    private selectedMeshes: Mesh[] = [];
    private originalPositions: Vector3[] = [];
    private lastPositions: Vector3[] = [];

    GOE: GameOfExistence;
    view: HTMLCanvasElement;
    scene: Scene;
    camera: UniversalCamera;
    movementThreshold: number = MINIMUM_MOVE_DISTANCE;
    private isDragging: boolean = false;
    isEnabled: boolean = false;
    meshSelector: MeshSelector = null;

    constructor(parent: MeshSelector) {
        this.GOE = parent.GOE;
        this.view = this.GOE.view;
        this.scene = this.GOE.scene;
        this.camera = this.GOE.camera;
        this.meshSelector = parent;
        this.initializeEvents();
    }

    private initializeEvents = () => {
        this.view.addEventListener("pointerdown", this.onPointerDown, false);
        this.view.addEventListener("pointerup", this.onPointerUp, false);
        this.view.addEventListener("pointermove", this.onPointerMove, false);

        this.scene.onDisposeObservable.add(() => {
            this.view.removeEventListener("pointerdown", this.onPointerDown);
            this.view.removeEventListener("pointerup", this.onPointerUp);
            this.view.removeEventListener("pointermove", this.onPointerMove);
        });
    }

    private getGroundPosition = (): Vector3 | null => {
        const GROUND = this.meshSelector.ground
        var pickinfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, function (mesh) { return mesh == GROUND; });
        if (pickinfo.hit) {
            return pickinfo.pickedPoint;
        }
        return null;
    }

    private onPointerDown = (e: PointerEvent) => {
        if (e.button !== 0) {
            return;
        }
        if(!this.isEnabled) return;
        if (this.meshSelector.isEnabled) return;
        // if(this.startingPoint) return;
        this.selectedMeshes = this.meshSelector.getSelectedMeshes();
        this.originalPositions = this.selectedMeshes.map(mesh => mesh.position.clone());
        this.lastPositions = this.originalPositions.map(pos => pos.clone());

        if (this.selectedMeshes.length > 0) {
            console.log("Selected meshes on POINTER DOWN: ", this.selectedMeshes);
            // console.log("New Mesh Ids: ", [...this.selectedMeshes].map((mesh)=>mesh.metadata.id))
            this.startingPoint = this.getGroundPosition();
            if (this.startingPoint) {
                this.isDragging = true;
                this.camera.detachControl(); // Detach control to prevent camera movement
            }
        }
    }

    private onPointerUp = () => {
        if(!this.isEnabled) return;
        if (!this.startingPoint) return;
        this.isDragging = false;
        this.camera.attachControl(this.view, true);
        this.startingPoint = null;
        this.selectedMeshes = [];
        this.originalPositions = [];
        this.lastPositions = [];
    }

    private onPointerMove = (evt: PointerEvent) => {
        if(!this.isEnabled) return;
        if (!this.isDragging) return;
        const currentPoint = this.getGroundPosition();
        if (!currentPoint) {
            return;
        }

        const diff = currentPoint.subtract(this.startingPoint);

        const totalMovementX = Math.abs(diff.x);
        const totalMovementZ = Math.abs(diff.z);

        const unitsX = Math.floor(totalMovementX / this.movementThreshold);
        const unitsZ = Math.floor(totalMovementZ / this.movementThreshold);

        const directionX = diff.x > 0 ? 1 : -1;
        const directionZ = diff.z > 0 ? 1 : -1;

        //No need to iterate inappropriately if sub threshold
        const isXSubthreshold = totalMovementX < this.movementThreshold;
        const isZSubthreshold = totalMovementZ < this.movementThreshold;
        if(isXSubthreshold && isZSubthreshold) return;
        this.startingPoint = currentPoint;

        let movedSpatialDirections = [];
        let spatialActionsMoved = [];
        let movedSpatialActionSettings = [];
        let actionCount = 0;
        this.selectedMeshes.forEach((mesh, index) => {
            const lastPosition = this.lastPositions[index];
            const deltaX = unitsX * this.movementThreshold * directionX;
            const deltaZ = unitsZ * this.movementThreshold * directionZ;
            // const newPositionX = Math.round(this.originalPositions[index].x + deltaX);
            // const newPositionZ = Math.round(this.originalPositions[index].z + deltaZ);
            const newPositionX = this.originalPositions[index].x + deltaX;
            const newPositionZ = this.originalPositions[index].z + deltaZ;
            const oldPosition = new Vector3(
                this.originalPositions[index].x,
                this.originalPositions[index].y,
                this.originalPositions[index].z,
            )
            if (mesh.metadata?.type === "arrow") {
                // const originalVertices = mesh.metadata.position;
                // // If the mesh relies on vertex data
                // const newVertices = originalVertices.map(v => {
                //     return new Vector3(
                //         v.x + (newPositionX - this.originalPositions[index].x),
                //         v.y,
                //         v.z + (newPositionZ - this.originalPositions[index].z)
                //     );
                // });

                // const vertexData = new Float32Array(newVertices.flatMap(v => [v.x, v.y, v.z]));
                // mesh.updateVerticesData("position", vertexData);
            } else {
                // If the mesh relies on position
                // mesh.position.x = newPositionX;
                // mesh.position.z = newPositionZ;
            };

            lastPosition.x = newPositionX;
            lastPosition.z = newPositionZ;
            // const {distance, direction} = this.getDraggedDistanceAndDirection(oldPosition, new Vector3(newPositionX, 0, newPositionZ))
            if (mesh.metadata?.type !== 'SpatialAction') return;
            actionCount+=1;
            // console.warn(`Moving ${mesh.metadata.id}`)
            // const spatialActionSettings: SpatialActionSettings = mesh.metadata;
            const spatialAction = this.GOE.spatialActionManager.getSpatialActionById(mesh.metadata.id)
            if(!spatialAction){
                console.log("Meta Data: ", mesh.metadata);
                console.log("Meta Data Id: ", mesh.metadata.id);
                // throw new Error("No action associated with this id");
                console.warn("No action associated with this id");
                return;
            }
            // const newSpatialAction = this.addPotentialObjectsToSpatialActions(this.getNewSpatialActionSettings(spatialAction, deltaX, deltaZ), deltaX, deltaZ)
            const newSpatialAction = this.getNewSpatialActionSettings(spatialAction, deltaX, deltaZ);
            
            movedSpatialActionSettings.push(newSpatialAction)
            if(newSpatialAction){
                // if(newSpatialAction.id === spatialAction.id) spatialAction.removeObjects();
                // // if(newSpatialAction.id === spatialAction.id) spatialAction.removeSelf();
                // console.log("Removing old spatial action: ", spatialAction.id);
                const oppAction = spatialAction.getOppositeAction();
                // oppAction.removeSelf();
                // spatialAction.removeSelf();

                // newSpatialAction.createVisuals(this.GOE.scene);
                movedSpatialDirections.push(spatialAction);

                // if(newSpatialAction.id !== spatialAction.id) return;
                // spatialActionsMoved.push(spatialAction);
            }
            else throw new Error("Failed to create spatial action");
        });
        const newSpatialActions = this.createSpatialDirections(movedSpatialActionSettings);
        // console.log("OG Spatial Actions Count: ", actionCount);
        // console.log("New Spatial Actions Count: ", newSpatialActions.length)
        // console.log("New Spatial Actions: ", new Set(newSpatialActions.map((obj)=>obj.id)));
        let newSelectedMeshes = this.renderSpatialDirections(newSpatialActions);
        newSelectedMeshes = newSelectedMeshes.filter((mesh: Mesh)=>{
            return mesh && mesh.metadata && mesh.metadata.id
        })

        // const newMeshIds = newSelectedMeshes.map((mesh)=>mesh.metadata?.id);

        for (let action of movedSpatialDirections){
            
            // const oppAction = action.getOppositeAction();
            // if(oppAction){
            //     if(!newMeshIds.includes(oppAction.id)) oppAction.removeSelf();
            // }
            // if(!newMeshIds.includes(action.id)) action.removeSelf();
            // if(oppAction) oppAction.removeSelf()
            action.removeSelf()
            // action.removeSelfFromMovedDirection()
        }

        this.GOE.spatialActionManager.transferMovedActionsToSpatialActions();
        // console.log("New Mesh Ids: ", newSelectedMeshes.map((mesh)=>mesh.metadata.id))
        // console.log("New Selected Meshes: ", newSelectedMeshes.length)
        if(newSelectedMeshes.length){
            this.selectedMeshes = newSelectedMeshes;
            this.meshSelector.setSelectedMeshes(newSelectedMeshes);
        }
    }

    private renderSpatialDirections=(spatialActions: SpatialAction[])=>{
        let newSelectedMeshes = [];
        for (let spatialAction of spatialActions){
            spatialAction.createVisuals(this.GOE.scene);
            // const oppAction = newSpatialAction.getOppositeAction();
            // if(oppAction) oppAction.createVisuals(this.GOE.scene);
            let meshes = spatialAction.getMeshesToMove();
            if(meshes.length){
                const newMeshes = meshes.map((mesh: Mesh)=>{
                    return this.meshSelector.addVertexPositionToMesh(mesh);
                })
                newSelectedMeshes = [...newSelectedMeshes, ...newMeshes]
            }
        }
        return newSelectedMeshes;
    }

    private createSpatialDirections=(movedSpatialActionSettings: SpatialActionSettings[])=>{
        let newSpatialActions = [];
        for (let spatialActionSettings of movedSpatialActionSettings){
            const newSpatialAction = this.GOE.spatialActionManager.createSpatialActionFromMeshDragger(spatialActionSettings.id, spatialActionSettings)
            if(!newSpatialAction) continue;
            newSpatialActions.push(newSpatialAction)
        }
        return newSpatialActions;
    }

    addPotentialObjectsToSpatialActions=(spatialActionSettings: SpatialActionSettings, deltaX:number, deltaZ: number)=>{
        spatialActionSettings.animationSettings.source = this.getNewPotentialObject(spatialActionSettings.animationSettings.source, deltaX, deltaZ);
        spatialActionSettings.animationSettings.target = this.getNewPotentialObject(spatialActionSettings.animationSettings.target, deltaX, deltaZ);
        return spatialActionSettings;
    }

    private getNewSpatialActionSettings=(spatialAction: SpatialAction | SpatialActionSettings, deltaX: number, deltaZ: number)=>{
        const newDirectionIndex = this.getNewDirectionIndex(spatialAction.directionSettings.id, deltaX, deltaZ)
        const newDirectionArray = this.getNewDirectionArray(spatialAction.directionSettings.directionArray, deltaX, deltaZ)
        const newSourceId = this.getNewDirectionIndex(spatialAction.sourceId, deltaX, deltaZ);
        const newTargetId = this.getNewDirectionIndex(spatialAction.targetId, deltaX, deltaZ);
        // console.log(`Old Id Style: ${newDirectionArray[0]}-${newDirectionArray[1]}-SA`);       
        // console.log(`Old Id Style: ${newSourceId}-${newTargetId}-SA`);       
        const newSettings: SpatialActionSettings = {
            id: `${newSourceId}-${newTargetId}-SA`,
            directionId: newDirectionIndex,
            directionSettings: {
                id: newDirectionIndex,
                directionArray: newDirectionArray,
                directionIndex: newDirectionIndex,
                position: this.getNewPosition(spatialAction.directionSettings.position, deltaX, deltaZ),
                GOE: this.GOE,
                causalField: this.GOE.causalField,
            },
            animationSettings: {
                caller: "GOE",
                // source: undefined,
                // target: undefined,
                source: this.getNewPotentialObject(spatialAction.animationSettings.source, deltaX, deltaZ),
                target: this.getNewPotentialObject(spatialAction.animationSettings.target, deltaX, deltaZ),
                energyToTransfer: spatialAction.animationSettings.energyToTransfer,
                type: "init cell to ce",
            },
            energyId: this.getNewDirectionIndex(spatialAction.energyId, deltaX, deltaZ),
            sourceId: newSourceId,
            targetId: newTargetId,
            position: this.getNewPosition(spatialAction.position, deltaX, deltaZ),
            sourcePosition: this.getNewPosition(spatialAction.sourcePosition, deltaX, deltaZ),
            targetPosition: this.getNewPosition(spatialAction.targetPosition, deltaX, deltaZ),
        }
        return newSettings;
        // // console.log("New settings: ", newSettings);
        // const newSpatialAction = this.GOE.spatialActionManager.createSpatialActionFromMeshDragger(newSettings.id, newSettings)
        // if(!newSpatialAction) return false;
        // // console.log("Success creating new spatial action");
        // return newSpatialAction;
    }

    getNewPotentialObject=(settings: PotentialObjectSettings, deltaX: number, deltaZ: number): PotentialObjectSettings=>{
        if(settings.type === "Direction"){
            const directionSettings = <PotentialDirectionSettings>settings.settings;
            return{
                type: "Direction",
                settings: {
                    directionArray: this.getNewDirectionArray(directionSettings.directionArray, deltaX, deltaZ),
                    position: this.getNewPosition(directionSettings.position, deltaX, deltaZ)
                }
            }
        }
        if(settings.type === "GridCell"){
            const gridCellSettings = <GridCellSettings>settings.settings;
            return{
                type: "GridCell",
                settings: {
                    index: this.getNewDirectionIndex(gridCellSettings.index, deltaX, deltaZ),
                    position: this.getNewPosition(gridCellSettings.position, deltaX, deltaZ),
                    initEnergyLevel: gridCellSettings.initEnergyLevel,
                }
            }
        }
        if(settings.type === "SpatialAction"){
            const spatialActionSettings = <SpatialActionSettings>settings.settings;
            return{
                type: "SpatialAction",
                settings: this.getNewSpatialActionSettings(spatialActionSettings, deltaX, deltaZ)
            }
        }
        return undefined
    }

    private getNewDirectionIndex = (directionIndex: string, deltaX: number, deltaZ: number) => {
        // console.log("Old Direction Index: ", directionIndex);
        const parsedIndex = this.parseCoordinateString(directionIndex);
        const newIndexArray = parsedIndex.map(({ vector, tag }) => {
            const shiftedPosition = this.getNewPosition(vector, deltaX, deltaZ);
            return { vector: shiftedPosition, tag };
        });
        const newDirectionIndex: string = newIndexArray.reduce((acc, { vector, tag }) => {
            return `${acc}(${vector.x},${vector.z})${tag}-`;
        }, "").slice(0, -1);
        // console.log("New Direction Index: ", newDirectionIndex);
        return newDirectionIndex;
    }

    parseCoordinateString=(input: string): { vector: Vector3, tag: string }[]=> {
        // Regular expression to match individual coordinate pairs including negative coordinates and tags
        const pairPattern = /\((-?\d+(\.\d+)?),(-?\d+(\.\d+)?)\)(-SA)?/g;
    
        const resultArray: { vector: Vector3, tag: string }[] = [];
        let match: RegExpExecArray | null;
    
        // Use a loop to find all matches in the input string
        while ((match = pairPattern.exec(input)) !== null) {
            const x = parseFloat(match[1]);
            const z = parseFloat(match[3]);
            const tag = match[5] || '';  // Capture the tag if it exists, otherwise use an empty string
            resultArray.push({ vector: new Vector3(x, 0, z), tag });
        }
    
        // Check if no valid pairs were found and handle the error if needed
        if (resultArray.length === 0) {
            throw new Error("Invalid format. The string must be in the form (x1,z1)-tag1-(x2,z2)-tag2...");
        }
    
        return resultArray;
    }

    private getNewDirectionArray = (directionArray: string[], deltaX: number, deltaZ: number)=>{
        const newDirectionSourceIndex = this.getNewDirectionIndex(directionArray[0], deltaX, deltaZ);
        const newDirectionTargetIndex = this.getNewDirectionIndex(directionArray[1], deltaX, deltaZ);
        return [newDirectionSourceIndex, newDirectionTargetIndex];
    }

    private getNewPosition=(originalPosition: Vector3, deltaX: number, deltaZ: number)=>{
        // originalPosition.add(direction.scale(distance));
        return new Vector3(
            originalPosition.x + deltaX,
            originalPosition.y,
            originalPosition.z + deltaZ
        )
    }

    public setEnabled=(value: boolean)=>{
        console.log(`${value ? "Enabling" : "Disabling"} mesh dragger`);
        this.isEnabled = value;
    }
  


}

export default MeshDragger;
