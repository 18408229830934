import React, { useEffect, useRef } from 'react';
import { COLORS } from '../constants';
import Card from './Card';

const EleaticFieldButton = React.forwardRef((props: any, ref) => {
  const { field, title, onClick, isMobile, isSelected, style } = props;
  const { component: Icon } = field;
  return (
    <Card
      onClick={()=>{
        // console.log("On click for: ", title);
        onClick && onClick()
      }}
      ref={ref}
      style={{
        minWidth: isMobile ? '80vw' : '10.5vw',
        marginLeft: isMobile ? '1em' : 0,
        marginRight: isMobile ? '.5em' : 0,
        borderRadius: 5,
        padding: 15,
        backgroundColor: isSelected ? COLORS.darkPastelGreen : COLORS.ivonaBrown,
        // backgroundColor: isSelected ? COLORS.pastelGreen : COLORS.cream,
        color: isSelected ? 'white' : 'white',
        fontWeight: '600',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        scrollSnapAlign: 'start', // Add scroll snap alignment
        boxShadow: '0px 0px 15px rgba(255, 255, 255, .5)',
        // display: 'inline-block',
      }}
    >
      <Icon style={{width: '4vw', height: '4vw'}}/>
      <div style={{textAlign: 'center', fontSize: '.5em'}}>
        {title}
      </div>
    </Card>
  );
});

export default EleaticFieldButton