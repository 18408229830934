import { ActionManager, Color3, Color4, ExecuteCodeAction, Mesh, MeshBuilder, StandardMaterial, Vector3 } from "@babylonjs/core";
import Direction from "./Direction";
import { DIRECTION_HOVER_HEIGHT, SPEED_OF_LIGHT } from "../constants";
import isObjEmpty from "../helpers/isObjEmpty";
import EnergyScroller from "./EnergyScroller";
import changeBoxColor from "../helpers/changeBoxColor";
import { ObjectSelectionStates } from "../types";
import StressBar from "./StressBar";
import GameOfExistence from "./GameOfExistence";


class DirectionVisuals {
    parent: Direction
    arrowObject: Mesh
    balancedArrow: Mesh
    selector: Mesh
    energyScroller: EnergyScroller;
    dissipationSignal: Mesh;
    bitFlipSignal: Mesh;
    arrowVisualsOff: boolean = false;
    otherVisualsOff: boolean = false;

    stressBar: StressBar;

    GOE: GameOfExistence = null;

    constructor(parent: Direction){
        this.parent = parent;
        this.GOE = this.parent.GOE;
        this.createStressBar();
    }

    updateVisuals = ()=>{
        // if(this.parent.justCreated) return;
        if(!this.parent.isCurrentlyActive() || !this.parent.inNeighborhood || !this.parent.isVisible){
            this.cleanUpVisuals(true);
            return;
        }
        if(this.parent.activeButOnlyHoldingSpace()){
            this.createSelector();
            return;
        }
        if(this.parent.dissipation.dissipated){
            this.disableDissipationSignal();
            this.cleanUpVisuals(true);
            return;
        }
        if(this.parent.dissipation.isDissipating){
            this.createDissipationSignal();
            this.disableStressBar();
            this.disableArrows();
            this.disableSelector();


            return;
        }

        if(this.parent.areForcesBalanced()){
            this.disableStressBar();

            if(this.arrowObject) this.disableCausalArrow();

            this.createBalancedArrow();
            this.createSelector();
            return;
        }
        if(this.parent.isForceGreaterThanOpposite()){
            this.enableAndUpdateStressBar();

            if(this.balancedArrow) this.disableBalancedArrow();
            const arrowResult = this.createCausalArrow();
            const selectorResult = this.createSelector();
            const oppSelectorResult = this.createOppositeSelector();
            return;
        }
        if(this.parent.isForceLessThanOpposite()){
            this.disableStressBar();

            if(this.balancedArrow) this.disableBalancedArrow();
            if(this.arrowObject) this.disableCausalArrow();

            this.createSelector();

            this.createOppositeArrow();
            return;
        }
    }
    updateOppositeVisuals=()=>{
        const oppObj = this.parent.getOppositeDirectionObject();
        if(oppObj) return oppObj.visuals.updateVisuals();
        else return;
    }
    disableAllVisualObjects=()=>{
        this.disableSelector();
        this.disableCausalArrow();
        this.disableBalancedArrow();
        this.disableEnergyScroller();
        this.disableDissipationSignal();
        this.disableStressBar();
        // this.disposeBitFlipSignal();
    }
    disposeAllVisualObjects=()=>{
        this.disposeSelector();
        this.disposeCausalArrow();
        this.disposeBalancedArrow();
        this.disposeEnergyScroller();
        this.disposeDissipationSignal();
        this.disposeStressBar();
        // this.disposeBitFlipSignal();
    }
    doesAnyVisualObjectExist = ()=>{
        if(
            this.selector ||
            this.arrowObject ||
            this.balancedArrow ||
            this.dissipationSignal ||
            this.energyScroller
        ) return true;
        else return false;
    }
    areVisualObjectsDisabled = ()=>{
        if(
            this.selector && this.selector.isEnabled() ||
            this.arrowObject && this.arrowObject.isEnabled() ||
            this.balancedArrow && this.balancedArrow.isEnabled() ||
            this.energyScroller && this.energyScroller.energyScroller.isEnabled() ||
            this.dissipationSignal && this.dissipationSignal.isEnabled() || 
            this.stressBar && this.stressBar.isEnabled()
        ) return false;
        else return true;
    }
    areVisualObjectsDisposed = ()=>{
        const isSelectorDisposed = !this.selector ? true : (this.selector && this.selector.isDisposed());
        const isArrowDisposed = !this.arrowObject ? true : (this.arrowObject && this.arrowObject.isDisposed())
        const isBalancedArrowDisposed = !this.balancedArrow ? true : (this.balancedArrow && this.balancedArrow.isDisposed())
        const isEnergyScrollerDisposed = !this.energyScroller?.energyScroller ? true : (this.energyScroller && this.energyScroller.energyScroller.isDisposed())
        const isDissipationSignalDisposed = !this.dissipationSignal ? true : (this.dissipationSignal && this.dissipationSignal.isDisposed())
        const isStressBarDisposed = !this.stressBar ? true : (this.stressBar && this.stressBar.isDisposed())
        if(
            isSelectorDisposed &&
            isArrowDisposed &&
            isBalancedArrowDisposed &&
            isEnergyScrollerDisposed &&
            isDissipationSignalDisposed &&
            isStressBarDisposed
        ) return true;
        else return false;
    }

    enableAndUpdateStressBar=()=>{
        if(!this.stressBar) return;
        if(!this.stressBar.isEnabled()) this.stressBar.enable();
        this.stressBar.updateStressBar();
    }

    switchColor=(state: ObjectSelectionStates)=>{
        if(!this.selector) return;
        //Switching the folor of the selector
        if(state === "default"){
            changeBoxColor(this.selector, new Color4(1,1,1,1))
            this.disableEnergyScroller();
            this.parent.spatialMode = false;
        }
        if(state === "cell"){
            const color = new Color4(0.721568627, 0.125490196, 0.145098039, 1);
            changeBoxColor(this.selector, color);
            this.createEnergyScroller();

        }
        if(state === "source"){
            const color = new Color4(0.0862745098,0.501960784,0.564705882, 1);
            changeBoxColor(this.selector, color);
        }
        if(state === "target"){
            const color = new Color4(0.874509804,1,0, 1);
            changeBoxColor(this.selector, color);
        }
        if(state === "spatial"){
            //Slate Gray
            const color = new Color4(0.44, 0.5, 0.56, 1);
            changeBoxColor(this.selector, color);
            this.parent.spatialMode = true;
        }
    }

    disableOppositeArrow = ()=>{
        // console.log(`Direction ${this.parent.id} attempting to remove opposite causal arrow ${this.parent.getOppositeDirectionObject().id}`);
        const oppObj = this.parent.getOppositeDirectionObject();
        // if(!oppObj) console.log(`Direction ${this.parent.id} failed to REMOVE opp arrow because obj doesn't exist`)
        if(!oppObj) return;
        const result = oppObj.visuals.disableCausalArrow();
        // console.log(`Direction ${this.parent.id} ${result ? 'succeeded' : 'failed'} in removing opposite arrow`)
        return result;
    }
    createOppositeArrow = ()=>{
        const oppObj = this.parent.getOppositeDirectionObject();
        // if(!oppObj) console.log(`Direction ${this.parent.id} failed to CREATE opp arrow because obj doesn't exist `);
        if(!oppObj) return;
        const result = oppObj.visuals.createCausalArrow();
        // console.log(`Direction ${this.parent.id} ${result ? 'succeeded' : 'failed'} in creating opposite arrow`);
        return result;
    }
    createOppositeSelector = ()=>{
        const oppObj = this.parent.getOppositeDirectionObject();
        // if(!oppObj) console.log(`Direction ${this.parent.id} failed to CREATE opp selector because obj doesn't exist `);
        if(!oppObj) return;
        const result = oppObj.visuals.createSelector();
        // console.log(`Direction ${this.parent.id} ${result ? 'succeeded' : 'failed'} in creating opposite arrow b/c it already exists`);
        return result;
    }
    disableOppositeSelector = ()=>{
        const oppObj = this.parent.getOppositeDirectionObject();
        // if(!oppObj) console.log(`Direction ${this.parent.id} failed to CREATE opp selector because obj doesn't exist `);
        if(!oppObj) return;
        const result = oppObj.visuals.disableSelector();
        // console.log(`Direction ${this.parent.id} ${result ? 'succeeded' : 'failed'} in removing opposite arrow b/c already removed`);
        return result;
    }
    cleanUpOppositeObjectVisuals = ()=>{
        const oppObj = this.parent.getOppositeDirectionObject();
        // if(!oppObj) console.log(`Direction ${this.parent.id} FAILED to clean up opp object visuals because doesn't exist`);
        if(!oppObj) return false;
        const result = oppObj.visuals.cleanUpVisuals();
        // console.log(`Direction ${this.parent.id} ${result?'SUCCEEDED' : 'FAILED'} to clean up opposite visual objects`);
        if(result) return true;
        else return false
    }
    disableArrows = ()=>{
        const arrowDisposed = this.disableCausalArrow();
        const balancedDisposed = this.disableBalancedArrow();
        // console.log(`Direction ${this.parent.id}`);
        // console.log("Was arrow disposed? ", arrowDisposed);
        // console.log("Was balanced arrow disposed? ", balancedDisposed);
    }
    cleanUpVisuals = (ignoreEnergyLevel: boolean = false)=>{
        if(this.parent.getEnergyLevel() > 0 && !ignoreEnergyLevel) return false;
        this.disableSelector();
        this.disableCausalArrow();
        this.disableBalancedArrow();
        this.disableEnergyScroller();
        this.disableDissipationSignal();
        this.disableStressBar();
        return true;
    }
    createStressBar=()=>{
        if(this.otherVisualsOff) return false;
        if(this.stressBar && !this.stressBar.isEnabled()){
            this.stressBar.enable();
            return false;
        };
        this.stressBar = new StressBar(this.parent);
        return true;
    }
    createSelector=()=>{
        if(this.otherVisualsOff) return;
        if(this.arrowVisualsOff) return;
        if(this.selector && this.selector.isEnabled()) return false;
        if(this.selector && !this.selector.isEnabled()){
            // console.log(`Direction ${this.parent.id} is enabling selector`)
            this.selector.setEnabled(true);
            this.selector.isVisible = true;
            return true;
        }
        if(!this.selector){
            // console.log(`Direction ${this.parent.id} is creating selector for the first time`)
            const distanceBetweenSourceAndTarget = this.parent.getDistanceBetweenSourceAndTarget();
            // console.log("distanceBetweenSourceAndTarget: ", distanceBetweenSourceAndTarget);
            const size = distanceBetweenSourceAndTarget*.1
            var box = MeshBuilder.CreateBox(`${this.parent.id}-selector`, { size: .05 }, this.GOE.scene);
            this.selector = box;
            // const {x,y,z} = this.getPosition();
            const {x,y,z} = this.parent.getPosition();
            // console.log("Parent Position: ", this.parent.position)
            // console.log(`Direction ${this.parent.id} position on create selector: `, [x,y,z])
            // console.log("Modified Position: ", [x,y*1.5,z])
            //TODO: The y position is definitely not getting set properly.
            // box.position = new Vector3(x,y,z);
            box.position = new Vector3(x,DIRECTION_HOVER_HEIGHT*1.5,z);
            box.actionManager = new ActionManager(this.GOE.scene);
            ((GOE, direction, self)=>{
                box.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function(ev){	
                   GOE.controls.onObjectSelect(direction);
                }));
                box.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function(ev){	
                    if(!direction.isVisible) return;
                    const { selectedObject, selectedSource, selectedTarget } = GOE.controls;
                    const isSelectedCell = selectedObject && (selectedObject.id === direction.id);
                    const isSelectedSource = selectedSource && (selectedSource.id === direction.id)
                    const isSelectedTarget = selectedTarget && (selectedTarget.id === direction.id)
                    const isSelected = isSelectedCell || isSelectedSource || isSelectedTarget
                    if(!isSelected){
                        // direction.switchColor('hover');
                        // self.selector.scaling = new Vector3(1.2, 1.2, 1.2); // Increase size by 20%
                    }
                }));
                box.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function(ev){	
                    if(!direction.isVisible) return;
                    const { selectedObject, selectedSource, selectedTarget } = GOE.controls;
                    const isSelectedCell = selectedObject && (selectedObject.id === direction.id);
                    const isSelectedSource = selectedSource && (selectedSource.id === direction.id)
                    const isSelectedTarget = selectedTarget && (selectedTarget.id === direction.id)
                    const isSelected = isSelectedCell || isSelectedSource || isSelectedTarget
                    if(!isSelected){
                        // direction.switchColor('default');
                        // self.selector.scaling = new Vector3(1, 1, 1); // Reset to original size
                    }
                }));
            })(this.GOE, this.parent, this)
            return true;
        }
    }
    createDissipationSignal=()=>{
        if(this.otherVisualsOff) return false;
        if( this.dissipationSignal && this.dissipationSignal.isEnabled() ) return false;
        // console.log(`Creating dissipation signal for ${this.id}`)
        if( this.dissipationSignal && !this.dissipationSignal.isEnabled() ){
            // console.log(`Direction ${this.parent.id} is setting dissipation signal enabled`);
            this.dissipationSignal.setEnabled(true);
            return true;
        }
        if( !this.dissipationSignal ){
            // console.log(`Direction ${this.parent.id} is creating dissipation signal for the first time`);
            var box = MeshBuilder.CreateBox(this.parent.id, { size: .1 }, this.GOE.scene);
            this.makeObjClickable(box);
            // changeBoxColor(box, new Color4(1,.01,.01,.8))
            var material = new StandardMaterial(`Diss-${this.parent.id}`, this.GOE.scene);
            material.alpha = 1;
            material.diffuseColor = new Color3(1.0, 0,0);
            material.specularColor = new Color3(1, 0, 0); // Specular color for shiny surfaces
    
            box.material = material;
            this.dissipationSignal = box;
            const {x,y,z} = this.parent.getPosition();
            box.position = new Vector3(x,y,z);
            return true;
        }
    }
    createBitFlipSignal=()=>{
        if(this.otherVisualsOff) return false;
        if( this.bitFlipSignal ) return;
        // console.log(`Creating dissipation signal for ${this.id}`)
        var box = MeshBuilder.CreateBox(this.parent.id, { size: .1 }, this.GOE.scene);
        changeBoxColor(box, new Color4(.1,.1,1,1))
        this.bitFlipSignal = box;
        const {x,y,z} = this.parent.getPosition();
        box.position = new Vector3(x,y,z);
    }
    createEnergyScroller=()=>{
        const {GOE, energyLevel} = this.parent;
        this.parent.energyScroller = new EnergyScroller({GOE, energyScrollerValue: energyLevel > 0 ? 1 : 0, parent: this.parent})
    }
    createCausalArrow = ()=>{
        if(this.arrowVisualsOff) return;
        if(!this.parent.isForceGreaterThanOpposite()) return;
        if(this.arrowObject && this.arrowObject.isEnabled()) return false;
        if(this.arrowObject && !this.arrowObject.isEnabled()){
            this.arrowObject.setEnabled(true);
            return true;
        }
        if(!this.arrowObject){
            const [arrow, position] = this.GOE.arrowVisuals.createArrow({
                id: this.parent.source.id, position: this.parent.source.getPosition()
            },
            {id: this.parent.target.id, position: this.parent.target.getPosition()},
            this.GOE.scene);
            this.assignCausalArrow(arrow);
            return true;
        }
    }
    makeObjClickable=(arrow: Mesh)=>{
        arrow.actionManager = new ActionManager(this.GOE.scene);
        ((GOE, direction, self)=>{
            arrow.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function(ev){
                console.log(`Direction ${direction.id} clicked: `, direction);

                console.log("Active? ", direction.isCurrentlyActive());
                console.log("Light speed passed? ", direction.hasClockTicked());
                const deltaTime = direction.GOE.gameTime - direction.lastTimeEnergyMoved;
                console.log("Delta time: ", deltaTime);
                console.log("last time energy moved: ", direction.lastTimeEnergyMoved);
                console.log("Speed of light: ", SPEED_OF_LIGHT);
                // const deltaTime = this.GOE.gameTime - this.lastTimeEnergyMoved;
                // if ( SPEED_OF_LIGHT > deltaTime){
                //     // console.log(`Direction ${this.id} speed limit enforced`);
                //     return false
                // }
                // if(!isCurrentlyActive) return this.removalProcess();
                if(direction.activeButOnlyHoldingSpace()) console.log(`Direction ${direction.id} active but holding space`);
                if(!direction.hasClockTicked()) console.log(`Light speed hasn't passed for ${direction.id}`)
                if(!direction.isForceGreaterThanOpposite()) console.log(`Direction ${direction.id} isn't greater than opposite`)
                if(direction.dissipation.dissipated) {
                    console.log(`Direction ${direction.id} dissipated and can't move with energy ${direction.getEnergyLevel()}`);
                    return;
                }
                // console.log(`Direction ${this.id} checking if task is possible on move energy`);
                if(direction.dissipation.isDissipating || direction.energyCapacity.isTaskImpossible(direction)) console.log(`Direction ${this.id} would dissipate`)
                if(direction.isCorrelating()){
                    console.log(`Direction ${direction.id} is balanced with ${direction.getEnergyLevel()} energy`);
                    return;
                }
            }));
            arrow.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function(ev){	
                if(!direction.isVisible) return;
                const { selectedObject, selectedSource, selectedTarget } = GOE.controls;
                const isSelectedCell = selectedObject && (selectedObject.id === direction.id);
                const isSelectedSource = selectedSource && (selectedSource.id === direction.id)
                const isSelectedTarget = selectedTarget && (selectedTarget.id === direction.id)
                const isSelected = isSelectedCell || isSelectedSource || isSelectedTarget
                if(!isSelected){
                    // direction.switchColor('hover');
                    if(self.arrowObject) self.arrowObject.scaling = new Vector3(1.2, 1.2, 1.2); // Increase size by 20%
                    if(self.balancedArrow) self.balancedArrow.scaling = new Vector3(1.2, 1.2, 1.2); // Increase size by 20%
                }
            }));
            arrow.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function(ev){	
                if(!direction.isVisible) return;
                const { selectedObject, selectedSource, selectedTarget } = GOE.controls;
                const isSelectedCell = selectedObject && (selectedObject.id === direction.id);
                const isSelectedSource = selectedSource && (selectedSource.id === direction.id)
                const isSelectedTarget = selectedTarget && (selectedTarget.id === direction.id)
                const isSelected = isSelectedCell || isSelectedSource || isSelectedTarget
                if(!isSelected){
                    // direction.switchColor('default');
                    // direction.selector.scaling = new Vector3(1, 1, 1); // Reset to original size
                    if(self.arrowObject) self.arrowObject.scaling = new Vector3(1, 1, 1); // Increase size by 20%
                    if(self.balancedArrow) self.balancedArrow.scaling = new Vector3(1, 1, 1); // Increase size by 20%

                }
            }));
        })(this.GOE, this.parent, this)
    }
    createBalancedArrow = ()=>{
        const { GOE } = this.parent;
        if(this.arrowVisualsOff) return;
        if(this.balancedArrow && this.balancedArrow.isEnabled()) return false;
        if(this.balancedArrow && !this.balancedArrow.isEnabled()){
            this.balancedArrow.setEnabled(true);
            return true;
        }

        // console.log("Creating balanced arrow");
        //Create double ended arrow and pass it to opposite direction as causal arrow.
        if(!this.balancedArrow){
            const [balancedCausalEdge, midpoint] = GOE.arrowVisuals.createDoubleEndedArrow(
                {id: this.parent.source.id, position: this.parent.source.getPosition()},
                {id: this.parent.target.id, position: this.parent.target.getPosition()},
                this.GOE.scene
            );
            this.assignBalancedArrow(balancedCausalEdge);
            return true;
        }

        // this.disableCausalArrow();
        // this.createSelector();
        // this.makeArrowClickable(balancedCausalEdge);
        // this.createOppositeSelector();
        // this.removeOppositeArrow();
    }
    assignCausalArrow = (arrow: Mesh)=>{
        // console.log(`Assigning causal arrow to ${this.id}`)
        this.arrowObject = arrow;
    }
    assignBalancedArrow = (arrow: Mesh)=>{
        // console.log(`Assigning balanced causal arrow to ${this.id}`)
        this.balancedArrow = arrow;
    }
    disableEnergyScroller=()=>{
        // console.log("disposing energy control");
        if(!this.parent.energyScroller) return false;
        if(this.parent.energyScroller.energyScroller.isDisposed()) return false;
        if(!this.parent.energyScroller.energyScroller.isEnabled()) return false;
        this.parent.energyScroller.energyScroller.setEnabled(false);
        // this.energyScroller = null;
        return true;
    }
    disableCausalArrow = ()=>{
        if(!this.arrowObject) return false;
        if(this.arrowObject.isDisposed()) return false;
        if(!this.arrowObject.isEnabled()) return false;
        // console.log(`Direction ${this.parent.id} disposing causal arrow`);
        this.arrowObject.setEnabled(false);
        // this.arrowObject = null;
        return true;
    }
    disableBalancedArrow = ()=>{
        if(!this.balancedArrow) return false;
        if(this.balancedArrow.isDisposed()) return false;
        if(!this.balancedArrow.isEnabled()) return false;
        // console.log(`Direction ${this.parent.id} disposing balanced causal arrow`);
        this.balancedArrow.setEnabled(false);
        this.balancedArrow = null;
        return true;
    }
    disableSelector=()=>{
        if(!this.selector) return false;
        if(this.selector.isDisposed()) return false;
        if(!this.selector.isEnabled()) return false;
        this.selector.setEnabled(false);
        return true;
    }
    disableStressBar=()=>{
        if(!this.stressBar) return false;
        if(!this.stressBar.isEnabled()) return false;
        // this.stressBar.hide();
        this.stressBar.disableStressBar();
        return true;
    }
    disableDissipationSignal=()=>{
        if(!this.dissipationSignal) return false;
        if(this.dissipationSignal.isDisposed()) return false;
        if(!this.dissipationSignal.isEnabled()) return false;
        this.dissipationSignal.setEnabled(false);
        return true;
    }
    disposeStressBar=()=>{
        if(!this.stressBar) return false;
        if(this.stressBar.isDisposed()) return true;
        this.stressBar.dispose();
        return true;
    }
    disposeEnergyScroller=()=>{
        // console.log("disposing energy control");
        if(!this.parent.energyScroller) return false;
        if(this.parent.energyScroller.energyScroller.isDisposed()) return false;
        this.parent.energyScroller.dispose();
        // this.energyScroller = null;
        return true;
    }
    disposeCausalArrow = ()=>{
        if(!this.arrowObject) return false;
        if(this.arrowObject.isDisposed()) return false;
        // console.log(`Direction ${this.parent.id} disposing causal arrow`);
        this.arrowObject.dispose();
        // this.arrowObject = null;
        return true;
    }
    disposeBalancedArrow = ()=>{
        if(!this.balancedArrow) return false;
        if(this.balancedArrow.isDisposed()) return false;
        // console.log(`Direction ${this.parent.id} disposing balanced causal arrow`);
        this.balancedArrow.dispose();
        // this.balancedArrow = null;
        return true;
    }
    disposeSelector=()=>{
        if(!this.selector) return false;
        if(this.selector.isDisposed()) return false;
        this.selector.dispose();
        return true;
    }
    disposeDissipationSignal=()=>{
        if(!this.dissipationSignal) return false;
        if(this.dissipationSignal.isDisposed()) return false;
        this.dissipationSignal.dispose();
        return true;
    }
    testCleanUp = (result: boolean)=>{
        if(result){
            const oppObj = this.parent.getOppositeDirectionObject();
            if(this.selector || oppObj.visuals.selector){
                console.log(`Direction ${this.parent.id}`, this.selector);
                console.log("Opp: ", oppObj.visuals.selector);
                console.warn(`Selector existed even after clean up`);
                if(oppObj.visuals.selector) oppObj.visuals.selector.dispose()
                if(this.selector) this.selector.dispose()
                // throw new Error("Selector exists even after clean up")
            }
        }
    }
  
}

export default DirectionVisuals;