import { Vector3 } from "@babylonjs/core";

const getMidpoint = (pointA: Vector3, pointB: Vector3) => {
    return pointA.add(pointB).scale(0.5);
    // return new Vector3(
    //     (pointA.x + pointB.x) / 2,
    //     (pointA.y + pointB.y) / 2,
    //     (pointA.z + pointB.z) / 2
    // );
}

export default getMidpoint