import { Control } from "@babylonjs/gui";
import Tutorial from ".";
import isObjEmpty from "../../helpers/isObjEmpty";
import Direction from "../Direction";
import GameControls from "../GameControls";
import GameOfExistence from "../GameOfExistence";
import GridCell from "../GridCell";
import { Color3, DynamicTexture, Mesh, MeshBuilder, StandardMaterial } from "@babylonjs/core";
import { GridMaterial } from "@babylonjs/materials";

const divOpenTag = `<div>`;
const divCloseTag = `</div>`;
const openSpanTag = `<span style='display: inline-block;'>`;
const closeSpanTag = `</span>`;

class TutorialManager {
    GOE: GameOfExistence = null;
    controls: GameControls = null;
    tutorialSteps: { instruction: string, condition: () => boolean }[];
    tutorial: Tutorial = null;
    sourceNeighborhoodMesh: Mesh;
    energyNeighborhoodMesh: Mesh;
    flags: {[key: string]: boolean} = {};
    // openSpanTag: `<span style='display: inline-block;'>`;
    // closeSpanTag: `</span>`;
    isDelaying: boolean = false;


    constructor(GOE: GameOfExistence, tutorial: Tutorial) {
        this.GOE = GOE;
        this.controls = GOE.controls;
        this.tutorial = tutorial;
        this.tutorialSteps = [];
    }
    delay(ms: number): Promise<void> {
        this.isDelaying = true; // Set the state to indicate delay is in progress
        // this.tutorial.disableNextButton();
        return new Promise<void>(resolve => {
            setTimeout(() => {
                // this.tutorial.enableStepButtons();
                this.isDelaying = false; // Reset the state after the delay
                resolve();
            }, ms);
        });
    }
    clearTutorialScene=async ()=>{
        this.GOE.spatialActionManager.removeSpatialActionsFromMainScene();
        await this.GOE.resetParticles();
    }
    introductionTutorial = ()=>{
        return(
            [
                {
                    instruction: `${divOpenTag}Welcome to HGoE!</br></br>Where the only goal of the game is to maintain one's existence by creating objects and maintaining the existence of those objects.${divCloseTag}`,
                    condition: () => true,
                },
                {
                    instruction: `In the middle of your screen, you'll see the 3x3 starting grid.</br></br>These grid cells are 'pieces of space' which can contain particles that represent 'pieces of energy'`,
                    condition: () => true // Automatically proceed
                },
                {
                    instruction: `The full grid is 10x10, but those grid cells are hidden until discovered as discussed later. </br> While the grid is finite in this example, there is no reason the grid cannot be made to expand indefinitely.`,
                    condition: () => true // Automatically proceed
                },
                {
                    instruction: `You can use the WASD keys to move around and click+drag with the mouse to control where the camera is looking.</br></br>Press "o" to unlock and relock the camera height so you can adjust it`,
                    condition: () => true // Automatically proceed
                },
                {
                    instruction: 'To create an object you must use the "Energy, Source, Target" (EST) rule</br> To demonstrate, click on a cell to select it as your Energy Cell.',
                    condition: () =>{
                        return !!this.controls.selectedObject // Proceed when the source cell is selected
                    }
                },
                {
                    instruction: `The number 0 will appear above the object.</br>Click the number to select it, then use your scroll wheel, or up down arrow keys</br> These allow you to choose the amount of energy you'd like your object to have`,
                    condition:() =>{
                        console.log("this.tutorial.getEnergyScrollerValue(): ", this.tutorial.getEnergyScrollerValue());
                        return this.tutorial.getEnergyScrollerValue() > 0 // Proceed when the energy is above zero
                    }
                },
                {
                    instruction: "Next, select a source cell.</br>The source cell must be in neighborhood of the energy cell as indicated by the yellow square",
                    cleanUp: ()=>{
                        console.log("Cleaning up energy mesh: ", {...this.energyNeighborhoodMesh})
                        this.energyNeighborhoodMesh && this.energyNeighborhoodMesh.setEnabled(false);
                        this.energyNeighborhoodMesh && this.energyNeighborhoodMesh.dispose();
                        this.energyNeighborhoodMesh = null;
                    },
                    sideEffect: ()=>{
                        const obj = this.controls.selectedObject;
                        if(!obj) return;
                        const scene = this.GOE.tools.scene;
                        // Create the plane
                        var plane = MeshBuilder.CreatePlane('radiusThang1', { size: 2, sideOrientation: Mesh.DOUBLESIDE }, scene);
                        plane.rotation.x = Math.PI / 2;
                        plane.position = obj.getPosition();
                        const materialObj = this.GOE.tools && this.GOE.tools.gridMaterial ? this.GOE.tools.gridMaterial : GridMaterial
                        const mat = new materialObj('groundMaterial1', scene);
                        mat.gridRatio = 0.1;
                        plane.material = mat;
                        
                        // mat.lineColor = new Color3(0.821568627,0.125490196,0.145098039);
                        // mat.lineColor = new Color3(0.721568627,0.125490196,0.145098039);
                        mat.lineColor = new Color3(0.974509804,1,0);
                        this.energyNeighborhoodMesh = plane;
                        this.tutorial.enableStepButtons();
                    },
                    condition: () =>{
                        console.log("This.controls.selectedSource: ", this.controls.selectedSource);
                        return !!this.controls.selectedSource // Proceed when the source cell is selected
                    }
                },
                {
                    instruction: "Now, select a Target Cell, which must be neighbors of both the source and energy cell.</br>Recall that there are hidden cells outside your vision with the same spacing.</br>You can try selecting one of these as your target, which will expand your field of view.",
                    cleanUp: ()=>{
                        console.log("Cleaning up source mesh: ", {...this.sourceNeighborhoodMesh})
                        this.sourceNeighborhoodMesh && this.sourceNeighborhoodMesh.setEnabled(false);
                        this.sourceNeighborhoodMesh && this.sourceNeighborhoodMesh.dispose();
                        this.sourceNeighborhoodMesh = null;
                    },
                    sideEffect: ()=>{
                        const obj = this.controls.selectedSource;
                        if(!obj) return;
                        const scene = this.GOE.tools.scene;
                        // Create the plane
                        var plane = MeshBuilder.CreatePlane('radiusThang2', { size: 2, sideOrientation: Mesh.DOUBLESIDE }, scene);
                        plane.rotation.x = Math.PI / 2;
                        plane.position = obj.getPosition();
                        const materialObj = this.GOE.tools && this.GOE.tools.gridMaterial ? this.GOE.tools.gridMaterial : GridMaterial
                        const mat = new materialObj('groundMaterial2', scene);
                        mat.gridRatio = 0.1;
                        plane.material = mat;
                        
                        // mat.lineColor = new Color3(0.821568627,0.125490196,0.145098039);
                        // mat.lineColor = new Color3(0.721568627,0.125490196,0.145098039);
                        mat.lineColor = new Color3(1.0,1.0,1.0);
                        this.sourceNeighborhoodMesh = plane;
                        this.tutorial.enableStepButtons('selectedSource');
                    },
                    condition: () => !!this.controls.selectedTarget// Proceed when the target cell is selected
                },
                {
                    instruction: `Fianlly, click the red arrow to create your object with your specified energy and direction.</br></br>Note: You can click on the background to deselect all objects at once, or click individual object to deselect them.`,
                    condition: () =>{
                        // const direction = Object.values(this.GOE.causalField.directions)[0];
                        return this.tutorial.doesDirectionExist(); // Proceed when direction is created

                    }
                },
                {
                    instruction: `Great, you have created your first object!</br>It will now transfer${this.tutorial.doesDirectionExist() ? ` ${Object.values(this.GOE.causalField.directions)[0].getEnergyLevel()} energy ` : ' energy '}from the source cell to the target cell as long as their is energy in the source</br>Therefore, the more energy the object has, the more energy it moves`,
                    sideEffect: ()=>{
                        // console.log("Cleaning source and energy meshes")
                        this.sourceNeighborhoodMesh && this.sourceNeighborhoodMesh.dispose();
                        this.energyNeighborhoodMesh && this.energyNeighborhoodMesh.dispose();
                        this.tutorial.enableStepButtons();
                    },
                    condition: () => true // Automatically proceed
                },
            ]
        );
    }
    stressTutorial = ()=>{
        return [
                            
            {
                //Side Effect: Stop time...
                instruction: "As the energy in the source is depleted, notice the small 'health bar' above the arrow that increases in size and begins to turn red.</br>This represents how 'stressed' or close to non-existence an arrow is.",
                condition: () => true // Automatically proceed
            },

            {
                //Side Effect: Start Time
                instruction: "Once depleted, the bar reaches its maximum size, the energy object scatters it's energy to neighboring cells, or objects, and its associated grid cells become hidden. </br>Wait till the object dissipates to proceed. </br></br>Tip: Add more energy to the direction via EST rule to shorten your wait!",
                condition: ()=> !this.tutorial.doesDirectionExist() // Automatically proceed
            },

            {
                instruction: `</br>Notice that when an object dissipates, grid cells become hidden.</br>The game of existence is lost when you're no longer able to create an object via the EST rule.</br> Note, in single player mode, you can press "T" to stop and start time.</br> You can press "C" to dissipate all objects automatically and reset grid to starting state`,
                condition: async () =>{
                    await this.GOE.resetParticles();
                    return !this.tutorial.doesDirectionExist();
                } // Automatically proceed
            },
            
            {
                instruction: `${divOpenTag}At the very top of your screen, you can see an equation which displays your 'health' or stress level represented by the symbol ${openSpanTag}$$\\sigma_g$$${closeSpanTag} This equation partially quantifies how close you may be to losing the game of existence.${divCloseTag}`,
                sideEffect: ()=>{
                    this.tutorial.triggerShowHealthBar();
                    this.tutorial.enableStepButtons();
                },
                condition: () => true // Automatically proceed 
            },
            {
                instruction: `${divOpenTag}${openSpanTag}$$\\sum_i F_i$$${closeSpanTag} is 'observed free energy', or energy that is located in a grid cell rather than an object${divCloseTag}`,
                condition: () => true // Automatically proceed
            },
            {
                instruction: `${divOpenTag}${openSpanTag}$$\\sum_i CE_i$$${closeSpanTag} is 'observed causal energy', which is energy located inside of each arrow${divCloseTag}`,
                condition: () => true // Automatically proceed
            },
            {
                instruction: `${divOpenTag}${openSpanTag}$$\\sum_i CE_i (1 - \\sigma_{CE})$$${closeSpanTag} is the 'observed causal energy' but weighted by its stress so that the more stressed objects are, the less those objects lower your own stress${divCloseTag}`,
                condition: () => true // Automatically proceed
            },
            {
                instruction: `While the goal of HGoE is only to exist, acting to minimize this stress value has the potential to create a self-sustaining world.</br>You can toggle your stress bar off and on with the "h" key `,
                condition: () =>{
                    // if(this.GOE.stress.isVisible()){
                    //     console.log("Stress is visible");
                    //     console.log("Triggering show health bar to hide");
                    //     this.tutorial.triggerShowHealthBar(false);
                    // }
                    return true;
                } // Automatically proceed
            },
            {
                instruction: `Virtual worlds are possible through what are called "Correlators". Correlators consists of two objects that are equal in energy, but opposite in direction`,
                demoStart: true,
                sideEffect: async ()=>{
                    // this.tutorial.disableNextButton();

                    if(this.GOE.stress.isVisible()){
                        console.log("Stress is visible");
                        console.log("Triggering show health bar to hide");
                        this.tutorial.triggerShowHealthBar(false);
                    }
                    await this.GOE.controls.recoverSpatialActionsAsync('correlatorDemo');
                    await this.delay(1000);
                    this.GOE.controls.exitSpatialMode();

                    this.tutorial.enableStepButtons();

                },
                condition: () =>{
                    //load correlatorDemo
                    return !this.isDelaying;
                } // Automatically proceed
            },
        ]
    }
    correlatorsTutorial = ()=>{
        return [
            {
                //Load demonstration of energy change
                instruction: `Correlators only move energy when the amount of energy at either of its ends changes`,
                sideEffect: async ()=>{
                    await this.GOE.controls.recoverSpatialActionsAsync('correlatorDemoOutArrow');
                    await this.delay(1000);
                    this.GOE.controls.exitSpatialMode();
                    this.tutorial.enableStepButtons();
                },
                condition: async () =>{
                    // await this.GOE.resetParticles();
                    return this.tutorial.areDirectionsDissipated();
                } // Automatically proceed
            },
            {
                //Load correlated arrow demo/ussage
                instruction: `This allows you to set up arrows whose directions are correlated`,
                demoStart: true,
                sideEffect: async ()=>{
                    await this.clearTutorialScene()
                    await this.delay(2000);                    
                    await this.GOE.controls.recoverSpatialActionsAsync('couplerDemo');
                    await this.delay(1000);
                    this.GOE.controls.exitSpatialMode();
                    this.tutorial.enableStepButtons();
                },
                condition: () => true
            },
            {
                instruction: `For example, if we flip the left bit, this triggers the correlators to move energy and flip the right bit`,
                sideEffect: async ()=>{
                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('couplerDemoFlipLeft');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['leftBit'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: () =>{
                    return this.flags['leftBit'];
                } // Automatically proceed
            },
            {
                instruction: `And, if we flip the right bit then we get the opposite`,
                sideEffect: async ()=>{
                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('couplerDemoFlipRight');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['rightBit'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: async () =>{
                    // this.GOE.spatialActionManager.removeSpatialActionsFromMainScene();
                    return this.flags['rightBit']
                } 
            },
            {
                //Load NAND Gate demo/ussage
                instruction: `When interpreting arrows as 0s and 1s in a computing circuit, this allows for the creation of logical gates like the universal NAND Gate.</br>NAND Gates can be put together to build a universal computer, like the one you're using right now.</br>Therefore, this demonstrates that it is in principle possible to build entire computers within this budding virtual world of HGoE.`,
                fontSize: '1.7em',
                demoStart: true,
                sideEffect: async ()=>{
                    await this.clearTutorialScene()
                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('NAND');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['NAND'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: () => this.flags['NAND']
            },
            {
                instruction: `Similarly, when we flip the left bit, our output bit (top most arrow) computes the appropriate value`,
                sideEffect: async ()=>{
                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('couplerDemoFlipLeft');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['nandLeft'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: () =>{
                    return this.flags['nandLeft'];
                } // Automatically proceed
            },
            {
                instruction: `And, if we flip the right bit we can reset the gate to it's original state`,
                sideEffect: async ()=>{
                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('couplerDemoFlipRight');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['nandRight'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: async () =>{
                    this.GOE.spatialActionManager.removeSpatialActionsFromMainScene();
                    await this.clearTutorialScene()
                    return this.flags['nandRight']
                } 
            },
            {
                //Load Construction Gate Form
                instruction: `Correlators can also be arranged to create a "Universal Construction Gate", which is a circuit capable of creating any arrow that you yourself can create.</br>"Construction gates" are to "Universal Constructors" as "Logical gates" are to "Universal Computers".</br>While a universal computer is capable of computing any possible program, a universal constructor is capable of building any possible physical object.`,
                fontSize: '1.7em',
                demoStart: true,
                sideEffect: async ()=>{
                    await this.clearTutorialScene()
                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('constructionGateDemoGridCellEqual');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['constructionGateDemoGridCellEqual'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: () => this.flags['constructionGateDemoGridCellEqual']
            },
            {
                instruction: `Living cells are effectively approximate universal constructors that rebuild themselves to prevent their own decay.</br>Thus, correlators allow us to study circuits that react to their own local stress levels by building, or rebuilding, arrows.`,
                condition: () => this.flags['constructionGateDemoGridCellEqual']
            },
            {
                //While Construction Gate Form/Demo Repeat
                instruction: `When two correlators share the same target, changes in the energy of that target causes an arrow to be created between the opposite ends of those correlators`,
                sideEffect: async ()=>{
                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('runConstructionGateDemoGridCellEqual');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['runConstructionGateDemoGridCellEqual'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: async () =>{
                    this.GOE.spatialActionManager.removeSpatialActionsFromMainScene();
                    return (this.flags['runConstructionGateDemoGridCellEqual'])
                } 
            },
            {
                //While Construction Gate Form/Demo Repeat
                instruction: `The arrow that gets created is encoded by the amount of energy inside each correlator.`,
                sideEffect: async ()=>{
                    await this.clearTutorialScene()
                    await this.delay(2000);                    
                    await this.GOE.controls.recoverSpatialActionsAsync('constructionGateDemoGridCellEqual');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);

                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('runConstructionGateDemoGridCellEqual');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['runConstructionGateDemoGridCellEqual2'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: async () =>{
                    this.GOE.spatialActionManager.removeSpatialActionsFromMainScene();
                    return (this.flags['runConstructionGateDemoGridCellEqual2'])
                }
            },
            {
                instruction: `If correlators each have equal energy, say 4, then another correlator with 4 energy in each direction will be created when the target energy changes`,
                sideEffect: async ()=>{
                    await this.clearTutorialScene()
                    await this.delay(2000);                    
                    await this.GOE.controls.recoverSpatialActionsAsync('constructionGateDemoGridCellEqual');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);

                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('runConstructionGateDemoGridCellEqual');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['runConstructionGateDemoGridCellEqual3'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: async () =>{
                    this.GOE.spatialActionManager.removeSpatialActionsFromMainScene();
                    return (this.flags['runConstructionGateDemoGridCellEqual3'])
                }
            },
            {
                // constructionGateDemoGridCellUnequal
                //Demo arrow creation
                instruction: `If correlators don't have equal energy, an arrow is created with the amount of energy in the lower correlator, and will point towards that correlator`,
                sideEffect: async ()=>{
                    await this.clearTutorialScene()
                    await this.delay(2000);                    
                    await this.GOE.controls.recoverSpatialActionsAsync('constructionGateDemoGridCellUnequal');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);

                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('runConstructionGateDemoGridCellEqual');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['runConstructionGateDemoGridCellUnequal'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: async () =>{
                    this.GOE.spatialActionManager.removeSpatialActionsFromMainScene();
                    await this.clearTutorialScene()
                    return (this.flags['runConstructionGateDemoGridCellUnequal'])
                }
            },
            {
                //Demo arrow creation
                instruction: `In this way, logical circuits that respond to changes in energy level by building new arrows can be created.`,
                condition: () => true // Automatically proceed
            },
            {
                //Demo collective movement
                instruction: `Correlators also bind the energy of the entire causal circuit together such that they can all be moved together</br>For instance, here's the NAND gate again.`,
                demoStart: true,
                sideEffect: async ()=>{
                    await this.clearTutorialScene()
                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('NAND');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['moveNANDstart'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: () => this.flags['moveNANDstart']
            },
            {
                //Demo collective movement
                instruction: `We'd like to go straight to the left, so we create a target that allows us to draw the appropriate arrow`,
                sideEffect: async ()=>{
                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('runConstructionGateDemoGridCellEqual');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('NANDgateMover');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['moveNANDdemo'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: async () =>{
                    this.GOE.spatialActionManager.removeSpatialActionsFromMainScene();
                    return (this.flags['moveNANDdemo'])
                }
            },
            {
                //Demo collective movement
                instruction: `Note that arrows move the amount of energy they are composed of, so an arrow with 5 energy moves 5 energy.</br>However, energy bound by correlators is considered to be "mass", and thus can be moved with much less energy`,
                condition: () => true // Automatically proceed
            },
            {
                //Demo collective movement
                instruction: `${divOpenTag}Mass is calculated via ${openSpanTag}$$E = mc^2$$${closeSpanTag} --> ${openSpanTag}$$m = E/c^2$$${closeSpanTag}</br>Where ${openSpanTag}$$E$$${closeSpanTag} is the number of particles in the bound object and ${openSpanTag}$$c$$${closeSpanTag} is the distance a particle can travel in one second.${divCloseTag}`,
                demoStart: true,
                sideEffect: async ()=>{
                    await this.clearTutorialScene()
                    await this.delay(2000);
                    await this.GOE.controls.recoverSpatialActionsAsync('NAND');
                    await this.delay(2000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    await this.GOE.controls.recoverSpatialActionsAsync('runConstructionGateDemoGridCellEqual');
                    await this.delay(1000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    await this.GOE.controls.recoverSpatialActionsAsync('NANDgateMover');
                    await this.delay(1000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['moveNANDstart2'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: async () =>{
                    this.GOE.spatialActionManager.removeSpatialActionsFromMainScene();
                    return (this.flags['moveNANDstart2'])
                }
            },
            {
                instruction: `Finally, once created, correlators take a greater amount of energy to break that depends on the energy inside the correlator and the distance between its targets.</br>If energy is insufficient, the energy is repelled into a random object.</br>The required bond breaking energy is calculated as ${openSpanTag}energy/distance${closeSpanTag}.`,
                sideEffect: async () =>{
                    await this.clearTutorialScene()
                    await this.GOE.controls.recoverSpatialActionsAsync('bondBreakDemo');
                    await this.delay(1000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    
                    await this.GOE.controls.recoverSpatialActionsAsync('breakBondOne');
                    await this.delay(1000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);


                    await this.GOE.controls.recoverSpatialActionsAsync('breakBondTwo');
                    await this.delay(1000);
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);

                    await this.GOE.controls.recoverSpatialActionsAsync('breakBondThree');
                    await this.delay(1000);
                    this.GOE.controls.exitSpatialMode();

                    this.flags['bondBreaks'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: async () =>{
                    this.GOE.spatialActionManager.removeSpatialActionsFromMainScene();
                    await this.clearTutorialScene()
                    return (this.flags['bondBreaks'])
                }                
            },
        ]
    }
    spatialActionsTutorial = ()=>{
        return [
            {
                instruction: `As a player, you can create "Spatial Actions", which allow you to create multiple arrows in a sequence at the fastest possible rate`,
                condition: () => true // Automatically proceed
            },
            {
                instruction: `To enter "Spatial Action Mode" press "Space Bar". Once engaged, try to create an object via the EST rule.`,
                condition: ()=> this.tutorial.doesSpatialActionExist()
            },
            {
                instruction: `Now press "m" to save that object to memory. You'll be asked to use a name, but if you use a number like 0-9, you can re-create the object via pressing that number on your keyboard.`,
                condition: () =>{
                    // Check that the action is truly in memory
                    const hasActionSaved = Object.keys(this.GOE.spatialActionViewer.savedActions).length;
                    return !!hasActionSaved;
                }
            },
            {
                //Preferably grab the action ID they created.
                instruction: `Now that your spatial action is saved, try double clicking an arrow in your action</br>This will remove the spatial action from the scene.</br>Press the key you saved your action under to automatically recreate your spatial action`,
                condition: ()=> this.tutorial.doesSpatialActionExist()
            },
            {
                //Create the action with spacebar...
                instruction: `To create our spatial actions, we exit "spatial action mode" by pressing spacebar again</br>Give it a shot!`,
                condition: ()=> !this.tutorial.doesSpatialActionExist()
            },
            {
                instruction: `If you press the " \` " key then you'll be able to view all the Spatial Actions that you've created and saved.</br>In the near future, you'll be able to create spatial actions from this menu.</br> Press the same button to close the menu`,
                condition: () => true // Automatically proceed
            },
            {
                instruction: `Finally, you can select spatial actions using the "q" key (in beta!), and drag them to another location to more easily create large circuits.</br>Be sure to select the entire visualization, or things may get weird!`,
                condition: () => true // Automatically proceed
            },
        ]
    }
    bonusTipTutorial = ()=>{
        return [
            {
                instruction: `As a bonus note, pressing "u" turns an experimental 'momentum' rule that allows for more chaotic behavior.</br>Here's what trying to create a NAND gate looks like with momentum on.`,
                sideEffect: async ()=>{
                    await this.clearTutorialScene()
                    await this.GOE.controls.recoverSpatialActionsAsync('NAND');
                    await this.delay(2000);
                    this.GOE.controls.toggleLightMomentum();
                    this.GOE.controls.exitSpatialMode();
                    await this.delay(1000);
                    this.flags['momentumNand'] = true;
                    this.tutorial.enableStepButtons();
                },
                condition: async () =>{
                    await this.clearTutorialScene()
                    this.GOE.controls.toggleLightMomentum();
                    return (this.flags['momentumNand']);
                }
            },
            {
                instruction: `There you have it, these are the fundamentals of HGoE. One of the next steps is to create artifically intelligent players that are capable of creating virtual worlds with virtual entities that evolve and sustain themselves by exploiting HGoE mechanics.`,
                condition: () => true // Automatically proceed
            },
            {
                instruction: `Furthermore, since HGoE allows you to quantify the cost of building, running and maintaining a circuit, it might be seen as a very early approximation to a 'Thermodynamics of Construction'. As such, constructing physical theories as Eleatic Games may be a fruitful way drive intuition and come up with new theories.`,
                condition: () => true // Automatically proceed
            },
            {
                instruction: `A Thermodynamics of Construction would tell us what physical transformations are possible in our universe and a way of learning how to make them happen. If universal constructors, like universal computers, are possible in our universe, then this would mean that there would be no physical transformation which is possible, but impossible for us to cause - at least not impossible by the laws of physics. If this is true, then all problems are solvable.`,
                fontSize: '1.7em',
                condition: () => true // Automatically proceed
            },
            {
                instruction: `Deutschian Optimism is the belief that all problems are caused by a lack of knowledge and that this knowledge can be attained. I am an optimist, and I hope you are too.`,
                condition: () => true // Automatically proceed
            },
        ]
    }
}

export default TutorialManager