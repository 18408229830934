import { useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { useSwipeable } from 'react-swipeable';
import EleaticFieldButton from "./EleaticFieldButton";
import { PiBrain } from "react-icons/pi";
import { LuBrainCircuit } from "react-icons/lu";
import { GiAtom } from "react-icons/gi";
import { PiCheckerboardFill } from "react-icons/pi";

const EleaticFieldRelations = forwardRef(({ isSwiping, setIsSwiping, isMobile, explanationView, setExplanationView }: any, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRefs = useRef<HTMLDivElement[]>([]);
  const fields = [
    {
      component: PiBrain,
      name: 'Neuroscience & Biology',
    },
    {
      component: PiCheckerboardFill,
      name: 'Artificial Life'
    },
    {
      component: LuBrainCircuit,
      name: 'Artificial Intelligence'
    },
    {
      component: GiAtom,
      name: 'Physics'
    }
  ];

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const currentIndex = fields.findIndex(field => field.name === explanationView);
      if (currentIndex < fields.length - 1) {
        setExplanationView(fields[currentIndex + 1].name);
      }
    },
    onSwipedRight: () => {
      const currentIndex = fields.findIndex(field => field.name === explanationView);
      if (currentIndex > 0) {
        setExplanationView(fields[currentIndex - 1].name);
      }
    },
    onSwiping: () => {
      if (!isSwiping) {
        setIsSwiping(true);
      }
    },
    onSwiped: () => {
      if (isSwiping) {
        setIsSwiping(false);
      }
    },
  });

  let scrollTimeout;

  const handleScroll = () => {
    if (isSwiping) {
      return; // Disable handleScroll when swiping
    }

    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    scrollTimeout = setTimeout(() => {
      const container = containerRef.current;
      if (!container) return;

      const containerRect = container.getBoundingClientRect();
      let closestButton = null;
      let closestDistance = Infinity;

      buttonRefs.current.forEach(button => {
        if (!button) return;
        const buttonRect = button.getBoundingClientRect();
        const distance = Math.abs(buttonRect.left + buttonRect.width / 2 - containerRect.left - containerRect.width / 2);
        if (distance < closestDistance) {
          closestDistance = distance;
          closestButton = button;
        }
      });

      if (closestButton) {
        closestButton.click();
      }
    }, 100); // Adjust this value as needed to detect when scrolling has stopped
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll, { passive: true });
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [isSwiping]); // Add isSwiping to the dependency array

  // Scroll to the leftmost position on mount
  // useEffect(() => {
  //   const container = containerRef.current;
  //   if (container) {
  //     console.log("Setting to left");
  //     container.scrollLeft = 0; // Set the scroll position to the leftmost
  //   }
  // }, []);

  useImperativeHandle(ref, () => ({
    scrollToButton(index) {
      const button = buttonRefs.current[index];
      if (button && containerRef.current) {
        const container = containerRef.current;
        const buttonRect = button.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const offset = buttonRect.left - containerRect.left - (containerRect.width / 2 - buttonRect.width / 2);
        container.scrollTo({
          left: container.scrollLeft + offset,
          behavior: 'smooth',
        });
      }
    }
  }));

  return (
    <div
      {...handlers}
      ref={containerRef}
      className="container" // Add this line
      style={{
        width: isMobile ? '100vw' : '85vw',
        paddingTop: 15,
        paddingBottom: 15,
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: isMobile ? 'none' : 'space-around',
        // alignContent: 'center',
        // alignItems: 'center',

        overflowX: 'auto',
        scrollSnapType: 'x mandatory',
        scrollbarWidth: 'none', /* Firefox */
        msOverflowStyle: 'none',  /* Internet Explorer 10+ */
        
        whiteSpace: 'nowrap',
      }}
    >
      {fields.map((field, index) => {
        const { name: title } = field;
        return (
          <EleaticFieldButton
            key={title}
            title={title}
            field={field}
            isMobile={isMobile}
            ref={(el: HTMLDivElement) => (buttonRefs.current[index] = el)}
            onClick={() => setExplanationView(title)}
            isSelected={explanationView === title}
          />
        )
      })}
    </div>
  );
});

export default EleaticFieldRelations;
