import { SolidParticle, SolidParticleSystem, Vector3 } from "@babylonjs/core";
import convertDirectionArrayToString from "../helpers/convertDirectionArrayToString";
import getConsecutiveRanges from "../helpers/getConsecutiveRanges";
import { ExistingObject, ParticleArrivalSettings, Task } from "../types";
import Direction from "./Direction";
import EnergyCapacity from "./EnergyCapacity";
import GameOfExistence from "./GameOfExistence";
import GridCell from "./GridCell";
import { DIRECTION_HOVER_HEIGHT, SPEED_OF_LIGHT, momentumTypes } from "../constants";
import lerp from "../helpers/lerp";
import Target from "./PotentialObject";
import PotentialObject from "./PotentialObject";


class TaskMechanics {
    GOE: GameOfExistence = null;
    energyCapacity: EnergyCapacity = null;
    SPS: SolidParticleSystem = null;

    constructor(GOE: GameOfExistence){
        this.GOE = GOE;
        this.energyCapacity = GOE.energyCapacity;
    }

    isTaskImpossible=(directionAttemptingTask: Direction, settings?: Task)=>{
        const isIt = this.GOE.energyCapacity.isTaskImpossible(directionAttemptingTask, settings);
        if(isIt) directionAttemptingTask.dissipation.dissipate("because task was impossible for some reason - TaskMechanics");
        return isIt
    }

    adjustEnergyToTransfer=(energyInSource, amount)=>{
        //Making sure not to move energy than is available
        return energyInSource >= amount ? amount : energyInSource;
    }

    taskImpossibleDueToEmptySource=(settings: Task, sourceObj: ExistingObject)=>{
        const { caller, source, target, energyToTransfer, type } = settings;
      //TODO: I feel like amount should never be zero?
        const callerObj = typeof caller === "string"  ? this.GOE.getObjectById(caller) : null
        if( callerObj instanceof Direction ){
            if(type === "energy transfer"){
                // console.warn(`Direction ${callerObj.id} got unlucky and source ended up with no energy before this animation could be called?`);
                // if(!callerObj.isDissipating) callerObj.dissipate("because the source was empty!");
            }
            return;
        }
        if(caller === "Player, or spatial action") return console.warn("A spatial action failed to be energized");
        if(caller === "GOE") return console.warn("There's zero energy in the source!");
        // if(caller instanceof dire)
        console.log("Called: ", caller);
        console.log("CallerObj: ", callerObj)
        console.log("Type: ", type);
        console.log("Source Obj: ", sourceObj);
        console.log("Energy in source: ", sourceObj.getEnergyLevel());
        console.log("Amount demanded: ", energyToTransfer);
        console.log("Source: ", source);
        console.log("Target: ", target);
        console.warn("Tried to draw energy from source with no particles");
        console.error("Drawing from a source with no energy should never be attempted, it is an impossible task.");
        return;
        // throw new Error("Drawing from 0 energy source")
        // if(caller === "Momentum") return console.warn("Caller was momentum");
        // if(caller instanceof Direction && (!caller.dissipated)) 
        // throw new Error("Caller wasn't 'dissipated', what's happening here?")
        // return;
    }

    applyCollectiveMotion = (settings: Task, source: ExistingObject)=>{
        const { type, } = settings;
        if(type === "momentum pull" && source.correlatingWith.length){
        // if(type === "momentum pull" && source instanceof Direction){
            // console.log(`Direction ${callerObj.id} is moving a connected object!`);
            const result = source.moveConnectedObjects(settings);
            if(!result) source.reactToEnergyLoss(settings);
            return result;
        }
    }

    removeAndGetParticleAddresses=(taskSettings: Task, energyToTransfer: number, sourceObj: ExistingObject): number[]=>{
        const { type } = taskSettings;
        if(type === "repulsion" && sourceObj instanceof Direction){
            return sourceObj.loseParticleAddressesFromQueue({...taskSettings, energyToTransfer: energyToTransfer})
        }
        return sourceObj.loseParticleAddresses({...taskSettings, energyToTransfer: energyToTransfer})

    }

    attemptTask=(settings: Task)=>{
        // const modifiedSettings = this.correctForSpatialActions(settings);
        let { caller, source, target, energyToTransfer, type } = settings;
        // console.log("Caller: ", caller);
        const isMomentumBased = (settings && settings.type) ? momentumTypes.includes(settings.type) : false
        // if(momentumTypes.includes(type) && (source.type === "Direction" || target.type === "Direction")){
        //     console.log("INTRO MOMENTUM ANIMATION: ", settings)
        // }
        const direction = this.GOE.causalField.getDirectionById(caller);
        if(direction && !direction.dissipation.isDissipating && this.isTaskImpossible(direction, settings)){
            // if(isMomentumBased) console.log("Momentum based cancellation")
            return;
        };
        const potentialSource = new PotentialObject(this.GOE, source);
        const sourceObj = potentialSource.getExistingObject();
        if(!sourceObj){
            // console.log("Source object didn't exist");
            return;
        }; //TODO DUE TO NON EXISTENCE
        const energyInSource = sourceObj.getEnergyLevel();
      
        // const adjustedEnergyToTransfer = this.adjustEnergyToTransfer(energyInSource, energyToTransfer);
        // if(adjustedEnergyToTransfer === 0){
        //     // if(isMomentumBased) console.log("task impossible energy in source adjusted energy transfer")
        //     this.taskImpossibleDueToEmptySource(settings, sourceObj);
        //     return;
        // }
  

        // console.warn("Momentum being applied before checking again if particles are actually present, can be bug.")
        
        // if(result) return;
        let particleAddresses: number[] = this.removeAndGetParticleAddresses(settings, energyToTransfer, sourceObj);
        if(!particleAddresses.length){
            if(isMomentumBased){
                // console.log("Task impossible no particle addresses");
                // console.log("Source Obj: ", sourceObj);
            }
            return this.taskImpossibleDueToEmptySource(settings, sourceObj)
        }
        const result = this.applyCollectiveMotion(settings, sourceObj);
        if(sourceObj instanceof GridCell) sourceObj.reactToEnergyLoss(settings);

        // if(momentumTypes.includes(type) && (source.type === "Direction" || target.type === "Direction")){
        //     console.log("END MOMENTUM ANIMATION: ", settings)
        // }

        
        const {GOE} = this;
        const startTime = GOE.gameTime
        const animationTimestamp = GOE.gameTime;
        
        const potentialTarget = new PotentialObject(this.GOE, target);
        const targetId = potentialTarget.getId();
        const sourceId = sourceObj.id
        const animationIndex = `${sourceId}-${targetId}-${animationTimestamp}-${Math.random()}`;
        // console.log(`Transfering ${energyToTransfer} from ${sourceId} to ${targetId}`);
        // if(type === "energy transfer") console.log(`Animation type ${type} of index ${animationIndex} created`);
        const updateParticle = this.createParticleUpdateFunction({
            taskSettings: settings,
            startTime,
            particleAddresses,
            source,
            target,
            animationIndex,
            animationTimestamp,
            type
        });

        const consecutiveRanges = getConsecutiveRanges(particleAddresses);
        // Store the update function inside the animation object
        const animation = {
            index: animationIndex,
            timestamp: animationTimestamp,
            priority: momentumTypes.includes(type) ? 1 : 2,
            updateParticle,
            particleAddresses,
            ranges: consecutiveRanges,
        };

        // this.setMostRecentAnimationForAllParticles(particleAddresses, animation);

        // const targetObj: ExistingObject = potentialTarget.getOrCreateTargetOnParticleArrival();
        // targetObj.addParticleAddressesToQueue(particleAddresses)
        // Add the new animation to the active animations
        this.GOE.particleVisuals.activeParticleAnimations.set(animationIndex, animation);
    }

    createParticleUpdateFunction=(settings: any)=>{
        const { GOE } = this;
        let {
            taskSettings,
            startTime,
            particleAddresses,
            source,
            target,
            animationIndex,
        } = settings;
        //TODO: Can we ensure this gets cleaned up quickly somehow?
        const potentialSource = new PotentialObject(this.GOE, taskSettings.source);
        const potentialTarget = new PotentialObject(this.GOE, target);
        let sourcePosition = potentialSource.getPosition();
        let targetPosition = potentialTarget.getPosition();
        if(!sourcePosition) throw new Error("Source position unavailable!");
        if(!targetPosition) throw new Error("Target position unavailable!");
        const {x,y: oldY,z} = targetPosition;
        //Set for .has function & get position
        const y = target instanceof GridCell ? oldY + DIRECTION_HOVER_HEIGHT : oldY;
        // if(type === 'energy transfer') console.log("Speed Of Light: ", SPEED_OF_LIGHT);
        // if(type === 'energy transfer') console.log("Game time at particle leave: ", this.GOE.gameTime);
        // if(type === 'energy transfer') console.log("Particles: ", particleAddresses);
        let particleStartingPositions: {[key: string]: Vector3} = {};
        particleAddresses.forEach((id: number)=>{
            const particlePosition = this.GOE.particleVisuals.SPS.getParticleById(id).position;
            const {x,y,z} = particlePosition;
            particleStartingPositions[id] = new Vector3(x,y,z);
        });
        let particleMaxes = {};
        for (let [id, position] of Object.entries(particleStartingPositions)){
            const pointToApproach = this.GOE.particleVisuals.getRandomPointAroundPosition(new Vector3(targetPosition.x,targetPosition.y,targetPosition.z), .1);
            const maximumDistanceToTravel = Math.sqrt(
                Math.pow(position.x - pointToApproach.x, 2) +
                Math.pow(position.z - pointToApproach.z, 2)
            );
            // const maximumDistanceToTravel = Math.sqrt(
            //     Math.pow(position.x - targetPosition.x, 2) +
            //     Math.pow(position.z - targetPosition.z, 2)
            // );
            // const maxDeltaTime = (maximumDistanceToTravel / SPEED_OF_LIGHT) * 1.2;
            const maxDeltaTime = (maximumDistanceToTravel / SPEED_OF_LIGHT) * 1.5;
            // console.log("Particle: ", id);
            // console.log("Distance: ", maximumDistanceToTravel)
            const ogTime = (maximumDistanceToTravel / SPEED_OF_LIGHT);
            // console.log("OG TIME: ", ogTime)
            // console.log("Time: ", maxDeltaTime)
            particleMaxes[id] = {
                distance: maximumDistanceToTravel, 
                time: maxDeltaTime,
                pointToApproach: pointToApproach,
            }
        }
        const maxDimensions = this.GOE.grid.getMaxDimensions();
        
        const targetObj: ExistingObject = potentialTarget.getOrCreateTargetOnParticleArrival();
        return (particle: SolidParticle): SolidParticle => {
            // const pointToApproach = this.GOE.particleVisuals.getRandomPointAroundPosition(new Vector3(x,y,z), .05);
            const pointToApproach = particleMaxes[particle.id].pointToApproach;
       
     
            if(particleAddresses.includes(particle.id)){
                if(!targetObj.particleQueue.includes(particle.id)) targetObj.addParticleAddressesToQueue([particle.id])
            
                const finalX = pointToApproach.x;
                const finalZ = pointToApproach.z;
                //TODO: Thought this was required for spatial actions, but it is not.
                // if (taskSettings.caller === "GOE" ) {
                //     this.onParticleArrive({
                //         animationIndex,
                //         particle,
                //         taskSettings: taskSettings,
                //         finalX,
                //         finalZ,
                //     })
                //     return;
                // }
                // if(type === "energy transfer") console.log(`Particle ${particle.id} of animation ${animationIndex} en route`);
    
                //distance between soure and 
                // const maxDeltaTime = maximumDistanceToTravel * SPEED_OF_LIGHT;
                // const potentialDelta = (GOE.gameTime - startTime)
                // const deltaTime = potentialDelta > maxDeltaTime ? maxDeltaTime : potentialDelta;
                const deltaTime = (GOE.gameTime - startTime) 

             
                const startX = particleStartingPositions[particle.id].x;
                const startZ = particleStartingPositions[particle.id].z;
                const t = deltaTime * SPEED_OF_LIGHT;
                // Calculate the new position using lerp
                particle.position.x = lerp(startX, finalX, t);
                particle.position.z = lerp(startZ, finalZ, t);

                const distanceToTarget = Math.sqrt(
                    Math.pow(particle.position.x - finalX, 2) +
                    Math.pow(particle.position.z - finalZ, 2)
                );

                const particlePosition = [particle.position.x, particle.position.y, particle.position.z]
                const isPointInBox = this.GOE.spatialPartioner.checkIfPointIsInBox(particlePosition, maxDimensions);
                // console.log("Delta Time: ", deltaTime);
                const distanceTraveled = Math.sqrt(
                    Math.pow(particle.position.x - particleStartingPositions[particle.id].x, 2) +
                    Math.pow(particle.position.z - particleStartingPositions[particle.id].z, 2)
                );
                if( (deltaTime > particleMaxes[particle.id].time) || (distanceTraveled > particleMaxes[particle.id].distance) ){
                    // throw new Error("Particle left playing field!");
                    // console.warn(`Particle ${particle.id} was traveling for too long, or for too far!`)
                    // console.log(`Maximum time: `, particleMaxes[particle.id].time);
                    // console.log(`Time taken DELTA: `, deltaTime);

                    // console.log("Maximum distance: ", particleMaxes[particle.id].distance);
                    // console.log("Distance traveled: ", distanceTraveled);

                    // console.log(`Placing particle ${particle.id} where it needs to be`);
                    this.onParticleArrive({
                        animationIndex,
                        particle,
                        taskSettings: taskSettings,
                        finalX,
                        finalZ,
                    })
                    return;
                }
                if(!isPointInBox){
                    // throw new Error("Particle left playing field!");
                    console.warn(`Particle ${particle.id} left playing field!`);
                    console.warn(`Placing particle ${particle.id} where it needs to be`);
                    this.onParticleArrive({
                        animationIndex,
                        particle,
                        taskSettings: taskSettings,
                        finalX,
                        finalZ,
                    })
                    return;
                }
                // if (distanceToTarget < 0.05) {
                if (distanceToTarget < 0.1) {
                    // throw new Error("Distance to target reached threshold");
                    this.onParticleArrive({
                        animationIndex,
                        particle,
                        taskSettings: taskSettings,
                        finalX,
                        finalZ,
                    })
                    return;
                }
            }
            return particle;      
        }
    }

    onParticleArrive=(settings: ParticleArrivalSettings)=>{
        const {
            taskSettings,
            animationIndex,
            particle,
            finalX,
            finalZ,
        } = settings;

        const { target } = taskSettings;
        // if(type === "energy transfer") console.log(`Particle ${particle.id} of animation ${animationIndex} ARRIVED`);
        // if(type === "energy transfer") console.log(`Particle ${particle.id} of arrive at ${this.GOE.gameTime}`);
        const isLastParticle = this.GOE.particleVisuals.isLastParticleInAnimation(animationIndex);
        // Only giving energy/particle ownership later on
        // console.log(`Adding particle ${particle.id} to: `, target);
        
        //Checking to see if it already exists, if not, then creating it.
        const potentialTarget = new PotentialObject(this.GOE, target, taskSettings);
        //TODO: Can we ensure this gets cleaned up quickly somehow?
        const targetObj: ExistingObject = potentialTarget.getOrCreateTargetOnParticleArrival();
        // targetObj.addParticleAddressesToQueue([particle.id])
        // if(isLastParticle) console.log("AND Is last particle!");
        if(isLastParticle) targetObj.reactToEnergyGain(taskSettings);
        // if(target instanceof GridCell) target.updateVisibility();
        if(targetObj instanceof GridCell){
            particle.isVisible = targetObj.isVisible;
        }

        //Amount will be 1 unless bulk energy send by user, or by physics.
        // if(isLastParticle) target.gainEnergy(amount, directionOfTargetEnergy, force);
        // Ensure the particle is exactly at the destination
        particle.position.x = finalX;
        particle.position.z = finalZ;
        //Check if you're the last particle in the animation
        this.GOE.particleVisuals.removeParticleFromAnimation(particle, animationIndex)
    }

    
}

export default TaskMechanics

