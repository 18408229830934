import { COLORS } from "../constants"
import screenShot from '../assets/HGoE-ScreenShot1.png';


const EleaticGamesIntroduction = ({isMobile}: any)=>{
    const EleaticGamesImage = ()=>{
        return(
          <div style={{
            // flex: 1,
            height: '32.5vh',
            minHeight: isMobile ? '150px' : 'none',
            width: isMobile ? '100%' : '45%',
            textAlign: 'center',
            // border: 'solid yellow 3px',
            // borderTop: 'solid white 2px',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            // backgroundImage: `${require('./assets/HGoE-ScreenShot1.png')}`,
            // backgroundSize: 10,
            backgroundImage: `url(${screenShot})`,
            backgroundSize: 'cover',
            backgroundPositionX: '-2.5vw',
            backgroundPositionY: '-2.5vh'
          }}>
            <div style={{
              width: '100%',
              height: '30%',
              backgroundColor: 'rgba(0,0,0,.6)',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  fontSize: '1.3em',
                  backgroundColor: 'rgba(0,0,0,.9)',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontWeight: '600',
                }}
              >
                What are Eleatic Games?
              </div>
            </div>
          </div>
        )
    }
    
    const EleaticGamesExplanation=()=>{
    return(
        <div style={{
        flex: 1,
        width: isMobile ? '100%' : '45%',
        textAlign: 'left',
        // textAlign: isMobile ? 'center' : 'left',
        // borderTop: 'solid black 2px',
        // justifyContent: 'center',
        // fontSize: '.8em',
        backgroundColor: '#E6CCA1',
        color: 'black',
        fontWeight: '500',
        }}>
        <div style={{margin: '5%', marginTop: '5%', marginBottom: '5%'}}>
            Eleatic Games are a type of <a href={'https://en.wikipedia.org/wiki/Finite_and_Infinite_Games'}>infinite game</a> in which the goal of the game is to persist one’s existence by creating objects, and maintaining the existence of those objects.
        </div>
        <div style={{ margin: '5%', marginTop: '5%', marginBottom: '5%'}}>
            In Eleatic Games, all objects slowly decay over time. However, their rate of decay can be slowed by the presence of other objects.
        </div>
        <div style={{margin: '5%', marginTop: '5%', marginBottom: '5%'}}>
            Eleatic Games are designed so that a player can build a computer out of these objects, and even build computers that then build other computers
        </div>
        <div style={{margin: '5%', marginTop: '5%', marginBottom: '5%'}}>
            This results in the potential to create virtual worlds with virtual entities that evolve and sustain themselves
        </div>
        <div style={{margin: '5%', marginTop: '5%', marginBottom: '5%'}}>
            The purpose of this site is to inspire different scientific fields to take up research programs around Eleatic Game Design and to help guide those efforts with accessible information.
        </div>
        </div>
    )
    }

    return(
        <div style={{
            // margin: '0 auto',
            // borderTop: 'solid yellow 3px',
            width: '100%',
            backgroundColor: 'black',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: `solid ${COLORS.pastelGreen} 1px`,
  
          }}>
            <EleaticGamesImage/>
            <EleaticGamesExplanation/>
          </div>
    )
}

export default EleaticGamesIntroduction