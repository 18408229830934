import { EnergyDirection } from "../types"


const isSourceAndTargetIdentical = (directionArray: EnergyDirection)=>{
    if(directionArray[0] === directionArray[1]){
        console.log(`Source: `, directionArray[0])
        console.log(`Target: `, directionArray[1])
        return true;
    };
    return false;
}

export default isSourceAndTargetIdentical