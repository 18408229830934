import { Vector3 } from "@babylonjs/core";

function calculateDistanceBetweenPoints(point1: Vector3, point2: Vector3): number {
    // Subtract the two points to get the difference vector
    const diff = point1.subtract(point2);

    // Return the length of the difference vector, which is the distance
    return diff.length();
}

export default calculateDistanceBetweenPoints;