import { Scene, Vector3, StandardMaterial, Color3, Mesh, MeshBuilder, Plane, Ray, Matrix, PointerEventTypes, PointerInfo, UniversalCamera, KeyboardEventTypes, KeyboardInfo, Texture, Color4, ActionManager, ExecuteCodeAction, DynamicTexture } from "@babylonjs/core";
import GameOfExistence from "./GameOfExistence";
import MeshDragger from "./MeshDragger";
import changeBoxColor from "../helpers/changeBoxColor";
import SpatialAction from "./SpatialAction";

class MeshSelector {
    private scene: Scene;
    private camera: UniversalCamera;
    private selectedMaterial: StandardMaterial;
    private borderMaterial: StandardMaterial;
    private mousePos0: { x: number, y: number };
    private mousePos1: { x: number, y: number };
    private lines: Mesh;
    private markers: Mesh[];
    private isSelecting: boolean;
    public isEnabled: boolean;
    public GOE: GameOfExistence = null;
    selectedActionIds: string[] = [];
    selectedMeshes: Mesh[] = [];
    pointerDown: any;
    pointerMove: any;
    pointerUp: any;
    clearSelectionsKey: any;
    ground: Mesh = null;
    meshDragger: MeshDragger;

    constructor(GOE: GameOfExistence, scene: Scene, camera: UniversalCamera) {
        this.GOE = GOE;
        this.scene = scene;
        this.camera = camera;
        this.selectedMaterial = new StandardMaterial("", scene);
        this.selectedMaterial.emissiveColor = new Color3(0.5, 0.5, 0.5);

        this.borderMaterial = new StandardMaterial("", scene);
        this.borderMaterial.emissiveColor = new Color3(0.5, 0.5, 1);

        this.markers = [];
        this.isSelecting = false;
        this.isEnabled = false;

        this.init();
        this.createGround();
        this.addGroundDeselect();
        this.meshDragger = new MeshDragger(this);
        console.log("Constructed MeshSelector (Beta)");
    }

    private init=()=>{
        console.log("Mesh Selector Init")
        // Initialize selection rectangle
        this.lines = MeshBuilder.CreateLines("lines", {
            points: [
                new Vector3(0, 0, 0),
                new Vector3(1, 0, 0),
                new Vector3(1, 1, 0),
                new Vector3(0, 1, 0),
                new Vector3(0, 0, 0)
            ]
        }, this.scene);
        this.lines.billboardMode = Mesh.BILLBOARDMODE_ALL;
        this.lines.isVisible = false; // Make lines invisible initially

        // Initialize markers
        for (let i = 0; i < 4; i++) {
            let marker = MeshBuilder.CreateSphere(`Marker${i}`, { segments: 5, diameter: 0.05 }, this.scene);
            marker.material = this.borderMaterial;
            marker.isVisible = false; // Make markers invisible initially
            this.markers.push(marker);
        }

        // Add keyboard event to toggle mesh selection
        this.scene.onKeyboardObservable.add(this.onKeyDown, KeyboardEventTypes.KEYDOWN);
    }

    private onClearSelectedActions = (keyboardInfo: KeyboardInfo) => {
        const event = keyboardInfo.event;
        if (event.key === 'c') { // Toggle mesh selection with the 's' key
            this.deleteSelectedActions();
        }
    }

    private deleteSelectedActions = ()=>{
        if(!this.selectedMeshes.length) return;
        let selectedSpatialActions = [];
        this.selectedMeshes.forEach((mesh, index) => {
            if (mesh.metadata?.type !== 'SpatialAction') return;
            // console.warn(`Moving ${mesh.metadata.id}`)
            // const spatialActionSettings: SpatialActionSettings = mesh.metadata;
            const spatialAction = this.GOE.spatialActionManager.getSpatialActionById(mesh.metadata.id)
            if(!spatialAction){
                console.log("Meta Data: ", mesh.metadata);
                console.log("Meta Data Id: ", mesh.metadata.id);
                throw new Error("No action associated with this id on delete selected actions");
                // console.warn("No action associated with this id");
                // return;
            }
            selectedSpatialActions.push(spatialAction);
        });
        if(!selectedSpatialActions.length) return;
        console.log(`Deleting ${selectedSpatialActions.length}`)
        selectedSpatialActions.forEach((spatialAction: SpatialAction)=>{
            spatialAction.removeSelf();
        })
    }

    public setSelectedMeshes = (meshes: Mesh[])=>{
        console.log(`Mesh dragger calling setSelectedMeshes`)
        this.selectedMeshes = meshes;
    }

    private createGround = ()=>{
        var ground = MeshBuilder.CreateGround("ground",
            {
                width: this.GOE.grid.width,
                height: this.GOE.grid.height,
                subdivisions: 1,
                updatable: false
            }, this.scene);
        ground.position = new Vector3(0,-0.1,0);
        // ground.isVisible = false;
        ground.isPickable = true;
        this.ground = ground;
        // var groundMaterial = new StandardMaterial("ground", this.scene);
        // ground.material = groundMaterial;
        var dynamicTexture = new DynamicTexture("dynamicTexture", {width: 512, height: 512}, this.GOE.tools.scene);
        var ctx = dynamicTexture.getContext();

        // Set transparent background
        ctx.fillStyle = "rgba(0, 0, 0, 0)";
        ctx.fillRect(0, 0, 512, 512);

        // Apply the dynamic texture to a material
        dynamicTexture.update();
        var planeMaterial = new StandardMaterial("planeMaterial", this.GOE.tools.scene);
        planeMaterial.diffuseTexture = dynamicTexture;
        planeMaterial.opacityTexture = dynamicTexture;
        planeMaterial.backFaceCulling = false;

        // Apply the material to the plane
        this.ground.material = planeMaterial;
    }

    private addGroundDeselect = ()=>{
        this.ground.actionManager = new ActionManager(this.GOE.tools.scene);
        ((GOE)=>{
            this.ground.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function(ev){
                if(GOE.controls.isDragging) return;
                GOE.controls.deselectAllObjects();
            }));
        })(this.GOE)
    }

    private onPointerDown = (event: PointerInfo) => {
        if (!this.isEnabled || event.event.button !== 0) {
            return;
        }
        this.isSelecting = true;
        this.mousePos0 = { x: this.scene.pointerX, y: this.scene.pointerY };
        let ray0 = this.scene.createPickingRay(this.mousePos0.x, this.mousePos0.y, Matrix.Identity(), this.camera);
        this.markers[0].position = ray0.origin.add(ray0.direction);
        this.updateVisibility(true);
        this.camera.detachControl(); // Detach control to prevent camera movement
    }

    private onPointerMove = (event: PointerInfo) => {
        if (!this.isSelecting) return;

        this.mousePos1 = { x: this.scene.pointerX, y: this.scene.pointerY };
        let rays: Ray[] = [];
        rays.push(this.scene.createPickingRay(this.mousePos0.x, this.mousePos0.y, Matrix.Identity(), this.camera));
        rays.push(this.scene.createPickingRay(this.mousePos1.x, this.mousePos0.y, Matrix.Identity(), this.camera));
        rays.push(this.scene.createPickingRay(this.mousePos0.x, this.mousePos1.y, Matrix.Identity(), this.camera));
        rays.push(this.scene.createPickingRay(this.mousePos1.x, this.mousePos1.y, Matrix.Identity(), this.camera));

        for (let i = 0; i < 4; i++)
            this.markers[i].position = rays[i].origin.add(rays[i].direction);

        this.lines.position.copyFrom(this.markers[0].position);
        this.lines.scaling.x = Vector3.Distance(this.markers[0].position, this.markers[1].position);
        this.lines.scaling.y = -Vector3.Distance(this.markers[0].position, this.markers[2].position);

        this.lines.lookAt(this.markers[1].position, -Math.PI * 0.5);

        if (this.mousePos1.y < this.mousePos0.y)
            this.lines.scaling.y *= -1;
    }

    private onPointerUp = (event: PointerInfo) => {
        if (!this.isSelecting) return;
        this.isSelecting = false;
        this.mousePos1 = { x: this.scene.pointerX, y: this.scene.pointerY };
        if (this.mousePos0.x > this.mousePos1.x) {
            let t = this.mousePos0.x;
            this.mousePos0.x = this.mousePos1.x;
            this.mousePos1.x = t;
        }
        if (this.mousePos0.y > this.mousePos1.y) {
            let t = this.mousePos0.y;
            this.mousePos0.y = this.mousePos1.y;
            this.mousePos1.y = t;
        }
        let rays: Ray[] = [];
        rays.push(this.scene.createPickingRay(this.mousePos0.x, this.mousePos0.y, Matrix.Identity(), this.camera));
        rays.push(this.scene.createPickingRay(this.mousePos1.x, this.mousePos0.y, Matrix.Identity(), this.camera));
        rays.push(this.scene.createPickingRay(this.mousePos0.x, this.mousePos1.y, Matrix.Identity(), this.camera));
        rays.push(this.scene.createPickingRay(this.mousePos1.x, this.mousePos1.y, Matrix.Identity(), this.camera));

        for (let i = 0; i < 4; i++)
            this.markers[i].position = rays[i].origin.add(rays[i].direction);

        let n: Vector3[] = [];
        n.push(Vector3.Cross(rays[0].direction, rays[1].direction));
        n.push(Vector3.Cross(rays[1].direction, rays[3].direction));
        n.push(Vector3.Cross(rays[2].direction, rays[0].direction));
        n.push(Vector3.Cross(rays[3].direction, rays[2].direction));

        let planes: Plane[] = [];
        for (let i = 0; i < 4; i++) {
            planes.push(new Plane(n[i].x, n[i].y, n[i].z,
                - rays[i].origin.x * n[i].x - rays[i].origin.y * n[i].y - rays[i].origin.z * n[i].z));
        }

        const spatialMeshes = this.GOE.spatialActionManager.getSpatialActionMeshes();
        let selectedActionIds = [];
        let selectedMeshes = [];
        for (let spatialDirectionMesh of spatialMeshes) {
            if (spatialDirectionMesh === this.lines || this.markers.includes(spatialDirectionMesh)){
                continue;
            };
            let contains = true;
            if(spatialDirectionMesh.metadata && spatialDirectionMesh.metadata.type === "arrow"){
                const position = this.constructVertexPosition(spatialDirectionMesh);
                spatialDirectionMesh.metadata.position = position;
            }
            // if(spatialDirectionMesh.metadata && spatialDirectionMesh.metadata.type === "SpatialArrow"){
            //     changeBoxColor(spatialDirectionMesh, new Color4(0,0,1,0))
            // }
            for (let plane of planes) {
                if (plane.signedDistanceTo(spatialDirectionMesh.position) > 0 && !this.isVertexObjInsidePlane(planes, spatialDirectionMesh)) {
                    // console.log("Signed Distance To: ", plane.signedDistanceTo(spatialDirectionMesh.position));
                    // console.log("Mesh not contained: ", spatialDirectionMesh.position);
                    contains = false;
                    break;
                }
            }

            if (contains) {
                if(spatialDirectionMesh && spatialDirectionMesh.metadata){
                    selectedActionIds.push(spatialDirectionMesh.metadata.id);
                    selectedMeshes.push(spatialDirectionMesh);
                }
                else{
                    // console.warn("Mesh with no Id - id: ", spatialDirectionMesh.id);
                    // console.warn("Mesh with no Id: ", spatialDirectionMesh);
                }
            }
        }
        // console.log("Selected action ids: ", selectedActionIds);
        // console.log("Selected Meshes: ", selectedMeshes);

        if(selectedMeshes.length){
            this.toggleSelectionMode()
            this.meshDragger.setEnabled(true);
            
        } else this.meshDragger.setEnabled(false);

        this.selectedActionIds = selectedActionIds
        console.log("MeshSelector - Setting selected meshes");
        this.selectedMeshes = selectedMeshes

        this.mousePos0 = null;
        this.updateVisibility(false);
        this.camera.attachControl(this.scene.getEngine().getRenderingCanvas(), true); // Reattach control to allow camera movement
    }

    public addVertexPositionToMesh=(mesh: Mesh)=>{
        if(mesh.metadata && mesh.metadata.type === "arrow"){
            const position = this.constructVertexPosition(mesh);
            mesh.metadata.position = position;
        }
        return mesh

    }

    isVertexObjInsidePlane=(planes, mesh)=>{
        const vertices = mesh?.metadata?.position;
        let contains = true;
        // console.log("Mesh name: ", mesh.name);
        // console.log("Vertices: ", vertices);
        if(!vertices) return false;
        if(!vertices.length) return false;
        for (let vertex of vertices) {
            for (let plane of planes) {
                if (plane.signedDistanceTo(vertex) > 0) {
                    contains = false;
                    break;
                }
            }
            if (!contains) break;
        }
        return contains
    }
    public constructVertexPosition=(mesh: Mesh)=>{
        const vertexData = mesh.getVerticesData("position");
        if(!vertexData) return [];
        let positions = [];
        for (let i = 0; i < vertexData.length; i += 3) {
            positions.push(new Vector3(vertexData[i], vertexData[i + 1], vertexData[i + 2]));
        }
        return positions;
    }

    private onKeyDown = (keyboardInfo: KeyboardInfo) => {
        const event = keyboardInfo.event;
        if (event.key === 'q') { // Toggle mesh selection with the 's' key
            this.toggleSelectionMode();
        }
    }

    private toggleSelectionMode=()=>{
        this.isEnabled = !this.isEnabled;
        this.updateVisibility(false);


        if (this.isEnabled) {
            console.log("Enabling selection mode");
            this.camera.detachControl(); // Detach camera control to enable mesh selection
            this.pointerDown = this.scene.onPointerObservable.add(this.onPointerDown, PointerEventTypes.POINTERDOWN);
            this.pointerMove = this.scene.onPointerObservable.add(this.onPointerMove, PointerEventTypes.POINTERMOVE);
            this.pointerUp = this.scene.onPointerObservable.add(this.onPointerUp, PointerEventTypes.POINTERUP);
            this.clearSelectionsKey = this.scene.onKeyboardObservable.add(this.onClearSelectedActions, KeyboardEventTypes.KEYDOWN);

        } else {
            console.log("Disabling selection mode");
            this.camera.attachControl(this.scene.getEngine().getRenderingCanvas(), true); // Reattach camera control to disable mesh selection
            this.scene.onPointerObservable.remove(this.pointerDown);
            this.scene.onPointerObservable.remove(this.pointerMove);
            this.scene.onPointerObservable.remove(this.pointerUp);
            this.scene.onPointerObservable.remove(this.clearSelectionsKey);



        }
    }

    private updateVisibility=(isVisible: boolean)=>{
        this.lines.isVisible = isVisible;
        this.markers.forEach(marker => marker.isVisible = isVisible);
    }

    public getSelectedMeshes=(): Mesh[]=> {
        return this.selectedMeshes;
    }

 
}

export default MeshSelector;


