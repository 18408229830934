//particleAddresses may not always be consecutive, 
export default (particleAddresses: number[]): Array<{start: number, end: number}>=> {
        if (particleAddresses.length === 0) {
            return [];
        }
        
        // Sort the addresses
        const sortedAddresses = particleAddresses.sort((a, b) => a - b);
        
        // Initialize the ranges array with the first range
        const ranges = [{ start: sortedAddresses[0], end: sortedAddresses[0] }];
    
        for(let i = 1; i < sortedAddresses.length; i++) {
            const currentAddress = sortedAddresses[i];
            const lastRange = ranges[ranges.length - 1];
    
            // If the current address is consecutive to the last range, extend the last range
            if(currentAddress === lastRange.end + 1) {
                lastRange.end = currentAddress;
            } 
            // If not, start a new range
            else {
                ranges.push({ start: currentAddress, end: currentAddress });
            }
        }
    
        return ranges;
}

// function testGroupConsecutives() {

//     // Test case 1: Consecutive numbers
//     let input = [1, 2, 3, 4, 5];
//     let expectedOutput = [{ start: 1, end: 5 }];
//     console.log(JSON.stringify(getConsecutiveRanges(input)) === JSON.stringify(expectedOutput) ? 'Pass' : 'Fail');

//     // Test case 2: Non-consecutive numbers
//     input = [1, 3, 5, 7, 9];
//     expectedOutput = [{ start: 1, end: 1 }, { start: 3, end: 3 }, { start: 5, end: 5 }, { start: 7, end: 7 }, { start: 9, end: 9 }];
//     console.log(JSON.stringify(getConsecutiveRanges(input)) === JSON.stringify(expectedOutput) ? 'Pass' : 'Fail');

//     // Test case 3: Mixed case
//     input = [1, 2, 3, 5, 6, 8, 9, 10];
//     expectedOutput = [{ start: 1, end: 3 }, { start: 5, end: 6 }, { start: 8, end: 10 }];
//     console.log(JSON.stringify(getConsecutiveRanges(input)) === JSON.stringify(expectedOutput) ? 'Pass' : 'Fail');

//     // Test case 4: Single element
//     input = [1];
//     expectedOutput = [{ start: 1, end: 1 }];
//     console.log(JSON.stringify(getConsecutiveRanges(input)) === JSON.stringify(expectedOutput) ? 'Pass' : 'Fail');

//     // Test case 5: Empty array
//     input = [];
//     expectedOutput = [];
//     console.log(JSON.stringify(getConsecutiveRanges(input)) === JSON.stringify(expectedOutput) ? 'Pass' : 'Fail');
// }

// testGroupConsecutives();
