import { Vector3, MeshBuilder, StandardMaterial, Scene, Color3, LinesMesh } from "@babylonjs/core";
import calculateDistanceBetweenPoints from "../helpers/calculateDistanceBetweenPoints";
import isNumberInInterval from "../helpers/isNumberInInterval";
import isObjEmpty from "../helpers/isObjEmpty";
import Direction from "./Direction";
import GridCell from "./GridCell";
import SpatialAction from "./SpatialAction";
import { PossibleObject, Subspace } from "../types";



class SpatialPartioner {
    skullSize: number[];
    origin: number[];
    scene: Scene;

    scale: number;

    subspaces: {
        [key: string]: Subspace;
    } = {};

    //Space Partioner works only for boxes!
    constructor(scene: Scene, origin: number[], dimensionsOfSpace: number[], depth: number, scale: number) {
        // console.log("Origin: ", origin);
        this.origin = origin;
        this.scene = scene;
        this.scale = scale;
        //TODO Had to make this slightly bigger, because neurons on the
        //border of the square have receptors outside the skull.
        //This allows for no receptors to be outside the skull.
        this.skullSize = dimensionsOfSpace.map((dimension) => dimension);
        // console.log("Scene: ", scene);
        // console.log("Skull Size: ", this.skullSize);

        // this.renderSkull(this.skullSize, this.origin);
        let boxes = this.partitionSpace(depth);
        //Adding boxes and their origins to the scene
        let boxCount = 0;
        if (boxes) {
            for(boxCount; boxCount<boxes.length; boxCount+=1){
                const box = boxes[boxCount];
                // this.addBoxToScene(box);
                // this.addOriginMesh(box.originOfBox);
                // this.drawMinMaxLinesForEachBox(box);
            }
            this.subspaces = this.convertArrayToObject(boxes);
        }
        // console.log("Subspace Count: ", boxCount);
        // console.log("Box dimensions: ", boxes[0].dimensionsOfBox);
        // const  maxAndMins = Object.values(this.subspaces).map((subspace: Subspace)=>{
        //     return subspace.dimensionalMaxAndMin
        // });
        // const largestX = maxAndMins.sort((a: Subspace["dimensionalMaxAndMin"], b: Subspace["dimensionalMaxAndMin"])=>{
        //     return b.x.max-a.x.max;
        // })[0]
        // const largestZ = maxAndMins.sort((a: Subspace["dimensionalMaxAndMin"], b: Subspace["dimensionalMaxAndMin"])=>{
        //     return b.z.max-a.z.max;
        // })[0]
        // const smallestX = maxAndMins.sort((a: Subspace["dimensionalMaxAndMin"], b: Subspace["dimensionalMaxAndMin"])=>{
        //     return a.x.min-b.x.min
        // })[0]
        // const smallestZ = maxAndMins.sort((a: Subspace["dimensionalMaxAndMin"], b: Subspace["dimensionalMaxAndMin"])=>{
        //     return a.z.min-b.z.min
        // })[0]
        // console.log("maxAndMins: ", maxAndMins);
        // console.log("largestX: ", largestX);
        // console.log("smallestX: ", smallestX);
        // console.log("largestZ: ", largestZ);
        // console.log("smallestZ: ", smallestZ);

        // console.log("Subspaces: ", this.subspaces);
        this.getAndStoreNeighborsOfEachSubspace();
    }

    expandSpace=()=>{

    }
    partitionSpace=(depth: number)=>{
        let boxes = [];
        for (let i = 1; i <= depth; i += 1) {
            //Initialize
            if (i === 1) boxes = this.splitBoxIntoFour(this.origin, this.skullSize);
            else {
                let newBoxes: any[] = [];
                Object.values(boxes).forEach((box) => {
                    newBoxes = newBoxes.concat(
                        this.splitBoxIntoFour(box.originOfBox, box.dimensionsOfBox)
                    );
                });
                boxes = newBoxes;
            }
        }
        return boxes;
    }

    splitBoxIntoFour=(originOfBox: any[], dimensionsOfBox: any[])=>{
        let boxes: any[] = [];
        // console.log("originOfBox: ", originOfBox);
        // console.log("DimensionsOfBox: ", dimensionsOfBox);
        let newBoxDimensions = dimensionsOfBox.map((dimension, index)=>{
            if(index === 1) return dimension;
            return dimension/2;
        })
        const instructions = [
            ['difference', 'sum', 'difference'], //Top Left - Back
            // ['difference', 'difference', 'difference'], //Bottom Left - Back
            ['sum', 'sum', 'difference'], //Top Right - Back,
            // ['sum', 'difference', 'difference'], //Bottom Right - Back,

            ['difference', 'sum', 'sum'], //Top Left - Front
            // ['difference', 'difference', 'sum'], //Bottom Left - Front
            ['sum', 'sum', 'sum'], //Top Right - Front,
            // ['sum', 'difference', 'sum'], //Bottom Right - Front,
        ];
        Object.values(instructions).forEach((instruction) => {
            let boxPosition = this.calculateBoxPosition(instruction, originOfBox, dimensionsOfBox);
            const minMax = this.calculateDimensionalMaxAndMin(boxPosition, newBoxDimensions);
            boxes.push({
                originOfBox: boxPosition,
                dimensionsOfBox: newBoxDimensions,
                dimensionalMaxAndMin: minMax,
                gridCells: {},
                causalEnergies: {},
                spatialActions: {},
                directions: {},
            });
        });

        return boxes;
    }

    getDistanceBetweenAnyTwoBoxOrigins=()=>{
        const [x1, y1, z1] = this.subspaces[0].originOfBox;
        const [x2, y2, z2] = this.subspaces[0].originOfBox;
        return calculateDistanceBetweenPoints(new Vector3(x1, y1, z1), new Vector3(x2, y2, z2));
    }

    getAndStoreNeighborsOfEachSubspace=()=>{
        Object.values(this.subspaces).forEach((space: any) => {
            this.subspaces[space.id]['neighbors'] = this.getNeighborsOfThisSubspace(space.id);
        });
    }

    getNeighborsOfThisSubspace=(subspaceId: number)=>{
        const subspace = this.subspaces[subspaceId];
        const [x, y, z] = subspace.originOfBox;
        const [dX, dY, dZ] = subspace.dimensionsOfBox;
        const neighbors = Object.values(this.subspaces).filter((space: any) => {
            const [sX, sY, sZ] = space.originOfBox;
            const isWithinXBounds = (sX <= x + dX) && (sX >= x - dX);
            const isWithinYBounds = (sY <= y + dY) && (sY >= y - dY);
            const isWithinZBounds = (sZ <= z + dZ) && (sZ >= z - dZ);
            const isInBounds = isWithinXBounds && isWithinYBounds && isWithinZBounds;
            const isNotMe = space.id !== subspace.id;
            if (isInBounds && isNotMe) return space;
        });
        // console.log("Neighbors: ", neighbors);
        if (neighbors.length > 27) console.log("Neighbors greater than 27: ", subspace);
        return neighbors;
    }

    convertArrayToObject=(boxes: any[])=>{
        let obj: any = {};
        for (let i = 0; i < boxes.length; i += 1) {
            boxes[i]['id'] = i;
            obj[i] = boxes[i];
        }

        return obj;
    }

    //TODO BABYLON TO CREATE BOX
    addOriginMesh=(position: number[])=>{
        const originMesh = MeshBuilder.CreateBox("originBox", { size: .05 }, this.scene);
        originMesh.isPickable = false;
        const originMat = new StandardMaterial("originMat", this.scene);
        originMesh.material = originMat;
        originMesh.position = new Vector3(...position);
    }

    addBoxToScene=(box: any)=>{
        const boxMesh = MeshBuilder.CreateBox("box", { width: box.dimensionsOfBox[0], height: box.dimensionsOfBox[1], depth: box.dimensionsOfBox[2] }, this.scene);
        const boxMaterial = new StandardMaterial("boxMaterial", this.scene);
        boxMesh.isPickable = false;
        boxMaterial.wireframe = true;
        boxMesh.material = boxMaterial;
        boxMesh.position = new Vector3(...box.originOfBox);
        // boxMesh.visibility = .30;
        // boxMesh.showBoundingBox = true;

        // const boxMesh = MeshBuilder.CreateBox(`${Math.random()}`, { size: .5 }, this.scene);
        // const boxMaterial = new StandardMaterial("boxMaterial", this.scene);
        // boxMaterial.wireframe = true;
        // boxMesh.material = boxMaterial;
        // console.log("Box: ", box);
        // const [x,y,z] = box.orginOfBox;
        // boxMesh.position = new Vector3(x,y,z);
        // console.log("Box Mesh: ", boxMesh.position);
        // const boxMesh = MeshBuilder.CreateBox("box", { width: box.dimensionsOfBox[0], height: box.dimensionsOfBox[1], depth: box.dimensionsOfBox[2] }, this.scene);
        // boxMesh.material = boxMaterial;
    }



    calculateBoxPosition=(directions: string[], originOfBox: number[], dimensionsOfBox: number[])=>{
        // console.log("Calculating box position");
        // console.log("Directions: ", directions);
        // console.log("originOfBox: ", originOfBox);
        // console.log("DimensionsOfBox: ", dimensionsOfBox);
        let newPosition = [];
        for (let i = 0; i <= 3; i += 1) {
            // if(i===1) continue;
            if (directions[i] === 'difference') {
                // console.log("originOfBox[i]: ", originOfBox[i])
                // console.log("dimensionsOfBox[i]: ", dimensionsOfBox[i])
                // console.log("originOfBox[i] - dimensionsOfBox[i] / 4: ", originOfBox[i] - dimensionsOfBox[i] / 4)
                newPosition[i] = originOfBox[i] - dimensionsOfBox[i] / 4;
            } else if (directions[i] === 'sum') {
                // console.log("originOfBox[i]: ", originOfBox[i])
                // console.log("dimensionsOfBox[i]: ", dimensionsOfBox[i])
                // console.log("originOfBox[i] + dimensionsOfBox[i] / 4: ", originOfBox[i] + dimensionsOfBox[i] / 4)
                newPosition[i] = originOfBox[i] + dimensionsOfBox[i] / 4;
            }
        }
        return newPosition;
    }

    getIdOfSubspacePointIsIn=(point: number[])=>{
        //TODO: weird I have to do this scale conversion thing
        // point = point.map((val)=>val*this.scale);
        for (let subspace of Object.values(this.subspaces)) {
            // console.log("Subspace being checked: ", subspace);
            if (!subspace.dimensionalMaxAndMin) {
                console.error("dimensionalMaxAndMin is undefined for subspace: ", subspace);
                continue; // Skip this subspace if dimensionalMaxAndMin is undefined
            }
            let result = this.checkIfPointIsInBox(point, subspace.dimensionalMaxAndMin);
            if (result) return subspace.id;
        }
        return undefined; // Explicitly return undefined if no subspace is found
    }

    isPointInSubspace=(position: Vector3)=>{
        const result = this.getIdOfSubspacePointIsIn([position.x, position.y, position.z]);
        if (!result) return false;
        return true;
    }
    

    storeObjectinSubspace=(object: PossibleObject)=>{
        //TODO: Broken when recovering saved spatialAction
        const {x,y,z} = object.getPosition()
        // console.log("Position: ", [x,y,z]);
        // const scaledPosition = [x*this.scale,y*this.scale,z*this.scale];
        // console.log("Scaled Position: ", scaledPosition);
        let subspaceId = this.getIdOfSubspacePointIsIn([x,y,z]);
        if (subspaceId !== 0 && !subspaceId){
            console.log("Object: ", object);
            throw new Error(`No subspace found: ${subspaceId}`);
        }
        const isGridCell = object instanceof GridCell;
        const isDirection = object instanceof Direction;
        const isSpatialAction = object instanceof SpatialAction;

        // console.log("subspaceId: ", subspaceId);
        const gridCells = this.subspaces[subspaceId]['gridCells'];
        if (isObjEmpty(gridCells) && isGridCell) this.subspaces[subspaceId]['gridCells'] = {};

        const directions = this.subspaces[subspaceId]['directions'];
        if (isObjEmpty(directions) && isDirection) this.subspaces[subspaceId]['directions'] = {};

        const spatialActions = this.subspaces[subspaceId]['spatialActions'];
        if (isObjEmpty(spatialActions) && isSpatialAction) this.subspaces[subspaceId]['spatialActions'] = {};

        if (isGridCell) this.subspaces[subspaceId]['gridCells'][object.id] = object;
        if (isDirection) this.subspaces[subspaceId]['directions'][object.id] = object;
        if (isSpatialAction) this.subspaces[subspaceId]['spatialActions'][object.id] = object;
        object.setSubspaceId(subspaceId);
        // console.log(`Added ${object.id} to subspace ${subspaceId}`);
        return subspaceId;
    }

    getObjectType=(object: PossibleObject)=>{
        if(object instanceof GridCell) return 'gridCells'
        if(object instanceof Direction) return 'directions'
        if(object instanceof SpatialAction) return 'spatialActions'
    }

    removeObjectFromSubspace=(object: PossibleObject)=>{
        let subspaceId = object.subspaceId;
        const objectType = this.getObjectType(object);
        // console.log("Subspaces: ", this.subspaces);
        // console.log("Subspaceid: ", subspaceId);
        // console.log("Subspaceid content: ", this.subspaces[subspaceId]);
        delete this.subspaces[subspaceId][objectType][object.id];
    }

    drawLine=(points: any, box: any)=>{
        const lineMesh = MeshBuilder.CreateLines("lines", { points: points.map(p => new Vector3(p.x, p.y, p.z)) }, this.scene);
        lineMesh.color = new Color3(0.87, 0.97, 0.09);
    }

    getMinOrMaxCoord=(type: 'min' | 'max', coord: any, origin: number[])=>{
        if (coord.key === 'x') {
            return { x: coord.value[type], y: origin[1], z: origin[2] };
        }
        if (coord.key === 'y') {
            return { x: origin[0], y: coord.value[type], z: origin[2] };
        }
        if (coord.key === 'z') {
            return { x: origin[0], y: origin[1], z: coord.value[type] };
        }
    }

    drawMinMaxLinesForEachBox=(box: any)=>{
        //@ts-ignore
        Object.entries(box.dimensionalMaxAndMin).forEach(([key, value]) => {
            let origin = { x: box.originOfBox[0], y: box.originOfBox[1], z: box.originOfBox[2] };
            let minPoints = [
                origin,
                this.getMinOrMaxCoord('min', { key, value }, box.originOfBox)
            ];
            let maxPoints = [
                origin,
                this.getMinOrMaxCoord('max', { key, value }, box.originOfBox)
            ];
            this.drawLine(minPoints, box);
            this.drawLine(maxPoints, box);
        });
    }

    calculateAbsoluteMaxAndMin=()=>{
        
    }
    checkIfPointIsInBox=(point: number[], dimensionalMaxAndMin: Subspace["dimensionalMaxAndMin"])=>{
        //xmin<=x<=xmax && ymin<=y<=ymax && zmin<=z<=zmax
        const {x,y,z} = dimensionalMaxAndMin;
        // const position = subspace.originOfBox;
        // console.log("Point: ", point);
        // console.log("Min Range: ", [x.min,y.min,z.min])
        // console.log("Max Range: ", [x.max,y.max,z.max])
        let isInXRange = isNumberInInterval(x.min, x.max, point[0]);
        // let isInYRange = isNumberInInterval(y.min, y.max, point[1]);
        let isInZRange = isNumberInInterval(z.min, z.max, point[2]);
        return isInXRange && isInZRange;
    }

    calculateDimensionalMaxAndMin=(position: number[], dimensions: number[])=>{
        // console.log("Dimensions: ", dimensions);
        // console.log("Position: ", position);
        return {
            x: {
                min: position[0] - dimensions[0] / 2,
                max: position[0] + dimensions[0] / 2
            },
            y: {
                min: position[1] - dimensions[1] / 2,
                max: position[1] + dimensions[1] / 2
            },
            z: {
                min: position[2] - dimensions[2] / 2,
                max: position[2] + dimensions[2] / 2
            },
        };
    }

    renderSkull=(skullSize: number[], origin: number[])=>{
        const skullMesh = MeshBuilder.CreateBox("skull", { width: skullSize[0], height: skullSize[1], depth: skullSize[2] }, this.scene);
        const skullMaterial = new StandardMaterial("skullMaterial", this.scene);
        skullMaterial.wireframe = true;
        skullMesh.material = skullMaterial;
        skullMesh.position = new Vector3(...origin);
    }
}

export default SpatialPartioner;
