const isPointerInsideRect = (x, y, rect) => {
    const rectInfo = rect._currentMeasure;
    return (
        x >= rectInfo.left &&
        x <= rectInfo.left + rectInfo.width &&
        y >= rectInfo.top &&
        y <= rectInfo.top + rectInfo.height*.45
    );
}

export default isPointerInsideRect