import { DynamicTexture, } from "@babylonjs/core";
import Tutorial from ".";
import { AdvancedDynamicTexture, Image, Button, Control, Rectangle, StackPanel } from "@babylonjs/gui";
import { TUTORIAL_SCALE_FACTOR } from "../../constants";


class Visuals {
    parent: Tutorial;
    instructionRectangle: Rectangle;
    nextButton: Button;
    previousButton: Button;
    skipButton: Button;
    showButton: Button;
    advancedTexture: AdvancedDynamicTexture = null;
    instructionElement: HTMLElement;
    instructionImage: Image;

    constructor(parent: Tutorial) {
        this.parent = parent;
        this.advancedTexture = this.parent.advancedTexture;
    }

    createInstructionImage = async () => {
        const instructionElement = this.createInstructionElement();
        this.addInstructionElementToHtml();
        const texture = await this.convertHtmlToCanvas(instructionElement);
        const imageObj = this.parent.GOE.getBabylonObject('Image', Image);
        const img = new imageObj('instructionImg', null);
        this.instructionImage = img;
        
        img.source = texture;
        img.width = "100%";
        img.height = "100%";
        img.background = "rgba(0,0,0,0)";
    
    
        return img;
    }
    

    createInstructionRectangle=()=>{
        const control = this.parent.GOE.getBabylonObject('Control', Control);
        const rectangleObj = this.parent.GOE.getBabylonObject('Rectangle', Rectangle);
        const rectangle = new rectangleObj();
        rectangle.width = "85%";
        rectangle.height = "20%";
        // rectangle.cornerRadius = 20;
        rectangle.borderRadius = 20;
        rectangle.color = "white";
        rectangle.thickness = 1;
        rectangle.background = 'black'
        rectangle.verticalAlignment = control.VERTICAL_ALIGNMENT_TOP;
        rectangle.top = '20px';
        return rectangle
    }

    createInstructionUI=async (advancedTexture: AdvancedDynamicTexture)=>{

        const instructions = await this.createInstructionImage();
        if(!instructions) throw new Error("Couldn't make instruction image");
        this.instructionRectangle = this.createInstructionRectangle();
        
        this.instructionRectangle.addControl(instructions);
        advancedTexture.addControl(this.instructionRectangle);
        this.createButtons(advancedTexture);

    }

    convertHtmlToCanvas = async (element): Promise<string> => {
        return new Promise((resolve, reject) => {
            const checkHtml2Canvas = () => {
                //@ts-ignore
                if (typeof html2canvas !== 'undefined') {
                    // Ensure MathJax is typeset
                    //@ts-ignore
                    MathJax.typesetPromise([element]).then(() => {
                        // Convert DOM element to image
                        //@ts-ignore
                        html2canvas(element, {
                            backgroundColor: 'rgba(0,0,0,.8)',
                            width: window.innerWidth * 0.8 * TUTORIAL_SCALE_FACTOR,
                            height: window.innerHeight * 0.2 * TUTORIAL_SCALE_FACTOR,
                            scale: TUTORIAL_SCALE_FACTOR, // Apply the scale factor
                            removeContainer: true, // Remove the temporary canvas container after rendering
                        }).then(canvas => {
                            // Create an image from the canvas
                            const img = new window.Image();
                            img.src = canvas.toDataURL();
                            img.onload = () => {
                                // Create a canvas and draw the image on it
                                const offscreenCanvas = document.createElement('canvas');
                                const context = offscreenCanvas.getContext('2d');
                                offscreenCanvas.width = img.width;
                                offscreenCanvas.height = img.height;
                                context.drawImage(img, 0, 0);
    
                                // Create DynamicTexture
                                const dt = new DynamicTexture('instructionDt', { width: offscreenCanvas.width, height: offscreenCanvas.height }, this.parent.GOE.tools.scene, false);
                                const dctx = dt.getContext();
                                const imageData = context.getImageData(0, 0, offscreenCanvas.width, offscreenCanvas.height);
                                dctx.putImageData(imageData, 0, 0);
                                dt.update(true);
    
                                // Return the URL of the canvas as the texture source
                                resolve(dt.getContext().canvas.toDataURL('image/png'));
                            };
                        }).catch((error) => {
                            console.error("Error: ", error);
                            reject("");
                        });
                    });
                } else {
                    setTimeout(checkHtml2Canvas, 100); // Retry after 100ms
                }
            };
            checkHtml2Canvas();
        });
    }
    
    // convertHtmlToCanvas = async (element):Promise<string> => {
    //     return new Promise((resolve, reject) => {
    //         // @ts-ignore
    //         MathJax.typesetPromise([element]);
    //         //@ts-ignore
    //         html2canvas(element, {
    //             backgroundColor: 'rgba(0,0,0,.8)',
    //             width: window.innerWidth * 0.8 * TUTORIAL_SCALE_FACTOR,
    //             height: window.innerHeight * 0.2 * TUTORIAL_SCALE_FACTOR,
    //             scale: TUTORIAL_SCALE_FACTOR, // Apply the scale factor
    //             removeContainer: true, // Remove the temporary canvas container after rendering
    //         }).then(canvas => {
    //             const {width, height} = canvas;
    //             this.removeInstructionElementFromHtml();
    //             var _ctx = canvas.getContext('2d');
    //             var dt = new DynamicTexture('instructionDt', canvas, this.parent.GOE.tools.scene, false, 1);
    //             var dctx = dt.getContext();
    //             var _iDat = _ctx.getImageData(0, 0, width, height);
    //             dctx.putImageData(_iDat, 0, 0);
    //             dt.update(true);
    //             // Return the URL of the canvas as the texture source
    //             resolve(dt.getContext().canvas.toDataURL('image/png'));
    //         }).catch((error) => {
    //             console.log("Error: ", error);
    //             reject("");
    //         })
    //     });
    // }

    updateInstructions = async (content: string) => {
        // this.parent.disableStepButtons('instructions');
        // Update the innerHTML of the instruction element
        this.instructionElement.innerHTML = content;
        this.addInstructionElementToHtml();
        // Re-render the canvas and update the image source
        const textureUrl = await this.convertHtmlToCanvas(this.instructionElement);
        if(!textureUrl){
            // if(!this.parent.blockInstructions)
            // this.parent.enableStepButtons();
            return console.warn("Error updating instructions");
        }
        this.instructionImage.source = textureUrl;
        // if(!this.parent.blockInstructions)
        // this.parent.enableStepButtons('instructions');
    }

    createInstructionElement = () => {
        if (this.instructionElement) return this.instructionElement;
        // Create an HTML element for the instructions
        const instructionElement = document.createElement("div");
        this.instructionElement = instructionElement;

        instructionElement.innerHTML = "Loading Tutorial...";
        instructionElement.className = "instruction";
        instructionElement.style.width = `${80 * TUTORIAL_SCALE_FACTOR}vw`,
        instructionElement.style.height = `${20 * TUTORIAL_SCALE_FACTOR}vh`,
        instructionElement.style.border = 'solid white 3px';
        instructionElement.style.color = "white";
        instructionElement.style.backgroundColor = "rgba(0, 0, 0, .8)";
        instructionElement.style.fontSize = `${1.5 * TUTORIAL_SCALE_FACTOR}em`;
        // instructionElement.style.padding = "10px";
        instructionElement.style.textAlign = "center";
        instructionElement.style.display = "flex";
        instructionElement.style.position = 'absolute';
        // instructionElement.style.flexDirection = "column";
        instructionElement.style.flex = "1";
        instructionElement.style.alignSelf = "center";
        instructionElement.style.alignContent = "center";
        instructionElement.style.alignItems = "center";
        instructionElement.style.justifyContent = "center";
        instructionElement.style.whiteSpace = "normal";
        instructionElement.style.left = '-9999px'

        // const canvasContainer = document.getElementById("canvas-container");
        // const parent = canvasContainer ? canvasContainer : document.body;
        // parent.appendChild(this.instructionElement);
        return instructionElement;
    }

    removeInstructionElementFromHtml=()=>{
        const canvasContainer = document.getElementById("canvas-container");
        const parent = canvasContainer ? canvasContainer : document.body;
        parent.removeChild(this.instructionElement);
    }
    addInstructionElementToHtml=()=>{
        const canvasContainer = document.getElementById("canvas-container");
        const parent = canvasContainer ? canvasContainer : document.body;
        parent.appendChild(this.instructionElement);
    }

    createButtons =(advancedTexture: AdvancedDynamicTexture)=>{
        const control = this.parent.GOE.getBabylonObject('Control', Control);
        const stackPanelObj = this.parent.GOE.getBabylonObject('StackPanel', StackPanel);
        const button = this.parent.GOE.getBabylonObject('Button', Button);

        const buttonPanel = new stackPanelObj();
        buttonPanel.isVertical = false;
        buttonPanel.height = "40px";
        buttonPanel.width = "300px";
        buttonPanel.verticalAlignment = control.VERTICAL_ALIGNMENT_BOTTOM;
        buttonPanel.horizontalAlignment = control.HORIZONTAL_ALIGNMENT_CENTER;
        buttonPanel.top = "-20px";
        advancedTexture.addControl(buttonPanel);
        this.previousButton = button.CreateSimpleButton("previousButton", "Previous");
        this.previousButton.width = "150px";
        this.previousButton.height = "40px";
        this.previousButton.color = "white";
        this.previousButton.background = "red";
        this.previousButton.paddingRight = "15px";
        this.previousButton.onPointerUpObservable.add(this.parent.onPreviousButtonClick);
        buttonPanel.addControl(this.previousButton);

        this.nextButton = button.CreateSimpleButton("nextButton", "Next");
        this.nextButton.width = "150px";
        this.nextButton.height = "40px";
        this.nextButton.color = "white";
        this.nextButton.background = "green";
        this.nextButton.paddingLeft = "15px";
        this.nextButton.onPointerUpObservable.add(this.parent.onNextButtonClick);
        buttonPanel.addControl(this.nextButton);

        this.skipButton = button.CreateSimpleButton("skipButton", "Skip Tutorial");
        this.skipButton.width = "150px";
        this.skipButton.height = "40px";
        this.skipButton.color = "white";
        this.skipButton.background = "gray";
        this.skipButton.horizontalAlignment = control.HORIZONTAL_ALIGNMENT_LEFT;
        this.skipButton.verticalAlignment = control.VERTICAL_ALIGNMENT_BOTTOM;
        this.skipButton.paddingLeft = "15px";
        this.skipButton.paddingBottom = "15px";
        this.skipButton.onPointerUpObservable.add(this.parent.onSkipButtonClick);
        advancedTexture.addControl(this.skipButton);
    }


    disposeElements = () => {
        this.instructionRectangle.dispose();
        this.instructionElement.remove();
        this.nextButton.dispose();
        this.previousButton.dispose();
        this.skipButton.dispose();
    }

    modifyShowButton = () => {
        const control = this.parent.GOE.getBabylonObject('Control', Control);
        const button = this.parent.GOE.getBabylonObject('Button', Button);

        this.showButton = button.CreateSimpleButton("showButton", "Show Tutorial");
        this.showButton.width = "150px";
        this.showButton.height = "40px";
        this.showButton.color = "white";
        this.showButton.background = "blue";
        this.showButton.horizontalAlignment = control.HORIZONTAL_ALIGNMENT_LEFT;
        this.showButton.verticalAlignment = control.VERTICAL_ALIGNMENT_BOTTOM;
        this.showButton.paddingLeft = "15px";
        this.showButton.paddingBottom = "15px";
        this.showButton.onPointerUpObservable.add(this.parent.startTutorial);
        this.advancedTexture.addControl(this.showButton);
    }
}

export default Visuals;
